/*eslint-disable*/

const d3 = require('d3');

//export functions
export function getSVGString(svgNode) {
  svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
  function getCSSStyles(parentElement) {
    const selectorTextArr = [];
    function contains(str, arr) {
      return arr.indexOf(str) !== -1;
    }
    selectorTextArr.push(`#${parentElement.id}`);
    for (let c = 0; c < parentElement.classList.length; c++) if (!contains(`.${parentElement.classList[c]}`, selectorTextArr)) selectorTextArr.push(`.${parentElement.classList[c]}`);

    const nodes = parentElement.getElementsByTagName('*');
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id;
      if (!contains(`#${id}`, selectorTextArr)) selectorTextArr.push(`#${id}`);

      const classes = nodes[i].classList;
      for (let c = 0; c < classes.length; c++) if (!contains(`.${classes[c]}`, selectorTextArr)) selectorTextArr.push(`.${classes[c]}`);
    }

    let extractedCSSText = '';
    for (let i = 0; i < document.styleSheets.length; i++) {
      const s = document.styleSheets[i];
      try {
        // eslint-disable-next-line no-continue
        if (!s.cssRules) continue;
      } catch (e) {
        if (e.name !== 'SecurityError') throw e; // for Firefox
        // eslint-disable-next-line no-continue
        continue;
      }
      const cssRules = s.cssRules;
      for (let r = 0; r < cssRules.length; r++) {
        if (contains(cssRules[r].selectorText, selectorTextArr)) extractedCSSText += cssRules[r].cssText;
      }
    }

    return extractedCSSText;
  }
  function appendCSS(cssText, element) {
    const styleElement = document.createElement('style');
    styleElement.setAttribute('type', 'text/css');
    styleElement.innerHTML = cssText;
    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }
  const cssStyleText = getCSSStyles(svgNode);
  appendCSS(cssStyleText, svgNode);

  const serializer = new XMLSerializer();
  let svgString = serializer.serializeToString(svgNode);
  svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

  return svgString;
}

//dropdown functions
export function svgDropDown(options) {
    if (typeof options !== 'object' || options === null || !options.container) {
      console.error(new Error("Container not provided"));
      alert("Container not provided");
      return;
    }
    const defaultOptions = {
      width: 200,
      members: [],
      fontSize: 12,
      color: "#333",
      fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
      x: 0,
      y: 0,
      changeHandler: function() {}
    };

    options = { ...defaultOptions,
      ...options
    };

    options.optionHeight = options.fontSize * 1.5;
    options.height = options.fontSize * 1.5;
    options.padding = 4;
    options.hoverColor = "#0c56f5";
    options.hoverTextColor = "#fff";
    options.bgColor = "#fff";
    options.width = options.width - 4;

    const g = options.container
      .append("svg")
      .attr("x", options.x)
      .attr("y", options.y)
      .attr("shape-rendering", "crispEdges")
      .attr("id", "dropDown")
      .append("g")
      .attr("transform", "translate(1,1)")
      .attr("font-family", options.fontFamily);

    let selectedOption =
      options.members.length === 0 ? {
        label: "",
        value: ""
      } :
      options.members[0];

    /** Rendering Select Field */
    const selectField = g.append("g");

    // background
    selectField
      .append("rect")
      .attr("width", options.width)
      .attr("height", options.height)
      .attr("class", "select")
      .attr("fill", options.bgColor)
      .style("stroke", "#a0a0a0")
      .style("stroke-width", "1");

    // text
    const activeText = selectField
      .append("text")
      .text(selectedOption.label)
      .attr("x", options.padding)
      .attr("y", options.height / 2 + options.fontSize / 3)
      .attr("font-size", options.fontSize)
      .attr("fill", options.color);

    // arrow symbol at the end of the select box
    selectField
      .append("text")
      .text("▼")
      .attr("x", options.width - options.fontSize - options.padding)
      .attr("y", options.height / 2 + (options.fontSize - 2) / 3)
      .attr("font-size", options.fontSize - 2)
      .attr("fill", options.color);

    // transparent surface to capture actions
    selectField
      .append("rect")
      .attr("width", options.width)
      .attr("height", options.height)
      .style("fill", "transparent")
      .on("click", handleSelectClick);

    /** rendering options */
    const optionGroup = g
      .append("g")
      .attr("transform", `translate(0, ${options.height})`)
      .attr("opacity", 0); //.attr("display", "none"); Issue in IE/Firefox: Unable to calculate textLength when display is none.

    // Rendering options group
    const optionEnter = optionGroup
      .selectAll("g")
      .data(options.members)
      .enter()
      .append("g")
        .text(function (d) {return d; })
      .on("click", handleOptionClick);

    // Rendering background
    optionEnter
      .append("rect")
      .attr("width", options.width)
      .attr("height", options.optionHeight)
      .attr("y", function(d, i) {
        return i * options.optionHeight;
      })
      .attr("class", "option")
      .style("stroke", options.hoverColor)
      .style("stroke-dasharray", (d, i) => {
        let stroke = [
          0,
          options.width,
          options.optionHeight,
          options.width,
          options.optionHeight
        ];
        if (i === 0) {
          stroke = [
            options.width + options.optionHeight,
            options.width,
            options.optionHeight
          ];
        } else if (i === options.members.length - 1) {
          stroke = [0, options.width, options.optionHeight * 2 + options.width];
        }
        return stroke.join(" ");
      })
      .style("stroke-width", 1)
      .style("fill", options.bgColor);

    // Rendering option text
    optionEnter
      .append("text")
      .attr("x", options.padding)
      .attr("y", function(d, i) {
        return (
          i * options.optionHeight +
          options.optionHeight / 2 +
          options.fontSize / 3
        );
      })
      .text(function(d) {
        return d.label;
      })
      .attr("font-size", options.fontSize)
      .attr("fill", options.color)
      .each(wrap);

    // Rendering option surface to take care of events
    optionEnter
      .append("rect")
      .attr("width", options.width)
      .attr("height", options.optionHeight)
      .attr("y", function(d, i) {
        return i * options.optionHeight;
      })
      .style("fill", "transparent")
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);

    //once the textLength gets calculated, change opacity to 1 and display to none
    optionGroup.attr("display", "none").attr("opacity", 1);

    d3.select("body").on("click", function() {
      optionGroup.attr("display", "none");
    });

    // Utility Methods
    function handleMouseOver() {
      d3.select(event.target.parentNode)
        .select(".option")
        .style("fill", options.hoverColor);

      d3.select(event.target.parentNode)
        .select("text")
        .style("fill", options.hoverTextColor);
    }

    function handleMouseOut() {
      d3.select(event.target.parentNode)
        .select(".option")
        .style("fill", options.bgColor);

      d3.select(event.target.parentNode)
        .select("text")
        .style("fill", options.color);
    }


    function handleOptionClick(event) {
      var dictTarget = d3.select(Object.values(event.target.parentNode));
      var arrTarget = dicionaryToArray(dictTarget);
      event.stopPropagation();
      selectedOption = arrTarget[0][1][0][0][0];
      activeText.text(selectedOption.label).each(wrap);

      var newElement = options.members[selectedOption.value - 1];
      options.members.splice(selectedOption.value - 1, 1);
      options.members.unshift(newElement);

     for (var prop in options.members) {
      options.members[prop].value = parseInt(prop) + 1;
      }
      typeof options.changeHandler === 'function' && options.changeHandler.call(this,  selectedOption);
      optionGroup.attr("display", "none");
    }

    function handleSelectClick() {
      event.stopPropagation();
      const visibility = optionGroup.attr("display") === "block" ? "none" : "block";
      optionGroup.attr("display", visibility);
    }

    // wraps words
    function wrap() {
      const width = options.width;
      const padding = options.padding;
      const self = d3.select(this);
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      const textArr = text.split(/\s+/);
      let lastWord = "";
      while (textLength > width - 2 * padding && text.length > 0) {
        lastWord = textArr.pop();
        text = textArr.join(" ");
        self.text(text);
        textLength = self.node().getComputedTextLength();
      }
      self.text(text + " " + lastWord);

      // providing ellipsis to last word in the text
      if (lastWord) {
        textLength = self.node().getComputedTextLength();
        text = self.text();
        while (textLength > width - 2 * padding && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + "...");
          textLength = self.node().getComputedTextLength();
        }
      }
    }
  }


export function dicionaryToArray(dict){
    var arr = [];

    for (var key in dict) {
        if (dict.hasOwnProperty(key)){
            arr.push( [ key, dict[key] ] );
        }
    }
    return arr;
}

export function deleteCheckboxes(){
    var element = document.getElementsByTagName("divcheckboxes"), index;
    for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
    }
}



export function deleteAndCreateDivElements(){

    var element = document.getElementsByTagName("divTable"), index;
    for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
    }

    if (document.contains(document.getElementById("divID"))) {
             document.getElementById("divID").remove();
     }
     if (document.contains(document.getElementById("play-range"))) {
             document.getElementById("play-pause-button").remove();
             document.getElementById("play-range").remove();
          }
      const elements = document.getElementsByClassName("highcharts-data-table");
      while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
          }
      // Add div Element for population pyramid if not exists
        if (!document.contains(document.getElementById("divID"))) {
            var divElement = document.createElement("Div");
            divElement.id = "divID";
            document.getElementById("modalContainer").appendChild(divElement);
        }
}

export function deleteDivElements(){

    if (document.contains(document.getElementById("divID"))) {
     document.getElementById("divID").remove();
  }
  if (document.contains(document.getElementById("play-range"))){
         document.getElementById("play-pause-button").remove();
     document.getElementById("play-range").remove();
  }
  if (document.contains(document.getElementById("fitFunction"))) {
             document.getElementById("fitFunction").remove();
  }

  const elements = document.getElementsByClassName("highcharts-data-table");
  while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
  }
}

function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};


export function getInfoHover(svg, div, dictInfo, screenHeight){

    const actorGroups = ["Staatlicher Akteur","Rebellengruppen","politische Milizen","ethnische Milizen", "gewalt. Demonstranten",
  "Demonstranten", "Zivilisten", "sonstige Kräfte"]
    const fontSize = screenHeight*0.016;
    let GIDName = "Land";
    if(dictInfo['GID'] == "GID_0"){GIDName = "Land"}
  else if(dictInfo['GID'] == "GID_1"){GIDName = "Region"}

  const nameCut = 100;

  let eventType = dictInfo['events'];
  if(eventType != 'Alle'){
      eventType = truncate(eventType.join(", "), nameCut);
  }
  let actor1 = dictInfo['actor1'];
  if(actor1 != 'Alle'){
      if(!actor1.some(i => !Number.isInteger(i))){
          const actor1i = [];
          for (const actor in actor1){
              actor1i.push(actorGroups[actor])
          }
          actor1 = truncate(actor1i.join(", "), nameCut);
      }
      else {
          actor1 = truncate(actor1.join(", "), nameCut);
      }
  }
  let actor2 = dictInfo['actor2'];
  if(actor2 != 'Alle'){
      if(!actor2.some(i => !Number.isInteger(i))){
          const actor2i = [];
          for (const actor in actor2){
              actor2i.push(actorGroups[actor])
          }
          actor2 = truncate(actor2i.join(", "), nameCut);
      }
      else {
          actor2 = truncate(actor2.join(", "), nameCut);
      }
  }

  const infoText1 = `Zeitraum:  ${dictInfo['startDate']} - ${dictInfo['endDate']} | ${GIDName}: ${dictInfo['countryName']}`;
  const infoText2 = `Ereignistyp: ${eventType}`;
  const infoText3 = `Akteur 1: ${actor1}`;
  const infoText4 = `Akteur 2: ${actor2}`;
  const infoText5 = `Suche: ${dictInfo['search']}
        | Konflikttote: [${dictInfo['death']}]`;


  const imageLink = './info.png';

  if (!document.contains(document.getElementById("infoImage"))) {
      svg.append("svg:image")
          .attr("id", 'infoImage')
          .attr('x', screenHeight*0.01)
          .attr('y', screenHeight*0.01)
          .attr('width', screenHeight*0.035)
          .attr('height', screenHeight*0.035)
          .attr("xlink:href", imageLink)
          .on("mouseover", function (d) {
           //  div.transition().duration(200)
                 // .style("opacity", .85);
              svg.append('rect').style("opacity", 0.85)
                .attr("id", 'infoBox')
                .attr('height', screenHeight*0.4)
                .attr('width', screenHeight)
                .attr('x', screenHeight*0.001)
                .attr('y', screenHeight*0.045)
                .style('fill', 'white');

              svg.append('text')
                  .attr("id", 'info1')
                  .text(infoText1)
                  .style('font-size', fontSize)
                  .style("fill", "black")
                  .attr('x', screenHeight*0.002)
                  .attr('y', screenHeight*0.07)
                  .attr('text-anchor', 'left');
              svg.append('text')
                  .attr("id", 'info2')
                  .text(infoText2)
                  .style('font-size', fontSize)
                  .style("fill", "black")
                  .attr('x', screenHeight*0.002)
                  .attr('y', screenHeight*0.09)
                  .attr('text-anchor', 'left');
              svg.append('text')
                  .attr("id", 'info3')
                  .text(infoText3)
                  .style('font-size', fontSize)
                  .style("fill", "black")
                  .attr('x', screenHeight*0.002)
                  .attr('y', screenHeight*0.11)
                  .attr('text-anchor', 'left');
              svg.append('text')
                  .attr("id", 'info4')
                  .text(infoText4)
                  .style('font-size', fontSize)
                  .style("fill", "black")
                  .attr('x', screenHeight*0.002)
                  .attr('y', screenHeight*0.13)
                  .attr('text-anchor', 'left');
              svg.append('text')
                  .attr("id", 'info5')
                  .text(infoText5)
                  .style('font-size', fontSize)
                  .style("fill", "black")
                  .attr('x', screenHeight*0.002)
                  .attr('y', screenHeight*0.15)
                  .attr('text-anchor', 'left');
          })
          .on("mouseout", function () {
              return [document.getElementById("info1").remove(), document.getElementById("info2").remove(),
                   document.getElementById("info3").remove(),document.getElementById("info4").remove(),
                  document.getElementById("info5").remove(), document.getElementById("infoBox").remove()];
          })
  }

   return svg;
}


export function getPopupLegend(svg, screenWidth, screenHeight) {

  const customPalette = ['#e3dfb8', '#912323', '#0b610c', '#9bcc9c', '#37409e', '#a3a8cf', '#631596', '#d8b6de'];
  const actorGroups = ["Staatlicher Akteur","Rebellengruppen","politische Milizen","ethnische Milizen", "gewalt. Demonstranten",
  "Demonstranten", "Zivilisten", "sonstige Kräfte"]
  let fontSize = screenWidth*0.01;
  let distRect = screenWidth*0.15;

  const color = d3.scaleOrdinal()
    .domain(actorGroups)
    .range(customPalette);

  const size = fontSize;
  const iStart = 4;
  const iEnd = 8;

  svg.selectAll('fitFunction')
    .data(actorGroups)
    .enter()
    .append('rect')
    .attr('id', 'legend')
    .attr('x', (d, i) => {
      let xPos = i * (size + distRect);
      if (i >= iStart && i < iEnd) { xPos = (i - iStart) * (size + distRect); }
      return xPos;
    })
    .attr('y', (d, i) => {
      let yPos = screenHeight*0.12;
      if (i >= iStart && i < iEnd) { yPos = screenHeight*0.16; }
      return yPos;
    })
    .attr('width', size)
    .attr('height', size)
    .style('fill', (d) => color(d));

    svg.selectAll('fitFunction')
    .data(actorGroups)
    .enter()
    .append('text')
    .style('font-size', fontSize)
    .attr('id', 'legend')
    .attr('x', (d, i) => {
      let xPos = screenWidth*0.015 + i * (size + distRect);
      if (i >= iStart && i < iEnd) { xPos = screenWidth*0.015 + (i - iStart) * (size + distRect); }
      return xPos;
    })
    .attr('y', (d, i) => {
      let yPos = screenHeight*0.135;
      if (i >= iStart && i < iEnd) { yPos = screenHeight*0.175; }
      return yPos;
    })
    .text((d) => d)
    .attr('text-anchor', 'left')
    .style('alignment-baseline', 'middle');

  return svg;
}

<template>
  <div id="all">
    <div id="modalWindow" class="modal">
        <div id="modal-content" class="modal-content">
          <span id="close" class="close">&times;</span>
          <div id="modalContainer"></div>
        </div>
    </div>
    <div id="dbSelect">
    <!--
      <a-radio-group v-model="db" :options="dbOptions"  />
    -->
    </div>
    <div id="map_OS">
    </div>
    <div id="dummy">
    </div>
    <div id="map_freeze">
      <b-row>
        <b-col>
          <b-button id="freeze_button" :pressed.sync="updateAble"> Fixieren</b-button>
        </b-col>
        <b-col>
          <b-button id="screenshot" pressed="false" @click="screenshot">
            <b-icon icon="camera"  scale="1" ></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link :to="{ path: 'manual', name: 'Manual' }" target="_blank" > <b-button id="manual_button" pressed="false"><b-icon icon="question-octagon" scale="1" ></b-icon></b-button> </router-link>
        </b-col>
      </b-row>

    </div>
    <div id="filter_bar">
      <a-button id="filters_button" icon="minus" @click="handleFilterView"/>
      <div v-if="filtersVisible">
        <b-row>
          <b-col class="text-right">
            Date: {{ $store.state.filter.startDate }}-{{ $store.state.filter.endDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Ereignisse: {{ $store.state.filter.event }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Akteurstypen: {{ $store.state.filter.interaction }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Länder: {{ $store.state.filter.country }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Akteur 1: {{ $store.state.filter.actor1}}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Schlüsselwort Suche: {{ $store.state.filter.search }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Karten-Ausschnitt:
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Westlicher Längengrad:{{ $store.state.filter.bounds._southWest.lng }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Östlicher Längengrad:{{ $store.state.filter.bounds._northEast.lat }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Südlicher Breitengrad:{{ $store.state.filter.bounds._southWest.lng }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            Nördlicher Breitengrad:{{ $store.state.filter.bounds._northEast.lat }}
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="webgl-events-picker flex" v-if="toggleWebglPicker">
      <a-radio-group v-model:value="webglEventsStyle" button-style="solid" @change="changeWebglStyle">
        <a-radio-button value="scatter">Scatterplot</a-radio-button>
        <a-radio-button value="heat">Heatmap</a-radio-button>
      </a-radio-group>
    </div>
    <Legend />
    <div id="legend">
      <div id="markerLegendContainer">
        <b-button id="minus" variant="light" @click="handleLegendCluster">
          <b-icon font-scale="0.7" icon="arrows-angle-expand" aria-hidden="true" v-if="legendClosed"/>
          <b-icon font-scale="0.7" v-else icon="arrows-angle-contract" aria-hidden="true"/>
        </b-button>
        <div id="markerLegend"></div>
      </div>
      <div id="militaryLegendContainer">
        <b-button id="minusMilitary" variant="light" @click="handleLegendMilitaryCluster" aria-hidden="true" style="display: none;">
          <b-icon font-scale="0.7" icon="arrows-angle-expand" aria-hidden="true" v-if="legendMilitaryClosed"/>
          <b-icon font-scale="0.7" v-else icon="arrows-angle-contract" aria-hidden="true"/>
        </b-button>
        <div id="militaryLegend"></div>
      </div>
      <div id="airportLegendContainer">
        <b-button id="minusAirport" variant="light" @click="handleLegendAirportCluster" aria-hidden="true" style="display: none;">
          <b-icon font-scale="0.7" icon="arrows-angle-expand" aria-hidden="true" v-if="legendAirportClosed"/>
          <b-icon font-scale="0.7" v-else icon="arrows-angle-contract" aria-hidden="true"/>
        </b-button>
        <div id="airportLegend"></div>
      </div>
      <div id="institutionContainer">
        <b-button id="minusInstitution" variant="light" @click="handleLegendInstitutionCluster" aria-hidden="true" style="display: none;">
          <b-icon font-scale="0.7" icon="arrows-angle-expand" aria-hidden="true" v-if="legendInstitutionClosed"/>
          <b-icon font-scale="0.7" v-else icon="arrows-angle-contract" aria-hidden="true"/>
        </b-button>
        <div id="institutionLegend"></div>
      </div>
      <div id="polyLegendContainer">
        <b-button id="minusPoly" variant="light" @click="handleLegendPoly" style="display: none;">
          <b-icon font-scale="0.7" icon="arrows-angle-expand"  aria-hidden="true" v-if="legendPolyClosed"/>
          <b-icon font-scale="0.7" v-else icon="arrows-angle-contract" aria-hidden="true"/>
        </b-button>
        <!--<div id="polygonLegend"></div>
        <div id="sliderContainer"></div>-->
      </div>
    </div>
  </div>
</template>

<script src="https://d3js.org/d3.v4.min.js" charset="utf-8"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js"></script>
<script src="https://d3js.org/d3-scale-chromatic.v1.min.js"></script>
<script src="js/canvas-to-blob.min.js"></script>

<script>
import * as d3 from 'd3';
import L from 'leaflet';
import "leaflet.markercluster";
import axios from 'axios';
import 'vue2-datepicker/index.css';
import {initMarkerCluster, markerCluster} from "../assets/js/markerCluster";

// Leaflet x Deck.gl
import {LeafletLayer} from 'deck.gl-leaflet';
import {MapView} from '@deck.gl/core';
import {GeoJsonLayer, ScatterplotLayer, PolygonLayer} from '@deck.gl/layers';
import {HeatmapLayer} from '@deck.gl/aggregation-layers';

// New legend implementation
import {getLegendPolygon} from '../assets/js/legend.js'
let testData;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import {PruneCluster, PruneClusterForLeaflet} from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';
import worker from '../assets/js/worker';
import {removeLegendPolygon, removeLegendCluster, clusterLayer, wmsLayer, polyLayer,
  addLegendCluster, addLegendPolygon, time, updateTime} from '../assets/js/Map.Legend';
import getEthnienHistogram from '../assets/js/Popups/Map.Layers.Ethnien.Popup';
import getReligionHistogram from '../assets/js/Popups/Map.Layers.Religion.Popup';
import getActorsHistogram from '../assets/js/Popups/Map.Layers.Actors.Popup';
import getActorsMatrix from '../assets/js/Popups/Map.Layers.ActorsMatrix.Popup';
import getPopulationChart from "../assets/js/Popups/Map.Layers.Population.Popup";
import getPredictionChart from '../assets/js/Popups/Map.Layers.Prediction.Popup';
import getSourcesChart from '../assets/js/Popups/Map.Layers.Sources.Popup';
import setLayerControl from '../assets/js/Map.Layers.Control';
import prepareLeafletMarker from '../assets/js/Map.Markers';
import prepareScreenshot from '../assets/js/Map.Screenshot';
import getPopUpStart from '../assets/js/Popups/Start.Popup';
import getPopUpTrend from '../assets/js/Popups/Map.Layers.Trend.Popup';
import {styleCountryEth, styleCountryReli, styleCountryEve, styleRegionEve, styleCountryRef, styleCountryDensity, styleRegionDensity,
    styleCountryFat, styleRegionFat, styleCountryFatMoni, styleCountryEveMoni, styleRegionFatMoni, styleRegionEveMoni,
    styleCountryActors, styleRegionActors, styleCountryPre, styleCountryFop, getaltColor} from '../assets/js/Map.Layers.Customized';
import {onEachFeatureCountryEth, onEachFeatureCountryEve, onEachFeatureCountryRef, onEachFeatureCountryReli,
    onEachFeatureRegionEve, onEachFeatureCountryFat, onEachFeatureCountryFatMoni, onEachFeatureRegionFatMoni, onEachFeatureCountryEveMoni, onEachFeatureRegionEveMoni, onEachFeatureRegionFat,
  onEachFeatureCountryDensity, onEachFeatureRegionDensity, onEachFeatureCountryActors,
  onEachFeatureRegionActors, onEachFeatureCountryPre, onEachFeatureCountryFop} from '../assets/js/Map.Layers.Customized';
//import {setWMSLayerMinerals} from '../assets/js/Map.Layers.WMSLayers';
import {setRefugeeMovements, pathGroup} from '../assets/js/Map.Layers.Refugee.Movements';
import {ESRI_WORLD_GRAY_CANVAS, ESRI_WORLD_IMAGERY, ESRI_WORLD_STREET_MAP} from '../assets/js/Map.Layers.Basemaps';
import Legend from './Legend.vue'
import { url } from '../App.vue'
import moment from "moment/moment";
import { getLegendObject } from '../assets/js/legend.js'

export let map, viewBounds, refugeeLayer, geoJsonCountryFatMoni,geoJsonRegionFatMoni,geoJsonCountryEveMoni,geoJsonRegionEveMoni,geoJsonCountryActors,geoJsonRegionActors, geoJsonCountryFat,
    geoJsonCountryEve,geoJsonRegionFat,geoJsonRegionEve,geoJsonCountryPre,geoJsonCountryFop,geoJsonRegionDensity,geoJsonCountryDensity,
    geoJsonCountryEth,geoJsonCountryReli,geoJsonCountryRef, pruneCluster, maxColorCountryActors,
    maxColorRegionActors,maxColorCountryFat,maxColorCountryEve,maxColorRegionFat,maxColorRegionEve,maxColorCountryPre, maxColorGridPre,
    maxColorCountryFop,maxColorRegionDensity, maxColorCountryDensity,maxColorCountryEth, maxColorCountryReli,
    minColorCountryReli,maxColorMoni,minColorMoni,timeMigration, internationalAirports, mediumAirports, smallAirports,
    heliportAirports, seaplaneAirports, militaryAirports, militaryBunkers, militaryCheckpoints, militaryTraining, militaryBarracks,
    hospitals, embassies, bordercontrol, aluminium, oil, coal, liquified_natural_gas, liquified_petrolium_gas, natural_gas, diamond, phosphate,
    petroleum_products, uranium, plutonium, copper, berrylium, bismut, cadmium, chromium, cobalt, gallium, gold, indium,
    iron, lead, lithium, magnesium, manganese, nickel, niobium, palladium, silver, tantalum, tin, titanium, vanadium, zinc,
    arsenic, bromine, germanium, silicon, helium, nitrogen, sulfur, granite, limestone, molybdenum, gemstones, sand,steel, cement,
    strontium, thallium, deckLayer, deckLayerGrid;
let showPolygons,showLegendCluster,showLegendWMS,simpleScreenShoter,showStartPopup,dbOptions,averageDays,averageDaysCompare,averageStartDate,
    averageEndDate,layerControl, newestDatesDB, parameterInfo;
let oldViewBounds, actorsTimeline, dataActorsGroups, ethnData, reliData, popData, densityData, femaleData, maleData, popAgesData, lifeExpData, pop_GrowthData, preData,
    bin_Data, pre_Data, ev_Data,cur_Data,src_Data, cov_Data, url_Data, ttu_Data, sco_Data, ran_Data, sub_Data, sur_Data, str_Data,
    polyFitData,dataActors,matrixActors,actorsList, clickedPolygon = {};
let actors1,actors2,weight,colors1,colors2,size1,size2 = [];
const colors = ['#FFB300', '#803E75', '#FF6800', '#A6BDD7', '#C10020', '#CEA262', '#817066', '#007D34','#F6768E','#00538A','#FF7A5C','#53377A','#FF8E00','#B32851','#F4C800','#7F180D','#93AA00','#593315','#F13A13','#232C16'];
const colors_rgb = [[255,179,0],[128,62,117],[255,104,0],[166,189,215],[193,0,32],[206,162,98],[129,112,102],[0,125,52],[246,118,142],[0,83,138],[255,122,92],[83,55,122],[255,142,0],[179,40,81],[244,200,0],[127,24,13],[147,170,0],[89,51,21],[241,58,19],[35,44,22]]
//change with new layer
//let layerCounter = 0;
let noNumber = 83;
//change with new ACLED layer
let ACLEDCheck = [true, false, false, false, false, false, false, false, false, false, false, false] //12 Acled Layer || TODO: GET RID OF THIS; why can't we simply use a reactive var?
let airportCheck = [false, false, false, false, false];
let militaryCheck = [false, false, false, false, false];
let institutionCheck = [false, false, false];

const searchControl = new GeoSearchControl({
  position: 'bottomright',
  provider: new OpenStreetMapProvider(),
  showMarker: false,
  retainZoomLevel: true,
  autoClose: true,
  searchLabel: 'Land oder Adresse eingeben',
  keepResult: true,
});

function setBounds() {
  const southWest = L.latLng(-89.98155760646617, -180);
  const northEast = L.latLng(89.99346179538875, 180);
  const bounds = L.latLngBounds(southWest, northEast);
  map.setMaxBounds(bounds);
  return bounds;
}

function setMarkerLayer() {
  pruneCluster = new PruneClusterForLeaflet();
  prepareLeafletMarker(pruneCluster, map);
  map.addLayer(pruneCluster);
}

function setCostumizedLayer() {
    geoJsonCountryFatMoni = L.geoJSON(null, {
      style: styleCountryFatMoni,
      onEachFeature: onEachFeatureCountryFatMoni,
      'z-index': 2,
    }).bindTooltip((layer) => {
      const content = `Land: ${layer.feature.properties.ADMIN}<br>`
          + `${averageStartDate}  - ${averageEndDate}<br>`
          + `Konflikttote der letzten ${averageDays} Tage: ${layer.feature.properties.sum_fat}<br>`
          + `Delta zu den vorherigen ${averageDaysCompare} Tagen: ${layer.feature.properties.delta_fat}<br>`;
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      clickedPolygon.type = 'fat';
      clickedPolygon.averageDays = averageDays;
      clickedPolygon.averageDaysCompare = averageDaysCompare;
      clickedPolygon.averageStartDate = averageStartDate;
      clickedPolygon.averageEndDate = averageEndDate;
      clickedPolygon.averageSum = layer.feature.properties.sum_fat;
      clickedPolygon.averageDelta = layer.feature.properties.delta_fat;
      return content;
    }, {
      permanent: false,
      opacity: 0.7,
    });


    geoJsonCountryEveMoni = L.geoJSON(null, {
      style: styleCountryEveMoni,
      onEachFeature: onEachFeatureCountryEveMoni,
      'z-index': 2,
    }).bindTooltip((layer) => {
      const content = `Land: ${layer.feature.properties.ADMIN}<br>`
          + `${averageStartDate}  - ${averageEndDate}<br>`
          + `Ereignisse der letzten ${averageDays} Tage: ${layer.feature.properties.sum_eve}<br>`
          + `Delta zu den vorherigen ${averageDaysCompare} Tagen: ${layer.feature.properties.delta_eve}<br>`;
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      clickedPolygon.type = 'eve';
      clickedPolygon.averageDays = averageDays;
      clickedPolygon.averageDaysCompare = averageDaysCompare;
      clickedPolygon.averageStartDate = averageStartDate;
      clickedPolygon.averageEndDate = averageEndDate;
      clickedPolygon.averageSum = layer.feature.properties.sum_eve;
      clickedPolygon.averageDelta = layer.feature.properties.delta_eve;
      return content;
    }, {
      permanent: false,
      opacity: 0.7,
    });

    geoJsonRegionFatMoni = L.geoJSON(null, {
      style: styleRegionFatMoni,
      onEachFeature: onEachFeatureRegionFatMoni,
      'z-index': 2,
    }).bindTooltip((layer) => {
      const content = `Region: ${layer.feature.properties.ADMIN}<br>`
          + `${averageStartDate}  - ${averageEndDate}<br>`
          + `Konflikttote der letzten ${averageDays} Tage: ${layer.feature.properties.sum_fat}<br>`
          + `Delta zu den vorherigen ${averageDaysCompare} Tagen: ${layer.feature.properties.delta_fat}<br>`;
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_1";
      clickedPolygon.type = 'fat';
      clickedPolygon.averageDays = averageDays;
      clickedPolygon.averageDays = averageDays;
      clickedPolygon.averageDaysCompare = averageDaysCompare;
      clickedPolygon.averageStartDate = averageStartDate;
      clickedPolygon.averageEndDate = averageEndDate;
      clickedPolygon.averageSum = layer.feature.properties.sum_fat;
      clickedPolygon.averageDelta = layer.feature.properties.delta_fat;
      return content;
    }, {
      permanent: false,
      opacity: 0.7,
    });

  geoJsonRegionEveMoni = L.geoJSON(null, {
    style: styleRegionEveMoni,
    onEachFeature: onEachFeatureRegionEveMoni,
    'z-index': 2,
  }).bindTooltip((layer) => {
    const content = `Region: ${layer.feature.properties.ADMIN}<br>`
        + `${averageStartDate}  - ${averageEndDate}<br>`
        + `Ereignisse der letzten ${averageDays} Tage: ${layer.feature.properties.sum_eve}<br>`
        + `Delta zu den vorherigen ${averageDaysCompare} Tagen: ${layer.feature.properties.delta_eve}<br>`;
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_1";
      clickedPolygon.type = 'eve';
      clickedPolygon.averageDays = averageDays;
      clickedPolygon.averageDaysCompare = averageDaysCompare;
      clickedPolygon.averageStartDate = averageStartDate;
      clickedPolygon.averageEndDate = averageEndDate;
      clickedPolygon.averageSum = layer.feature.properties.sum_eve;
      clickedPolygon.averageDelta = layer.feature.properties.delta_eve;
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

 geoJsonCountryFat = L.geoJSON(null, {
      style: styleCountryFat,
      onEachFeature: onEachFeatureCountryFat,
      'z-index': 2,
    }).bindTooltip((layer) => {
      const content = `Land: ${layer.feature.properties.ADMIN}<br>`
          + `Konflikttote: ${layer.feature.properties.FATALITIES}`;
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      clickedPolygon.type = 'fat';
      return content;
    }, {
      permanent: false,
      opacity: 0.7,
    });

  geoJsonCountryEve = L.geoJSON(null, {
    style: styleCountryEve,
    onEachFeature: onEachFeatureCountryEve,
    'z-index': 3,
  }).bindTooltip((layer) => {
    const content = `Land: ${layer.feature.properties.ADMIN}<br>`
        + `Ereignisse: ${layer.feature.properties.Length}`;
    clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
    clickedPolygon.level = "GID_0";
    clickedPolygon.type = 'eve';
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

  geoJsonRegionFat = L.geoJSON(null, {
    style: styleRegionFat,
    onEachFeature: onEachFeatureRegionFat,
    'z-index': 4,
  }).bindTooltip((layer) => {
    const content = `Region: ${layer.feature.properties.ADMIN}<br>`
        + `Konflikttote: ${layer.feature.properties.FATALITIES}`;
    clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
    clickedPolygon.level = "GID_1";
    clickedPolygon.type = 'fat';
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

  geoJsonRegionEve = L.geoJSON(null, {
    style: styleRegionEve,
    onEachFeature: onEachFeatureRegionEve,
    'z-index': 5,
  }).bindTooltip((layer) => {
    //TANJA BUGFIX
    const content = `Region: ${layer.feature.properties.ADMIN}<br>`
        + `Ereignisse: ${layer.feature.properties.Length}`;
    clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
    clickedPolygon.level = "GID_1";
    clickedPolygon.type = 'eve';
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

    geoJsonCountryActors = L.geoJSON(null, {
      style: styleCountryActors,
      onEachFeature: onEachFeatureCountryActors,
      "z-index": 8
    }).bindTooltip(function (layer) {
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      var content = "Land: " + layer.feature.properties.ADMIN + "<br>" + "Anzahl der Akteure: " + layer.feature.properties.NumberOfActors;
      return content;
    }, {
      permanent: false,
      opacity: 0.7
    })

     geoJsonRegionActors = L.geoJSON(null, {
      style: styleRegionActors,
      onEachFeature: onEachFeatureRegionActors,
      "z-index": 8
    }).bindTooltip(function (layer) {
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_1";
      var content = "Land: " + layer.feature.properties.ADMIN + "<br>" + "Anzahl der Akteure: " + layer.feature.properties.NumberOfActors;
      return content;
    }, {
      permanent: false,
      opacity: 0.7
    })

    geoJsonCountryDensity = L.geoJSON(null, {
      style: styleCountryDensity,
      onEachFeature: onEachFeatureCountryDensity,
      "z-index": 8
    }).bindTooltip(function (layer) {
      if( typeof time === 'undefined' || time === null ){
        updateTime(0, 'countryPop');
      }
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      clickedPolygon.year = time;
      var content = "Land: " + layer.feature.properties.ADMIN + "<br>" + "Bevölkerungsdichte: " + layer.feature.properties[time] + " Einwohner/km^2 " + "<br>" + "Jahr: " + time;
      return content;
    }, {
      permanent: false,
      opacity: 0.7
    })

    geoJsonRegionDensity = L.geoJSON(null, {
      style: styleRegionDensity,
      onEachFeature: onEachFeatureRegionDensity,
      "z-index": 9
    }).bindTooltip(function (layer) {
      if( typeof time === 'undefined' || time === null ){
        updateTime(0, 'regionPop');
      }
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_1";
      clickedPolygon.year = time;
      var content = "Region: " + layer.feature.properties.ADMIN + "<br>" + "Bevölkerungsdichte: " + layer.feature.properties[time] + " Einwohner/km^2 " + "<br>" + "Jahr: " + time;
      return content;
    }, {
      permanent: false,
      opacity: 0.7
    })

    geoJsonCountryEth = L.geoJSON(null, {
    style: styleCountryEth,
    onEachFeature: onEachFeatureCountryEth,
    "z-index": 10
  }).bindTooltip((layer) => {
    const content = `Land: ${layer.feature.properties.NAME_0}` + "<br>" + "Anzahl der Ethnien: " + layer.feature.properties.actv_groups_count;
    clickedPolygon.ADMIN = layer.feature.properties.NAME_0;
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

    geoJsonCountryReli = L.geoJSON(null, {
    style: styleCountryReli,
    onEachFeature: onEachFeatureCountryReli,
    "z-index": 10
  }).bindTooltip((layer) => {
    const content = `Land: ${layer.feature.properties.NAME_0}` + "<br>" + "Anzahl der Religionen: " + layer.feature.properties.Religion;
    clickedPolygon.ADMIN = layer.feature.properties.NAME_0;
    return content;
  }, {
    permanent: false,
    opacity: 0.7,
  });

    geoJsonCountryPre = L.geoJSON(null, {
      style: styleCountryPre,
      onEachFeature: onEachFeatureCountryPre,
      "z-index": 8
    }).bindTooltip(function (layer) {
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      var content = "Land: " + layer.feature.properties.ADMIN + "<br>"
          + "Wahrscheinlichkeit Konflikteskalation: " + layer.feature.properties.PREDICTION + " % " + "<br>"
          + "Vorhergesagte Monate: " + layer.feature.properties.PREDICTED_MONTHS;
      return content;
    } , {
    permanent: false,
    opacity: 0.7
    })

    geoJsonCountryFop = L.geoJSON(null, {
      style: styleCountryFop,
      onEachFeature: onEachFeatureCountryFop,
      "z-index": 8
    }).bindTooltip(function (layer) {
      clickedPolygon.ADMIN = layer.feature.properties.ADMIN;
      clickedPolygon.level = "GID_0";
      var content = "Land: " + layer.feature.properties.ADMIN + "<br>" + "Press Freedom Index: " + layer.feature.properties.Gesamt;
      return content;
    } , {
    permanent: false,
    opacity: 0.7
    })

    geoJsonCountryRef = L.geoJSON(null, {
      style: styleCountryRef,
      onEachFeature: onEachFeatureCountryRef,
      "z-index": 15
    }).bindPopup(function (layer) {
      map = setRefugeeMovements(map, layer, '2013');
      //sumRefugee = getSumRefugee();
    })

  // Deck.gl Layer is defined here; let's see if this works just like the other layers
  // It doesn't, as LayerControl is not working with Leaflet Deck.gl Layers, as deckgl
  // layers cannot be reinitialized but need to be completely destroyed.
  // Solution: load an empty layer and destroy the attached layers whenever a layer is removed
  deckLayer = new LeafletLayer({
    views: [
      new MapView({
        repeat: false
      })
    ],
    layers: []
  });

  deckLayerGrid = new LeafletLayer({
    views: [
      new MapView({
        repeat: false
      })
    ],
    layers: [],
    getTooltip: ({ object }) => object && { html: Math.round(object.properties.prediction*100)/100 },
  });

   internationalAirports = initMarkerCluster(internationalAirports, "icons/airport_big_black.svg");
   mediumAirports = initMarkerCluster(mediumAirports, "icons/airport_medium_black.svg");
   smallAirports = initMarkerCluster(smallAirports, "icons/airport_small_black.svg");
   heliportAirports = initMarkerCluster(heliportAirports, "icons/heli_airport_black.svg");
   seaplaneAirports = initMarkerCluster(seaplaneAirports, "icons/waterplane_black.svg");
   militaryAirports = initMarkerCluster(militaryAirports, "icons/airport_black.svg");
   militaryBunkers = initMarkerCluster(militaryBunkers, "icons/bunker_black.svg");
   militaryCheckpoints = initMarkerCluster(militaryCheckpoints, "icons/checkpoint_black.svg");
   militaryTraining = initMarkerCluster(militaryTraining, "icons/training_black.svg");
   militaryBarracks = initMarkerCluster(militaryBarracks, "icons/barracks_black.svg");
   hospitals = initMarkerCluster(hospitals, "icons/hospital_black.svg");
   embassies = initMarkerCluster(embassies, "icons/embassy_black.svg");
   bordercontrol = initMarkerCluster(bordercontrol, "icons/border_control_black.svg");
   aluminium = initMarkerCluster(aluminium, "icons/rohstoffe/elemente/metalle/aluminium.svg");
   tin = initMarkerCluster(tin, "icons/rohstoffe/elemente/halbmetalle/tin.svg");
   berrylium = initMarkerCluster(berrylium, "icons/rohstoffe/elemente/metalle/berrylium.svg");
   bismut = initMarkerCluster(bismut, "icons/rohstoffe/elemente/metalle/bismut.svg");
   cadmium = initMarkerCluster(cadmium, "icons/rohstoffe/elemente/metalle/cadmium.svg");
   chromium = initMarkerCluster(chromium, "icons/rohstoffe/elemente/metalle/chromium.svg");
   cobalt = initMarkerCluster(cobalt, "icons/rohstoffe/elemente/metalle/cobalt.svg");
   copper = initMarkerCluster(copper, "icons/rohstoffe/elemente/metalle/copper.svg");
   gallium = initMarkerCluster(gallium, "icons/rohstoffe/elemente/metalle/gallium.svg");
   gold = initMarkerCluster(gold, "icons/rohstoffe/elemente/metalle/gold.svg");
   indium = initMarkerCluster(indium, "icons/rohstoffe/elemente/metalle/indium.svg");
   iron = initMarkerCluster(iron, "icons/rohstoffe/elemente/metalle/iron.svg");
   lead = initMarkerCluster(lead, "icons/rohstoffe/elemente/metalle/lead.svg");
   lithium = initMarkerCluster(lithium, "icons/rohstoffe/elemente/metalle/lithium.svg");
   magnesium = initMarkerCluster(magnesium, "icons/rohstoffe/elemente/metalle/magnesium.svg");
   molybdenum = initMarkerCluster(molybdenum, "icons/rohstoffe/elemente/metalle/molybdenum.svg");
   manganese = initMarkerCluster(manganese, "icons/rohstoffe/elemente/metalle/manganese.svg");
   nickel = initMarkerCluster(nickel, "icons/rohstoffe/elemente/metalle/nickel.svg");
   niobium = initMarkerCluster(niobium, "icons/rohstoffe/elemente/metalle/niobium.svg");
   palladium = initMarkerCluster(palladium, "icons/rohstoffe/elemente/metalle/palladium.svg");
   silver = initMarkerCluster(silver, "icons/rohstoffe/elemente/metalle/silver.svg");
   tantalum = initMarkerCluster(tantalum, "icons/rohstoffe/elemente/metalle/tantalum.svg");
   titanium = initMarkerCluster(titanium, "icons/rohstoffe/elemente/metalle/titanium.svg");
   vanadium = initMarkerCluster(vanadium, "icons/rohstoffe/elemente/metalle/vanadium.svg");
   strontium = initMarkerCluster(strontium, "icons/rohstoffe/elemente/metalle/strontium.svg")
   thallium = initMarkerCluster(thallium, "icons/rohstoffe/elemente/metalle/thallium.svg")
   zinc = initMarkerCluster(zinc, "icons/rohstoffe/elemente/metalle/zinc.svg");
   arsenic = initMarkerCluster(arsenic, "icons/rohstoffe/elemente/halbmetalle/arsenic.svg");
   bromine = initMarkerCluster(bromine, "icons/rohstoffe/elemente/halbmetalle/bromine.svg");
   germanium = initMarkerCluster(germanium, "icons/rohstoffe/elemente/halbmetalle/germanium.svg");
   silicon = initMarkerCluster(silicon, "icons/rohstoffe/elemente/halbmetalle/silicon.svg");
   helium = initMarkerCluster(helium, "icons/rohstoffe/elemente/nichtmetalle/helium.svg");
   sulfur = initMarkerCluster(sulfur, "icons/rohstoffe/elemente/nichtmetalle/sulfur.svg");
   nitrogen = initMarkerCluster(nitrogen, "icons/rohstoffe/elemente/nichtmetalle/nitrogen.svg");
   oil = initMarkerCluster(oil, "icons/rohstoffe/oil.svg");
   coal = initMarkerCluster(coal, "icons/rohstoffe/coal.svg");
   diamond = initMarkerCluster(diamond, "icons/rohstoffe/mineralien/diamond.svg");
   phosphate = initMarkerCluster(phosphate, "icons/rohstoffe/mineralien/phosphate.svg");
   gemstones = initMarkerCluster(gemstones, "icons/rohstoffe/mineralien/gemstone.svg")
   granite = initMarkerCluster(granite, "icons/rohstoffe/mineralien/granite.svg")
   limestone = initMarkerCluster(limestone, "icons/rohstoffe/mineralien/limestone.svg")
   sand = initMarkerCluster(sand, "icons/rohstoffe/mineralien/sand.svg")
   steel = initMarkerCluster(steel, "icons/rohstoffe/mineralien/steel.svg")
   cement = initMarkerCluster(cement, "icons/rohstoffe/mineralien/cement.svg")
   uranium = initMarkerCluster(uranium, "icons/rohstoffe/elemente/actionide/uranium.svg");
   plutonium = initMarkerCluster(plutonium, "icons/rohstoffe/elemente/actionide/plutonium.svg");
   liquified_natural_gas = initMarkerCluster(liquified_natural_gas, "icons/rohstoffe/liquid_natural_gas.svg");
   liquified_petrolium_gas = initMarkerCluster(liquified_petrolium_gas, "icons/rohstoffe/liquid_petrolium_gas.svg");
   natural_gas = initMarkerCluster(natural_gas, "icons/rohstoffe/natural_gas.svg");
   petroleum_products = initMarkerCluster(petroleum_products, "icons/rohstoffe/petroleum_products.svg");
}

const makeMap = async () =>  {
  if(showStartPopup){
    getPopUpStart();
  }

  const BASECOORDS = [40, 50];
  map = L.map(
      'map_OS', {
        preferCanvas: false,
      },
  ).setView(BASECOORDS, 4.5);
  ESRI_WORLD_GRAY_CANVAS.addTo(map);
  const BOUNDS = setBounds();
  map.on('drag', () => {
    map.panInsideBounds(BOUNDS, {
      animate: false,
    });
  });

  viewBounds = map.getBounds();

  setMarkerLayer();
  setCostumizedLayer();
  layerControl = setLayerControl(ESRI_WORLD_GRAY_CANVAS, ESRI_WORLD_IMAGERY, ESRI_WORLD_STREET_MAP,true, true).addTo(map);
  map.addControl(searchControl);
  map.zoomControl.setPosition('bottomright');
  simpleScreenShoter = new SimpleMapScreenshoter({hidden: true}).addTo(map);
}

function setParameterInfoVisible(){
  if(parameterInfo == true){document.getElementById('filter_bar').style.visibility="visible";}
  else if (parameterInfo == false){document.getElementById('filter_bar').style.visibility="hidden";}
}

function setACLEDFeaturesVisible(){
  document.getElementById('network').style.visibility="visible";
  document.getElementById('chart').style.visibility="visible";
  document.getElementById('table').style.visibility="visible";
  document.getElementById('filter').style.visibility="visible";
  document.getElementById('dbSelect').style.visibility="visible";
  document.getElementById('freeze_button').style.visibility="visible";
  document.getElementById('settings').style.visibility="visible";
  if(parameterInfo == true){document.getElementById('filter_bar').style.visibility="visible";}
}

function setACLEDFeaturesUnvisible(){
  document.getElementById('network').style.visibility="hidden";
  document.getElementById('chart').style.visibility="hidden";
  document.getElementById('filter').style.visibility="hidden";
  document.getElementById('table').style.visibility="hidden";
  document.getElementById('dbSelect').style.visibility="hidden";
  document.getElementById('freeze_button').style.visibility="hidden";
  document.getElementById('filter_bar').style.visibility="hidden";
  document.getElementById('settings').style.visibility="hidden";
}

function conditionsForACLEDFeatures(){
  //how many times does array ACLEDCheck contains value true
  //What is happening here? The function is always the same! Why separate this into different variables?
  //Okay, this is used to check whether the the layer is still active; but why do this so complicated? Why not writing this into the state
  //Could be a simple array with all active layers and then the legend is rendered as soon as this is changing...
  var airportTrueCount = ACLEDCheck.reduce(function(n, val) {
          return n + (val === true);}, 0);
  if(airportTrueCount === 0){setACLEDFeaturesUnvisible();}else{setACLEDFeaturesVisible();}

  var militaryTrueCount = ACLEDCheck.reduce(function(n, val) {
          return n + (val === true);}, 0);
  if(militaryTrueCount === 0){setACLEDFeaturesUnvisible();}else{setACLEDFeaturesVisible();}

  var institutionTrueCount = ACLEDCheck.reduce(function(n, val) {
          return n + (val === true);}, 0);
  if(institutionTrueCount === 0){setACLEDFeaturesUnvisible();}else{setACLEDFeaturesVisible();}
}

export function getLayerPopup() {
  var modal = document.getElementById("modalWindow");
  var span = document.getElementsByClassName("close")[0];
  modal.style.display = "block";
  // document.getElementById("modalWindow").style.zIndex = "1000";
  span.onclick = function () {
    modal.style.display = "none";
    document.getElementById("fitFunction").remove();
  }

}

function getInfo(form, clickedPolygon){
  const dictInfo = {};
  dictInfo['startDate'] = form.startDate;
      dictInfo['endDate'] = form.endDate;
      dictInfo['GID'] = clickedPolygon.level;
      dictInfo['countryName'] = clickedPolygon.ADMIN;
      dictInfo['events'] = form.event;
      dictInfo['interactions'] = form.interaction;
      dictInfo['search'] = form.search;
      dictInfo['death'] = form.deaths;
      dictInfo['actor1'] = form.actor1;
      dictInfo['actor2'] = form.actor2;
      dictInfo['type'] = clickedPolygon.type;
      dictInfo['averageDays'] = clickedPolygon.averageDays;
      dictInfo['averageEndDate'] = clickedPolygon.averageEndDate;
      dictInfo['averageSum'] = clickedPolygon.averageSum;
      dictInfo['averageDelta'] = clickedPolygon.averageDelta;

      return dictInfo;
}

function getLegendGroup(layerName) {
  // TODO: this could be done cleaner; e.g. by iterating a nested dict
  const acledEvents = ['pruneCluster']
  const deckGl = ['eventsWebgl']
  const military = ['militaryAirports', 'militaryBunkers', 'militaryCheckpoints', 'militaryTraining', 'militaryBarracks']
  const airports = ['internationalAirports', 'mediumAirports', 'smallAirports', 'heliportAirports', 'seaplaneAirports']
  const institutions = ['hospitals', 'embassies', 'bordercontrol']
  const resources = [
    'plutonium', 'uranium', 'berrylium', 'magnesium', 'lithium', 'strontium', 'aluminium', 'bismut', 'lead', 
    'cadmium', 'chromium', 'iron', 'gallium', 'gold', 'indium', 'cobalt', 'copper', 'manganese', 'molybdenum', 'nickel', 
    'niobium', 'palladium', 'silver', 'tantalum', 'titanium', 'vanadium', 'zinc', 'arsenic', 'bromine', 'germanium', 'silicon', 
    'tin', 'helium', 'nitrogen', 'sulfur', 'oil', 'petroleum_products', 'coal', 'liquified_natural_gas', 'liquified_petrolium_gas', 
    'natural_gas', 'diamond', 'gemstones', 'granite', 'limestone', 'phosphate', 'sand', 'steel', 'cement'
  ]
  const aggregations = [
    'geoJsonRegionFat', 'geoJsonCountryFat', 'geoJsonRegionEve', 'geoJsonCountryEve', 'geoJsonCountryActors', 'geoJsonRegionActors', 
    'geoJsonCountryFatMoni', 'geoJsonRegionFatMoni', 'geoJsonCountryEveMoni', 'geoJsonRegionEveMoni', 'geoJsonCountryDensity', 'geoJsonRegionDensity', 
    'geoJsonCountryEth', 'geoJsonCountryPre', 'geoJsonGridPre', 'geoJsonCountryFop', 'geoJsonCountryReli'
  ]
  if (acledEvents.includes(layerName)) {
    return 'events'
  }
  if (deckGl.includes(layerName)) {
    return 'deckgl'
  }
  if (military.includes(layerName)) {
    return 'military'
  }
  if (airports.includes(layerName)) {
    return 'airports'
  }
  if (institutions.includes(layerName)) {
    return 'institutions'
  }
  if (resources.includes(layerName)) {
    return 'resources'
  }
  if (aggregations.includes(layerName)) {
    return 'aggregations'
  }
}

export default {
  name: 'Map',
  props: {
    event: Object,
  },
  components: {
    Legend
  },
  data: () => ({
    legendClosed: false,
    legendAirportClosed: false,
    legendInstitutionClosed: false,
    legendMilitaryClosed: false,
    legendPolyClosed: false,
    legendWMSMineralsClosed: false,
    legendWMSCommodityClosed: false,
    layerToRemove: null,
    progress: 10,
    filtersVisible: false,
    zoom: null,
    activeLayers: [], // unfortunately, Set objects are not reactive in Vue2, hence we need to use an array
    layerData: {},
    webglEventsStyle: 'scatter',
    dbOptions,
    events: ['Ausschreitungen', 'Explosionen/Gewalt auf Distanz', 'Gewalt gegen Zivilisten', 'Kampfhandlungen', 'Proteste', 'Strategische Entwicklungen'],
  }),

  async mounted() {
    await makeMap()
    const store = this.$store
    // TODO: implement gridWorker 
//     worker.gridWorker.onmessage = function (e) {
// /*       const pgids = Array.from(new Int32Array(e.data['pgids']))
//       const polygons = Array.from(new Float32Array(e.data['polygons']))
//       testData = {'pgids': pgids, 'polygons': polygons} */
//       testData = e.data
//       let layer = new PolygonLayer({
//           data: testData.slice(20, 5000),
//           pickable: true,
//           lineWidthMinPixels: 1,
//           getPolygon: d => d.polygon,
//           extruded: false,
//           getFillColor: [255, 0, 0, 80],
//           positionFormat: 'XY',
//           onHover: (info, even) => console.log('Hovered:', info, event)
//       })
//       deckLayer.setProps({
//         'layers': [layer]
//       })
//     };
    this.activeLayers.push('pruneCluster')
    this.zoom = map.getZoom()
    this.manageLegends();
    setParameterInfoVisible();
      geoJsonCountryActors.on('click', function () {
        this.getActorsLayerData();
      }, this);
      geoJsonRegionActors.on('click', function () {
        this.getActorsLayerData();
      }, this);
      geoJsonCountryFat.on('click', function () {
        this.getActorsData();
      }, this);
      geoJsonCountryEve.on('click', function () {
        this.getActorsData();
      }, this);
      geoJsonRegionFat.on('click', function () {
        this.getActorsData();
      }, this);
      geoJsonRegionEve.on('click', function () {
        this.getActorsData();
      }, this);
      geoJsonCountryFatMoni.on('click', function () {
        this.getFitFunctions();
      }, this);
      geoJsonCountryEveMoni.on('click', function () {
        this.getFitFunctions();
      }, this);
      geoJsonRegionFatMoni.on('click', function () {
        this.getFitFunctions();
      }, this);
      geoJsonRegionEveMoni.on('click', function () {
        this.getFitFunctions();
      }, this);
      geoJsonCountryDensity.on('click', function () {
        this.getPopulationPopup();
      }, this);
      geoJsonRegionDensity.on('click', function () {
        this.getPopulationPopup();
      }, this);
      geoJsonCountryEth.on('click', function () {
        this.getEthnPopup();
      }, this);
      geoJsonCountryReli.on('click', function () {
        this.getReliPopup();
      }, this);
      geoJsonCountryPre.on('click', function () {
        this.getPredictionPopup();
      }, this);
      geoJsonCountryFop.on('click', function () {
        this.getSourcesPopup();
      }, this);
    this.update();
    map.on('moveend', this.update);
    map.on('zoomend', (e) => {
      this.zoom = e.target._zoom;
      //deckLayer.props.layers[0].draw();
    });
    const bounds = map.getBounds();
    //viewBounds = bounds;
    this.legendClosed = this.addLegendCluster('markerCluster');
    showPolygons = 'markerCluster';
    this.$store.commit('setBounds', bounds);
    window.dispatchEvent(new Event('resize'));
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    await axios.get(url+'dboptions/',{headers: {
        authorization: `Bearer ${accessToken}`}})
      .then((response) => {
            this.dbOptions = response.data;
      }).catch();
     await axios.post(url + 'layerInfo/',{},{headers: {
        authorization: `Bearer ${accessToken}`}})
        .then((response) => {
        refugeeLayer = response.data['refugee_layer'];
        newestDatesDB = response.data['newest_dates'];
        parameterInfo = response.data['parameter_info'];
        showStartPopup = response.data['start_popup'];
        const EndDate = response.data['newest_dates'][0];
        this.$store.commit('setEndDate', EndDate);
        const StartDate = new Date(EndDate)//.toISOString().slice(0, 10)
        StartDate.setMonth(StartDate.getMonth() - 1)
        const newStartDate = StartDate.toISOString().slice(0, 10)
        this.$store.commit('setStartDate', newStartDate);

      })
  },
  beforeDestroy() {
    if (map) {
      map.remove();
    }
  },
  watch: {
    gridData: {
      deep: true,
      handler() {
        /* let layer = new PolygonLayer({
          data: this.gridData,
          getPolygon: d => d.polygon,
          positionFormat: 'XY'
        })
        deckLayer.setProps({
          'layers': [layer]
        })
        console.log(deckLayer) */
      }
    },
    mainLegendFilter: { // Whenever the quick filter changes, setProps on deckLayer
      deep: true,
      handler() {
        if (this.activeLayers.includes('eventsWebgl')) {
          let filteredData = this.layerData['eventsWebgl'].filter((event) => this.mainLegendFilter.includes(event.FIVE_TYPE_DEU))
          let layer = deckLayer.props.layers[0].clone({'data': filteredData})
          deckLayer.setProps({
            'layers': [layer]
          })
        }
        if (this.activeLayers.includes('pruneCluster')) {
          const activeIndices = this.mainLegendFilter.map((e) => this.events.indexOf(e))
          const markers = pruneCluster.GetMarkers()
          for (let i = 0; i < markers.length; i++) {
            if (!activeIndices.includes(markers[i].category)) {
              markers[i].filtered = true;
            } else {
              markers[i].filtered = false;
            }
          }
          pruneCluster.ProcessView();
        }
      }
    },
    updateAble: {
      deep: true,
      handler() {
        const button = document.getElementById('freeze_button');
        if (this.updateAble) {
          button.innerHTML = 'Fixieren';
        } else {
          button.innerHTML = 'Aufheben';
          button.style.backgroundColor = '#dc143c';
        }// 'image' - return base64, 'canvas' - return canvas
      },
    },
    layerToRemove: {
      deep: true,
      handler(message) {
        map.removeLayer(this.layerToRemove);
      },
    },
    event: {
      deep: true,
      handler(message) { // 
        const e = message.message;
        pathGroup.eachLayer(function(layer) { map.removeLayer(layer);});
        if (e.data.view) {
          /* eslint-disable no-underscore-dangle */
          map.fitBounds([[e.data.view._southWest.lat, e.data.view._southWest.lng],
            [e.data.view._northEast.lat, e.data.view._northEast.lng]]);
          /* eslint-enable no-underscore-dangle */
        } else if (e.data.clear) {
          pruneCluster.RemoveMarkers();
          pruneCluster.ProcessView();
          deckLayer.setProps(
            {'layers' : []}
          )
          deckLayerGrid.setProps(
            {'layers' : []}
          )
        } else if (e.data.data) {
          const activeIndices = this.mainLegendFilter.map((e) => this.events.indexOf(e))
          e.data.data.forEach((element) => {
            const marker = new PruneCluster.Marker(element.LATITUDE, element.LONGITUDE);
            marker.data.properties = {
              db: this.$store.state.db,
              id: element.DATA_ID,
              event: this.events.indexOf(element.FIVE_TYPE_DEU),
            };
            marker.category = this.events.indexOf(element.FIVE_TYPE_DEU);
            marker.filtered = activeIndices.includes(marker.category) ? false : true;
            pruneCluster.RegisterMarker(marker);
          });
          pruneCluster.ProcessView();
        }
        if (e.data.newLayer === 'eventsWebgl/') {
          this.layerData['eventsWebgl'] = e.data.polys_layer
          let filteredData = this.layerData['eventsWebgl'].filter((event) => this.mainLegendFilter.includes(event.FIVE_TYPE_DEU))
          let layer;
          if (this.webglEventsStyle == 'scatter') {
            layer = new ScatterplotLayer({
              data: filteredData,
              pickable: true,
              opacity: 0.8,
              stroked: false,
              filled: true,
              radiusScale: 1,
              lineWidthMinPixels: 1,
              getRadius: this.zoom * 0.25, // TODO: play with the values for a smoother experience
              radiusUnits: 'pixels',
              getFillColor: d => [...colors_rgb[this.events.indexOf(d.FIVE_TYPE_DEU)], 200], // same colors as with the default marker clusters
              getPosition: d => [d.LONGITUDE, d.LATITUDE],
              //onClick: (info, event) => console.log(info, this.zoom)
            })
            deckLayer.setProps({
              'layers': [layer]
            })
          }
          if (this.webglEventsStyle == 'heat') {
            layer = new HeatmapLayer({
              data: filteredData,
              radiusPixels: 50,
              getPosition: d => [d.LONGITUDE, d.LATITUDE],
              aggregation: 'SUM'
            });
            deckLayer.setProps({
              'layers': [layer]
            })
          }
        }
        if (e.data.newLayer === 'gridLayer/') {
          this.layerData['gridPreds'] = e.data.polys_layer
          let layer;
          deckLayerGrid.setProps({
            'layers': [],
          })
          layer = new GeoJsonLayer({
            data: this.layerData['gridPreds'],
            extruded: false,
            getFillColor: d => [...Object.values(getaltColor(d.properties.prediction, 'grid_pred', 1)).slice(0, 3), 255 * 0.8],
            getLineColor: [0, 255, 0, 255 * 0.5],
            getLineWidth: 0.25,
            stroked: true,
            pickable: true,
            lineWidthUnits: 'pixels'
          })
          deckLayerGrid.setProps({
            'layers': [layer],
          })
        }
        if (e.data.newLayer === 'ethnLayer/') {
          showPolygons = 'country_eth';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorCountryEth = e.data.max_color;
          geoJsonCountryEth.clearLayers();
          geoJsonCountryEth.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_eth');
        }
        if (e.data.newLayer === 'reliLayer/') {
          showPolygons = 'country_reli';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorCountryReli = e.data.max_color;
          minColorCountryReli = e.data.min_color;
          geoJsonCountryReli.clearLayers();
          geoJsonCountryReli.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_reli');

        }
        if (e.data.newLayer === 'airports_international/') {
          if(!airportCheck[0]){airportCheck[0] = true} else{airportCheck[0] = false}
          showPolygons = 'airportCluster';
          conditionsForACLEDFeatures();
          internationalAirports = markerCluster(internationalAirports, "icons/airport_big_black.svg", e.data.points.features, 'large_airport', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'airports_medium/') {
          if(!airportCheck[1]){airportCheck[1] = true} else{airportCheck[1] = false}
          showPolygons = 'airportCluster';
          conditionsForACLEDFeatures();
          mediumAirports = markerCluster(mediumAirports, "icons/airport_medium_black.svg", e.data.points.features, 'medium_airport', url + 'cluster_popup/')
         // layerCounter=0;
        }
        if (e.data.newLayer === 'airports_small/') {
          if(!airportCheck[2]){airportCheck[2] = true} else{airportCheck[2] = false}
          showPolygons = 'airportCluster';
          conditionsForACLEDFeatures();
          smallAirports = markerCluster(smallAirports, "icons/airport_small_black.svg", e.data.points.features, 'small_airport', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'airports_heliport/') {
          if(!airportCheck[3]){airportCheck[3] = true} else{airportCheck[3] = false}
          showPolygons = 'airportCluster';
          conditionsForACLEDFeatures();
          heliportAirports = markerCluster(heliportAirports, "icons/heli_airport_black.svg", e.data.points.features, 'heliport', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'airports_seaplane/') {
          if(!airportCheck[4]){airportCheck[4] = true} else{airportCheck[4] = false}
          showPolygons = 'airportCluster';
          conditionsForACLEDFeatures();
          seaplaneAirports = markerCluster(seaplaneAirports, "icons/waterplane_black.svg", e.data.points.features, 'seaplane_base', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'airports_military/') {
          if(!militaryCheck[0]){militaryCheck[0] = true} else{militaryCheck[0] = false}
          showPolygons = 'militaryCluster';
          conditionsForACLEDFeatures();
          militaryAirports = markerCluster(militaryAirports, "icons/airport_black.svg", e.data.points.features, 'military_airport', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'military_bunker/') {
          if(!militaryCheck[1]){militaryCheck[1] = true} else{militaryCheck[1] = false}
          showPolygons = 'militaryCluster';
          conditionsForACLEDFeatures();
          militaryBunkers = markerCluster(militaryBunkers, "icons/bunker_black.svg", e.data.points.features, 'military_bunker', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'military_checkpoint/') {
          if(!militaryCheck[2]){militaryCheck[2] = true} else{militaryCheck[2] = false}
          showPolygons = 'militaryCluster';
          conditionsForACLEDFeatures();
          militaryCheckpoints = markerCluster(militaryCheckpoints, "icons/checkpoint_black.svg", e.data.points.features, 'military_checkpoint', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'military_training/') {
          if(!militaryCheck[3]){militaryCheck[3] = true} else{militaryCheck[3] = false}
          showPolygons = 'militaryCluster';
          conditionsForACLEDFeatures();
          militaryTraining = markerCluster(militaryTraining, "icons/training_black.svg", e.data.points.features, 'military_training', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'military_barracks/') {
          if(!militaryCheck[4]){militaryCheck[4] = true} else{militaryCheck[4] = false}
          showPolygons = 'militaryCluster';
          conditionsForACLEDFeatures();
          militaryBarracks = markerCluster(militaryBarracks, "icons/barracks_black.svg", e.data.points.features, 'military_barrack', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'hospitals/') {
          if(!institutionCheck[0]){institutionCheck[0] = true} else{institutionCheck[0] = false}
          showPolygons = 'institutionCluster';
          conditionsForACLEDFeatures();
          hospitals = markerCluster(hospitals, "icons/hospital_black.svg", e.data.points.features, 'hospital', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'embassies/') {
          if(!institutionCheck[1]){institutionCheck[1] = true} else{institutionCheck[1] = false}
          showPolygons = 'institutionCluster';
          conditionsForACLEDFeatures();
          embassies = markerCluster(embassies, "icons/embassy_black.svg", e.data.points.features, 'embassy', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'bordercontrol/') {
          if(!institutionCheck[2]){institutionCheck[2] = true} else{institutionCheck[2] = false}
          showPolygons = 'institutionCluster';
          conditionsForACLEDFeatures();
          bordercontrol = markerCluster(bordercontrol, "icons/border_control_black.svg", e.data.points.features, 'bordercontrol', url + 'cluster_popup/')
          //layerCounter=0;
        }
        if (e.data.newLayer === 'aluminium/') {
          conditionsForACLEDFeatures();
          aluminium = markerCluster(aluminium, "icons/rohstoffe/elemente/metalle/aluminium.svg", e.data.points.features, 'aluminum', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'uranium/') {
          conditionsForACLEDFeatures();
          uranium = markerCluster(uranium, "icons/rohstoffe/elemente/actionide/uranium.svg", e.data.points.features, 'uranium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'plutonium/') {
          conditionsForACLEDFeatures();
          plutonium = markerCluster(plutonium, "icons/rohstoffe/elemente/actionide/plutonium.svg", e.data.points.features, 'plutonium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'oil/') {
          conditionsForACLEDFeatures();
          oil = markerCluster(oil, "icons/rohstoffe/oil.svg", e.data.points.features, 'oil', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'coal/') {
          conditionsForACLEDFeatures();
          coal = markerCluster(coal, "icons/rohstoffe/coal.svg", e.data.points.features, 'coal', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'berrylium/') {
          conditionsForACLEDFeatures();
          berrylium = markerCluster(berrylium, "icons/rohstoffe/elemente/metalle/berrylium.svg", e.data.points.features, 'berrylium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'bismut/') {
          conditionsForACLEDFeatures();
          bismut = markerCluster(bismut, "icons/rohstoffe/elemente/metalle/bismut.svg", e.data.points.features, 'bismuth', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'cadmium/') {
          conditionsForACLEDFeatures();
          cadmium = markerCluster(cadmium, "icons/rohstoffe/elemente/metalle/cadmium.svg", e.data.points.features, 'cadmium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'chromium/') {
          conditionsForACLEDFeatures();
          chromium = markerCluster(chromium, "icons/rohstoffe/elemente/metalle/chromium.svg", e.data.points.features, 'chromium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'cobalt/') {
          conditionsForACLEDFeatures();
          cobalt = markerCluster(cobalt, "icons/rohstoffe/elemente/metalle/cobalt.svg", e.data.points.features, 'cobalt', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'copper/') {
          conditionsForACLEDFeatures();
          copper = markerCluster(copper, "icons/rohstoffe/elemente/metalle/copper.svg", e.data.points.features, 'copper', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'gallium/') {
          conditionsForACLEDFeatures();
          gallium = markerCluster(gallium, "icons/rohstoffe/elemente/metalle/gallium.svg", e.data.points.features, 'gallium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'gold/') {
          conditionsForACLEDFeatures();
          gold = markerCluster(gold, "icons/rohstoffe/elemente/metalle/gold.svg", e.data.points.features, 'gold', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'indium/') {
          conditionsForACLEDFeatures();
          indium = markerCluster(indium, "icons/rohstoffe/elemente/metalle/indium.svg", e.data.points.features, 'indium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'iron/') {
          conditionsForACLEDFeatures();
          iron = markerCluster(iron, "icons/rohstoffe/elemente/metalle/iron.svg", e.data.points.features, 'iron', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'lead/') {
          conditionsForACLEDFeatures();
          lead = markerCluster(lead, "icons/rohstoffe/elemente/metalle/lead.svg", e.data.points.features, 'lead', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'lithium/') {
          conditionsForACLEDFeatures();
          lithium = markerCluster(lithium, "icons/rohstoffe/elemente/metalle/lithium.svg", e.data.points.features, 'lithium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'magnesium/') {
          conditionsForACLEDFeatures();
          magnesium = markerCluster(magnesium, "icons/rohstoffe/elemente/metalle/magnesium.svg", e.data.points.features, 'magnesium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'molybdenum/') {
          conditionsForACLEDFeatures();
          molybdenum = markerCluster(molybdenum, "icons/rohstoffe/elemente/metalle/molybdenum.svg", e.data.points.features, 'molybdenum', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'manganese/') {
          conditionsForACLEDFeatures();
          manganese = markerCluster(manganese, "icons/rohstoffe/elemente/metalle/manganese.svg", e.data.points.features, 'manganese', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'nickel/') {
          conditionsForACLEDFeatures();
          nickel = markerCluster(nickel, "icons/rohstoffe/elemente/metalle/nickel.svg", e.data.points.features, 'nickel', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'niobium/') {
          conditionsForACLEDFeatures();
          niobium = markerCluster(niobium, "icons/rohstoffe/elemente/metalle/niobium.svg", e.data.points.features, 'niobium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'palladium/') {
          conditionsForACLEDFeatures();
          palladium = markerCluster(palladium, "icons/rohstoffe/elemente/metalle/palladium.svg", e.data.points.features, 'palladium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'silver/') {
          conditionsForACLEDFeatures();
          silver = markerCluster(silver, "icons/rohstoffe/elemente/metalle/silver.svg", e.data.points.features, 'silver', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'tantalum/') {
          conditionsForACLEDFeatures();
          tantalum = markerCluster(tantalum, "icons/rohstoffe/elemente/metalle/tantalum.svg", e.data.points.features, 'tantalum', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'tin/') {
          conditionsForACLEDFeatures();
          tin = markerCluster(tin, "icons/rohstoffe/elemente/halbmetalle/tin.svg", e.data.points.features, 'tin', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'titanium/') {
          conditionsForACLEDFeatures();
          titanium = markerCluster(titanium, "icons/rohstoffe/elemente/metalle/titanium.svg", e.data.points.features, 'titanium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'vanadium/') {
          conditionsForACLEDFeatures();
          vanadium = markerCluster(vanadium, "icons/rohstoffe/elemente/metalle/vanadium.svg", e.data.points.features, 'vanadium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'strontium/') {
          conditionsForACLEDFeatures();
          strontium = markerCluster(strontium, "icons/rohstoffe/elemente/metalle/strontium.svg", e.data.points.features, 'strontium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'thallium/') {
          conditionsForACLEDFeatures();
          thallium = markerCluster(thallium, "icons/rohstoffe/elemente/metalle/thallium.svg", e.data.points.features, 'thallium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'zinc/') {
          conditionsForACLEDFeatures();
          zinc = markerCluster(zinc, "icons/rohstoffe/elemente/metalle/zinc.svg", e.data.points.features, 'zinc', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'arsenic/') {
          conditionsForACLEDFeatures();
          arsenic = markerCluster(arsenic, "icons/rohstoffe/elemente/halbmetalle/arsenic.svg", e.data.points.features, 'arsenic', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'bromine/') {
          conditionsForACLEDFeatures();
          bromine = markerCluster(bromine, "icons/rohstoffe/elemente/halbmetalle/bromine.svg", e.data.points.features, 'bromine', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'germanium/') {
          conditionsForACLEDFeatures();
          germanium = markerCluster(germanium, "icons/rohstoffe/elemente/halbmetalle/germanium.svg", e.data.points.features, 'germanium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'silicon/') {
          conditionsForACLEDFeatures();
          silicon = markerCluster(silicon, "icons/rohstoffe/elemente/halbmetalle/silicon.svg", e.data.points.features, 'silicon', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'helium/') {
          conditionsForACLEDFeatures();
          helium = markerCluster(helium, "icons/rohstoffe/elemente/nichtmetalle/helium.svg", e.data.points.features, 'helium', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'nitrogen/') {
          conditionsForACLEDFeatures();
          nitrogen = markerCluster(nitrogen, "icons/rohstoffe/elemente/nichtmetalle/nitrogen.svg", e.data.points.features, 'nitrogen', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'sulfur/') {
          conditionsForACLEDFeatures();
          sulfur = markerCluster(sulfur, "icons/rohstoffe/elemente/nichtmetalle/sulfur.svg", e.data.points.features, 'sulfur', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'petroleum_products/') {
          conditionsForACLEDFeatures();
          petroleum_products = markerCluster(petroleum_products, "icons/rohstoffe/petroleum_products.svg", e.data.points.features, 'petroleum products', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'diamond/') {
          conditionsForACLEDFeatures();
          diamond = markerCluster(diamond, "icons/rohstoffe/mineralien/diamond.svg", e.data.points.features, 'diamond', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'gemstones/') {
          conditionsForACLEDFeatures();
          gemstones = markerCluster(gemstones, "icons/rohstoffe/mineralien/gemstone.svg", e.data.points.features, 'gemstones', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'sand/') {
          conditionsForACLEDFeatures();
          sand = markerCluster(sand, "icons/rohstoffe/mineralien/sand.svg", e.data.points.features, 'sand', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'cement/') {
          conditionsForACLEDFeatures();
          cement = markerCluster(cement, "icons/rohstoffe/mineralien/cement.svg", e.data.points.features, 'cement', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'steel/') {
          conditionsForACLEDFeatures();
          steel = markerCluster(steel, "icons/rohstoffe/mineralien/steel.svg", e.data.points.features, 'steel', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'phosphate/') {
          conditionsForACLEDFeatures();
          phosphate = markerCluster(phosphate, "icons/rohstoffe/mineralien/phosphate.svg", e.data.points.features, 'phosphate', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'granite/') {
          conditionsForACLEDFeatures();
          granite = markerCluster(granite, "icons/rohstoffe/mineralien/granite.svg", e.data.points.features, 'granite', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'limestone/') {
          conditionsForACLEDFeatures();
          limestone = markerCluster(limestone, "icons/rohstoffe/mineralien/limestone.svg", e.data.points.features, 'limestone', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'liquified_natural_gas/') {
          conditionsForACLEDFeatures();
          liquified_natural_gas = markerCluster(liquified_natural_gas, "icons/rohstoffe/liquid_natural_gas.svg", e.data.points.features, 'liquified natural gas', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'liquified_petrolium_gas/') {
          conditionsForACLEDFeatures();
          liquified_petrolium_gas = markerCluster(liquified_petrolium_gas, "icons/rohstoffe/liquid_petrolium_gas.svg", e.data.points.features, 'liquified petroleum gas', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'natural_gas/') {
          conditionsForACLEDFeatures();
          natural_gas = markerCluster(natural_gas, "icons/rohstoffe/natural_gas.svg", e.data.points.features, 'natural gas', url + 'cluster_popup/')
        }
        if (e.data.newLayer === 'refugeeLayer/') {
          showPolygons = 'country_ref';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          geoJsonCountryRef.clearLayers();
          geoJsonCountryRef.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_ref');
        }
        if (e.data.newLayer === 'densityLayerCountry/') {
          showPolygons = 'country_density';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorCountryDensity = e.data.max_color;
          geoJsonCountryDensity.clearLayers();
          geoJsonCountryDensity.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_density');
        }
        if (e.data.newLayer === 'densityLayerRegion/') {
          showPolygons = 'region_density';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorRegionDensity = e.data.max_color;
          geoJsonRegionDensity.clearLayers();
          geoJsonRegionDensity.addData(e.data.polys_layer);
          //this.addLegendPolygon('region_density');
        }
        if (e.data.newLayer === 'predLayer/') {
          showPolygons = 'country_pre';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorCountryPre =  e.data.max_color;
          geoJsonCountryPre.clearLayers();
          geoJsonCountryPre.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_pre');
        }
        if (e.data.newLayer === 'gridLayer/') {
          showPolygons = 'grid_pred';
          //ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          //conditionsForACLEDFeatures();
          maxColorGridPre =  e.data.max_color;
          //this.addLegendPolygon('country_pre');
        }
        if (e.data.newLayer === 'fopLayer/') {
          showPolygons = 'country_fop';
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
          maxColorCountryFop =  e.data.max_color;
          geoJsonCountryFop.clearLayers();
          geoJsonCountryFop.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_fop');
        }
        if (e.data.newLayer === 'layerCountryFat/') {
          if(ACLEDCheck[1]==false){ACLEDCheck[1]=true}else(ACLEDCheck[1]=false)
          showPolygons = 'country_fat';
          conditionsForACLEDFeatures();
          maxColorCountryFat = e.data.max_color;
          geoJsonCountryFat.clearLayers();
          geoJsonCountryFat.addData(e.data.polys_layer);
          //this.$store.commit('addAggLegend', getLegendPolygon('country_fat'))
          ////this.addLegendPolygon('country_fat');
        }
        if (e.data.newLayer === 'layerRegionFat/') {
          if(ACLEDCheck[2]==false){ACLEDCheck[2]=true}else(ACLEDCheck[2]=false)
          showPolygons = 'region_fat';
          conditionsForACLEDFeatures();
          maxColorRegionFat = e.data.max_color;
          geoJsonRegionFat.clearLayers();
          geoJsonRegionFat.addData(e.data.polys_layer);
          //this.addLegendPolygon('region_fat');
        }
        if (e.data.newLayer === 'layerCountryEve/') {
          if(ACLEDCheck[3]==false){ACLEDCheck[3]=true}else(ACLEDCheck[3]=false)
          showPolygons = 'country_eve';
          conditionsForACLEDFeatures();
          maxColorCountryEve = e.data.max_color;
          geoJsonCountryEve.clearLayers();
          geoJsonCountryEve.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_eve');
        }
        if (e.data.newLayer === 'layerRegionEve/') {
          if(ACLEDCheck[4]==false){ACLEDCheck[4]=true}else(ACLEDCheck[4]=false)
          showPolygons = 'region_eve';
          conditionsForACLEDFeatures();
          maxColorRegionEve = e.data.max_color;
          geoJsonRegionEve.clearLayers();
          geoJsonRegionEve.addData(e.data.polys_layer);
          //this.addLegendPolygon('region_eve');
        }
        if (e.data.newLayer === 'actorsLayerCountry/') {
          if(ACLEDCheck[5]==false){ACLEDCheck[5]=true}else(ACLEDCheck[5]=false)
          showPolygons = 'country_act';
          conditionsForACLEDFeatures();
          maxColorCountryActors = e.data.max_color;
          geoJsonCountryActors.clearLayers();
          geoJsonCountryActors.addData(e.data.polys_layer);
          //this.addLegendPolygon('country_act');
        }
        if (e.data.newLayer === 'actorsLayerRegion/') {
          if(ACLEDCheck[6]==false){ACLEDCheck[6]=true}else(ACLEDCheck[6]=false)
          showPolygons = 'region_act';
          conditionsForACLEDFeatures();
          maxColorRegionActors = e.data.max_color;
          geoJsonRegionActors.clearLayers();
          geoJsonRegionActors.addData(e.data.polys_layer);
          //this.addLegendPolygon('region_act');
        }
        if (e.data.newLayer === 'hotspotLayerCountryFat/') {
          if(ACLEDCheck[7]==false){ACLEDCheck[7]=true}else(ACLEDCheck[7]=false)
          showPolygons = 'countryMoniFat';
          conditionsForACLEDFeatures();
          maxColorMoni = e.data.max_color;
          minColorMoni = e.data.min_color;
          geoJsonCountryFatMoni.clearLayers();
          geoJsonCountryFatMoni.addData(e.data.polys_layer);
          averageDays = e.data.days;
          averageDaysCompare = e.data.deltaDays;
          averageStartDate = e.data.startDate;
          averageEndDate = e.data.endDate;
          //this.addLegendPolygon('countryMoniFat');
        }
         if (e.data.newLayer === 'hotspotLayerRegionFat/') {
           if(ACLEDCheck[8]==false){ACLEDCheck[8]=true}else(ACLEDCheck[8]=false)
           showPolygons = 'regionMoniFat';
          conditionsForACLEDFeatures();
          maxColorMoni = e.data.max_color;
          minColorMoni = e.data.min_color;
          geoJsonRegionFatMoni.clearLayers();
          geoJsonRegionFatMoni.addData(e.data.polys_layer);
          averageDays = e.data.days;
          averageDaysCompare = e.data.deltaDays;
          averageStartDate = e.data.startDate;
          averageEndDate = e.data.endDate;
          //this.addLegendPolygon('regionMoniFat');
        }
         if (e.data.newLayer === 'hotspotLayerCountryEve/') {
           if(ACLEDCheck[9]==false){ACLEDCheck[9]=true}else(ACLEDCheck[9]=false)
           showPolygons = 'countryMoniEve';
          conditionsForACLEDFeatures();
          maxColorMoni = e.data.max_color;
          minColorMoni = e.data.min_color;
          geoJsonCountryEveMoni.clearLayers();
          geoJsonCountryEveMoni.addData(e.data.polys_layer);
          averageDays = e.data.days;
          averageDaysCompare = e.data.deltaDays;
          averageStartDate = e.data.startDate;
          averageEndDate = e.data.endDate;
          //this.addLegendPolygon('countryMoniEve');
        }
        if (e.data.newLayer === 'hotspotLayerRegionEve/') {
          if(ACLEDCheck[10]==false){ACLEDCheck[10]=true}else(ACLEDCheck[10]=false)
          showPolygons = 'regionMoniEve';
          conditionsForACLEDFeatures();
          maxColorMoni = e.data.max_color;
          minColorMoni = e.data.min_color;
          geoJsonRegionEveMoni.clearLayers();
          geoJsonRegionEveMoni.addData(e.data.polys_layer);
          averageDays = e.data.days;
          averageDaysCompare = e.data.deltaDays;
          averageStartDate = e.data.startDate;
          averageEndDate = e.data.endDate;
          //this.addLegendPolygon('regionMoniEve');
        }
        if(showPolygons && !['keine', 'markerCluster', 'markerCluster','airportCluster','militaryCluster','institutionCluster'].includes(showPolygons) ) {
          this.$store.commit('addAggLegend', showPolygons);
        }
        if(showPolygons == 'keine') {
          this.$store.commit('removeAggLegend');
        }
      },
    },
  },
  computed: {
    gridData() {
      return this.$store.state.gridData
    },
    toggleWebglPicker() {
      return this.activeLayers.includes('eventsWebgl')
    },
    mainLegendFilter() {
      return this.$store.state.legend.main !== undefined ? this.$store.state.legend.main.items.filter((item) => item.selected).map((item) => item.label): null
    },
    updateAble: {
      get() {
        return this.$store.state.freeze;
      },
      set(value) {
        this.$store.commit('setFreeze', value);
      }
    },
    db: {
      get() {
        return this.$store.state.db;
      },
      async set(value) {
        this.getEvents(value);
        const access_Token = await this.$auth.getTokenSilently();
        const res = await axios.get(url + 'range/', {params: {db: value},headers:
              {authorization: `Bearer ${access_Token}`}});
        const range = [0, parseInt(res.data.max, 10)];
        this.$store.commit('setInteraction', ['Alle']);
        this.$store.commit('setEvent', ['Alle']);
        this.$store.commit('setDeaths', range);
        if(value == 0){
            noNumber = 83; // !?!?!?!
            //layerControl.remove(map);
            //layerControl = setLayerControl(ESRI_WORLD_GRAY_CANVAS, ESRI_WORLD_IMAGERY, ESRI_WORLD_STREET_MAP, true, true).addTo(map);
            //map.addControl(searchControl);
            //map.zoomControl.setPosition('bottomright');

         // if(this.$store.state.filter.endDate > newestDatesDB[0]) {
            alert("Das Enddatum wird auf das aktuelleste ACLED Datum zurück gesetzt " + newestDatesDB[0])
            this.$store.state.filter.endDate = newestDatesDB[0];
            var newestStartDate = moment(newestDatesDB[0]).subtract(1, 'months').format("YYYY-MM-DD");
            this.$store.state.filter.startDate = newestStartDate;
            this.$store.state.filter.actor1 = ["Alle"];
            this.$store.state.filter.actor2 = ["Alle"];
         // }
        }
        if(value == 1){
            noNumber = 81;
            //geoJsonCountryPre.remove(map);
            //geoJsonCountryFop.remove(map);
            //layerControl.remove(map);
            //layerControl = setLayerControl(ESRI_WORLD_GRAY_CANVAS, ESRI_WORLD_IMAGERY, ESRI_WORLD_STREET_MAP,  false, false).addTo(map);
            //map.addControl(searchControl);
            //map.zoomControl.setPosition('bottomright');

          //if(this.$store.state.filter.endDate > newestDatesDB[1]) {
            alert("Das Enddatum wird auf das aktuelleste GTD Datum zurück gesetzt " + newestDatesDB[1])
            this.$store.state.filter.endDate = newestDatesDB[1];
            var newestStartDate = moment(newestDatesDB[1]).subtract(1, 'months').format("YYYY-MM-DD");
            this.$store.state.filter.startDate = newestStartDate;
            this.$store.state.filter.actor1 = ["Alle"];
            this.$store.state.filter.actor2 = ["Alle"];
         // }
        }
        if(value == 2){
            noNumber = 81;
            //geoJsonCountryPre.remove(map);
            //geoJsonCountryFop.remove(map);
            //layerControl.remove(map);
            //layerControl = setLayerControl(ESRI_WORLD_GRAY_CANVAS, ESRI_WORLD_IMAGERY, ESRI_WORLD_STREET_MAP,  false, false).addTo(map);
            //map.addControl(searchControl);
            //map.zoomControl.setPosition('bottomright');
          //if(this.$store.state.filter.endDate > newestDatesDB[2]) {
            alert("Das Enddatum wird auf das aktuelleste UCDP Datum zurück gesetzt " + newestDatesDB[2])
            this.$store.state.filter.endDate = newestDatesDB[2];
            var newestStartDate = moment(newestDatesDB[2]).subtract(1, 'months').format("YYYY-MM-DD");
            this.$store.state.filter.startDate = newestStartDate;
            this.$store.state.filter.actor1 = ["Alle"];
            this.$store.state.filter.actor2 = ["Alle"];
          //}
        }
        const accessToken = await this.$auth.getTokenSilently();
        worker.send({
          dbSet: true,
          db: value,
          bbox: map.getBounds(),
          form: this.$store.state.filter,
          token: accessToken,
        });
        this.$store.commit('setDB', value)
      }
    },
  },
  methods: {
    async getPopulationPopup(){
     const accessToken = await this.$auth.getTokenSilently();
     worker.send({progress: 10,});
     axios.post(url + 'population/', {clickedPolygon: clickedPolygon},
         {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
           popData = response.data.population;
           densityData = response.data.density;
           femaleData = response.data.femalePopData;
           maleData = response.data.malePopData;
           popAgesData = response.data.populationAges;
           lifeExpData = response.data.lifeExp;
           pop_GrowthData = response.data.popGrowth;
           let div = d3.select("#modalContainer");
           getPopulationChart(popData, densityData, femaleData, maleData, popAgesData, lifeExpData, pop_GrowthData, div, clickedPolygon);
           worker.send({progress: 100,});
           getLayerPopup();
         })
   },
   async getEthnPopup(){
     const form = this.$store.state.filter;
     const dictInfo = getInfo(form, clickedPolygon);
     const accessToken = await this.$auth.getTokenSilently();
     worker.send({progress: 10,});
     axios.post(url + 'ethnien/', {clickedPolygon: clickedPolygon},
         {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
            ethnData = response.data;
            let div = d3.select("#modalContainer");
            getEthnienHistogram(ethnData[0], ethnData[1], ethnData[2], ethnData[3], div, dictInfo);
            worker.send({progress: 100,});
            getLayerPopup();
         })
   },
    async getReliPopup(){
     const form = this.$store.state.filter;
     const dictInfo = getInfo(form, clickedPolygon);
     const accessToken = await this.$auth.getTokenSilently();
     worker.send({progress: 10,});
     axios.post(url + 'religion/', {clickedPolygon: clickedPolygon},
         {headers: { authorization: `Bearer ${accessToken}`}})
         .then((response) => {
            reliData = response.data;
            let div = d3.select("#modalContainer");
            getReligionHistogram(reliData, div, dictInfo);
            worker.send({progress: 100,});
            getLayerPopup();
         })
   },
   async getPredictionPopup(){
     const accessToken = await this.$auth.getTokenSilently();
     worker.send({progress: 10,});
     axios.post(url + 'prediction/', {clickedPolygon: clickedPolygon},
         {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
           pre_Data = response.data.target;
           bin_Data = response.data.bins;
           ev_Data = response.data.battles;
           cur_Data = response.data.current;
           let div = d3.select("#modalContainer");
           getPredictionChart(pre_Data, bin_Data, ev_Data, cur_Data, div, clickedPolygon);
           worker.send({progress: 100,});
           getLayerPopup();
         })
   },
   async getSourcesPopup(){
    worker.send({progress: 10,});
     const accessToken = await this.$auth.getTokenSilently();
    axios.post(url + 'sources/', {clickedPolygon: clickedPolygon},
        {headers: { authorization: `Bearer ${accessToken}`}})
     .then((response) => {
       src_Data = response.data.sources;
       cov_Data = response.data.coverage;
       url_Data = response.data.url;
       ttu_Data = response.data.ttu;
       sco_Data = response.data.sco;
       ran_Data = response.data.ran;
       sub_Data = response.data.sub;
       sur_Data = response.data.sur;
       str_Data = response.data.str;
       let div = d3.select("#modalContainer");
       getSourcesChart(src_Data, cov_Data, url_Data, ttu_Data, sco_Data, ran_Data, sub_Data, sur_Data, str_Data, div, clickedPolygon);
       worker.send({progress: 100,});
       getLayerPopup();
     })
    },
    async getActorsLayerData(){
      const form = this.$store.state.filter;
      const db = this.$store.state.db;
      const dictInfo = getInfo(form, clickedPolygon);
      const accessToken = await this.$auth.getTokenSilently();
      worker.send({progress: 10,});
      axios.post(url + 'actorsMatrixData/', {form, db, clickedPolygon: clickedPolygon},
            {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
           matrixActors = response.data.actors_matrix;
           actorsList = response.data.actors_list;
           actors1 = response.data.net_actors1;
           actors2 = response.data.net_actors2;
           weight = response.data.net_weight;
           colors1 = response.data.net_colors1;
           colors2 = response.data.net_colors2;
           size1 = response.data.net_node_size1;
           size2 = response.data.net_node_size2;
           actorsTimeline = response.data.actors_timeline;
           let div = d3.select("#modalContainer")
           getActorsMatrix(dictInfo, matrixActors, actorsList, actors1, actors2, weight, colors1, colors2, size1, size2, actorsTimeline, div);
           worker.send({progress: 100,});
           getLayerPopup();
         })
    },
    async getActorsData(){
      const form = this.$store.state.filter;
      const db = this.$store.state.db;
      const dictInfo = getInfo(form, clickedPolygon);
      const accessToken = await this.$auth.getTokenSilently();
      worker.send({progress: 10,});
      axios.post(url + 'actorsData/', {form, db, clickedPolygon: clickedPolygon},
          {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
           dataActors = response.data.actors;
           dataActorsGroups = response.data.actors_groups;
           let div = d3.select("#modalContainer")
           getActorsHistogram(dataActors, dataActorsGroups, dictInfo, div);
           worker.send({progress: 100,});
           getLayerPopup();
         })
    },
   async getFitFunctions(){
     const form = this.$store.state.filter;
     const db = this.$store.state.db;
     const dictInfo = getInfo(form, clickedPolygon);
     const accessToken = await this.$auth.getTokenSilently();
     worker.send({progress: 10,});
      axios.post(url + 'fit/', {form, db, clickedPolygon: clickedPolygon},
          {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
            polyFitData = response.data;
            let div = d3.select("#modalContainer")
            const contentTrend = getPopUpTrend(polyFitData, div, dictInfo);
            if(contentTrend != 0) {
              getLayerPopup();
            }
            worker.send({progress: 100,});
         })
    },
    async getEvents(db) {
      const accessToken = await this.$auth.getTokenSilently();
      axios.get(url+'events/', {
        params:{
          db: db
        },
        headers:{
          authorization: `Bearer ${accessToken}`
        }
      })
    .then((response) => {
          this.events = response.data;
          if(!this.legendClosed){
            this.addLegendCluster('markerCluster')
          }
    }).catch();
    },
    addLegendCluster,
    removeLegendCluster,
    addLegendPolygon,
    removeLegendPolygon,
    // initGrid() {
    //   worker.sendGrid("initGrid")
    // },
    screenshot() {
     simpleScreenShoter.takeScreen('image').then((image) => {
        prepareScreenshot(map, image, clusterLayer, wmsLayer, polyLayer, this.legendPolyClosed, this.legendClosed, this.legendWMSCommodityClosed, this.legendWMSMineralsClosed);
      }).catch((e) => {
        console.error(e);
      });
    },
    changeWebglStyle() {
      let filteredData = this.layerData['eventsWebgl'].filter((event) => this.mainLegendFilter.includes(event.FIVE_TYPE_DEU))
      let layer;
      if (this.webglEventsStyle == 'scatter') {
        layer = new ScatterplotLayer({
          data: filteredData,
          pickable: true,
          opacity: 0.8,
          stroked: false,
          filled: true,
          radiusScale: 1,
          lineWidthMinPixels: 1,
          getRadius: this.zoom * 0.25, // TODO: play with the values for a smoother experience
          radiusUnits: 'pixels',
          getFillColor: d => [...colors_rgb[this.events.indexOf(d.FIVE_TYPE_DEU)], 200], // same colors as with the default marker clusters
          getPosition: d => [d.LONGITUDE, d.LATITUDE],
          //onClick: (info, event) => console.log(info, this.zoom)
        })
      }
      if (this.webglEventsStyle == 'heat') {
        layer = new HeatmapLayer({
          data: filteredData,
          radiusPixels: 50,
          getPosition: d => [d.LONGITUDE, d.LATITUDE],
          aggregation: 'SUM'
        });
      }
      deckLayer.setProps({
        'layers': [layer]
      })
    },
    handleFilterView() {
      this.filtersVisible = !this.filtersVisible;
    },
    handleLegendMilitaryCluster(){
      if (this.legendMilitaryClosed) {
          this.legendMilitaryClosed = this.addLegendCluster('militaryCluster');
         }
       else {
           this.legendMilitaryClosed = this.removeLegendCluster('militaryCluster', "visible")
        }
    },
    handleLegendAirportCluster(){
      if (this.legendAirportClosed) {
          this.legendAirportClosed = this.addLegendCluster('airportCluster');
         }
       else {
           this.legendAirportClosed = this.removeLegendCluster('airportCluster', "visible")
        }
    },
    handleLegendInstitutionCluster(){
      if (this.legendInstitutionClosed) {
          this.legendInstitutionClosed = this.addLegendCluster('institutionCluster');
         }
       else {
           this.legendInstitutionClosed = this.removeLegendCluster('institutionCluster', "visible")
        }
    },
    handleLegendCluster() {
        if (this.legendClosed) {
          this.legendClosed = this.addLegendCluster('markerCluster');
        } else {
          this.legendClosed = this.removeLegendCluster('markerCluster', "visible")
        }
    },
    handleLegendPoly() {
      if (this.legendPolyClosed) {
        if (showPolygons == 'countryMoniFat' || showPolygons == 'regionMoniFat') {
          this.legendPolyClosed = this.addLegendPolygon(showPolygons);

        } else if (showPolygons == 'countryMoniEve' || showPolygons == 'regionMoniEve') {
              this.legendPolyClosed = this.addLegendPolygon(showPolygons);

        } else {
            this.legendPolyClosed = this.addLegendPolygon(showPolygons);
        }
      }
        else {
          this.legendPolyClosed = this.removeLegendPolygon('visible');
        }
    },
    manageLegends() {
      // TODO: move all these numbered names into a dictionary to make this easier to change and process
      //       the main issue is that the tree view is using the position of the elements as a name
      //       adding a new checkbox/radiobutton moves all layers after the new one by one
      //       it is super weird, that the "real" name of the layer is not used
      //       this doesn't even make sense for sorting...
      map.on('overlayadd', (eventLayer)=> {
        let groupName = getLegendGroup(eventLayer.name)
        this.activeLayers.includes(eventLayer.name) ? null : this.activeLayers.push(eventLayer.name)
        if (groupName == 'events') { 
          //if(ACLEDCheck[0]==false){ACLEDCheck[0]=true}else(ACLEDCheck[0]=false) // TODO: That doesn't make any sense? Why should the value be set to false if the overlay is added?
          ACLEDCheck[0] = true;
          showLegendCluster = 'markerCluster';
          this.legendClosed = this.addLegendCluster(showLegendCluster);
          this.$store.commit('enableLegend')
          conditionsForACLEDFeatures();
        }
        else if (groupName == 'deckgl') {
          // Same as above, but can now be customized
          ACLEDCheck[11] = true;
          showLegendCluster = 'markerCluster';
          this.legendClosed = this.addLegendCluster(showLegendCluster);
          this.$store.commit('enableLegend')
          conditionsForACLEDFeatures();
        }
        else if(groupName == 'military'){ 
            showPolygons = 'militaryCluster';
            addLegendCluster('militaryCluster');
            this.$store.commit('addSubLegend', getLegendObject('militaryCluster'));
            conditionsForACLEDFeatures();
        }
        else if(groupName == 'airports') { 
           showPolygons = 'airportCluster';
            addLegendCluster('airportCluster');
            this.$store.commit('addSubLegend', getLegendObject('airportCluster'));
            conditionsForACLEDFeatures();
        }
        else if(groupName == 'institutions') { 
           showPolygons = 'institutionCluster';
            addLegendCluster('institutionCluster');
            this.$store.commit('addSubLegend', getLegendObject('institutionCluster'));
            conditionsForACLEDFeatures();
        }
        else if(groupName == 'resources') {  
            conditionsForACLEDFeatures();
        } else if(groupName == 'aggregations') {
          //this.$store.commit('addAggLegend', eventLayer.name);
        }
        else if (eventLayer.name == noNumber) { // not used for anything? CHECK reason for this? I believe that this is the "Keine" Aggregationslayer
          showPolygons = 'keine';
          pathGroup.eachLayer(function(layer) { map.removeLayer(layer);});
          ACLEDCheck = [ACLEDCheck[0], false, false, false, false, false, false, false, false, false, false];
          conditionsForACLEDFeatures();
        }
    });
      map.on('overlayremove', (eventLayer) => {
        let groupName = getLegendGroup(eventLayer.name)
        this.activeLayers = this.activeLayers.filter((e) => e != eventLayer.name)
        if (groupName == 'events') {
            //if(ACLEDCheck[0]==false){ACLEDCheck[0]=true}else(ACLEDCheck[0]=false) // TODO: Why are we setting the check to true? THE LAYER IS REMOVED
            ACLEDCheck[0]=false
            if (!this.activeLayers.includes("eventsWebgl")) {
              this.legendClosed = this.removeLegendCluster('markerCluster',"unvisible")
              this.$store.commit('disableLegend')
            }
            conditionsForACLEDFeatures();
        }
        else if (groupName == 'deckgl') {
          ACLEDCheck[11]=false
          if (!this.activeLayers.includes("pruneCluster")) {
              this.legendClosed = this.removeLegendCluster('markerCluster',"unvisible")
              this.$store.commit('disableLegend')
            }
          deckLayer.props.layers = []
          conditionsForACLEDFeatures();
        }
         else if(groupName == 'military'){
           //how many times does array militaryCheck contains value true
          var militaryTrueCount = militaryCheck.reduce(function(n, val) {
          return n + (val === true);}, 0);
          if(militaryTrueCount === 1) {
            removeLegendCluster('militaryCluster', 'unvisible')
            this.$store.commit('removeSubLegend', 'militaryCluster');
            conditionsForACLEDFeatures();
          }
        }
         else if(groupName == 'airports') {
           var airportTrueCount = airportCheck.reduce(function(n, val) {
          return n + (val === true);}, 0);
          if(airportTrueCount === 1) {
            removeLegendCluster('airportCluster', 'unvisible')
            this.$store.commit('removeSubLegend', 'airportCluster');
            conditionsForACLEDFeatures();
          }
        }
         else if(groupName == 'institutions') {
           var institutionTrueCount = institutionCheck.reduce(function(n, val) {
          return n + (val === true);}, 0)
          if(institutionTrueCount === 1) {
            removeLegendCluster('institutionCluster', 'unvisible')
            this.$store.commit('removeSubLegend', 'institutionCluster');
            conditionsForACLEDFeatures();
          }
        }
         else if(groupName == 'resources') {
          conditionsForACLEDFeatures();
        }
          else{
            this.$store.commit('removeAggLegend');
            //removeLegendPolygon('unvisible')
        }
    });
},
    async update() {
      if (this.updateAble) {
        oldViewBounds = viewBounds;
        const bounds = map.getBounds();
        viewBounds = bounds;
        this.$store.commit('setBounds', bounds);
        const {filter} = this.$store.state;
        const accessToken = await this.$auth.getTokenSilently();
        worker.send({
          bbox: filter.bounds,
          token:accessToken,
        });
        /*const clusterLayers = [internationalAirports, mediumAirports, smallAirports, heliportAirports, seaplaneAirports, militaryAirports, militaryBunkers, militaryCheckpoints, militaryTraining, militaryBarracks, hospitals, embassies, bordercontrol];
        const urlCluster = ['airports_international/', 'airports_medium/', 'airports_small/', 'airports_heliport/', 'airports_seaplane/', 'airports_military/', 'military_bunker/', 'military_checkpoint/', 'military_training/', 'military_barracks/', 'hospitals/', 'embassies/', 'bordercontrol/']
        clusterLayers.forEach(function (element, index) {
          if (element != "" && element.getLayers().length && layerCounter == 0) {
            worker.send({
              selectedLayer: urlCluster[index],
              view: viewBounds,
              oldView: oldViewBounds,
            });
          }
        })
        layerCounter += 1;*/
      }
    },
  }
}

</script>

<style scoped>
@import "https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css";
@import "https://cdn.jsdelivr.net/npm/leaflet@1.6.0/dist/leaflet.css";
@import "https://cdn.rawgit.com/mapbox/supercluster/v4.0.1/demo/cluster.css";
@import "../assets/css/L.Control.Layers.Tree.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css";

#map_OS {
  position: relative;
  top: 0em;
  right: 0.1%;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
}

#legend {
  position: fixed;
  bottom: 0;
  left: 2;
  z-index: 5;
  background-color: white;
  border-color: grey;
  border-style: solid;
  border-radius: 5px;
  width: auto;
  display: none;
}

#screenshot {
  color:black;
  background-color: #ffffff00;
  border-color: black;
  border-radius: 10px;
  background: rgba(0,0,0,0.05) !important;
}

#freeze_button {
  z-index: 5;
  color:black;
  background-color: #ffffff00;
  border-color: black;
  border-radius: 10px;
  background: rgba(0,0,0,0.05) !important;
}

#freeze_button:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .8) !important;
}
#screenshot:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .8) !important;
}

.webgl-events-picker {
  position: fixed;
  bottom: 10px;
  z-index: 5;
  left: 285px;
}

.active{
  z-index: 5;
  color:black !important;
  background-color: #ffffff00 !important;
  border-color: black !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

#map_freeze {
  position: fixed;
  top: 22px;
  right: 4px;
  z-index: 5;
}

#filter_bar {
  font-size: 0.8em;
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 1;
}

.circle {
  background: #0000ff; /* color of the circle */
  border-radius: 50%; /* make the div a circular shape */
  box-shadow: 4px 4px 3px grey; /* see http://www.w3schools.com/css/css3_shadows.asp */
  -moz-box-shadow: 4px 4px 3px grey;
  -webkit-box-shadow: 4px 4px 3px grey;
}

.leaflet-div-icon2 {
  background: red;
  border: 5px solid rgba(255, 255, 255, 0);
  color: blue;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
}


.mydivicon {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background: #00f7ff;
  border: 1px solid #000;
  opacity: 0.85
}

#minus {
  float: left;
  width: 10px;
  hight: 10px;
}

#dummy {
  z-index: 5;
}

#filters_button {
  float: right;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
}
#minusAirport {
  float: left;
  width: 10px;
  hight: 10px;
}
#minusInstitution {
  float: left;
  width: 10px;
  hight: 10px;
}
#minusMilitary {
  float: left;
  width: 10px;
  hight: 10px;
}

#minusPoly{
  float:left;
    width:20px;
  hight:20px;
}

#minusWMSCommodity {
  float: left;
  width: 10px;
  hight: 10px;
}

#minusPoly {
  float: left;
  width: 10px;
  hight: 10px;
}
#dbSelect {
  position: fixed;
  top: 22px;
  left: 42%;
  z-index: 5;
}

@media only screen and (max-width: 1536px) and (max-height: 824px) {
   #modal {
      z-index: 2;
      position: fixed; /* or absolute */
      top: 2%;
      right: 20%;
      width: 55%; /* Full width */
      height: 97%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: white; /* Black w/ opacity */
      opacity: 100%;
      border: 3px solid #888;
    }

  #close {
    position: fixed;
    right: 22%;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 7000px) and (min-width: 1536px){
   #modal {
      z-index: 2;
      position: fixed; /* or absolute */
      top: 20px;
      right: 400px;
      width: 800px; /* Full width */
      height: 675px; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: white; /* Black w/ opacity */
      opacity: 100%;
      border: 3px solid #888;
    }

  #close {
    position: fixed;
    right: 440px;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
}


#modal-content {
  background-color: white;
  margin: 0%; /* 2% from the top and centered */
  width: 100%; /* Could be more or less, depending on screen size */
  height: 100%; /* Could be more or less, depending on screen size */
  overflow: auto; /* Enable scroll if needed */
}

#modalWindow{
  top: 0.5%;
}

#close{
  position: absolute;
  right: 3%;
}

#close:hover,
#close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


#manual_button {
  color:black;
  background-color: #ffffff00;
  border-color: black;
  border-radius: 10px;
  top: 70px;
  right: 4px;
  position: fixed;
  background: rgba(0,0,0,0.05) !important;
}

#manual_button:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .8) !important;
}

</style>

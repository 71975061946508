export default function namingConventions(){
    const names = {
    "german": {
        "messages": {
            "startPopups": {
                "title": "Demonstrator Alpha Testversion",
                "subTitle": "Der Demonstrator ist keine produktive Version und kann Fehler aller Art enthalten. Sollte ein" +
                    "Fehler auftreten, dann melde das bitte! Das Demonstrator Team bedankt sich!",
            }
        },
        "technical": {
            "legend": {
                "events": "Hauptereignistypen",
                "hotspotFat": "Hotspotmap Delta Konflikttote",
                "hotspotEve": "Hotspotmap Delta Ereignisse",
                "prediction": "Konflikteskalationswahrscheinlichkeit (%)",
                "pressfreedom": "Press Freedom Index 2022",
                "countryFat": "Länder Konflikttote",
                "regionFat": "Regionen Konflikttote",
                "countryEve": "Länder Ereignisse",
                "regionEve": "Regionen Ereignisse",
                "countryAct": "Länder Anzahl Akteure",
                "regionAct": "Regionen Anzahl Akteure",
                "ethnien": "Anzahl der Ethnien 2020",
                "religions": "Anzahl der Religionen 2020",
                "refugees": "Geflüchtete im Jahr ",
                "countryPop": "Länder Einwohner pro km^2 ",
                "regionPop": "Regionen Einwohner pro km^2 ",
                "minerals": "Mineralvorkommen",
                "criticalMinerals": "kritische Mineralvorkommen",
            },
            "control": {
                "basemaps": "Hintergrundkarten",
                "basemapGrey": "Grau",
                "basemapTopo": "Topo",
                "basemapStreet": "Straße",
                "events": "Ereignisse",
                "minerals": "Mineralvorkommen",
                "criticalMinerals": "kritische Mineralvorkommen",
                "layerGroupAmount": "ANZAHL Ereignisse und Konflikttote",
                "layerGroupActors": "ANZAHL Akteure",
                "layerGroupHotspot": "HOTSPOTMAP Ereignisse und Konflikttote",
                "layerGroupPop": "Bevölkerungsdichte",
                "layerGroupEth": "Ethnien",
                "layerGroupReli": "Religionen",
                "layerGroupPre": "Vorhersage",
                "layerGroupFop": "Pressefreiheit",
            },
            "popups": {
                "axis": {
                    "amountFat": "Konflikttote",
                    "amountEve": "Ereignisse",
                },
            },
        }
    }
};
    return names;
}
/* eslint no-undef: 0 */
import 'leaflet-swoopy';

let mapRef;
let layerRef;
const myRequest = new Request('refugee.ico');
const iconUri = myRequest.url;
export const pathGroup = L.layerGroup();
// eslint-disable-next-line import/no-mutable-exports
export let countryAsylum;
const refugeeIcon = L.icon({
  iconUrl: iconUri,
  iconSize: [25, 25],
  iconAnchor: [15, 28],
  popupAnchor: [-3, -36],
});

export function updateData(timeMigration) {
  setRefugeeMovements(mapRef, layerRef, timeMigration);
}

function deleteLastPathLayer(map) {
  pathGroup.eachLayer((layer) => { map.removeLayer(layer); });
}

function getRouteFromString(word, coordOrigin, yearDataDict, timeMigration) {
  let k = 0;
  let latAsylum;
  let lonAsylum;
  let path = '';
  let circle = '';
  word.split(',').forEach((word1) => {
    if (k === 0) {
      countryAsylum = word1.slice(1, -1);
    }
    if (k === 1) {
      latAsylum = word1.slice(2, -1);
    }
    if (k === 2) {
      lonAsylum = word1.slice(2, -1);
      let data = 0;
      if (yearDataDict[timeMigration]) { data = yearDataDict[timeMigration]; }
      let lineStrength = 0;
      if (data <= 1) { lineStrength = 1; }
      if (data > 1 && data <= 5) { lineStrength = 1; }
      if (data > 5 && data <= 15) { lineStrength = 1; }
      if (data > 15 && data <= 50) { lineStrength = 1; }
      if (data > 50 && data <= 250) { lineStrength = 2; }
      if (data > 250 && data <= 1000) { lineStrength = 3; }
      if (data > 1000) { lineStrength = 4; }
      if (data > 0) {
        path = L.swoopyArrow([coordOrigin[0], coordOrigin[1]], [latAsylum, lonAsylum], {
          iconAnchor: [20, 10],
          iconSize: [20, 16],
          weight: lineStrength,
          color: '#006400',
          opacity: 0.8,
          factor: 0.2,
          arrowFilled: true,
          keyboard: false,
        });
        circle = L.marker([latAsylum, lonAsylum], {
          icon: refugeeIcon,
        }).bindPopup('Auswanderungsjahr: ' + timeMigration + '<br> Auswanderungsland: ' + countryAsylum + '<br> Anzahl: ' + data);
      }
    }
    k++;
  });
  return [path, circle];
}

export function setRefugeeMovements(map, layer, timeMigration) {
  const route = [];
  let latOrigin;
  let lonOrigin;
  const coordOrigin = [];
  mapRef = map;
  layerRef = layer;

  deleteLastPathLayer(map);

  // eslint-disable-next-line guard-for-in
  for (const key in layer.feature.properties) {
    const yearDataDict = {};
    if (key === 'Latitude_y') {
      latOrigin = layer.feature.properties[key];
      coordOrigin.push(latOrigin);
    }
    if (key === 'Longitude_y') {
      lonOrigin = layer.feature.properties[key];
      coordOrigin.push(lonOrigin);
      route.push(coordOrigin);
    }
    if (key === 'asylum') {
      let i = 0;
      let lastDataCut = layer.feature.properties[key];
      const indexLast = lastDataCut.length - 2;
      lastDataCut = lastDataCut.substring(2, indexLast);
      let firstWord;
      let firstData = true;
      lastDataCut.split(', [[').forEach((word) => {
        if (i === 0) {
          firstWord = word;
        }
        if (i === 1) {
          let j = 0;
          word.split(']]], [').forEach((word2) => {
            if (j === 0) {
              let l = 0;
              let year = '';
              let data = '';
              word2.split(',').forEach((word3) => {
                if (l === 0) {
                  year = word3.slice(0, -2);
                }
                if (l === 1) {
                  data = word3.slice(0, -1);
                  yearDataDict[year] = data;
                }
                if (l === 2) {
                  year = word3.slice(2, -2);
                  l = 0;
                }
                l++;
              });
            }
            if (j === 1) {
              const path = getRouteFromString(firstWord, coordOrigin, yearDataDict, timeMigration)[0];
              const circleInvisible = getRouteFromString(firstWord, coordOrigin, yearDataDict, timeMigration)[1];

              if (path) {
                pathGroup.addLayer(path);
                pathGroup.addLayer(circleInvisible);
              }
              firstData = false;
              if (!firstData) { firstWord = word2; }
            }
            j++;
          });
          i = 0;
        }
        i++;
      });
    }
  }
  pathGroup.addTo(map);
  return map;
}

<template>
  <div>
    <a-row align="middle">
      <a-col :span="filterSize" style="height:100vh; ">
        <b-button
            id="filters"
            :pressed.sync="visible"
            v-if="visible!=true"
            style="height:100%;position:fixed;">
          <a-icon type="right"/>
        </b-button>
        <b-card v-if="visible" no-body>
          <b-tabs card>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab v-for="i in tabs" :key="'dyn-tab-' + i" :title="filterNames[getIndex(i) ]">
              <FilterMenu
                  :filter-input.sync="filters[getIndex(i)]"
                  :filter-name-input.sync="filterNames[getIndex(i)]"
                  :bounds="bounds[getIndex(i)]"
                  v-on:closeTab="closeTab(i)"/>
            </b-tab>

            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-end>
              <b-nav-item role="presentation" @click.prevent="newTab" href="#"><b>+</b></b-nav-item>
            </template>

            <!-- Render this if no tabs -->
            <template #empty>
              <div class="text-center text-muted">
                Kein Filter <br>
                Mit <b>+</b> neuen Filter erstellen.
              </div>
            </template>
          </b-tabs>
        </b-card>
      </a-col>
      <a-col :span="size">
        <b-row>
          <b-col style="align-content: center;">
            <b-form-group label="Zeitaggregation" v-slot="{ ariaDescribedbyData }">
              <b-form-radio
                  v-model="selectedDate"
                  :aria-describedby="ariaDescribedbyData"
                  name="some-radios-v"
                  value="Tage">
                Tage
              </b-form-radio>
              <b-form-radio
                  v-model="selectedDate"
                  :aria-describedby="ariaDescribedbyData"
                  name="some-radios-v"
                  value="Monate">
                Monate
              </b-form-radio>
              <b-form-radio
                  v-model="selectedDate"
                  :aria-describedby="ariaDescribedbyData"
                  name="some-radios-v"
                  value="Jahre">
                Jahre
              </b-form-radio>
            </b-form-group>
          </b-col>
          <b-col align-self="center">

            <b-button size="sm" variant="success" class="float-right" @click="calculateChart">
              Chart berechnen
            </b-button>
          </b-col>
          <b-col>
            <b-form-group label="Aggregationstyp" v-slot="{ ariaDescribedby }">
              <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="Tote">
                Anzahl der Toten
              </b-form-radio>
              <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="Ereignisse">
                Anzahl der Ereignisse
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <apexchart
            ref="chart"
            width="100%"
            type="line"
            :options="chartOptions"
            :series="series">
        </apexchart>
        <a-range-picker
            v-model="dates"
            style="width: 100%"
            @change="(date,dateString)=> changeDate(date,dateString)"/>
        <b-row>
          <b-col align="center">
            <div style="width: 550px; height:300px;" id="map_small">
            </div>
          </b-col>
        </b-row>

      </a-col>
    </a-row>

  </div>
</template>

<script>
import 'leaflet-draw';
import 'leaflet.locationfilter';
import 'leaflet-area-select';
import axios from 'axios';
import * as d3 from 'd3';
import L from 'leaflet';
import moment from 'moment';
import FilterMenu from './FilterMenu.vue';
import { url } from '../App.vue';

const URL_CHART = url + 'plot/';
const COLOR = d3.scaleOrdinal(d3.schemeCategory10).range();
let map;

function clone(instance) {
  return Object.assign(
    Object.create(
      // Set the prototype of the new object to the prototype of the instance.
      // Used to allow new object behave like class instance.
      Object.getPrototypeOf(instance),
    ),
    // Prevent shallow copies of nested structures like arrays, etc
    JSON.parse(JSON.stringify(instance)),
  );
}

function makeMap() {
  map = L.map(
    'map_small', {
      preferCanvas: false,
    },
  ).setView([0, 0], 0);
  L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    {
      maxBounds: [[-90, -180], [90, 180]],
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
      noWrap: true,
    },
  ).addTo(map);
  // eslint-disable-next-line no-underscore-dangle
  map._onResize();
}

export default {
  name: 'ChartContent',
  components: { FilterMenu },
  props: {
    opened: Boolean,
  },
  data() {
    return {
      tabs: [],
      visible: true,
      filters: [],
      tabCounter: 0,
      selected: 'Tote',
      selectedDate: 'Tage',
      bounds: [],
      dates: [],
      filterNames: [],
      size: 12,
      filterSize: 12,
      data: new Map(),
      data_month: new Map(),
      data_year: new Map(),

      series: [],
      chartOptions: {

        chart: {
          type: 'line',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
        },
        tooltip: {
          shared: true,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'Anzahl der Toten',
          align: 'left',
        },
        yaxis: {
          labels: {
            formatter(val) {
              return (val).toFixed(0);
            },
          },
          title: {
            text: 'Tote',
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 10,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        colors: COLOR,
        stroke: {
          width: 2,
          curve: 'smooth',
        },
      },

    };
  },
  beforeMount() {

  },
  mounted() {
    makeMap();
  },
  watch: {
    visible: {
      deep: true,
      handler() {
        if (this.visible) {
          this.size = 12;
          this.filterSize = 12;
        } else {
          this.filterSize = 2;
          this.size = 22;
        }
      },
    },
    opened: {
      deep: true,
      handler() {
        map.invalidateSize();
      },
    },
    selectedDate: {
      deep: true,
      handler() {
        this.setYAxisOptions();
        this.series = [];
        for (let i = 0; i < this.filters.length; i++) {
          this.series.push({ name: 'Filter', data: [] });
        }
        if (this.selectedDate === 'Tage') {
          this.setChartOptionsDays();
          this.data.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        } else if (this.selectedDate === 'Monate') {
          this.setChartOptionsMonth();
          this.data_month.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        } else if (this.selectedDate === 'Jahre') {
          this.setChartOptionsYears();
          this.data_year.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        }
      },
    },

    selected: {
      deep: true,
      handler() {
        this.setYAxisOptions();

        this.series = [];
        if (this.selectedDate === 'Tage') {
          this.data.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        } else if (this.selectedDate === 'Monate') {
          this.data_month.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        } else if (this.selectedDate === 'Jahre') {
          this.data_year.forEach((value, key) => {
            this.addDataToChart(value, this.filterNames[key], key);
          });
        }
      },
    },
    filters: {
      deep: true,
      handler() {
        this.filters.forEach((value, i) => {
          map.removeLayer(this.bounds[i]);

          /* eslint-disable no-underscore-dangle */
          const rect = L.rectangle([[value.bounds._southWest.lat,
            value.bounds._southWest.lng],
          [value.bounds._northEast.lat,
            value.bounds._northEast.lng]],
          { color: COLOR[i] });
          /* eslint-enable no-underscore-dangle */
          //
          rect.editing.enable();
          this.bounds[i] = rect;
          map.addLayer(this.bounds[i]);
          rect.on('edit', () => {
            this.updateBorder(this.tabs[i], rect);
          });
        });
      },
    },
  },
  methods: {

    setYAxisOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          title: {
            text: `Anzahl der ${this.selected}`,
          },
        },
      };
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: {
            title: {
              text: this.selected,
            },
          },
        },
      };
    },
    setChartOptionsYears() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            tickPlacement: 'between',
            labels: {
              formatter(val) {
                return val;
              },
            },
          },
        },
      };
    },
    setChartOptionsMonth() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            tickPlacement: 'between',
            labels: {
              formatter(val) {
                const date = new Date(val);
                return moment(date).format('MMM \'yy');
              },
            },
          },

        },
      };
    },
    setChartOptionsDays() {
      delete this.chartOptions.xaxis.tickPlacement;
      delete this.chartOptions.xaxis.labels;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {

            type: 'datetime',
            labels: {
              format: undefined,
              formatter: undefined,
              datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm',
              },
            },
          },
        },
      };
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: [],
              },
              export: {
                csv: {
                  filename: undefined,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString();
                  },
                },
                svg: {
                  filename: undefined,
                },
                png: {
                  filename: undefined,
                },
              },
              autoSelected: 'zoom',
            },
          },
        },
      };
    },
    getIndex(x) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i] === x) {
          return i;
        }
      }
      return -1;
    },
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i] === x) {
          map.removeLayer(this.bounds[i]);
          this.bounds.splice(i, 1);
          this.tabs.splice(i, 1);
          this.filters.splice(i, 1);
          this.filterNames.splice(i, 1);
        }
      }
      this.changeColorBounds();
    },
    changeColorBounds() {
      for (let i = 0; i < this.bounds.length; i++) {
        map.removeLayer(this.bounds[i]);
        /* eslint-disable no-underscore-dangle */
        const rect = L.rectangle([[this.bounds[i].getBounds()._southWest.lat,
          this.bounds[i].getBounds()._southWest.lng], [this.bounds[i].getBounds()._northEast.lat,
          this.bounds[i].getBounds()._northEast.lng]], { color: COLOR[i] });
        /* eslint-enable no-underscore-dangle */
        rect.editing.enable();
        this.bounds[i] = rect;
        map.addLayer(this.bounds[i]);
      }
    },
    changeDate(date, dateString) {
      this.filters.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        [element.startDate, element.endDate] = dateString;
      });
    },
    updateBorder(i, rect) {
      /* eslint-disable no-underscore-dangle */
      this.filters[this.getIndex(i)].bounds = rect.getBounds();
      /* eslint-disable no-underscore-dangle */
    },
    newTab() {
      this.filterNames.push(`Filter${this.tabCounter}`);
      if (this.tabs.length === 0) {
        this.filters.push(clone(this.$store.state.filter));
        this.dates = [this.$store.state.filter.startDate, this.$store.state.filter.endDate];
      } else {
        const i = this.tabs.length - 1;
        this.filters.push(clone(this.filters[i]));
      }
      let i = this.filters.length - 1;
      const rect = L.rectangle([[this.filters[i].bounds._southWest.lat,
        this.filters[i].bounds._southWest.lng],
      [this.filters[i].bounds._northEast.lat,
        this.filters[i].bounds._northEast.lng]],
      { color: COLOR[i] });
      map.addLayer(rect);
      rect.editing.enable();
      this.bounds.push(rect);
      i = this.tabCounter;
      rect.on('edit', () => {
        this.updateBorder(i, rect);
      });
      this.tabs.push(this.tabCounter++);
    },
    calculateChart() {
      this.visible = false;
      this.data = new Map();
      this.data_month = new Map();
      this.data_year = new Map();
      if (this.filters.length === 0) {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            noData: {
              text: 'No Filters',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#000000',
                fontSize: '14px',
                fontFamily: 'Helvetica',
              },
            },
          },
        };
      } else {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            noData: {
              text: 'Loading...',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#000000',
                fontSize: '14px',
                fontFamily: 'Helvetica',
              },
            },
          },
        };
      }

      this.series = [];

      for (let i = 0; i < this.filters.length; i++) {
        this.series.push({ name: 'Filter', data: [] });
      }
      this.filters.forEach(this.getDataForChart);
    },
    async getDataForChart(form, index) {
      const accessToken = await this.$auth.getTokenSilently();
      const db = this.$store.state.db;
      axios.post(URL_CHART, { form, db },
            {headers: {authorization: `Bearer ${accessToken}`}})
        .then((response) => {
          this.data.set(index, JSON.parse(response.data.data_day));
          this.data_month.set(index, JSON.parse(response.data.data_month));
          this.data_year.set(index, JSON.parse(response.data.data_year));
          if (this.selectedDate === 'Tage') {
            this.addDataToChart(JSON.parse(response.data.data_day), this.filterNames[index], index);
          } else if (this.selectedDate === 'Monate') {
            this.addDataToChart(JSON.parse(response.data.data_month), this.filterNames[index], index);
          } else if (this.selectedDate === 'Jahre') {
            this.addDataToChart(JSON.parse(response.data.data_year), this.filterNames[index], index);
          }
        }).catch(() => console.log('error'));
    },
    addDataToChart(seriesData, filterName, index) {
      const linedata = [];
      seriesData.forEach((data) => {
        if (this.selected === 'Tote') {
          linedata.push({ x: data.EVENT_DATE, y: data.Fatalities });
        } else {
          linedata.push({ x: data.EVENT_DATE, y: data.EventCount });
        }
      });
      this.series[index] = { name: filterName, data: linedata };
      this.series = [...this.series];
    },
  },
};
</script>

<style scoped>
#filters {
  border: 0.1em solid black !important;
  margin: 0 0.3em 0.3em 0 !important;
  background-color: var(--primary) !important;
  color: var(--text) !important;
  border-radius: 5px;
}
</style>

/*eslint-disable*/
import {
  deleteAndCreateDivElements,
  deleteCheckboxes,
  deleteDivElements,
  getInfoHover,
  getPopupLegend
} from '../Utils/Map.Layers.Popups.Utils.js';

const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

const w = document.body.clientWidth - document.body.clientWidth*0.2; // width histogram
const h = document.body.clientHeight - document.body.clientHeight*0.75;
const marginNo = document.body.clientWidth*0.001;
const maxData = 25;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };

const actorColorMapping = {
  "Staatlicher Akteur": '#e3dfb8',
  "Rebellengruppen": '#912323',
  "politische Milizen": '#0b610c',
  "ethnische Milizen": '#9bcc9c',
  "gewalt. Demonstranten": '#37409e',
  "Demonstranten": '#a3a8cf',
  "Zivilisten": '#631596',
  "sonstige Kräfte": '#d8b6de'
}

const actorsMapping = {
  1: "Staatlicher Akteur",
  2: "Rebellengruppen",
  3: "politische Milizen",
  4: "ethnische Milizen",
  5: "gewalt. Demonstranten",
  6: "Demonstranten",
  7: "Zivilisten",
  8: "sonstige Kräfte"
}

export default function  getActorsHistogram(dataActors, dataActorsGroups, dictInfo, div){

  let dataActorNamesOrig = [];
  let dataActorAmountOrig = [];
  let dataActorGroupsOrig = [];
  let dataActorNames = [];
  let dataActorAmount = [];
  let dataActorGroups = [];


  deleteDivElements()

  let svg = div.append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .attr("id", 'fitFunction')

    deleteAndCreateDivElements();
    deleteCheckboxes();

  for (var key in dataActors) {
    if (dataActors.hasOwnProperty(key)) {
        dataActorNamesOrig.push( [ key] );
        dataActorAmountOrig.push( [dataActors[key] ] );
        dataActorGroupsOrig.push( [dataActorsGroups[key] ] );
    }
  }

  if (dataActorNamesOrig.length >= maxData) {
    dataActorNames = dataActorNamesOrig.slice(0, maxData);
    dataActorAmount = dataActorAmountOrig.slice(0, maxData);
    dataActorGroups = dataActorGroupsOrig.slice(0, maxData);
    svg.attr('height', h + margin.top + margin.bottom);
  } else {
    dataActorNames = dataActorNamesOrig;
    dataActorAmount = dataActorAmountOrig;
    dataActorGroups = dataActorGroupsOrig;}

  let dataActorsList = []

  for (let i in dataActorNames){
    const colorActor = actorColorMapping[actorsMapping[dataActorsGroups[dataActorNames[i][0]]]];
    let dataActorsDict = {
      name: dataActorNames[i][0],
      y: dataActorAmount[i][0],
      drilldown: dataActorNames[i][0],
      color: colorActor
    };
    dataActorsList.push(dataActorsDict)
  }

  let dataActorsRedList = []
  for (let i in dataActorNames){
    let dataActorsReducedList = []
    for (let i2 in dataActorNames){
      const colorActor = actorColorMapping[actorsMapping[dataActorsGroups[dataActorNames[i2][0]]]];
      let dataActorsDict = {
        name: dataActorNames[i2][0],
        y: dataActorAmount[i2][0],
        color: colorActor
      };
      dataActorsReducedList.push(dataActorsDict)
    }
    dataActorsReducedList.splice(i, 1)

    let dataActorsDict = {
      name: dataActorNames[i][0],
      id: dataActorNames[i][0],
      data: dataActorsReducedList
    };
    dataActorsRedList.push(dataActorsDict)
  }

// Create the chart
Highcharts.chart('divID', {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Akteure für ' + dictInfo['countryName'] + ' zwischen ' + dictInfo['startDate'] + ' und ' + dictInfo['endDate']
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },
  xAxis: {
    title: {
      text: 'Akteure'
    },
    type: 'category'
  },
  yAxis: {
    allowDecimals: false,
    title: {
      text: 'Anzahl der Interaktionen'
    }

  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        //format: '{point.y:.1f}%'
      }
    }
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span>: <b>{point.y}</b><br>',
    pointFormat: '<span style="color:black">{point.name}</span><br/>',
    backgroundColor: {
      linearGradient: [0, 0, 0, 60],
      stops: [
          [0, '#FFFFFF'],
        [1, '#E0E0E0']
      ]
    },
    borderWidth: 1,
    borderColor: '#AAA',
  },

  series: [
    {
      name: "Anzahl der Interaktionen",
      colorByPoint: true,
      data: dataActorsList
    }
  ],
  drilldown: {
    series: dataActorsRedList
  }
});

  getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);
  svg = getPopupLegend(svg, document.body.clientWidth, document.body.clientHeight);


  return div.node();
}

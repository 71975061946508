import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import manual from '../pages/manual/manual.vue'
import DokuRohstoffe from '../pages/manual/DokuRohstoffe.vue'
import { authenticationGuard } from '../auth/authenticationGuard.js';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        //path: "/:catchAll(.*)",  // app may be deployed beneath a prefix
        name: 'Home',
        component: Home,
        title: 'Demonstrator',
        beforeEnter: authenticationGuard,
    },
    {
        path: '/DokuRohstoffe',
        //path: "/:catchAll(.*)",  // app may be deployed beneath a prefix
        name: 'DokuRohstoffe',
        component: DokuRohstoffe,
        title: 'Demonstrator Dokumentation Rohstoffe',
        beforeEnter: authenticationGuard,
    },
        {
        path: '/manual',
        //path: "/:catchAll(.*)",  // app may be deployed beneath a prefix
        name: 'manual',
        component: manual,
        title: 'Demonstrator Anwendungsbeschreibung',
        beforeEnter: authenticationGuard,
    }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
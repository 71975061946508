<template>
  <div>

    <b-row>

      <b-col>
        <a-input
            v-model="search"
            :placeholder="`Durchsuche alle Daten`"
            @pressEnter="() => handleSearchAll()"
        />
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="1">
        <a-button
            type="primary"
            icon="search"
            size="small"
            @click="() => handleSearchAll()"
        >
          Suche
        </a-button>
      </b-col>

      <b-col cols="1">
        <a-button
            type="primary"
            size="small"
            @click="() => handleSearchReset()"
        >
          Suche zurücksetzen
        </a-button>
      </b-col>

    </b-row>
    <a-table :columns="columns"
             :data-source="data"
             :pagination="pagination"
             :loading="loading"
             @change="handleTableChange">
      <template slot="INTER1" slot-scope="INTER1,record"> {{ INTER1 }} </template>
      <template slot="INTER2" slot-scope="INTER2,record"> {{ INTER2 }} </template>
      <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
        {{ record.NOTES }} ({{ record.SOURCE }})
      </p>
      <div
          slot="filterDropdown"
          slot-scope="{ confirm}"
          style="padding: 8px"
      >
        <b-row>
          <b-col>
            <a-button
                type="primary"
                icon="Suche"
                @click="() => handleSearch(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col>
            <a-tree-select
                v-model="country"
                :tree-data="optionsCountry"
                tree-checkable
                treeNodeFilterProp="title"
                :show-checked-strategy="SHOW_PARENT"
                search-placeholder="Auswahl"/>
          </b-col>
        </b-row>

      </div>
      <div
          slot="filterDropdownDate"
          slot-scope="{  confirm }"
          style="padding: 8px"
      >
        <b-row>
          <b-col>
            <a-button
                type="primary"
                icon="search"
                @click="() => handleSearchCustom(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col>
            <a-range-picker v-model="date"/>
          </b-col>
        </b-row>
      </div>
      <div
          slot="filterDropdownEvent"
          slot-scope="{ confirm}"
          style="padding: 8px"
      >
        <b-row>
          <b-col>
            <a-button
                type="primary"
                icon="search"
                @click="() => handleSearchCustom(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col>
            <EventFilter :eventInput.sync="event"/>
          </b-col>
        </b-row>
      </div>
      <div
          slot="filterDropdownActors"
          slot-scope="{ confirm}"
          style="padding: 8px"
      >
        <b-row>
          <b-col>
            <a-button
                type="primary"
                icon="search"
                @click="() => handleSearchCustom(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col>

            <ActorFilter :actorInput.sync="actor"/>
          </b-col>
        </b-row>

      </div>
       <div
          slot="filterDropdownActor1"
          slot-scope="{ confirm}"
          style="padding: 8px"
      >
        <b-row>
          <b-col>
            <a-button
                type="primary"
                icon="search"
                @click="() => handleSearchCustom(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col>

            <ActorFilter1 :actorInput1.sync="actor1"/>
          </b-col>
        </b-row>

      </div>
      <div
          slot="filterDropdownDeaths"
          slot-scope="{ confirm }"
          style="padding: 8px"
      >
        <b-row>
          <b-col cols="4">
            <a-button
                type="primary"
                icon="search"
                @click="() => handleSearchCustom(confirm)"
            >
              Suche
            </a-button>
          </b-col>
          <b-col cols="8">
            <SliderInput :min=0 :max="maxRange" :value.sync="deaths">

            </SliderInput>
          </b-col>
        </b-row>

      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />

      <template slot="footer">
        <b-row>
          <b-col>

            <a-button type="primary" icon="download" :size="size" @click="downloadFile">
              Download
            </a-button>
          </b-col>
          <b-col>

            Anzahl der Ereignisse: {{ totalCount }}
          </b-col>
        </b-row>

      </template>
    </a-table>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue';
import axios from 'axios';
import worker from '../assets/js/worker';
import SliderInput from './SliderInput.vue';
import ActorFilter from './ActorFilter.vue';
import ActorFilter1 from './ActorFilter1.vue';
import EventFilter from './EventFilter.vue';
import { url } from '../App.vue';

const { SHOW_PARENT } = TreeSelect;

const columnsFilter = [
  {
    title: 'Land',
    dataIndex: 'GID_0',
    sorter: true,
    key: 'GID_0',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Datum',
    dataIndex: 'EVENT_DATE',
    sorter: true,
    key: 'EVENT_DATE',
    scopedSlots: {
      filterDropdown: 'filterDropdownDate',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Ereignis',
    children: [
      {
        title: 'Hauptereignistyp',
        dataIndex: 'FIVE_TYPE_DEU',
        sorter: true,
        key: 'FIVE_TYPE_DEU',
      }, {
        title: 'Unterereignistyp',
        dataIndex: 'SUB_EVENT_TYPE_DEU',
        sorter: true,
        key: 'SUB_EVENT_TYPE_DEU',
      },
    ],
    scopedSlots: {
      filterDropdown: 'filterDropdownEvent',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Akteure',
    children: [
      {
        title: '1. Akteur',
        dataIndex: 'ACTOR1',
        sorter: true,
        key: 'ACTOR1',
        scopedSlots: {
          customRender: 'ACTOR1',
        },
      }, {
        title: '2. Akteur',
        dataIndex: 'ACTOR2',
        sorter: true,
        key: 'ACTOR2',
        scopedSlots: {
          customRender: 'ACTOR2',
        },
      },
    ],

    scopedSlots: {
      filterDropdown: 'filterDropdownActor1',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Akteurstypen',
    children: [
      {
        title: '1. Akteurstyp',
        dataIndex: 'INTER1',
        sorter: true,
        key: 'INTER1',
        scopedSlots: {
          customRender: 'INTER1',
        },
      }, {
        title: '2. Akteurstyp',
        dataIndex: 'INTER2',
        sorter: true,
        key: 'INTER2',
        scopedSlots: {
          customRender: 'INTER2',
        },
      },
    ],

    scopedSlots: {
      filterDropdown: 'filterDropdownActors',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Konflikttote',
    dataIndex: 'FATALITIES',
    sorter: true,
    key: 'FATALITIES',
    scopedSlots: {
      filterDropdown: 'filterDropdownDeaths',
      filterIcon: 'filterIcon',
    },
  },
];

const data = [];

export default {
  components: {
    EventFilter,
    ActorFilter,
    ActorFilter1,
    SliderInput,
  },
  data() {
    return {
      totalCount: 0,
      range: [0,9999],
      loading: false,
      maxRange: 9999,
      SHOW_PARENT,
      priority: 'LEAF_PRIORITY',
      optionsCountry: {},
      format: 'YYYY-MM-DD',
      data,
      disabled: false,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columns: columnsFilter,
    };
  },
  mounted() {
    this.$store.commit('setDeaths', this.range);
    this.getMaxDeath(this.$store.state.db);
    this.getCountryOption();
  },
  computed: {
    date: {
      get() {
        return [this.$store.state.filter.startDate, this.$store.state.filter.endDate];
      },
      set(value) {
        this.$store.commit('setStartDate', value[0].format('YYYY-MM-DD'));
        this.$store.commit('setEndDate', value[1].format('YYYY-MM-DD'));
      },
    },
    event: {
      get() {
        return this.$store.state.filter.event;
      },
      set(value) {
        this.$store.commit('setEvent', value);
      },
    },
    actor: {
      get() {
        return this.$store.state.filter.interaction;
      },
      set(value) {
        this.$store.commit('setInteraction', value);
      },
    },
    actor1: {
      get() {
        return this.$store.state.filter.actor1;
      },
      set(value) {
        this.$store.commit('setActor1', value);
      },
    },
    deaths: {
      get() {
        return this.$store.state.filter.deaths;
      },
      set(value) {
        this.$store.commit('setDeaths', value);
      },
    },
    minDeath: {
      get() {
        return this.$store.state.filter.deaths[0];
      },
      set(value) {
        if (value <= this.$store.state.filter.deaths[1]) {
          const puffer = [value, this.$store.state.filter.deaths[1]];
          this.$store.commit('setDeaths', puffer);
        }
      },
    },
    maxDeath: {
      get() {
        return this.$store.state.filter.deaths[1];
      },
      set(value) {
        if (value >= this.$store.state.filter.deaths[0]) {
          const puffer = [this.$store.state.filter.deaths[0], value];
          this.$store.commit('setDeaths', puffer);
        }
      },
    },
    search: {
      get() {
        return this.$store.state.filter.search;
      },
      set(value) {
        this.$store.commit('setSearch', value);
      },
    },
    country: {
      get() {
        return this.$store.state.filter.country;
      },
      set(value) {
        this.$store.commit('setCountry', value);
      },
    },
    // ...
  },
  props: {
    message: Object,
  },
  watch: {
    '$store.state.db': function () {
      this.getMaxDeath(this.$store.state.db);
    },
    message: {
      deep: true,
      handler(message) {
        const e = message.message;
        if (e.data.dataTable) {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = e.data.size;
          this.totalCount = e.data.size;
          this.pagination = pagination;
          this.data = e.data.dataTable;
        }
      },
    },
  },
  methods: {
    async getCountryOption() {
      const accessToken = await this.$auth.getTokenSilently();
      axios.get(url + 'countries/', {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        this.optionsCountry = response.data;
      }).catch();
    },
    async getMaxDeath(db) {
      const accessToken = await this.$auth.getTokenSilently();
      axios.get(url + 'range/', {
        params: {
          db,
        },
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          this.maxRange = parseInt(response.data.max, 10);
          this.range = [0, parseInt(response.data.max, 10)];
          this.$store.commit('setDeaths', this.range);
        }).catch();
    },
    async downloadFile() {
      const accessToken = await this.$auth.getTokenSilently();
      const db = this.$store.state.db;
      const urlTable = url + 'file/';
      const form = this.$store.state.filter;
      worker.send({
        progress: 10,
      });
      return axios.post(urlTable, { form, db }, {headers: {
          authorization: `Bearer ${accessToken}`}, responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'suchdaten.csv';
          link.click();
          worker.send({
            progress: 100,
          });
          URL.revokeObjectURL(link.href);
        }).catch();
    },
    async handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      const sort = {
        col: sorter.field,
        ord: sorter.order,
      };
      this.$store.commit('setPage', pager.current);
      this.$store.commit('setSort', sort);
      const { filter } = this.$store.state;
      this.loading = true;
      const accessToken = await this.$auth.getTokenSilently();
      worker.send({
        //sort: true,
        form: filter,
        bbox: filter.bounds,
        selectedLayer: 'tableSearch/',
        token: accessToken,
      });
    },
    async handleSearch(confirm) {
      const accessToken = await this.$auth.getTokenSilently();
      confirm();
      this.loading = true;
      const { filter } = this.$store.state;
      worker.send({
        form: filter,
        bbox: filter.bounds,
        selectedLayer: 'tableSearch/',
        token: accessToken,
      });
    },
    async handleSearchCustom(confirm) {
      confirm();
      const accessToken = await this.$auth.getTokenSilently();
      this.loading = true;
      const { filter } = this.$store.state;
      worker.send({
        form: filter,
        bbox: filter.bounds,
        selectedLayer: 'tableSearch/',
        token: accessToken,
      });
    },
    async handleSearchAll() {
      const accessToken = await this.$auth.getTokenSilently();
      this.loading = true;
      const { filter } = this.$store.state;
      worker.send({
        form: filter,
        bbox: filter.bounds,
        selectedLayer: 'tableSearch/',
        token: accessToken,
      });
    },
    handleSearchReset() {
      this.$store.commit('setSearch', '');
      this.handleSearchAll();
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

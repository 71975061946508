import L from 'leaflet';

// basemaps
// https://leaflet-extras.github.io/leaflet-providers/preview/

const MAX_ZOOM = 16;
const MIN_ZOOM = 1;

export const ESRI_WORLD_GRAY_CANVAS = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
  maxZoom: MAX_ZOOM,
  minZoom: MIN_ZOOM,
  maxBounds: [[-90, -180], [90, 180]],
  attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
  noWrap: true,
});

export const ESRI_WORLD_IMAGERY = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
  maxZoom: MAX_ZOOM,
  minZoom: MIN_ZOOM,
  maxBounds: [[-90, -180], [90, 180]],
  attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  noWrap: true,
});

export const ESRI_WORLD_STREET_MAP = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
  maxZoom: MAX_ZOOM,
  minZoom: MIN_ZOOM,
  maxBounds: [[-90, -180], [90, 180]],
  attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
  noWrap: true,
});

<template>
<div id="app">
<div class="vspace5blue"></div>


  <h3 class="heading" align="center"> <div class="vspace10"></div> Demonstrator Dokumentation Rohstoffe </h3>

<div class="vspace5blue"></div>
<div class="vspace10"></div>

 <!-- <h4 class="heading">Überschrift</h4>-->

  <h5 class="heading">Datenquellen der Koordinaten</h5>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column2" >

   <p class="text">
    Die Rohstoffe sind in drei Hauptkategorien eingeteilt: Elemente, Fossilie Energieträger und Mineralien. Die Grundlage der Daten bildet
    die <a href="https://mikedenly.com/datasets/global-resources-dataset" target="_blank">"Global Resource Database" (GRD)</a>.

    Die Koordinaten verweisen auf Minen, Rohstoffgewinnungsstandorte oder Einrichtungen zur Rohstoffverarbeitung.  <a href="https://mikedenly.com/files/GRD_Codebook.pdf" target="_blank">Codebook</a> <br><br>
    <div class="column" >
      <img src="./assets/Ressouces_Mouseover.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
    </div>

    <p class="text">

     Zu den Koordinaten der GRD Daten werden die folgenden Metainformationen im Mouseover angeboten:
    <ul>
     <li>Resource - das entsprechende chemische Element</li>
     <li>Country</li>
     <li>Minetype - die Art der Einrichtung, z.B. Raffinerien</li>
     <li>Mineownership - die Zugehörigkeit der Einrichtung zu einer Organisation</li>
     <li>Locationname - der Name der Einrichtung</li>
     <li>Precisioncode - Angabe zur Genauigkeit der erfassten Koordinate</li>
     <li>heat_content - der Wärmeinhalt einer Resource in in MMBtu/bbl. (Kann ein Qualitätsmerkmal sein?) </li>
     <li>lootable - Um 'lootable' zu sein muss ein Rohstoffe einen hohen Wert und niedrige Barriere zur Abtragung/Förderung haben.
        Beispielsweise kann Gold von jedem abgebaut werden und hat den lootable Wert 1.</li>
    </ul>
    <br>
    Der überwiegende Teil der Koordinaten der Resourcen und die dazugehörigen Metainformationen stammen aus der GRD Datenbank.
    Diese Datenquelle ist nicht vollständig.
     <br><br>
    Für die wichtigen Rohstoffe Öl und Gas wurde eine weitere Datenbank hinzu
    genommen: <a href="https://globalenergymonitor.org/projects/global-oil-gas-extraction-tracker/" target="_blank">"Global Energy Monitor" (GEM)</a>.
    Für die GEM Koordinaten werden die folgenden Metainformationen im Mouseover angezeigt:
    <ul>
      <li>Locationname - der Name der Einrichtung</li>
      <li>Resource - das entsprechende chemische Element</li>
      <li>Unit type - Beschreibung der Einheit, z.B. Feld, Brunnen, Bohrloch ...</li>
      <li>Country</li>
      <li>Location accuracy - Angabe zur Genauigkeit der erfassten Koordinate</li>
      <li>Status - z.B. entdeckt, in Entwicklung, in Betrieb, verlassen</li>
      <li>Status year - das Jahr in dem der Status festgelegt wurde</li>
      <li>Discovery year - das Jahr der Entdeckung</li>
      <li>Production start year - das Jahr des Produktionsstartes</li>
      <li>Operator - Betreiber</li>
      <li>Owner - Eigentümer</li>
      <li>Parent - Übergeordnete Eigentümer</li>
      <li>Basin - Becken</li>
      <li>GEM.wiki Seite mit weiteren Details</li>
      <li>Link zur GEM Seite</li>
    </ul>
    Die Rohstoff Layer Öl und Erdgas enthalten Koordinaten und Metainformationen aus beiden Datenquellen (GEM und GRD).
    <br>
    Die GEM Daten scheinen die GRD Daten perfekt zu ergänzen, denn es wurden, nach dem Zusammenlegen, keine doppelt geführten Koordinaten
    identifiziert (muss noch überprüft werden). GRD Daten sind überwiegend in Krisengebieten vorzufinden, GEM Daten dagegen auch z.B. in Europa.
    <br><br>

    Die erste Hauptkategorie der chemischen Elemente des Periodensystems ist weiter gegliedert in die folgenden Sparten:
    <ul>
     <li>Actinoide</li>
      <ul>
        <li>Plutonium</li>
        <li>Uran</li>
      </ul>
     <li>Seltene Erden</li>
     <li>Alkalimetalle</li>
       <ul>
        <li>Berrylium</li>
        <li>Magnesium</li>
         <li>Lithium</li>
        <li>Strontium</li>
      </ul>
     <li>Metalle</li>
      <ul>
        <li>Aluminium</li>
        <li>Bismut</li>
         <li>Blei</li>
        <li>Cadmium</li>
        <li>Chromium</li>
        <li>Eisen</li>
         <li>Gallium</li>
        <li>Gold</li>
        <li>Indium</li>
        <li>Kobalt</li>
         <li>Kupfer</li>
        <li>Mangan</li>
        <li>Molybdän</li>
        <li>Nickel</li>
         <li>Niobium</li>
        <li>Palladium</li>
        <li>Silber</li>
        <li>Tantalum</li>
         <li>Titan</li>
        <li>Vanadium</li>
        <li>Zink</li>
      </ul>
     <li>Halbmetalle</li>
      <ul>
        <li>Arsen</li>
        <li>Brom</li>
         <li>Germanium</li>
        <li>Silizium</li>
        <li>Zinn</li>
      </ul>
     <li>Nichtmetalle</li>
      <ul>
        <li>Helium</li>
        <li>Nitrogen</li>
         <li>Schwefel</li>
      </ul>
    </ul>
    Actinoide sind Elemente die zerfallen können, z.B radioaktive Elemente. <br>
    Seltene Erden sind in der GRD Datenbank nicht gelistet. Datenquellen zu seltenen Erden sind derzeit nicht bekannt.

    <br><br>
    Die Hauptkategorie "Fossile Energieträger" ist unterteilt in:
    <ul>
     <li>Öl</li>
     <li>Erdölprodukte</li>
     <li>Kohle</li>
     <li>Gas</li>
      <ul>
       <li>Verflüssigtes Erdgas</li>
       <li>Flüssiggas (Petrolium)</li>
       <li>Erdgas</li>
      </ul>
    </ul>

    <br><br>
    Die Hauptkategorie "Mineralien" ist unterteilt in:
    <ul>
     <li>Diamant</li>
     <li>Edelsteine</li>
     <li>Granit</li>
     <li>Kalkstein</li>
      <li>Phosphat</li>
     <li>Sand</li>
     <li>Stahl</li>
     <li>Zement</li>
    </ul>
   <br><br>

    Folgende Rohstoffe enthalten eine Kollektion aus mehreren, in der GRD Datenbank geführten, Rohstofftypen:<br>
    Öl = ['oil', 'oil and gas']<br>
    Erdgas = ['natural gas', 'oil and gas']<br>
    Aluminium = ['aluminum', 'aluminum floride', 'alumina']<br>
    Arsen = ['arsenic', 'arsenic trioxide']<br>
    Berrylium = ['beryllium', 'beryl','beryl and emerald']<br>
    Chromium = ['chromium', 'ferro-chromium','chromite', 'chromite ferrochromium']<br>
    Eisen = ['iron', 'iron and steel', 'iron oxides', 'iron pyrites']<br>
    Kupfer = ['copper', 'copper sulfate']<br>
    Lithium = ['lithium', 'lithium chloride', 'lithium hydroxide']<br>
    Mangan = ['manganese', 'ferro-manganese', 'silicomanganese']<br>
    Molybdän = ['molybdenum', 'ferro-molybdenum','molybdenum oxide']<br>
    Nickel = ['nickel', 'ferro-nickel']<br>
    Niobium = ['niobium', 'niobium and tantalum']<br>
    Nitrogen = ['nitrogen', 'nitrogen ammonia', 'nitrogen urea']<br>
    Sand = ['sand', 'sand and gravel','sandstone']<br>
    Silizium = ['silicon', 'ferro-silicon']<br>
    Stahl = ['steel', 'iron and steel']<br>
    Vanadium = ['vanadium', 'ferro-vanadium', 'vanadium pentoxide']<br>
     <br><br>
    Für Öl und Erdgas gibt es für jedes Land zusätzlich einen Pin an der Koordinate der Hauptstadt. Da die GRD Datenbank nicht immer Koordinaten für alle
    Länder abdeckt, aber Metadaten für die Popup Grafiken für alle Länder vorhanden sind, wurden diese Hauptstadt Pins für die beiden
    Layer Öl und Erdgas eingeführt. Für diese beiden Layer gibt es Grafiken für alle Länder.

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <br>


   <h5 class="heading">Datenquellen der Grafiken</h5>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
    <img src="./assets/Ressources_Iran_Oil.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
  </div>
  <div class="column2">
    <p class="text">
      Für jedes Rohstoff Icon lässt sich ein Popup öffnen. Es erscheint eine Grafik "Preise (Land) und Ereignisse (Land)".
      <br><br>
      Sofern vorhanden wird standardmäßig ein Graph zu den "Weltbank Export" Preisen angezeigt und dazu, falls vorhanden, die auf
      Jahresebene aggregierten Ereignisse aus den Datenbanken "ACLED", "GTD" und "UCDP". <br>
      Wenn in der Grafik ein Rechteck aufgezogen wird, dann wird in diesen Ausschnitt hineingezoomt.<br>
      Mit dem Klick auf eines der Datensets (direkt unter der x-Achse der Grafik) wird ein neuer Graph angezeigt, bzw entfernt, oder
      die Datenbank Ereignisse aktivert, bzw. deaktiviert. Die y-Achse passt sich entsprechend der aktiven Grphfen automatisch an.
      Es ist zu empfehlen die Ereignisse getrennt voneinander zu betrachten, da so ein genauerer Vergleich mit den Preisen möglich ist.<br>
      Es gibt drei y-Achsen: Ereignisse, Preise und Menge. Falls Comtrade Daten vorhanden sind, wird zusätzlich die Aktivierung der Export und Import Menge
      angezeigt. Falls keine Daten zu den Preisen, Mengen oder Ereignissen vorhanden sind, wird auch kein Datenset unter der x-Achse abgebildet.<br>
      <br>
      Das Datenbanksymbol öffnet eine Reihe von Checkbox Auswahl Möglichkeiten. Hier kann man die Ereignistypen zu den jeweiligen Datenbanken
      aussuchen. In der Default Einstellung sind alle Ereignistypen gewählt.
      <br><br>
      Für die Preise sind verschieden Datenquellen möglich, je nach Verfügbarkeit:
     <ul>
        <li>Weltbank (nominal) - Export und Import Preis</li>
        <li>Comtrade - Export und Import Preis</li>
        <li>IEA - Export Preis</li>
        <li>GRD Daten:</li>
        <ul>
          <li>Weltbank - Export Preis</li>
          <li>Comtrade - Export Preis</li>
          <li>Multicolor - Export Preis</li>
          <li>USGS - Export Preis</li>
        </ul>
      </ul>

    <br>
    Die nominalen Weltbank Daten wurden aus einem sogenannten "Pink Sheet - Annual Prices" extrahiert <a href="https://www.worldbank.org/en/research/commodity-markets" target="_blank"> Weltbank Waren Daten</a>
    Es wurden die folgenden Spalten aus der Tabelle verwendet:
    <ul>
          <li>Öl: "Crude oil, average"</li>
          <li>Kohle: "Coal, Australian"</li>
          <li>Erdgas: "Natural gas, US"</li>
          <li>Verflüssigtes Erdgas: "Liquefied natural gas, Japan"</li>
          <li>Aluminium: Aluminum</li>
          <li>Eisen: "Iron ore, cfr spot"</li>
          <li>Kupfer: Copper</li>
          <li>Blei: Lead</li>
          <li>Zinn: Tin</li>
          <li>Nickel: Nickel</li>
          <li>Zink: Zinc</li>
          <li>Gold: Gold</li>
          <li>Platin: Platinum</li>
          <li>Silber: Silver</li>
    </ul>
    Wie oben aufgelistet wurde für Öl die Spalte "Crude oil, average" verwendet. Hier wäre es angebracht einen Fachkundigen zu befragen,
    da auch weitere Spalten zum Ölpreis angeboten wurden, die eventuell besser geeignet sind: "Crude oil, Brent","Crude oil, Dubai","Crude oil, WTI"

    <br><br>
    For die unten aufgezähtlen Rohstoffe sind die folgenden Datenquellen verwendet worden. Zu den Weltbank Daten standen
    zwei in Frage kommende Preiskategorien zur Auswahl. Der nominale Preis und der reale Preis. Hier wurde der nominale Preis gewählt. Der
    Realpreis beinhaltet eine Inflationsanpassung. Für die Wahl der  <a href="https://comtrade.un.org/data" target="_blank">UN Comtrade Daten</a> nach Rohstoff ist ein Code erforderlich, die
    sogenannte  <a href="https://unstats.un.org/wiki/display/comtrade/Finding+6-digit+detailed+commodity+codes+in+HS+Classification+in+UN+Comtrade" target="_blank">HS Klassifikation</a>.
    Die Codes für die jeweiligen Rohstoffe wurden nach bestem Wissen gewählt. Da für manche Rohstoffe mehrere plausible Codes zur Auswahl standen, ist noch die Überprüfung eines Fachkundigen nötig.
    Die verwendeten Codes sind in der Auflistung unten bei Comtrade aufgelistet.
    Für den Comtrade Preis wurde die Spalte "Trade Value (US$)" und für die Menge die Spalte "Netweight (kg)" verwendet.
    <br>
    Die Datenquelle GRD, die hauptsächlich als Koordinatenquelle verwendet wurde, enthält auch Preisinformationen. Allerdings sind beispielsweise
    Comtrade Daten nur bis 2015 eingefügt und auch nicht immer vollständig. Für die Visualisierung gibt es eine Priorisierungsreihenfolge.
    Falls aktuelle Weltbank oder Comtrade Daten verfügbar sind, werden diese in der Grafik angeboten. Sind für den entsprechenden Rohstoff
    keine aktuellen Daten vorhanden, werden die Preisquellen der GRD Datenbank verwendet (Weltbank Export, Comtrade Export, Multicolor Export oder
    USGS Export). Falls aktuelle Comtrade Daten vorhanden sind werden auch aktuelle Comtrade Export- und Import Mengen in kg in der Grafik
    angeboten. Für die GRD Preisinformation wurde die Spalte "Price" verwendet. Diese Spalte ist nicht im GRD Codebook beschrieben, daher
    ist hier noch Bedarf an Klärung.
    <br><br>
    <ul>
        <li>Öl:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 2709</li>
          <li>IEA (nur für Koordinaten aus der GEM Datenquelle)</li>
        </ul>
        <li>Erdgas:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 271121</li>
        </ul>
        <li>Verflüssigtes Erdgas:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 27111 </li>
        </ul>
        <li>Flüssiggas (Petrolium):</li>
        <ul>
          <li>Comtrade - Export und Import - 271129</li>
        </ul>
        <li>Kohle:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 2701</li>
        </ul>
      <li>Aluminium:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 7610</li>
        </ul>
      <li>Arsen:</li>
        <ul>
          <li>Comtrade - Export und Import - 280480</li>
        </ul>
      <li>Bismut:</li>
        <ul>
          <li>Comtrade - Export und Import - 8160</li>
        </ul>
      <li>Blei:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 7801</li>
        </ul>
      <li>Brom:</li>
        <ul>
          <li>Comtrade - Export und Import - 28130</li>
        </ul>
      <li>Cadmium:</li>
        <ul>
          <li>Comtrade - Export und Import - 811269</li>
        </ul>
      <li>Chromium:</li>
        <ul>
          <li>Comtrade - Export und Import - 811220</li>
        </ul>
      <li>Diamant:</li>
        <ul>
          <li>Comtrade - Export und Import - 7102</li>
        </ul>
      <li>Edelsteine:</li>
        <ul>
          <li>Comtrade - Export und Import - 7103</li>
        </ul>
      <li>Eisen:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 2601</li>
        </ul>
      <li>Gallium, Indium und Germanium (zusammen gefasst):</li>
        <ul>
          <li>Comtrade - Export und Import - 81129</li>
        </ul>
      <li>Gold:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 7108</li>
        </ul>
      <li>Granit:</li>
        <ul>
          <li>Comtrade - Export und Import - 2516</li>
        </ul>
      <li>Kalk:</li>
        <ul>
          <li>Comtrade - Export und Import - 2521</li>
        </ul>
      <li>Kobalt:</li>
        <ul>
          <li>Comtrade - Export und Import - 8105</li>
        </ul>
      <li>Kupfer:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 740110</li>
        </ul>
      <li>Mangan:</li>
        <ul>
          <li>Comtrade - Export und Import - 2602</li>
        </ul>
       <li>Molybdän:</li>
        <ul>
          <li>Comtrade - Export und Import- 2613</li>
        </ul>
      <li>Nickel:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 2604</li>
        </ul>
      <li>Niobium:</li>
        <ul>
          <li>Comtrade - Export und Import - 2615</li>
        </ul>
      <li>Palladium:</li>
        <ul>
          <li>Comtrade - Export und Import - 711021</li>
        </ul>
      <li>Phosphate:</li>
        <ul>
          <li>Comtrade - Export und Import - 2835</li>
        </ul>
      <li>Sand:</li>
        <ul>
          <li>Comtrade - Export und Import - 2502</li>
        </ul>
      <li>Silber:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 7106</li>
        </ul>
      <li>Silizium:</li>
        <ul>
          <li>Comtrade - Export und Import - 3910</li>
        </ul>
      <li>Stahl:</li>
        <ul>
          <li>Comtrade - Export und Import - 7218</li>
        </ul>
      <li>Uran:</li>
        <ul>
          <li>Comtrade - Export und Import - 284410</li>
        </ul>
      <li>Tantalium:</li>
        <ul>
          <li>Comtrade - Export und Import - 8103</li>
        </ul>
      <li>Titan:</li>
        <ul>
          <li>Comtrade - Export und Import - 8108</li>
        </ul>
      <li>Vanadium:</li>
        <ul>
          <li>Comtrade - Export und Import - 720292</li>
        </ul>
      <li>Zement:</li>
        <ul>
          <li>Comtrade - Export und Import - 2523</li>
        </ul>
      <li>Zink:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 7901</li>
        </ul>
      <li>Zinn:</li>
        <ul>
          <li>Weltbank (nominal) - Export und Import</li>
          <li>Comtrade - Export und Import - 2609</li>
        </ul>
    </ul>



<br><br>
    <div class="column" >
    <img src="./assets/Ressources_IEA.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
  </div>

    <p class="text">
    Für den Datenlayer 'Öl' und die IEA Staaten gibt es zusätzlich eine Grafik auf IEA Daten basierend. Hier für wurden die <a href="https://www.iea.org/data-and-statistics/data-product/monthly-oil-price-statistics-2" target="_blank">"monthly oil product prices"</a>
    verwendet. Die "IEA Energy Prices" Daten bieten jährliche Energiepreise für mehr als 100 Nicht OECD Länder für Benzin, Autodiesel,
    Erdgas, Strom und andere Produkte sowie Daten zu Endverbrauchspreisen und Steuern, die für die OECD-Länder vierteljährlich aktualisiert werden.
    In der IEA Grafik wird derzeit nur das laufende Jahr 2022 dargestellt. Der aktuellste Monat ist September. Hier können die Preiseschwankungen
    für Diesel unter den IEA Ländern verglichen werden.

<br><br><br><br><br>
    Für die Popup Grafiken der Global Energy Monitoring (GEM) Daten gibt es eine weitere Besonderheit. In einigen wenigen Daten wird
      ein Werk nicht einem Land zugeordnet, sondern zweien mit einem Bindestrich verbunden. Z.B. Malaysia-Indonesia.
      Im Mousover werden beide Namen angezeigt, aber im Popup werden Daten zu Preisen und Mengen, in diesem speziellen Fall, nur für das erste Land angeboten.

<br><br>
    Weltbank, Comtrade und IEA Datensätze werden regelmäßig geupdatet.

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <br>

  </div>



  </div>
</template>


<script>
export default {
  name: "DokuRohstoffe",
}
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position:absolute;
  left: 5px;
  top:5px;
  right: 5px;
  bottom: 5px;
}

html,body {
  height: 100%;

}

.scrshot{
  width: 100%;
}

.heading {
  font-weight: bold;
  font-family: "Lucida Console", "Courier New", monospace;
}

.text {
  font-size: 14px;
  color: rgba(4, 59, 153, 1);
  font-family: "Lucida Console", "Courier New", monospace;
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
}
.column2 {
  float: left;
  width: 100%;
  padding: 10px;
}


.row{
  background-color: rgba(46, 34, 43, 0.15);
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

li.nostyle {
  list-style-type: none;
}
.vspace15 {
  height: 15px;
  background-color: transparent;
}

.vspace10 {
  height: 10px;
  background-color: transparent;
}

.vspace10blue {
  height: 10px;
  background-color: rgba(116, 183, 218, 0.5);
}

.vspace5blue {
  height: 5px;
  background-color: rgba(116, 183, 218, 0.5);
}


</style>

import L from 'leaflet';
import domtoimage from 'dom-to-image';
import mergeImages from 'merge-images';
const FileSaver = require('file-saver');

export default function prepareScreenshot(map, image, clusterLayer, wmsLayer, polyLayer, legendPolyClosed, legendClosed, legendWMSCommodityClosed, legendWMSMineralsClosed) {
  const div = document.getElementById('dummy');
  legendPolyClosed = false;
  div.innerHTML = '';
  div.style.width = `${document.getElementById('map_OS').clientWidth}px`;
  div.style.backgroundColor = 'white';
  div.style.verticalAlign = 'top';
  const legends = [];
  let leg;
  //polygon layer
  if(polyLayer != '' & !legendPolyClosed)
  {
    leg = document.getElementById('polygonLegend').cloneNode(true);
    leg.style.verticalAlign = 'top';
    leg.style.display = 'inline-block';
    legends.push(leg);
  }
  // cluster layer
  if (clusterLayer != '' & !legendClosed) {
    leg = document.getElementById('markerLegend').cloneNode(true);
    leg.style.display = 'inline-block';
    legends.push(leg);
  }
  //wms layer
  if (wmsLayer.includes('wms_minerals') & !legendWMSMineralsClosed) {
    //minerals
      leg = document.getElementById('wms_minerals').cloneNode(true);
      leg.style.display = 'inline-block';
      legends.push(leg);
  }
   if (wmsLayer.includes('wms_commodity') & !legendWMSCommodityClosed) {
     //critical minerals
      leg = document.getElementById('wms_commodity').cloneNode(true);
      leg.style.display = 'inline-block';
      legends.push(leg);
  }
  /*if (map.hasLayer(wmsLayerCommodity) & !legendWMSCommodityClosed) {
    const labels = ['<strong>Mineralstoffvorkommen  </strong>'];
    const uri = 'https://mrdata.usgs.gov/services/pp1802?version=1.3.0&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&layer=points&format=image/png&STYLE=default';
    leg = document.createElement('div');
    leg.style.display = 'inline-block';
    leg.style.verticalAlign = 'top';
    leg.innerHTML += labels;
    const divlegend = L.DomUtil.create('div', 'div-image-legend', leg);
    const imglegend = L.DomUtil.create('img', 'image-legend', divlegend);
    imglegend.src = uri;
    legends.push(leg);
  }
  if (map.hasLayer(wmsLayerDeposits) & !legendWMSMineralsClosed) {
    const labels = ['<strong>kritische Minteralstoffvorkommen </strong>'];
    const uri = "https://mrdata.usgs.gov/services/ofr20051294?version=1.3.0&amp;service=WMS&amp;request=GetLegendGraphic&amp;sld_version=1.1.0&amp;layer=commodity&amp;format=image/png&amp;STYLE=default";
    leg = document.createElement('div');
    leg.style.display = 'inline-block';
    leg.style.verticalAlign = 'top';
    leg.innerHTML += labels;
    const divlegend = L.DomUtil.create('div', 'div-image-legend', leg);
    const imglegend = L.DomUtil.create('img', 'image-legend', divlegend);
    imglegend.src = uri;
    legends.push(leg);
  }*/
  legends.forEach((value) => {
    div.append(value);
  });
  if (legends.length > 0) {
    domtoimage.toJpeg(div).then((dataUrl) => {
      mergeImages([
        { src: image, x: 0, y: 0 },
        { src: dataUrl, x: 0, y: document.getElementById('map_OS').clientHeight },
      ], {
        height: document.getElementById('map_OS').clientHeight + div.clientHeight,
      }).then((b64) => {
        const today = new Date();
        // eslint-disable-next-line prefer-template
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        // eslint-disable-next-line prefer-template
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        // eslint-disable-next-line prefer-template
        FileSaver.saveAs(b64, 'Demo_KompZKFE_' + date + '_' + time + '.jpeg');
        legends.forEach((value) => {
          div.removeChild(value);
        });
      });
    })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
        legends.forEach((value) => {
          div.remove(value);
        });
      });
  } else {
    FileSaver.saveAs(image, 'my-node.jpeg');
  }
}

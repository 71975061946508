import {
    svgDropDown,
    deleteAndCreateDivElements,
    deleteCheckboxes
} from '../Utils/Map.Layers.Popups.Utils.js';
import axios from 'axios';
import { url, urlFrontendResource } from '../../../App.vue';
import {Auth0Client} from '@auth0/auth0-spa-js';

import { getTokenSilently } from '../../../auth/getToken.js';


const Highcharts = require('highcharts');

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);


const w = document.body.clientWidth - document.body.clientWidth*0.2;  // width histogram;
let h = document.body.clientHeight - document.body.clientHeight*0.84;
const marginNo = document.body.clientHeight*0.001;
const fontSize = document.body.clientHeight*0.02;
const yStart = document.body.clientHeight*0.02;

const infoLink = './info.png';
const dbEreignisse = './db_icon.png'

let Dropdown = 1;
let GID = "";
let SVG, Years, Comtrade_price, Comtrade_price_2021, Comtrade_price_import_2021, Wb_price, Wb_price_nominal, Iea_price, Iea_price_2022, Usgs_price, Multicolour_price, Resource, Country, Name, Annuallocationcapacity = "";
let StartYear, EndYear = 0;
let Events_ACLED, Events_GTD, Events_UCDP = [];
let div;
let config;
let meta_info = "";
let counterReopen = 0;
var dokuWindow_ = null;

const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };


function get_capacity_events_highchart(capacity, title){
    Highcharts.chart('divID', {
    chart:{
        zoomType: 'xy'
    },
    title: {
        text: title
    },
    subtitle: {
        text: 'Quelle Koordinaten: <a href="https://mikedenly.com/datasets/global-resources-dataset" target="_blank"> The Global Resources Dataset</a>'
    },
    xAxis: {
        title: {
            enabled: true,
            text: 'Jahr'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        allowDecimals: false
    },
    yAxis: [{
        title: {
            text: 'Kapazität'
        },
    },{
        title: {
            text: 'Ereignisse'
        },
        allowDecimals: false,
    },

    ],
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
    },
    series: [{
        name: 'Kapazität',
        color: 'rgba(183, 17, 17, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Kapazität: {point.y}'},
        data: capacity
    },
    {
        name: 'Ereignisse ACLED',
        color: 'rgba(0, 0, 0, .8)',
        type: 'scatter',
        data: Events_ACLED,
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        yAxis: 1
    },
    /*{
        name: 'Ereignisse GTD',
        color: 'rgba(24, 87, 23, .8)',
        type: 'scatter',
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        data: Events_GTD,
        yAxis: 1
    },
    {
        name: 'Ereignisse UCDP',
        color: 'rgba(29, 199, 26, .8)',
        type: 'scatter',
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        data: Events_UCDP,
        yAxis: 1
    },*/]
});
}

function get_price_events_highchart(dictData, source, title){
    const price_unit = meta_info['price_unit'];

    Highcharts.chart('divID', {
    chart:{
        zoomType: 'xy'
    },
    title: {
        text: title
    },
    subtitle: {
        text: source
    },
    xAxis: {
        title: {
            enabled: true,
            text: 'Jahr'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        allowDecimals: false
    },
    yAxis: [{
        title: {
            text: 'Preis ' + price_unit
        },
    },{
        title: {
            text: 'Ereignisse'
        },
        allowDecimals: false,
    },{
        title: {
            text: 'Menge [kg]'
        },
    },],
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
    },
    series: dictData
});
}

function get_iea_2022_highchart(dictData){
    Highcharts.chart('divID', {
    chart:{
        zoomType: 'xy'
    },
    title: {
        text: `IEA Produkt Preise für DIESEL in den IEA Ländern für das Jahr 2022.`
    },
    subtitle: {
        text: 'Quelle: <a href="https://www.iea.org/data-and-statistics/data-product/monthly-oil-price-statistics-2" target="_blank"> IEA Monthly Oil Price Statistics</a>',
    },
     xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        accessibility: {
            description: 'Months of the year'
        }
    },
    yAxis: {
        title: {
            text: 'Preis (in US$/Liter))'
        },
    },
     tooltip: {
        crosshairs: true,
        shared: true
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
    },
    series: dictData
});
}

async function get_checkboxes(source, title, titleCap){
        const accessToken = await getTokenSilently();
        if(counterReopen > 0){
        const checkedBoxesAll = ['0_0','0_1','0_2','0_3','0_4','0_5','1_0','1_1','1_2','1_3','1_4','1_5','1_6','1_7','2_0','2_1','2_2'];
        axios.post(url + 'eventtypes/', {checkedDict: checkedBoxesAll, GID: GID},
         {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
               if(Dropdown == 1) {
                   get_price_chart(SVG, response.data.eventsACLED, response.data.eventsGTD = 0, response.data.eventsUSDP = 0, source, title);
               }
               else if(Dropdown = 2){
                   get_capacity_chart(SVG, response.data.eventsACLED, response.data.eventsGTD = 0, response.data.eventsUSDP = 0, titleCap);
               }
         })
    }
    counterReopen++;
    var element = document.getElementsByTagName("divcheckboxes"), index;
    if (element.length > 0){
        for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
        }
        return;
    }

    var divCheckboxes = document.createElement('divCheckboxes');
   var acledCheckboxes = document.createElement('acledCheckboxes');
   var gtdCheckboxes = document.createElement('gtdCheckboxes');
   var ucdpCheckboxes = document.createElement('ucdpCheckboxes');


    acledCheckboxes.innerHTML = `
    <div><b>ACLED: </b>   
          <input type="checkbox" id="1" checked="checked" name="checkboxesDB" value="0_0">
          <label for="AG-Wahl" >Ausschreitungen</label>
          <input type="checkbox" id="2" checked="checked" name="checkboxesDB" value="0_1">
          <label for="AG-Wahl">Explosionen / Gewalt auf Distanz</label>
          <input type="checkbox" id="3" checked="checked" name="checkboxesDB" value="0_2">
          <label for="AG-Wahl">Gewalt gegen Zivilisten</label>
          <input type="checkbox" id="4" checked="checked" name="checkboxesDB" value="0_3">
          <label for="AG-Wahl">Kampfhandlungen</label>
          <input type="checkbox" id="5" checked="checked" name="checkboxesDB" value="0_4">
          <label for="AG-Wahl">Proteste</label>
          <input type="checkbox" id="6" checked="checked" name="checkboxesDB" value="0_5">
          <label for="AG-Wahl">Strategische Entwicklungen</label>
        </div>`

    gtdCheckboxes.innerHTML = `
    <div><b>GTD: </b>   
            <input type="checkbox" id="7" checked="checked" name="checkboxesDB" value="1_0">
            <label for="AG-Wahl">Bewaffneter Angriff</label>
            <input type="checkbox" id="8" checked="checked" name="checkboxesDB" value="1_1">
            <label for="AG-Wahl">Bombenexplossion</label>
            <input type="checkbox" id="9" checked="checked" name="checkboxesDB" value="1_2">
            <label for="AG-Wahl">Entführung</label>
            <input type="checkbox" id="10" checked="checked" name="checkboxesDB" value="1_3">
            <label for="AG-Wahl">Ermordung</label>
            <input type="checkbox" id="11" checked="checked" name="checkboxesDB" value="1_4">
            <label for="AG-Wahl">Geiselnahme (Barrikade)</label>
            <input type="checkbox" id="12" checked="checked" name="checkboxesDB" value="1_5">
            <label for="AG-Wahl">Geiselnahme (Menschenraub)</label>
            <input type="checkbox" id="13" checked="checked" name="checkboxesDB" value="1_6">
            <label for="AG-Wahl">Unbekannt</label>
            <input type="checkbox" id="14" checked="checked" name="checkboxesDB" value="1_7">
            <label for="AG-Wahl">Unbewffneter Angriff</label>
         </div>`

    ucdpCheckboxes.innerHTML = `
    <div><b>UCDP: </b> 
            <input type="checkbox" id="15" checked="checked" name="checkboxesDB" value="2_0">
            <label for="AG-Wahl">Einseitige Gewalt</label>
            <input type="checkbox" id="16" checked="checked" name="checkboxesDB" value="2_1">
            <label for="AG-Wahl">Konflikt (mit staatlicher Beteiligung)</label>
            <input type="checkbox" id="17" checked="checked" name="checkboxesDB" value="2_2">
            <label for="AG-Wahl">Konflikt (ohne staatliche Beteiligung)</label>
        </div>`

    divCheckboxes.innerHTML = `
    <div> Hauptereignistypen</div>`;
    for (const item of Events_ACLED){
        if(!Number.isNaN(item[1])){divCheckboxes.appendChild(acledCheckboxes); break}
    }
    for (const item of Events_GTD){
        if(!Number.isNaN(item[1])){divCheckboxes.appendChild(gtdCheckboxes); break}
    }
    for (const item of Events_UCDP){
        if(!Number.isNaN(item[1])){divCheckboxes.appendChild(ucdpCheckboxes); break}
    }

    document.getElementById('modal-content').appendChild(divCheckboxes);


    for(var i = 1; i< 17; i++) {
         if (document.contains(document.getElementById(i))) {
             document.getElementById(i).onclick = function () {
                 get_checkbox_values(source, title, titleCap);
             }
         }
    }

}

function prepareDatabaseData(dict_events_ACLED, dict_events_GTD, dict_events_UCDP){
        //database data to global lists
        Events_ACLED = []; Events_GTD = []; Events_UCDP = [];
       for (const [key, value] of Object.entries(dict_events_ACLED)) {
            let point = [];
            point.push(parseInt(key));
            point.push(parseInt(value));
            Events_ACLED.push(point);
        }
       for (const [key, value] of Object.entries(dict_events_GTD)) {
            let point = [];
            point.push(parseInt(key));
            point.push(parseInt(value));
            Events_GTD.push(point);
        }
       for (const [key, value] of Object.entries(dict_events_UCDP)) {
            let point = [];
            point.push(parseInt(key));
            point.push(parseInt(value));
            Events_UCDP.push(point);
        }
}


function get_price_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, source, title, titleCap){

    deleteAndCreateDivElements();
    deleteGEMTable();
    prepareDatabaseData(dict_events_ACLED, dict_events_GTD, dict_events_UCDP)
    //get_checkboxes(source, title, titleCap);

    let comtradeNetweight2021 = [];
    let comtradePrice2021 = [];
    let comtradeImportNetweight2021 = [];
    let comtradeImportPrice2021 = [];
    let price_unit = meta_info['price_unit'];
    let comtradeColumn = meta_info['comtrade_column'];
    let comtradWeightColumn = meta_info['comtrade_netweight'];
    let wbColumn = meta_info['wb_column'];

    if(Comtrade_price_2021.length > 0) {
        let comtradeYearsList = Comtrade_price_2021.map(function (o) {
            return o.Year;
        });
        let comtradePriceList = Comtrade_price_2021.map(function (o) {
            return o[comtradeColumn];
        });
        let comtradeNetweightList = Comtrade_price_2021.map(function (o) {
            return o[comtradWeightColumn];
        });
        for (let i = 0; i < Object.keys(Comtrade_price_2021).length; i++) {
            let point = {};
            let pointWeight = [];
            point['x'] = parseInt(comtradeYearsList[i]);
            point['y'] = parseFloat(comtradePriceList[i]);
            point['z'] = parseFloat(comtradeNetweightList[i]);
            pointWeight.push(parseInt(comtradeYearsList[i]));
            pointWeight.push(parseInt(comtradeNetweightList[i]));
            comtradePrice2021.push(point);
            comtradeNetweight2021.push(pointWeight);
        }
    }

    if(Comtrade_price_import_2021.length > 0) {
        let comtradeYearsList = Comtrade_price_import_2021.map(function (o) {
            return o.Year;
        });
        let comtradePriceList = Comtrade_price_import_2021.map(function (o) {
            return o[comtradeColumn];
        });
        let comtradeNetweightList = Comtrade_price_import_2021.map(function (o) {
            return o[comtradWeightColumn];
        });
        for (let i = 0; i < Object.keys(Comtrade_price_import_2021).length; i++) {
            let point = {};
            let pointWeight = [];
            point['x'] = parseInt(comtradeYearsList[i]);
            point['y'] = parseFloat(comtradePriceList[i]);
            point['z'] = parseFloat(comtradeNetweightList[i]);
            pointWeight.push(parseInt(comtradeYearsList[i]));
            pointWeight.push(parseInt(comtradeNetweightList[i]));
            comtradeImportPrice2021.push(point);
            comtradeImportNetweight2021.push(pointWeight);
        }
    }

    let wbPriceNominal = [];
    if(Wb_price_nominal.length > 0) {
        let wbYearsList = Wb_price_nominal.map(function (o) {
            return o.Year;
        });
        let wbPriceNominalList = Wb_price_nominal.map(function (o) {
            return o[wbColumn];
        });
        for (let i = 0; i < Object.keys(Wb_price_nominal).length; i++) {
            let point = [];
            point.push(parseInt(wbYearsList[i]));
            point.push(parseFloat(wbPriceNominalList[i]));
            wbPriceNominal.push(point)
        }
    }

    let ieaPrice = [];
    if(Iea_price.length > 0) {
        let ieaYearsList = [];
        for (let i = 2015; i <= 2022; i++) {
            ieaYearsList.push(i);
        }
        let ieaPriceList = Object.keys(Iea_price[0]).map(function (key) {
            return Iea_price[0][key];
        });
        ieaPriceList.splice(0, 3);
        ieaPriceList.splice(8, 8);
        for (let i = 0; i < 7; i++) {
            let point = [];
            point.push(parseInt(ieaYearsList[i]));
            point.push(parseFloat(ieaPriceList[i]));
            ieaPrice.push(point)
        }
    }

    let comtradePrice = [];
    for (let i = 0; i < Years.length; i++){
      let point = [];
      point.push(parseInt(Years[i]));
      point.push(parseFloat(Comtrade_price[i]));
      comtradePrice.push(point)
    }

    let wbPrice = [];
    for (let i = 0; i < Years.length; i++){
      let point = [];
      point.push(parseInt(Years[i]));
      point.push(parseFloat(Wb_price[i]));
      wbPrice.push(point)
    }

    let usgsPrice = [];
   for (let i = 0; i < Years.length; i++){
      let point = [];
      point.push(parseInt(Years[i]));
      point.push(parseFloat(Usgs_price[i]));
      usgsPrice.push(point)
    }
    let multicolourPrice = [];
    for (let i = 0; i < Years.length; i++){
      let point = [];
      point.push(parseInt(Years[i]));
      point.push(parseFloat(Multicolour_price[i]));
      multicolourPrice.push(point)
    }

    const comtradeWeightDict = {
        name: 'Comtrade Export Menge (kg)',
        color: 'rgba(231, 171, 41, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Menge: {point.y} kg'},
        data: comtradeNetweight2021,
        yAxis: 2,
        visible: false
    };

    const comtradeImportWeightDict = {
        name: 'Comtrade Import Menge (kg)',
        color: 'rgba(214, 79, 108, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Menge: {point.y} kg'},
        data: comtradeImportNetweight2021,
        yAxis: 2,
        visible: false
    };

    const comtradeDict = {
        name: 'Comtrade Preis (GRD Datenbank)',
        color: 'rgba(183, 17, 17, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y} '},
        data: comtradePrice,
        visible: false
    };
    const comtrade2021Dict = {
        name: 'Comtrade Export Preis',
        color: 'rgba(87, 65, 65, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}' + price_unit +', Gesamtverkauf: {point.z} [kg]'},
        data: comtradePrice2021,
        visible: false
    };
    const comtradeImport2021Dict = {
        name: 'Comtrade Import Preis',
        color: 'rgba(135, 153, 57, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}' + price_unit +', Gesamtverkauf: {point.z} [kg]'},
        data: comtradeImportPrice2021,
        visible: false
    };
    const multicolorDict = {
        name: 'Multicolor Preis (GRD Datenbank)',
        color: 'rgba(255, 165, 0, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y} US$'},
        data: multicolourPrice
    };
    const weltbankDict = {
        name: 'Weltbank Preis (GRD Datenbank)',
        color: 'rgba(0, 0, 139, .8)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}'},
        data: wbPrice,
        visible: false,
    };
    const usgsDict = {
        name: 'USGS Preis (GRD Datenbank)',
        color: 'rgba(107, 201, 247, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}'},
        data: usgsPrice,
        visible: false,
    };
    const wbNominalDict = {
        name: 'Weltbank Preis Nominal',
        color: 'rgba(155, 55, 168, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}' + price_unit},
        data: wbPriceNominal
    };
    const ieaDict = {
        name: 'IEA Energie Preis für Diesel (Endnutzerpreis)',
        color: 'rgba(3, 111, 255, .5)',
        type: 'spline',
        marker: {
            symbol: 'diamond',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Preis: {point.y}' + price_unit},
        data: ieaPrice,
        visible: false,
    };
    const acledDict = {
        name: 'Ereignisse ACLED',
        color: 'rgba(0, 0, 0, .8)',
        type: 'scatter',
        data: Events_ACLED,
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        yAxis: 1
    };
    let gtdDict = {
        name: 'Ereignisse GTD',
        color: 'rgba(24, 87, 23, .8)',
        type: 'scatter',
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        data: Events_GTD,
        yAxis: 1
    };
    let ucdpDict = {
        name: 'Ereignisse UCDP',
        color: 'rgba(29, 199, 26, .8)',
        type: 'scatter',
        marker: {
            symbol: 'dot',
        },
        tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: 'Jahr: {point.x}, Ereignisse: {point.y}'},
        data: Events_UCDP,
        yAxis: 1
    };

    let dictData = [];
    for (const item of comtradeWeightDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(comtradeWeightDict);  break;}
    }
    for (const item of comtradeImportWeightDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(comtradeImportWeightDict); break;}
    }
    for (const item of comtradeDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(comtradeDict); break;}
    }
    for (const item of comtrade2021Dict['data']){
        if(!Number.isNaN(item[1])){dictData.push(comtrade2021Dict); break;}
    }
    for (const item of comtradeImport2021Dict['data']){
        if(!Number.isNaN(item[1])){dictData.push(comtradeImport2021Dict); break;}
    }
    for (const item of multicolorDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(multicolorDict); break;}
    }
    for (const item of weltbankDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(weltbankDict); break}
    }
    for (const item of usgsDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(usgsDict); break}
    }
    for (const item of wbNominalDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(wbNominalDict); break}
    }
    for (const item of ieaDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(ieaDict); break}
    }

    for (const item of acledDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(acledDict); break}
    }
    for (const item of gtdDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(gtdDict); break}
    }
    for (const item of ucdpDict['data']){
        if(!Number.isNaN(item[1])){dictData.push(ucdpDict); break}
    }

    get_price_events_highchart(dictData, source, title);
}


function get_capacity_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, title){
    deleteAndCreateDivElements();
    prepareDatabaseData(dict_events_ACLED, dict_events_GTD, dict_events_UCDP)
   // get_checkboxes();

    let capacity = [];
    for (let i = 0; i < Years.length; i++) {
            let point = [];
            point.push(parseInt(Years[i]));
            point.push(parseFloat(Annuallocationcapacity[i]));
            capacity.push(point)
        }
    get_capacity_events_highchart(capacity, title);
}

async function get_checkbox_values(source, title, titleCap) {
  const allChecked = document.querySelectorAll('input[name=checkboxesDB]:checked');
  const checkedBoxes = Array.from(allChecked).map(checkbox => checkbox.value);
  const accessToken = await getTokenSilently();
    axios.post(url + 'eventtypes/', {checkedDict: checkedBoxes, GID: GID},
         {headers: {authorization: `Bearer ${accessToken}`}})
         .then((response) => {
               if(Dropdown == 1) {
                   get_price_chart(SVG, response.data.eventsACLED, response.data.eventsGTD = 0, response.data.eventsUSDP = 0, source, title);
               }
               else if(Dropdown = 2){
                   get_capacity_chart(SVG, response.data.eventsACLED, response.data.eventsGTD = 0, response.data.eventsUSDP = 0, titleCap);
               }
             /*  var element = document.getElementsByTagName("divcheckboxes"), index;
                for (index = element.length - 1; index >= 1; index--) {
                    element[index].parentNode.removeChild(element[index]);
                }*/
         })
    }

function getIEA2022Chart(svg){
    deleteAndCreateDivElements();
    deleteGEMTable();
    deleteCheckboxes();

    let countryDict = [];
    for (let i = 0; i < Iea_price_2022.length; i++) {
        let count = 0;
        let countryName = "";
        let ieaPrice2022 = [];
        for (var key in Iea_price_2022[i]) {
            if (count == 0) {
                countryName = Iea_price_2022[i][key];
            } else if (count == 10) {
                break
            }
            if (count > 0) {
                let point = [];
                point.push(key);
                let price = parseFloat(Iea_price_2022[i][key])*158.987; //Umrechnung in barrel
                point.push(parseFloat(price.toFixed(2)));
                ieaPrice2022.push(point)
            }
            count++;
        }
        let setVisible = false;
        if (countryName == 'Germany'){setVisible = true;}
        let dict = {
            name: countryName,
            type: 'spline',
            marker: {
                symbol: 'dot',
            },
            data: ieaPrice2022,
            visible: setVisible,
        };
        countryDict.push(dict);
    }

    get_iea_2022_highchart(countryDict);
}


function getGlobalEnergyMonitoringChart(svg, dict){

    deleteAndCreateDivElements();
    deleteCheckboxes();

    var element = document.getElementsByTagName("divTable"), index;
    for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
    }

    var unitName = dict[0]['Unit name'];
    var unitType = dict[0]['Unit type'];
    var country = dict[0]['Country'];
    var subNation = dict[0]['Subnation unit (province, state)'];
    var status = dict[0]['Status'];
    var statusYear = dict[0]['Status year'];
    var dicscoveryYear = dict[0]['Discovery year'];
    var productionStart = dict[0]['Production start year'];
    var operator = dict[0]['Operator'];
    var owner = dict[0]['Owner'];
    var parent = dict[0]['Parent'];
    var basin = dict[0]['Basin'];
    var wikiUrl = dict[0]['Wiki URL'];
    var source = 'https://globalenergymonitor.org/projects/global-oil-gas-extraction-tracker/';



    var divTable = document.createElement('divTable');

    let textNode = document.createElement("div");
    textNode.innerHTML = `
    <p align="left" style="padding-left: 15%;  padding-right: 15%; padding-bottom: 5%; float: left">
        <b>NAME: </b>${unitName}  </br>
        <b>TYP: </b>${unitType} </br>
        <b>LAND: </b>${country} </br>
        <b>REGION: </b>${subNation} </br>
        <b>BASIN: </b>${basin} </br>
        <b>BETREIBER: </b>${operator}  </br>
        <b>BESITZER: </b>${owner} </br>
        <b>ÜBERGEORDNET: </b>${parent} </br>
        <b>STATUS: </b>${status} </br>
        <b>STATUS JAHR: </b>${statusYear} </br>
        <b>ENTDECKUNGS JAHR: </b>${dicscoveryYear} </br>
        <b>PRODUKTIONS START: </b>${productionStart} </br>
        <a href=${wikiUrl} target="_blank">WIKI</a> </br>
        <a href=${source} target="_blank">QUELLE</a> </br>
    </p>
`;

     divTable.appendChild(textNode);

    var center = document.createElement('center');
    var table = document.createElement('table');
    table.style.width = '90%';
    var thead = document.createElement('thead');
    var trhead = document.createElement('tr');
    var tdhead1 = document.createElement('td')
    var tdhead2 = document.createElement('td')
    var tdhead3 = document.createElement('td')
    var tdhead4 = document.createElement('td')
    var tdhead5 = document.createElement('td')
    tdhead1.style.background = 'linear-gradient(to right, #FFCC00, yellow)';
    tdhead2.style.background = 'linear-gradient(to right, #FFCC00, yellow)';
    tdhead3.style.background = 'linear-gradient(to right, #FFCC00, yellow)';
    tdhead4.style.background = 'linear-gradient(to right, #FFCC00, yellow)';
    tdhead5.style.background = 'linear-gradient(to right, #FFCC00, yellow)';
    var head1 = document.createTextNode('Production/reserves');
    var head2 = document.createTextNode('Fuel description');
    var head3 = document.createTextNode('Data year');
    var head4 = document.createTextNode('Quantity (converted)');
    var head5 = document.createTextNode('Units (converted)');
    tdhead1.appendChild(head1);
    tdhead2.appendChild(head2);
    tdhead3.appendChild(head3);
    tdhead4.appendChild(head4);
    tdhead5.appendChild(head5);
    trhead.appendChild(tdhead1);
    trhead.appendChild(tdhead2);
    trhead.appendChild(tdhead3);
    trhead.appendChild(tdhead4);
    trhead.appendChild(tdhead5);
    thead.appendChild(trhead);
    table.appendChild(thead);

    var tbody = document.createElement('tbody');
    for (var i = 0; i < dict.length; i++) {
        var trbody = document.createElement('tr');
        var td1 = document.createElement('td');
        var td2 = document.createElement('td');
        var td3 = document.createElement('td');
        var td4 = document.createElement('td');
        var td5 = document.createElement('td');
        var text1 = document.createTextNode(dict[i]['Production/reserves']);
        var text2 = document.createTextNode(dict[i]['Fuel description']);
        var text3 = document.createTextNode(dict[i]['Data year']);
        var text4 = document.createTextNode(dict[i]['Quantity (converted)']);
        var text5 = document.createTextNode(dict[i]['Units (converted)']);
        td1.append(text1);
        td2.append(text2);
        td3.append(text3);
        td4.append(text4);
        td5.append(text5);
        trbody.appendChild(td1);
        trbody.appendChild(td2);
        trbody.appendChild(td3);
        trbody.appendChild(td4);
        trbody.appendChild(td5);
        tbody.appendChild(trbody);
    }
    table.appendChild(tbody);
    center.appendChild(table)
    divTable.appendChild(center)

    document.getElementById('modalContainer').appendChild(divTable);

    return svg.node()
}


function deleteGEMTable(){
    var element = document.getElementsByTagName("divTable"), index;
    for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
    }
}

function infoButton(svg){
    svg.append("svg:image")
          .attr("id", 'infoImage')
          .attr('x', w * 0.5)
          .attr('y', h * 0.15)
          .attr('width', w * 0.025)
          .attr('height', w * 0.025)
          .attr("xlink:href", infoLink)
          .on("click", function (d) {
              if(dokuWindow_ == null || dokuWindow_.closed)
                dokuWindow_ = window.open(urlFrontendResource, '_blank')
              else
                dokuWindow_.focus()
                        }
          )
    return svg;
}

function dbButton(svg, source, title, titleCap){
    svg.append("svg:image")
          .attr("id", 'infoImage')
          .attr('x', w * 0.4)
          .attr('y', h * 0.15)
          .attr('width', w * 0.025)
          .attr('height', w * 0.025)
          .attr("xlink:href", dbEreignisse)
          .on("click", function (d) {
             get_checkboxes(source, title, titleCap);
            }
          )
    return svg;
}

export default function getResourcesChart(meta_info_, dict_iea_price_2022, dict_comtrade, dict_comtrade_import, wb_price_nominal, dict_iea_price, dict, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, GID_, div_){
    dict_events_GTD = 0;
    dict_events_UCDP = 0;
    var chartOpen = document.getElementById("fitFunction");
    if(chartOpen){
       return;
    }

    div = div_;
    deleteCheckboxes();
    deleteGEMTable();
    deleteAndCreateDivElements();
    let svg = div.append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .attr("id", 'fitFunction');

    GID = GID_;
    let source = "";
    meta_info = meta_info_;

    Comtrade_price_2021 = dict_comtrade;
    Comtrade_price_import_2021 = dict_comtrade_import;
    Wb_price_nominal = wb_price_nominal;
    Iea_price = dict_iea_price;
    Iea_price_2022 = dict_iea_price_2022;

    Country = dict[0]['country'];
    Resource = dict[0]['resource'];
    Name = dict[0]['locationname'];
    var title = `${Country}: Preise für ${Resource} und aggregierte Ereignisse.`
    if (Name == 'Länderübersicht'){
        title = `${Country}: Preise für ${Resource} und aggregierte Ereignisse.`
    }
    var titleCap = ` ${Country}: Kapazität für ${Resource} im Werk ${Name} und aggregierte Ereignisse.`

    svg = infoButton(svg);
    svg = dbButton(svg, source, title, titleCap);

    Annuallocationcapacity = [];
    Comtrade_price = [];
    Wb_price = [];
    Usgs_price = [];
    Multicolour_price = [];
    Years = [];

    for (const element of dict) {
        Years.push(element['year']);
        if(element['annuallocationcapacity']){Annuallocationcapacity.push(element['annuallocationcapacity']);}
        Comtrade_price.push(element['comtrade_price']);
        Wb_price.push(element['wb_price']);
        Usgs_price.push(element['usgs_price']);
        Multicolour_price.push(element['multicolour_price']);
    }

    StartYear = Years[0];
    EndYear = Years[Years.length-1];



    SVG = svg;

    //Dropdowns and Popup for GRD and Worldbank Data
     if(Annuallocationcapacity.length > 0) {
         source = 'Quelle Koordinaten: <a href="https://mikedenly.com/datasets/global-resources-dataset" target="_blank"> The Global Resources Dataset</a>';
         let members = [{
                label: "Preise (Land) und Ereignisse (Land)",
                value: 1
            },
            {
                label: "Kapazität (Werk) und Ereignisse (Land)",
                value: 2
            },
            ];
         config = {
             width: document.body.clientWidth * 0.18,
             container: svg,
             members,
             fontSize: fontSize,
             color: "#333",
             fontFamily: "calibri",
             x: document.body.clientWidth * 0.001,
             y: yStart,
             changeHandler: function (option) {
                 svg.selectAll("*").remove();

                 if (option.label == "Preise (Land) und Ereignisse (Land)") {
                     deleteCheckboxes();
                     get_price_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, source, title);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     svg = dbButton(svg, source, title, titleCap);
                     Dropdown = 1;
                 } else if (option.label == "Kapazität (Werk) und Ereignisse (Land)") {
                     deleteCheckboxes();
                     get_capacity_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, titleCap);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     svg = dbButton(svg, source, title, titleCap);
                     Dropdown = 2;
                 }
             }
         };
         svgDropDown(config);
     }

     //Dropdowns and Popup for GEM and Worldbank Data for oil (iea data)
     if ('Subnational unit (province, state)' in dict[0] && dict_iea_price_2022) {
         source = 'Quelle Koordinaten: <a href="https://globalenergymonitor.org/projects/global-oil-gas-extraction-tracker/" target="_blank"> Global Energy Monitor</a>';
         let members = [{
                label: "Preise (Land) und Ereignisse (Land)",
                value: 1
            },
             {
                label: "IEA Preise Diesel 2022",
                value: 2
            },
            {
                label: "Daten",
                value: 3
            },
         ];

          config = {
             width: document.body.clientWidth * 0.18,
             container: svg,
             members,
             fontSize: fontSize,
             color: "#333",
             fontFamily: "calibri",
             x: document.body.clientWidth * 0.001,
             y: yStart,
             changeHandler: function (option) {
                 svg.selectAll("*").remove();

                 if (option.label == "Preise (Land) und Ereignisse (Land)") {
                     deleteCheckboxes();
                     get_price_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, source, title);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     svg = dbButton(svg, source, title, titleCap);
                     Dropdown = 1;
                 }
                 else if (option.label == "IEA Preise Diesel 2022") {
                     getIEA2022Chart(svg);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     Dropdown = 2;
                 }
                 else if (option.label == "Daten") {
                     getGlobalEnergyMonitoringChart(svg, dict);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     Dropdown = 3;
                 }
             }
         };
         svgDropDown(config);
     }

     //Dropdowns and Popup for GEM and Worldbank Data for gas (no iea data)
     if ('Subnational unit (province, state)' in dict[0] && !dict_iea_price_2022) {
         source = 'Quelle Koordinaten: <a href="https://globalenergymonitor.org/projects/global-oil-gas-extraction-tracker/" target="_blank"> Global Energy Monitor</a>';
         let members = [{
                label: "Preise (Land) und Ereignisse (Land)",
                value: 1
            },
            {
                label: "Daten",
                value: 2
            },
         ];

          config = {
             width: document.body.clientWidth * 0.18,
             container: svg,
             members,
             fontSize: fontSize,
             color: "#333",
             fontFamily: "calibri",
             x: document.body.clientWidth * 0.001,
             y: yStart,
             changeHandler: function (option) {
                 svg.selectAll("*").remove();

                 if (option.label == "Preise (Land) und Ereignisse (Land)") {
                     deleteCheckboxes();
                     get_price_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, source, title);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     svg = dbButton(svg, source, title, titleCap);
                     Dropdown = 1;
                 }
                 else if (option.label == "Daten") {
                     getGlobalEnergyMonitoringChart(svg, dict);
                     svgDropDown(config);
                     svg = infoButton(svg);
                     svg = dbButton(svg, source, title, titleCap);
                     Dropdown = 2;
                 }
             }
         };
         svgDropDown(config);
     }

    get_price_chart(svg, dict_events_ACLED, dict_events_GTD, dict_events_UCDP, source, title);

    return svg.node()
}


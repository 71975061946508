import Vue from 'vue';
import { getCurrentUser, signInWithRedirect, signOut, fetchAuthSession } from '@aws-amplify/auth';

let instance;

// let's use the AWS Amplify API for this
// this should allow us to easily manage the user sessions

export const getInstance = () => instance;

export const useCognito = () => {
  if (instance) return instance; // return instance if already instantiated

  instance = new Vue({ // create instance if not instantiated yet
    data() {
      return {
        isLoading: true, // While login is taking place, set is Loading to true
        isAuthenticated: false, // False be default, set to true after check
        accessToken: null,
        error: null, // Store any error messages here
        user: {} // store user data here
      };
    },
    methods: {  
      loginWithRedirect(options) {
        // Simple redirect; no options required, just kept for 
        signInWithRedirect()
      },

      logout(options) {
        // Simple redirect to the official logout endpoint
        signOut()
      },

      async getTokenSilently() {
        // We keep this name so we don't have to change all these lines of code
        // Check if token is valid and not expired;
        // i)   If token is valid and not expired simply return token
        // ii)  If token is valid but expired use refresh token and call Cogntio API
        // iii) If there's no refresh token or no token at all, redirect to login
        if (this.isAuthenticated && this.accessToken) {
          return this.accessToken
        } else {
          const { accessToken } = (await fetchAuthSession()).tokens ?? {};
          this.accessToken = accessToken.toString()
          return this.accessToken
        }
      },
    },

    async created() {
      console.log("[AWS-COGNITO] Plugin loaded...")
      // Do some initial checks
      // Is there a local storage item?
      // If so, are the tokens valid? If expired, use refresh token to get a new one
      // other than that keep isAuthenticated to false
      // As "created" is called everytime the page loads new, we can assume that there's 
      // a redirect happening; so no need for a redirect endpoint as we can simply check
      // for vars etc. This is actually the most important function
      // This will handle logout and login
      this.isLoading = true;
      // Try to get an existing session; if it exists set access token and authenticated to true
      // Get User data
      try {
        const { username, userId } = await getCurrentUser();
        this.user = { username, userId }
        this.isAuthenticated = true;
        this.isLoading = false
      } catch (error) {
        console.log('[AWS-COGNITO] Could not get user data:', error);
      }
      if (!this.isAuthenticated) {
        signInWithRedirect()
      }
      try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        this.accessToken = accessToken.toString()
        this.isAuthenticated = true;
        this.isLoading = false
      } catch (error) {
        console.log('[AWS-COGNITO] Authentication Error: ', error)
      }

    },
  });

  return instance;
};
  
/**
 *  Vue.js Plugin Definition
 */

export const CognitoPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useCognito(options);
  },
};

/*eslint-disable*/
import {deleteAndCreateDivElements, deleteCheckboxes} from '../Utils/Map.Layers.Popups.Utils.js';


const d3 = require('d3');
const Highcharts = require('highcharts');

const width = document.body.clientWidth - document.body.clientWidth*0.2; // width histogram
let height = document.body.clientHeight - document.body.clientHeight*0.7;
const marginNo = document.body.clientHeight*0.001;
const fontSize = document.body.clientHeight*0.02;
const maxData = 25;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };
  const colorFill = '#7777BB';
  const hoverColor = '#0000ff';
  const pressedColor = '#000077';
  const customPalette = ['#124006', '#2f7506', '#7bb858', '#b7ed98', '#F5AD51', '#ed7a77', '#9e1713', '#5c61db', '#0a0e7d'];
  const statusKeys = ['MONOPOLY', 'DOMINANT', 'SENIOR PARTNER', 'JUNIOR PARTNER', 'SELF-EXCLUSION', 'POWERLESS', 'DISCRIMINATED', 'STATE COLLAPSE', 'IRRELEVANT'];

  const ethnieColorMapping = {
  "MONOPOLY": '#124006',
  "DOMINANT": '#2f7506',
  "SENIOR PARTNER": '#7bb858',
  "JUNIOR PARTNER": '#b7ed98',
  "SELF-EXCLUSION": '#F5AD51',
  "POWERLESS": '#ed7a77',
  "DISCRIMINATED": '#9e1713',
  "STATE COLLAPSE": '#5c61db',
  "IRRELEVANT": '#0a0e7d'
}

  let newYear;

function getPopupLegend(svg) {

  let fontS = fontSize;
  let addDist = document.body.clientWidth*0.15;

  const color = d3.scaleOrdinal()
    .domain(statusKeys)
    .range(customPalette);

  const size = fontSize;
  const iStart = 5;
  const iEnd = 10;

  svg.selectAll('legend')
    .data(statusKeys)
    .enter()
    .append('rect')
    .attr('x', (d, i) => {
      let xPos = i * (size + addDist);
      if (i >= iStart && i < iEnd) { xPos = (i - iStart) * (size + addDist); }
      return xPos;
    })
    .attr('y', (d, i) => {
      let yPos = document.body.clientHeight*0.07;
      if (i >= iStart && i < iEnd) { yPos = document.body.clientHeight*0.11; }
      return yPos;
    })
    .attr('width', size)
    .attr('height', size)
    .style('fill', (d) => color(d));

  svg.selectAll('mylabels')
    .data(statusKeys)
    .enter()
    .append('text')
    .style('font-size', fontS)
    .attr('x', (d, i) => {
      let xPos = document.body.clientWidth*0.015 + i * (size + addDist);
      if (i >= iStart && i < iEnd) { xPos = document.body.clientWidth*0.015 + (i - iStart) * (size + addDist); }
      return xPos;
    })
    .attr('y', (d, i) => {
      let yPos = document.body.clientHeight*0.082;
      if (i >= iStart && i < iEnd) { yPos = document.body.clientHeight*0.122; }
      return yPos;
    })
    .text((d) => d)
    .attr('text-anchor', 'left')
    .style('alignment-baseline', 'middle');

  return svg;
}


function getPopupLink(svg, countryName) {
  const linkText = 'Mehr Infos';
  const linkUrl = `https://growup.ethz.ch/pfe/${countryName}`;
  svg.append('text')
    .style('font-size', fontSize)
    .style('fill', '#7777BB')
    .text(linkText)
    .style('text-decoration', 'underline')
    .attr('x', document.body.clientWidth*0.01)
    .attr('y', document.body.clientHeight*0.03)
    //.attr('text-anchor', 'left')
    .on('mouseover', function () { d3.select(this).style('cursor', 'pointer'); })
    .on('mouseout', function () { d3.select(this).style('cursor', 'default'); })
    .on('click', () => { window.open(linkUrl); });

  return svg;
}
function getPopupTimeButtons(svg, g, years, countryName, names, parts, status) {
  const bWidth = document.body.clientWidth*0.05;
  const bHeight = document.body.clientHeight*0.04;
  const bSpace = document.body.clientHeight*0.007;
  const x0 = 0;
  const y0 = document.body.clientHeight*0.2;

  const allButtons = svg.append('g').attr('id', 'allButtons');
  const labels = years;
  const buttonGroups = allButtons.selectAll('g.button')
    .data(labels)
    .enter()
    .append('g')
    .attr('class', 'button')
    .style('cursor', 'pointer')
    .on('click', function (d, i) {
      updateButtonColors(d3.select(this), d3.select(this.parentNode));
      newYear = i;
      let dataEthnieList = getDataSet(names, status, parts, newYear)[0];
      let dataEthnieRedList = getDataSet(names, status, parts, newYear)[1];
      getPopupHistogramm(countryName, newYear, dataEthnieList, dataEthnieRedList);

    })
    .on('mouseover', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', hoverColor);
      }
    })
    .on('mouseout', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', colorFill);
      }
    });

  buttonGroups.append('rect')
    .attr('class', 'buttonRect')
    .attr('width', bWidth)
    .attr('height', bHeight)
    .attr('x', (d, i) => {
      let xPos = x0 + (bWidth + bSpace) * i;
      //if (i >= 10 && i < 20) { xPos = x0 + 15 + width - (bWidth + bSpace) * (i - 9); }
      // if (i >= 20 && i < 30) { xPos = x0 + 15 + width - (bWidth + bSpace) * (i - 18); }
      //if (i >= 30) { xPos = x0 + 15 + width - (bWidth + bSpace) * (i - 27); }
      return xPos;
    })
    .attr('y', (d, i) => {
     // if (i < 10) { return y0; }
     // if (i >= 10 && i < 20) { return y0 + 30; }
     // if (i >= 20 && i < 30) { return y0 + 60; }
      // if (i >= 30) { return y0 + 90; } return y0;
      return y0;
    })
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('fill', colorFill);

  buttonGroups.append('text')
    .attr('class', 'buttonText')
   // .attr('font-family', 'FontAwesome')
      .attr('font-size', fontSize)
    .attr('x', (d, i) => {
      let xPos = x0 + (bWidth + bSpace) * i + bWidth / 2;
      //if (i >= 10 && i < 20) { xPos = 15 + width - (bWidth + bSpace) * (i - 9) + bWidth / 2; }
      //if (i >= 20 && i < 30) { xPos = 15 + width - (bWidth + bSpace) * (i - 18) + bWidth / 2; }
      //if (i >= 30) { xPos = 15 + width - (bWidth + bSpace) * (i - 27) + bWidth / 2; }
      return xPos;
    })
    .attr('y', (d, i) => {
      //if (i >= 10 && i < 20) { return y0 + 30 + bHeight / 2; }
      //if (i >= 20 && i < 30) { return y0 + 60 + bHeight / 2; }
      //if (i >= 30) { return y0 + 90 + bHeight / 2; }
      return y0 + bHeight / 2;
    })
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('fill', 'white')
    .text((d) => d);

  function updateButtonColors(button, parent) {
    parent.selectAll('rect')
      .attr('fill', colorFill);

    button.select('rect')
      .attr('fill', pressedColor);
  }

  return [svg, newYear];
}

function sortDictionary(toSort){
  var sorted = {}
  var keys = Object.keys(toSort);
  keys.sort();
  for (var i=0; i<keys.length; i++) {
    var key = keys[i];
    var value = toSort[key];
    sorted[key] = value;
    }

  return sorted;
}

function getPopupHistogramm(countryName, newYear, dataEthnieList, dataEthnieRedList){
    // Create the chart
Highcharts.chart('divID', {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Aufteilung in Ethnien für ' + countryName + ', ' + newYear
  },
  subtitle: {
    text: 'Quelle: <a href="https://growup.ethz.ch/rfe#" target="_blank"> ETH Zürich GROWup, Last Updated: 6/8/2021</a>'
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },
  xAxis: {
    title: {
      text: 'Ethnien'
    },
    type: 'category'
  },
  yAxis: {
    title: {
      text: 'Anteil der Ethnien in %'
    }

  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      }
    }
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: '<span style="color:black">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
    backgroundColor: {
      linearGradient: [0, 0, 0, 60],
      stops: [
          [0, '#FFFFFF'],
        [1, '#E0E0E0']
      ]
    },
    borderWidth: 1,
    borderColor: '#AAA',
  },

  series: [
    {
      name: "Anteil der Ethnien in %",
      colorByPoint: true,
      data: dataEthnieList
    }
  ],
  drilldown: {
    series: dataEthnieRedList
  }
});
}

function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

function getDataSet(names, status, parts, newYear){
  let part = parts[newYear];
  let name = names[newYear];
  let stat = status[newYear];

  if (stat.length > maxData + 1) {
    part = part.slice(0, maxData);
    stat = stat.slice(0, maxData);
    name = name.slice(0, maxData);
  }
  let dataEthnieList = []

  for (let i in name){
    const colorEthnie = ethnieColorMapping[stat[i]];
    var partNumber = parseFloat(part[i])*100;
    partNumber = round(partNumber);
    let dataEthnieDict = {
      name: name[i].toString(),
      y: partNumber,
      drilldown: name[i].toString(),
      color: colorEthnie
    };
    dataEthnieList.push(dataEthnieDict)
  }

  let dataEthnieRedList = []
  for (let i in name){
    let dataEthnieReducedList = []
    for (let i2 in name){
      const colorEthnie = ethnieColorMapping[stat[i2]];
      var partNumber = parseFloat(part[i2])*100;
      partNumber = round(partNumber);
      let dataEthnieDict = {
        name: name[i2].toString(),
        y: partNumber,
        color: colorEthnie
      };
      dataEthnieReducedList.push(dataEthnieDict);
    }
    dataEthnieReducedList.splice(i, 1);

    let dataEthnieDict = {
      name: name[i].toString(),
      id: name[i].toString(),
      data: dataEthnieReducedList
    };
    dataEthnieRedList.push(dataEthnieDict);
  }
  return [dataEthnieList, dataEthnieRedList];
}

export default function getEthnienHistogram(years, names, parts, status, div, dictInfo) {

  const countryName = dictInfo['countryName'];
    //sort arrays and dictionarys
    years = years.sort()
    parts = sortDictionary(parts);
    names = sortDictionary(names);
    status = sortDictionary(status);

    newYear = years[years.length - 1]

  let dataEthnieList = getDataSet(names, status, parts, newYear)[0];
  let dataEthnieRedList = getDataSet(names, status, parts, newYear)[1];
  
  let svg = div.append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .attr("id", 'fitFunction')

  let g = svg.append('g')
    .attr('transform', `translate(${[margin.left, margin.top]})`);

  deleteAndCreateDivElements();
  deleteCheckboxes();

  svg = getPopupLink(svg, countryName);
  svg = getPopupLegend(svg);
  svg = getPopupTimeButtons(svg, g, years, countryName, names, parts, status)[0];
  newYear = getPopupTimeButtons(svg, g, years, countryName, names, parts, status)[1];

    getPopupHistogramm(countryName, newYear, dataEthnieList, dataEthnieRedList);

  return div.node();
}

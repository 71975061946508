<template>
    <div v-if="mainLegend && mainLegend.active" class="legend-new">
        <a-card 
            v-if="toggled"
            class="legend-box" 
            size="small" 
            :headStyle="{ 'font-weight': 700, 'margin-bottom': '-8px'}"
            :bordered="true" 
            style="border: 0.1em solid black !important; background: rgba(255, 255, 255, 0.8);"
            :title=mainLegend.title>
            <template #extra>
                <div class="btn-shrink" v-on:click="toggled = !toggled">
                    <b-icon icon="arrows-angle-contract" /> 
                </div>
            </template>
            <a-row type="flex" style="align-items: center;" v-for="item in mainLegend.items" :key="item.id">
                <a-checkbox :id="item.id" v-model:checked="item.selected" style="margin: 0 5px 0 0;"></a-checkbox>
                <span class="legend-item-dot" v-bind:style="{ 'background-color': item.icon}"></span>
                {{  item.label }}
            </a-row>
            <template v-if="subLegends.length" v-for="subLegend in subLegends">
                <a-row type="flex" class="legend-sub-head">
                    <div class="legend-sub-title">{{ subLegend.title }}</div>
                    <div class="btn-shrink" v-on:click="toggled = !toggled">
                        <b-icon icon="arrows-angle-contract" /> 
                    </div>
                </a-row>
                <a-row type="flex" style="align-items: center;" v-for="item in subLegend.items" :key="item.id">
                    <a-checkbox v-if="subLegend.checkbox" :id="item.id" v-model:checked="item.selected" style="margin: 0 5px 0 0;"></a-checkbox>
                    <span  v-if="subLegend.iconType == 'dot'" class="legend-item-dot" v-bind:style="{ 'background-color': item.icon}"></span>
                    <div v-if="subLegend.iconType == 'icon'" class="legend-sub-item-icon">
                        <img :src="item.icon">
                    </div>
                    <template v-if="item.href">
                        <a :href="item.href" target="_blank">{{  item.label }}</a>
                    </template>
                    <template v-else>
                        {{  item.label }}
                    </template>
                </a-row>
                <a-row v-if="Object.hasOwn(subLegend, 'meta')">
                    {{ subLegend.meta }}
                </a-row>
            </template>
            <a-row v-if="aggLegends" class="polygond-legend-container">
                <div v-html="aggLegendHtml"></div>
                <a-slider v-if="sliderTypes.includes(aggLegends)" :default-value="2020" :min="2000" :max="2020" :marks="marks" :step="5" @afterChange="timeUpdate" />
            </a-row>
        </a-card>
        <div v-if="!toggled" class="legend-button-open">
            <a-button
                style="border: 0.1em solid black !important; background: rgba(255, 255, 255, 0.8); font-weight: 700;"
                v-on:click="toggled = !toggled"
            >
            <b-icon font-scale="0.8" icon="arrows-angle-expand" style="margin-right: 5px;"/> 
            Legende
            </a-button>
        </div>  
    </div>
    <div v-else-if="subLegends.length" class="legend-new">
        <a-card 
            v-if="toggled"
            class="legend-box" 
            size="small" 
            :headStyle="{ 'font-weight': 700, 'margin-bottom': '-8px'}"
            :bordered="true" 
            style="border: 0.1em solid black !important; background: rgba(255, 255, 255, 0.8);"
            :title=subLegends[0].title>
            <template #extra>
                <div class="btn-shrink" v-on:click="toggled = !toggled">
                    <b-icon icon="arrows-angle-contract" /> 
                </div>
            </template>
            <a-row type="flex" style="align-items: center;" v-for="item in subLegends[0].items" :key="item.id">
                <a-checkbox v-if="subLegends[0].checkbox" :id="item.id" v-model:checked="item.selected" style="margin: 0 5px 0 0;"></a-checkbox>
                <span  v-if="subLegends[0].iconType == 'dot'" class="legend-item-dot" v-bind:style="{ 'background-color': item.icon}"></span>
                <div v-if="subLegends[0].iconType == 'icon'" class="legend-sub-item-icon">
                    <img :src="item.icon">
                </div>
                <template v-if="item.href">
                    <a :href="item.href" target="_blank">{{  item.label }}</a>
                </template>
                <template v-else>
                    {{  item.label }}
                </template>
            </a-row>
            <a-row v-if="Object.hasOwn(subLegends[0], 'meta')">
                    {{ subLegends[0].meta }}
                </a-row>
            <template v-if="subLegends.length > 1" v-for="subLegend in subLegends.slice(1)">
                <a-row type="flex" class="legend-sub-head">
                    <div class="legend-sub-title">{{ subLegend.title }}</div>
                    <div class="btn-shrink" v-on:click="toggled = !toggled">
                        <b-icon icon="arrows-angle-contract" /> 
                    </div>
                </a-row>
                <a-row type="flex" style="align-items: center;" v-for="item in subLegend.items" :key="item.id">
                    <a-checkbox v-if="subLegend.checkbox" :id="item.id" v-model:checked="item.selected" style="margin: 0 5px 0 0;"></a-checkbox>
                    <span  v-if="subLegend.iconType == 'dot'" class="legend-item-dot" v-bind:style="{ 'background-color': item.icon}"></span>
                    <div v-if="subLegend.iconType == 'icon'" class="legend-sub-item-icon">
                        <img :src="item.icon">
                    </div>
                    <template v-if="item.href">
                        <a :href="item.href" target="_blank">{{  item.label }}</a>
                    </template>
                    <template v-else>
                        {{  item.label }}
                    </template>
                </a-row>
                <a-row v-if="Object.hasOwn(subLegend, 'meta')">
                    {{ subLegend.meta }}
                </a-row>
            </template>
            <!-- Turn this into a component -->
            <a-row v-if="aggLegends" class="polygond-legend-container">
                <div v-html="aggLegendHtml"></div>
                <a-slider v-if="sliderTypes.includes(aggLegends)" :default-value="2020" :min="2000" :max="2020" :marks="marks" :step="5" @afterChange="timeUpdate" />
            </a-row>
        </a-card>
        <div v-if="!toggled" class="legend-button-open">
            <a-button
                style="border: 0.1em solid black !important; background: rgba(255, 255, 255, 0.8); font-weight: 700;"
                v-on:click="toggled = !toggled"
            >
            <b-icon font-scale="0.8" icon="arrows-angle-expand" style="margin-right: 5px;"/> 
            Legende
            </a-button>
        </div> 
    </div>
    <div v-else-if="aggLegends && !mainLegend.active && !subLegends.length" class="legend-new">
        <a-card 
            v-if="toggled"
            class="legend-box" 
            size="small" 
            :bordered="true" 
            style="border: 0.1em solid black !important; background: rgba(255, 255, 255, 0.8);">
            <!-- Turn this into a component -->
            <a-row v-if="aggLegends" class="polygond-legend-container-single">
                <div v-html="aggLegendHtml"></div>
                <a-slider v-if="sliderTypes.includes(aggLegends)" :default-value="2020" :min="2000" :max="2020" :marks="marks" :step="5" @afterChange="timeUpdate" />
            </a-row>
        </a-card>
    </div>
</template>

<script>

import {getLegendPolygon} from '../assets/js/legend';
import { updateTime } from '../assets/js/Map.Legend';
import {
    geoJsonRegionDensity, geoJsonCountryDensity
} from './Map.vue';

    export default {
        name: "Legend",
        props: {
            title: String
        }, 
        methods: {
            getLegendPolygon,
            timeUpdate(value) {
                updateTime(Object.values(this.marks).indexOf(value), 'countryPop')
                this.setAggLegendHtml()
                geoJsonCountryDensity.resetStyle();
                geoJsonRegionDensity.resetStyle();
            },
            setAggLegendHtml() {
                this.aggLegendHtml = getLegendPolygon(this.aggLegends)
            }
        },
        data() {
            return {
                toggled: true,
                marks: { 2000: 2000, 2005: 2005, 2010: 2010, 2015: 2015, 2020: 2020},
                sliderTypes: ['country_density', 'region_density'],
                aggLegendHtml: null
            }
        },
        computed: {
            mainLegend() {
                return this.$store.state.legend.main !== undefined ? this.$store.state.legend.main : null
            },
            subLegends() {
                return this.$store.state.legend.sub !== undefined ? this.$store.state.legend.sub : null
            },
            aggLegends() {
                return this.$store.state.legend.agg !== undefined ? this.$store.state.legend.agg : null
            }
        },
        watch: {
            aggLegends: function(newAgg, oldAgg) {
                this.setAggLegendHtml()
            }
        }
    }
</script>

<style scoped>

.legend-sub-item-icon {
    margin-right: 10px;
}

.legend-sub-item-icon img {
    max-width: 18px;
}
.legend-sub-head {
    min-height: 36px;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    margin: 0 -12px 0 -12px;
    padding: 0 12px 0 12px;
}

.legend-sub-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 700;
    flex: 1;
}

.legend-item-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
.legend-new {
    position: fixed;
    bottom: 10px;
    z-index: 10;
}

.polygond-legend-container {
    margin: 10px 0 0 0;
    border-top: 1px solid #e8e8e8;
    padding: 0 12px 0 12px;
}

.polygond-legend-container-single {
    margin: 0;
    padding: 0 12px 0 12px;
}
.legend-box {
    border-radius: 10px;
}

.ant-card-head-title {
    font-weight: 700;
}

.ant-btn span {
    color: rgb(44, 62, 80);
}

.ant-btn:hover {
    color: rgb(44, 62, 80);
    box-shadow: 0 0 11px rgba(33,33,33,.8);
}

.btn-shrink {
    border-radius: 10px;
    margin-left: 5px;
    padding: 5px;
    color: rgb(44, 62, 80);
    cursor: pointer;
    margin: -8px 0 -8px 0;
}
.btn-shrink:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8);
}

</style>


import L from 'leaflet';
import axios from 'axios';
import worker from './worker';
import getPopUp from './Popups/Map.Markers.Popup';
import { url } from '../../App.vue';
import { getTokenSilently } from '../../auth/getToken';

const colors = ['#FFB300', '#803E75', '#FF6800', '#A6BDD7', '#C10020', '#CEA262', '#817066', '#007D34', '#F6768E', '#00538A', '#FF7A5C', '#53377A', '#FF8E00', '#B32851', '#F4C800', '#7F180D', '#93AA00', '#593315', '#F13A13', '#232C16'];
let clicked = false;

function getCircleIcon(event, map) {
  const color = colors[event];
  const zoom = map.getZoom();
  const size = 16 + zoom * 0.5;
  const icon = L.divIcon({
    html: `<div class='leaflet-div-icon2' style='width: ${size}px;height: ${size}px;border-radius: 10px;background: ${color};border: 1px solid #000;opacity: 0.85'>  </div>`,
    className: 'leaflet-div-icon2',
  });

  return icon;
}

function createPruneClusterIcon(pruneCluster) {
  // eslint-disable-next-line no-param-reassign
  pruneCluster.BuildLeafletClusterIcon = function (cluster) {
    const e = new L.Icon.MarkerCluster();
    e.stats = cluster.stats;
    e.population = cluster.population;
    return e;
  };
  const pi2 = Math.PI * 2;
  L.Icon.MarkerCluster = L.Icon.extend({
    options: {
      iconSize: new L.Point(44, 44),
      className: 'prunecluster leaflet-markercluster-icon',
    },

    createIcon() {
      // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
      const e = document.createElement('canvas');
      // eslint-disable-next-line no-underscore-dangle
      this._setIconStyles(e, 'icon');
      const s = this.options.iconSize;
      e.width = s.x;
      e.height = s.y;
      this.draw(e.getContext('2d'), s.x, s.y);
      return e;
    },

    createShadow() {
      return null;
    },

    /* eslint-disable no-param-reassign */
    draw(canvas) {
      let start = 0;
      for (let i = 0, l = colors.length; i < l; ++i) {
        const size = this.stats[i] / this.population;

        if (size > 0) {
          canvas.beginPath();
          canvas.moveTo(22, 22);
          canvas.fillStyle = colors[i];
          let from = start + 0.14;
          const to = start + size * pi2;

          if (to < from) {
            from = start;
          }
          canvas.arc(22, 22, 22, from, to);

          start += size * pi2;
          canvas.lineTo(22, 22);
          canvas.fill();
          canvas.closePath();
        }
      }

      canvas.beginPath();
      canvas.fillStyle = 'white';
      canvas.arc(22, 22, 18, 0, Math.PI * 2);
      canvas.fill();
      canvas.closePath();

      canvas.fillStyle = '#555';
      canvas.textAlign = 'center';
      canvas.textBaseline = 'middle';
      canvas.font = 'bold 12px sans-serif';

      canvas.fillText(this.population, 22, 22, 40);
    },
    /* eslint-enable no-param-reassign */
  });
}

export default async function prepareLeafletMarker(pruneCluster, map) {
  const accessToken = await getTokenSilently();
  createPruneClusterIcon(pruneCluster);
  // eslint-disable-next-line no-param-reassign
  pruneCluster.PrepareLeafletMarker = function (leafletMarker, data) {
    leafletMarker.on('click', async () => {
      if (!clicked) {
        clicked = true;
        if (!data.properties.NOTES) {
          worker.send({
            progress: 10,
          });
          const res = await axios.get(url + 'data/', { params: { id: data.properties.id, db: data.properties.db },
                      headers: {authorization: `Bearer ${accessToken}`} });
          let resData;
          if (Object.prototype.toString.call(res.data) === '[object Array]') {
            resData = res.data;
          } else {
            resData = JSON.parse(res.data);
          }
          // eslint-disable-next-line no-param-reassign
          data.properties = { ...data.properties, ...resData[0] };
          worker.send({
            progress: 100,
          });
        }
        getPopUp(data);
        clicked = false;
      }
    });
    const icon = getCircleIcon(data.properties.event, map);
    leafletMarker.setIcon(icon);
  };
  // eslint-disable-next-line no-param-reassign
  pruneCluster.BuildLeafletCluster = function (cluster, position) {
    const m = new L.Marker(position, {
      icon: pruneCluster.BuildLeafletClusterIcon(cluster),
    });

    m.on('click', () => {
      // Compute the  cluster bounds (it's slow : O(n))
      const markersArea = pruneCluster.Cluster.FindMarkersInArea(cluster.bounds);
      const b = pruneCluster.Cluster.ComputeBounds(markersArea);

      if (b) {
        const bounds = new L.LatLngBounds(
          new L.LatLng(b.minLat, b.maxLng),
          new L.LatLng(b.maxLat, b.minLng),
        );

        /* eslint-disable no-underscore-dangle */
        const zoomLevelBefore = pruneCluster._map.getZoom();
        const zoomLevelAfter = pruneCluster._map.getBoundsZoom(bounds, false,
          new L.Point(20, 20, null));

        // If the zoom level doesn't change
        if (zoomLevelAfter === zoomLevelBefore) {
          // Send an event for the LeafletSpiderfier
          pruneCluster._map.fire('overlappingmarkers', {
            cluster: pruneCluster,
            markers: markersArea,
            center: m.getLatLng(),
            marker: m,
          });

          pruneCluster._map.setView(position, zoomLevelAfter);
        } else {
          pruneCluster._map.fitBounds(bounds);
        }
        /* eslint-enable no-underscore-dangle */
      }
    });
    m.on('mouseover', () => {
      // do mouseover stuff here
    });
    m.on('mouseout', () => {
      // do mouseout stuff here
    });

    return m;
  };
}

import {
    deleteAndCreateDivElements,
    deleteCheckboxes,
    deleteDivElements,
    getInfoHover
} from '../Utils/Map.Layers.Popups.Utils.js';
import {minColorMoni, maxColorMoni} from '../../../components/Map.vue';

import namingConventions from '../../../../NamingConventions';
const amountEve = namingConventions().german.technical.popups.axis.amountEve;
const amountFat = namingConventions().german.technical.popups.axis.amountFat;

const Highcharts = require('highcharts');
const moment = require('moment');
const d3 = require('d3');

const marginNo = document.body.clientWidth*0.001;
const fontSize = document.body.clientHeight*0.02;
const w = document.body.clientWidth - document.body.clientWidth*0.2; // width histogram
const h = document.body.clientHeight - document.body.clientHeight*0.82;
let hotspotColor;

const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };

const yStart = document.body.clientHeight*0.01;
let Type = amountFat;
let GID = "Land";

function getColor(t,minColor, maxColor) {
  let linScale;
  let colorScaleLog;
    linScale = d3.scaleSqrt().domain([minColor, maxColor]);
    colorScaleLog = d3.scaleSequential((d) => d3.interpolateHslLong("green", "red")(linScale(d)));
   let rgbaColor = colorScaleLog(t);
   let rgbaColor2 = rgbaColor.slice(0, -1) + ', 0.2)';
  return rgbaColor2;
}

function getDaysArray(start, end) {
    for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(moment(new Date(dt)).format('YYYY-MM-DD') );
    }
    return arr;
};


function getArrow(svg, infoDiv, Type, GID, endDate){

    let sevenDaysStart = new Date(endDate);
    const averageDelta = infoDiv['averageDelta'];
    sevenDaysStart.setDate(sevenDaysStart.getDate() - 6);
    const formatYmd = sevenDaysStart.toISOString().slice(0, 10);
    const text1 = `${Type} der letzten ${infoDiv['averageDays']} Tage (${formatYmd} bis ${infoDiv['averageEndDate']} ): ${infoDiv['averageSum']}`;

    svg.append('text')
    .style('font-size', fontSize)
    .text(text1)
     .attr('x', w / 2 - w*0.3)
    .attr('y', yStart + document.body.clientHeight*0.04)
    .attr('text-anchor', 'right')

    const text2 = `Wachstum ${Type} (Delta) relativ zu den den vorhergegangenen Wochen: ${averageDelta}`;
  svg.append('text')
    .style('font-size', fontSize)
    .text(text2)
    .attr('x', w / 2 - w*0.3)
    .attr('y', yStart + document.body.clientHeight*0.07)
    .attr('text-anchor', 'right')

}

export default function getPopUpTrendStart(polyFitData, div, dictInfo) {

  let country = dictInfo['countryName'];
  var pointCloudString = polyFitData['point_cloud'];
  //no data
  if(pointCloudString == 0){
      alert("Keine Daten für " + country + " vorhanden!");
      return 0;
  }

  const startDate = polyFitData['start_date'];
  const endDate = polyFitData['end_date'];
  const dataLengthString = pointCloudString.length;
  const fitParamA = polyFitData.fit_parameters['a'];
  const fitParamB = polyFitData.fit_parameters['b'];
  const fitY1 = fitParamB;
  let fitY2;

  if(dictInfo['type'] === 'eve'){
      Type = amountEve;
      hotspotColor = getColor(dictInfo['averageDelta'],minColorMoni['country_eve'], maxColorMoni['country_eve']);
      if(dictInfo['GID'] === 'GID_1'){
          hotspotColor = getColor(dictInfo['averageDelta'],minColorMoni['region_eve'], maxColorMoni['region_eve']);
      }
  }
  else{
      Type = amountFat;
      hotspotColor = getColor(dictInfo['averageDelta'],minColorMoni['country_fat'], maxColorMoni['country_fat']);
      if(dictInfo['GID'] === 'GID_1'){
          hotspotColor = getColor(dictInfo['averageDelta'],minColorMoni['region_fat'], maxColorMoni['region_fat']);
      }
  }
  if(dictInfo['GID'] === 'GID_1'){GID = 'Region';}
  else{GID = 'Land';}

  let pointCloud;
  if(dataLengthString > 0 ){
    pointCloudString = pointCloudString.slice(1, -1);
    pointCloud = pointCloudString.split(",");
  }

  const dataLength = pointCloud.length;
  if(dataLength < 14){alert("Es ist ein Zeitraum von mind. 7 Tagen für die Berechnung notwendig!");}

  var daylist = getDaysArray(new Date(startDate),new Date(endDate));
  let pointCloudArray = [];
  for (let i = 0; i < dataLength; i++){
      let pointDict = {};
      pointDict['x'] = new Date(daylist[i]);
      pointDict['y'] = parseInt(pointCloud[i]);
      pointCloudArray.push(pointDict)
  }

  const lengthOfData = pointCloudArray.length;
  fitY2 = fitParamA*pointCloudArray.length + fitParamB;
  const bandStart = pointCloudArray[lengthOfData - 7]['x'];
  const bandEnd = pointCloudArray[lengthOfData - 1]['x'];

  deleteDivElements();

let svg = div.append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .attr("id", 'fitFunction')


  //exception text if time span is too short
  if(dataLength == 0) {
      svg.append('text')
        .attr('x', w/5)
        .attr('y', h/2)
        .attr('font-size', fontSize)
        .text('Zu kleine Zeitspanne!')

      svg.append('text')
        .attr('x', w/5)
        .attr('y', h/2 - 30)
        .attr('font-size', fontSize)
        .text('Zeitspanne muss mindestens 6 Tage lang sein.')

    return svg.node()
  }

getArrow(svg, dictInfo, Type, GID, endDate);
getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);
deleteAndCreateDivElements();
deleteCheckboxes();

Highcharts.chart('divID', {
    chart: {
        type: 'scatter',
        zoomType: 'xy',
        marginRight: 30
    },

    tooltip: {
        backgroundColor: {
            linearGradient: [0, 0, 0, 60],
            stops: [
                [0, '#FFFFFF'],
                [1, '#E0E0E0']
            ]
        },
        borderWidth: 1,
        borderColor: '#AAA',
        formatter: function() {
            let labeltext1 = "Datum: ";
            let labeltext2 = `${Type}: `;
          return labeltext1.bold() + Highcharts.dateFormat('%d.%m.%Y', (this.x)) + "<br/>" + labeltext2.bold() + this.y;
        }
    },
    title: {
        text: `${Type} für ${country} zwischen ${startDate} und ${endDate}`
    },
    subtitle: {
        text: 'Quelle: <a href="https://acleddata.com/#/dashboard" target="_blank"> ACLED Datenbank</a>'
    },
    navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20,
                    y: 20
                }
            },
    xAxis: {
        plotBands: [{
            from: bandStart,
            to: bandEnd,
            color: hotspotColor
        }],
      type: 'datetime',
      crosshair: true,

      labels: {
        formatter: function() {
            return Highcharts.dateFormat('%d.%m.%y', (this.value));
        }
      }
    },
    yAxis: {
        allowDecimals: false,
        title: {
            text: Type
        }
    },
    legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 100,
        y: 70,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
        borderWidth: 1
    },
    plotOptions: {
        scatter: {
            stickyTracking: false,
            turboThreshold: 0,
            marker: {
                radius: 5,
                states: {
                    hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                    }
                }
            },
            states: {
                hover: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}: {point.y}</b><br>',
                pointFormat: '{point.x}'
            }
        }
    },
    series: [{
        type: 'scatter',
        name: Type,
        color: 'rgba(35, 46, 152, .5)',
        data: pointCloudArray,
        marker: {
                radius: 4
            }
    },
        {
            type: 'line',
            name: 'Lineare Regression',
            color: 'rgba(35, 46, 152, .5)',
            data: [{"x": pointCloudArray[0]['x'], "y":fitY1}, {"x": pointCloudArray[lengthOfData - 1]['x'], "y": fitY2}],
            marker: {
                enabled: false
            }
        }]
},);

  return svg.node()

}

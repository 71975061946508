<template>
  <a-tree-select
      style="width: 100%"
      treeNodeFilterProp="title"
      :tree-data="countryOptions"
      tree-checkable
      v-model="country"
      :show-checked-strategy="SHOW_PARENT"
      search-placeholder="Auswahl"/>
</template>
<script>
import axios from 'axios';
import { TreeSelect } from 'ant-design-vue';
import { url } from '../App.vue';

const { SHOW_PARENT } = TreeSelect;

export default {
  name: 'CountryFilter',
  data() {
    return {
      SHOW_PARENT,
      tabs: [],
      visible: true,
      countryPuffer: this.countryInput,
      countryOptions: [],
      size: 12,
      filterSize: 12,
      // filter: this.filterInput,
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    await axios.get(url + 'countries/', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params: {
        db: this.$store.state.db,
      },
    })
      .then((response) => {
        this.countryOptions = response.data;
      }).catch();
  },
  props: {
    countryInput: {
      required: true,
      type: Array,
    },
  },
  computed: {
    country: {
      get() {
        return this.countryPuffer;
      },
      set(value) {
        this.$emit('update:countryInput', value);
      },
    },
  },
  watch: {
    '$store.state.db': function () {
      const db = this.$store.state.db;
      this.getSelectOptionCountry(db);
    },
    countryInput: {
      deep: true,
      handler(value) {
        this.countryPuffer = value;
      },
    },
  },
  methods: {
    async getSelectOptionCountry(db) {
      const accessToken = await this.$auth.getTokenSilently();
      await axios.get(url + 'countries/', {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        params: {
          db,
        },
      })
        .then((response) => {
          this.countryOptions = response.data;
          this.$emit('update:countryInput', this.countryPuffer);
        }).catch();
    },
  },
};
</script>

import {
    svgDropDown,
    deleteDivElements,
    deleteAndCreateDivElements,
    deleteCheckboxes
} from '../Utils/Map.Layers.Popups.Utils.js';

const Highcharts = require('highcharts');
require('highcharts/modules/streamgraph')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/no-data-to-display.js')(Highcharts);
require('highcharts/highcharts-more.js')(Highcharts);
require('highcharts/modules/timeline.js')(Highcharts);


const w = document.body.clientWidth - document.body.clientWidth*0.2;  // width histogram
const h = document.body.clientHeight;
const fontSize = document.body.clientHeight*0.02;
const marginNo = document.body.clientHeight*0.001;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
      };
const heightSpaceForDropDown = document.body.clientHeight*0.2;
const yStart = document.body.clientHeight*0.02;


function getChartSources(svg, config, name,  sources, noa, cov, url_Data){

    svg.attr('width', w + margin.left + margin.right)
    .attr('height', heightSpaceForDropDown)

    deleteAndCreateDivElements();

    svgDropDown(config);

    Highcharts.chart('divID', {
        chart: {
            height:428,
            type: 'bar'
        },
        title: {
            useHTML: true,
            text: `Quellen ACLED-Daten für ${name}`,
            align: 'center',
            style: {
                    color: 'black',
                    fontWeight: 'bold',
            }
        },
        subtitle: {
            style: {
                fontSize: '10px',
                color: 'black'
            },
            useHTML: true,
            text: '<strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zeitraum: 01.01.2017 - 09.12.2021</strong><br> ' +
                  '<strong>- zitiert in mindestens 5 % aller Ereignisse -</strong>'
        },
        xAxis: {
            type: 'category',
            title: {
                text: null
            },
            categories: sources,
            labels: {
                step: 1,
                style: {
                        color: 'black',
                        fontWeight: 'bold',
                },
                useHTML: true,
                formatter: function() {
                           return '<a href="'+url_Data[this.value] +'"  target="_blank">'+ this.value +'</a>';
                }
            }
        },
        yAxis: [{
            gridLineWidth: 0.5,
            allowDecimals: false,
            title: {
                text: 'Anzahl Meldungen',
                style: {
                    color: 'rgba(17, 179, 8, 1)',
                    fontWeight: 'bold',
                }
            },
            labels: {
                style: {
                    color: 'rgba(17, 179, 8, 1)',
                    fontWeight: 'bold',
                }
            }
        },{
            gridLineWidth: 0.5,
            allowDecimals: false,
            title: {
                text: 'Anteil an Gesamtereignissen',
                style: {
                    color: 'rgba(0, 75, 147, 1)',
                    fontWeight: 'bold',
                }
            },
            labels:{
                format: '{value} %',
                style:{
                    color: 'rgba(0, 75, 147, 1)',
                    fontWeight: 'bold',
                }
            },
            opposite: true
        }],
        tooltip: {
            shared: true
        },
        plotOptions: {
            bar: {
                events: {
                    legendItemClick: function () {
                        if (this.name === 'Anteil an Gesamtereignissen') {
                            if (this.visible) {
                                this.chart.yAxis[1].update({visible: false});
                            } else {
                                this.chart.yAxis[1].update({visible: true});
                            }
                        } else {
                            if (this.visible) {
                                this.chart.yAxis[0].update({visible: false});
                            } else {
                                this.chart.yAxis[0].update({visible: true});
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                }
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Anteil an Gesamtereignissen',
            color: 'rgba(0, 75, 147, 1)',
            data: cov,
            yAxis: 1,
            tooltip: {
                valueSuffix: ' %'
            },
        },{
            name: 'Anzahl Meldungen',
            color: 'rgba(17, 179, 8, 1)',
            data: noa,
        }],
        lang: {
            noData: "Keine Daten !"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        }
    });
}

function getChartUsers(svg, config, usr, origin, name) {

    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

    deleteAndCreateDivElements();

    svgDropDown(config);

    let source = '<strong>Quelle:</strong> <a href="https://datareportal.com/library" target="_blank"><strong>DATAREPORTAL</strong></a>'

    Highcharts.chart('divID', {
        chart: {
            type: 'column',
        height: 428,
        },
        title: {
            useHTML: true,
            text: `Social Media- & und Internet-Nutzung für ${name}`,
            style: {
                    color: 'black',
                    fontWeight: 'bold',
            }
        },
        subtitle: {
            style: {
                fontSize: '10px',
                color: 'black'
            },
            text: source
        },
        xAxis: {
            categories: origin,
            labels: {
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                },
            },
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Anteil an der Gesamtbevölkerung',
                style: {
                        color: 'black',
                        fontWeight: 'bold',
                }
            },
            labels:{
                format: '{value} %',
                style:{
                    color: 'black',
                    fontWeight: 'bold',
                }
            },
        },
        tooltip: {
            shared: true,
            valueSuffix: ' %'
        },
        plotOptions: {
            series: {
                pointPadding: 0.2,
                borderWidth: 0,
                colorByPoint: true,
                depth: 25,
            }
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Anteil',
            color: 'rgba(17, 179, 8, 1)',
            data: usr,
        }],
        lang: {
            noData: "Keine Daten !"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
    });
}

function getChartFOP(svg, config, name, scoArray, ranArray) {

    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

    deleteAndCreateDivElements();

    svgDropDown(config);

    let source = '<strong>Quelle:</strong> <a href="https://rsf.org/en" target="_blank"><strong>REPORTERS WITHOUT BORDERS</strong></a>'

    Highcharts.chart('divID', {
        chart: {
            height:428,
            type: 'spline'
        },
        legend: {
            symbolWidth: 40,
        },
        title: {
            useHTML: true,
            text: `Press Freedom Index für ${name}`,
            style: {
                    color: 'black',
                    fontWeight: 'bold',
            }
        },
        subtitle: {
            style: {
                fontSize: '10px',
                color: 'black'
            },
            text: source
        },
        yAxis: [{
            gridLineWidth: 0.5,
            allowDecimals: false,
            title: {
                text: 'Index',
                style: {
                    color: 'rgba(17, 179, 8, 1)',
                    fontWeight: 'bold',
                }
            },
            labels: {
                style: {
                    color: 'rgba(17, 179, 8, 1)',
                    fontWeight: 'bold',
                }
            }
        },{
            gridLineWidth: 0.5,
            allowDecimals: false,
            title: {
                text: 'Rang',
                style: {
                    color: 'rgba(0, 75, 147, 1)',
                    fontWeight: 'bold',
                }
            },
            labels:{
                format: 'Nr. {value}',
                style:{
                    color: 'rgba(0, 75, 147, 1)',
                    fontWeight: 'bold',
                }
            },
            opposite: true,
            visible: false
        }],
        xAxis: {
            crosshair: {
                color:'black',
                dashStyle: 'shortdot',
            },
            title: {
                text: 'Jahr',
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                },
            },
            type: 'datetime',
            tickInterval:  31536000000, // one year
            labels: {
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function () {
                        if (this.name === 'Rang') {
                            if (this.visible) {
                                this.chart.yAxis[1].update({visible: false});
                            } else {
                                this.chart.yAxis[1].update({visible: true});
                            }
                        } else {
                            if (this.visible) {
                                this.chart.yAxis[0].update({visible: false});
                            } else {
                                this.chart.yAxis[0].update({visible: true});
                            }
                        }
                    }
                },
            }
        },
        series: [{
            name: 'Index',
            data: scoArray,
            color: 'rgba(17, 179, 8, 1)',
            dashStyle: 'ShortDot',
            lineWidth: 3,
            marker: {
                symbol:'diamond',
                fillColor: 'blue',
                radius:5
            }
        },
        {
            name: 'Rang',
            data: ranArray,
            color: 'rgba(0, 75, 147, 1)',
            dashStyle: 'ShortDot',
            yAxis: 1,
            lineWidth: 3,
            marker: {
                symbol:'diamond',
                fillColor: 'red',
                radius:5
            },
            visible: false
        }],
        credits: {
            enabled: false
        },
        lang: {
            noData: "Keine Daten !"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
        exporting: {
            csv: {
                dateFormat: '%Y'
            }
        },
        tooltip: {
            borderColor: 'black',
            shared: true,
            useHTML: true,
            pointFormatter: function() {
                var point = this,
                series = point.series,
                legendSymbol = "<svg width='30' height='25' align='left'>" + series.legendSymbol.element.outerHTML + "</svg>";
                return legendSymbol + series.name + ": <b>" + point.y + "</b><br/>";
            }
        }
    });
}

function getChartSubindices(svg, config, name, sub, sur, index_cat, labels, str) {

    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

    deleteAndCreateDivElements();

    svgDropDown(config);

    const container = document.getElementById('divID');
    container.innerHTML += '<div id="upper-container"></div><div id="lower-container"></div>';

    let source = '<a href="https://rsf.org/en/index-methodologie-2022?year=2022&data_type=general" target="_blank"><strong>-- Rang (weltweit) basierend auf Indexwerten [0-100] --</strong></a>'

    Highcharts.chart('upper-container', {

        chart: {
            style: {
                fontSize: '0px'
            },
            height: 300,
            polar: true,
            type: 'line',
        },
        title: {
            text: `Press Freedom Subindices 2022 für ${name}`,
            style: {
                    color: 'black',
                    fontWeight: 'bold',
            }
        },
        subtitle: {
            style: {
                fontSize: '10px'
            },
            text: source
        },
        pane: {
            size: '80%'
        },
        xAxis: {
            categories: index_cat,
            tickmarkPlacement: 'on',
            lineWidth: 0,
            labels: {
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            }
        },
        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0
        },
        series: [{
            name: 'Index',
            showInLegend: true,
            data: sub,
            pointPlacement: 'on',
            color: 'rgba(17, 179, 8, 1)',
            dashStyle: 'ShortDot',
            lineWidth: 3,
            marker: {
                symbol:'diamond',
                fillColor: 'blue',
                radius:5
            },
        },
        {
            name: 'Rang',
            showInLegend: true,
            data: sur,
            pointPlacement: 'on',
            color: 'rgba(0, 75, 147, 1)',
            dashStyle: 'ShortDot',
            lineWidth: 3,
            marker: {
                symbol:'diamond',
                fillColor: 'red',
                radius:5
            },
            visible: false
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'top',
                        layout: 'horizontal'
                    },
                    pane: {
                        size: '70%'
                    }
                }
            }]
        },
        credits: {
            enabled: false
        },
        navigation: {
            menuItemStyle: {
                        fontFamily: 'Comic Sans MS',
                                    'font-size': '8.5px'
            }
        },
        legend: {
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginRight: 50,
        },
        tooltip: {
            borderColor: 'black',
            shared: true,
            useHTML: true,
            pointFormatter: function() {
                                var point = this,
                                series = point.series,
                                legendSymbol = "<svg width='30' height='25' align='left'>" + series.legendSymbol.element.outerHTML + "</svg>";
                                return legendSymbol + series.name + ": <b>" + point.y + "</b><br/>";
                            }
        }
    });

    Highcharts.chart('lower-container', {
        chart: {
            height:128,
            type: 'timeline',
        },
        accessibility: {
            screenReaderSection: {
                beforeChartFormat: '<h5>{chartTitle}</h5>' +
                    '<div>{typeDescription}</div>' +
                    '<div>{chartSubtitle}</div>' +
                    '<div>{chartLongdesc}</div>' +
                    '<div>{viewTableButton}</div>'
            },
            point: {
                valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
            }
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            visible: false,
        },
        title: {
            text: null
        },
        colors: ['rgba(0, 75, 147, 1)'
        ],
        series: [{
            name: '',
            data: [{
            name: labels[0],
            description: str[0],
        }, {
            name: labels[1],
            description: str[1]
        }, {
            name: labels[2],
            description: str[2]
        }, {
            name: labels[3],
            description: str[3]
        }, {
            name: labels[4],
            description: str[4]
        }, {
            name: labels[5],
            description: str[5]
        }]
        }],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        fontWeight: 'bold',
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            backgroundColor: 'white'
        },
    });
}

export default function getSourcesChart(src_Data,cov_Data, url_Data, ttu_Data, sco_Data, ran_Data, sub_Data, sur_Data, str_Data, div, clickedPolygon) {

    const name = clickedPolygon['ADMIN'];
    let GID = clickedPolygon['level'];
    var members;

    if(GID == 'GID_0'){
        members = [{
        label: "ACLED-Quellen",
        value: 1
        },
        {
        label: "Social Media & Internet",
        value: 2
        },
        {
        label: "Press Freedom Index/Rank Zeitreihen",
        value: 3
        },
        {
        label: "Press Freedom Subindices 2022",
        value: 4
        }];
    }
    const labels = Object.keys(str_Data); //xData subindex labels
    let str = Object.values(str_Data); //yData text

    const index_cat = Object.keys(sub_Data); //xData subindex labels
    let sub = Object.values(sub_Data); //yData number subindex scores
    let sur = Object.values(sur_Data); //yData number subindex ranks

    const sources = Object.keys(src_Data); //xData category labels
    let noa = Object.values(src_Data); //yData number of articles
    let cov = Object.values(cov_Data); //yData coverage
    // links can be acquired directly from json

    const origin = Object.keys(ttu_Data); //xData category labels
    let usr = Object.values(ttu_Data);    //yData users

    const yearssco = Object.keys(sco_Data); //xData
    let datasco = Object.values(sco_Data); //yData
    let scoArray =[];

    for (var i in datasco) {
        let scoDict = {};
        scoDict['x'] = new Date(yearssco[i]).getTime();
        scoDict['y'] = parseInt(datasco[i]);
        scoArray.push(scoDict)
    }

    const yearsran = Object.keys(ran_Data); //xData
    let dataran = Object.values(ran_Data); //yData
    let ranArray =[];

    for (var i in dataran) {
        let ranDict = {};
        ranDict['x'] = new Date(yearsran[i]).getTime();
        ranDict['y'] = parseInt(dataran[i]);
        ranArray.push(ranDict)
    }

    deleteDivElements();
    deleteCheckboxes();

    let svg = div.append('svg')
        .attr('width', w + margin.left + margin.right)
        .attr('height', h + margin.top + margin.bottom)
        .attr('id', 'fitFunction');

    var config = {
            width: document.body.clientWidth*0.18,
            container: svg,
            members,
            fontSize: fontSize,
            color: "#333",
            fontFamily: "calibri",
            x: document.body.clientWidth*0.035,
            y: yStart,
            changeHandler: function (option) {
                svg.selectAll("*").remove();
                if (option.label == 'ACLED-Quellen') {
                    getChartSources(svg, config, name,  sources, noa, cov, url_Data);
                } else if (option.label == 'Social Media & Internet') {
                    getChartUsers(svg, config, usr, origin, name);
                } else if (option.label == 'Press Freedom Index/Rank Zeitreihen') {
                    getChartFOP(svg, config, name, scoArray, ranArray);
                } else {
                    getChartSubindices(svg, config, name, sub, sur, index_cat, labels, str)
                }
            }
    };

    getChartSources(svg, config, name, sources, noa, cov, url_Data);

    return svg.node()
}

<template>
  <b-container>
      <b-row>
        {{title}}
      </b-row>
      <b-row>
        <a-input-group compact>
          <a-input-number
              v-model="inputMin"
              style=" width: 100px; "
              :min="min" :max="max"
              placeholder="Minimum"/>
          <a-input
              style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
              placeholder="~"
              disabled
          />

          <a-input-number
              v-model="inputMax"
              style="width: 100px;"
              :min="min" :max="max"
              placeholder="Maximum"/>
        </a-input-group>
      </b-row>
      <b-row>
        <a-slider
            range
            v-model="sliderValue"
            :min="min" :max=" max"/>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'SliderInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    sliderValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
    inputMin: {
      get() {
        return this.value[0];
      },
      set(value) {
        if (value <= this.value[1]) {
          const puffer = [value, this.value[1]];
          this.$emit('update:value', puffer);
        }
      },
    },
    inputMax: {
      get() {
        return this.value[1];
      },
      set(value) {
        if (value >= this.value[0]) {
          const puffer = [this.value[0], value];
          this.$emit('update:value', puffer);
        }
      },
    },
  },
};
</script>

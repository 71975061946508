<template>
  <div class="authbutton">
    <AuthenticationButton />
  </div>
</template>

<script>
  import AuthenticationButton from './AuthenticationButton.vue';

  export default {
    name: 'AuthNav',
    components: { AuthenticationButton },
  };

</script>


<style scoped>
@import './../assets/css/colors.css';

.btn {
    border:0.1em solid black !important;
    margin:0 0.3em 0.3em 0 !important;
    background-color: var(--primary) !important;
    color: var(--text) !important;
    border-radius: 5px;
    width: 3em;
    hight: 1em;
    right: 0em;
    top: 0em;
}

.btn:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8) !important;
}
.btn:focus {
    box-shadow: none;
}

  .navbar.navbar-dark.bg-dark{
    font-family: var(--font-family);
    font-size: var(--font-size);
    background-color: transparent !important;
    z-index: 6;

 }
  .nav-link{
    color: var(--text);
  }

.navbar .nav-item .nav-link {
    border:0.1em solid black;
    margin:0 0.3em 0.3em 0;
    background-color: var(--primary);
    color: var(--text);
    border-radius: 5px;
    width: 5rem;
}

.navbar .nav-item:hover .nav-link {
    box-shadow: 0 0 11px rgba(33,33,33,.8);
}

.navbar :focus {
    box-shadow: none;
}

.authbutton {
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: 70px;
  right : 74px;


}


</style>
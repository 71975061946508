<template>
<div id="app">
<div class="vspace5blue"></div>


  <h3 class="heading" align="center"> <div class="vspace10"></div> Demonstrator Anwendungsbeschreibung </h3>

<div class="vspace5blue"></div>
<div class="vspace10"></div>

  <h4 class="heading">Die Startseite</h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Startseite.png">
  </div>
  <div class="column" >
  <p class="text">
  Beim Start der Anwendung werden die gebündelten Ereignisse aus der ACLED Datenbank auf einer einfachen,
  grauen Basiskarte, dargestellt. Da beim Start viele Daten geladen werden, kann es etwas dauern, bis der
  blaue Ladebalken (ganz oben auf der Seite) vollständig durchgelaufen ist.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <br>

  <h5 class="heading">Die Navigation</h5>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column2" >
  <p class="text">
  Die Karte kann mit der Maus hin und her geschoben werden. Dabei werden neue Ereignisse geladen.
  Wenn der Ladebalken ganz nach rechts durchgelaufen (also durchgehend blau) ist, sind die Daten vollständig geladen.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <br>

  <h5 class="heading">Der Zoom</h5>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
    <img src="./assets/Zoom.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
  </div>
  <div class="column">
    <p class="text">
      Der Zoom befindet sich rechts unten in der Anwendung. Mit Plus wird in die Karte hineingezoomed und mit
      Minus herausgezoomed.
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <br>

  <h5 class="heading">Die Suche</h5>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column">
    <img src="./assets/Suche.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
  </div>
  <div class="column">
    <p class="text">
      Mit der Suche, rechts unten, kann man nach Ländern, Städten oder Adressen suchen. Die Eingabe wird erkannt und
      eine Auswahl an bekannten Orten angezeigt. Eines der Ergebnisse muss Ausgewählt werden. Die Suche kann in Deutsch
      oder Englisch abgefragt werden. Nach der Auswahl wird automatisch zum Ort hingeflogen und dabei die aktuelle Zoomstufe
      beibehalten. Gegebenenfalls muss noch weiter heran- oder herausgeszoomed werden.
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <br>

  <h5 class="heading">Der Snapshot </h5>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >

    <img class="scrshot" src="./assets/SnapShoot.png" style="width:80%;margin-left:auto;margin-right:auto;display: block;">
  </div>
  <div class="column" >
    <p class="text">
      Mit dem Snapshot Werkzeug, rechts oben, kann der aktuelle Kartenausschnitt mit den Visualisierungen in der App als
      Bild gespeichert werden.
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <br>

  <h4 class="heading">Der Filter</h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Filter.png">
  </div>
  <div class="column" >
  <p class="text">
  Mit dem Filter, links oben, kann das angezeigte Ergebnis eingeschränkt und kategorisiert werden. Folgende Hauptkategorien von Filtertypen
    stehen zur Verfügung:
    <ul>
      <li>Zeitspanne</li>
      <li>Ereignistypen</li>
      <li>Länder</li>
      <li>Akteur 1</li>
      <li>Akteur 2</li>
    </ul>
    Jeder Filtertyp ist in Kategorien und Unterkategorien aufgeteilt.
    <br>  Im Filter Akteur 1 und Akteur 2 kann ausgewählt werden welche zwei <b>interagierenden Akteurstypen</b> angezeigt werden
    sollen. Der Filter Akteur 1 und Akteur 2 lässt in der Unterkategorie eine Auswahl der namentlichen Akteure zu.
    </p>
    </div>
    </div>
    <div class="vspace10blue"></div>
    <div class="row">
    <div class="column" >
    <img class= scrshot src="./assets/FilterAuswahl.png">
    </div>
    <div class="column">
      <p class="text">
        In der Darstellung werden bei einer Auswahl nur solche
    Ereignisse, bzw. Aggregationen, angezeigt, die diesen Akteur und den Akteur mit dem interagiert wurde, beeinhalten. <br> <br>
    Alle Filter beziehen sich auf den gewählten Zeitraum. Zusätzlich sind die Filter Lander, Akteur 1 und Akteur 2 voneinander abhängig.
    Wir bespielsweise ein Land gewählt, so beinhaltet der Filter Akteur 1 nur noch die Akteure, die in diesem Land vorhanden sind. Wird
    ein Akteur 1 gewählt, dann beinhaltet der Filter des Akteur 2 nur noch die Akteure, die mit Akteur 1 interagieren. Die Abhängigkeit geht nur
    in eine Richtung: Land -> Akteur 1 -> Akteur 2.<br><br>

    Die Auswahl hat Einfluß auf die <b>Ereignismarker</b> und die folgenden <b>aggregierten Layer</b>:
    <ul>
      <li>ANZAHL Ereignisse und Konflikttote</li>
      <li>ANZAHL Akteure</li>
      <li>HOTSPOTMAP Ereignisse und Konflikttote</li>
    </ul>


      </p>

    </div>
    </div>
    <div class="vspace10blue"></div>
    <div class="row">
    <div class="column2" >
  <p class="text">
    Die Unterkategorien der Filter Ereignistypen, Akteurstypen und Akteure sind für die drei Datenbanken unterschiedlich (siehe Kapitel Datenbanken). Hingegen ist die Länderauswahl
    für alle Datenbanken gleich. Beim Wechsel auf eine neue Datenbank wird die Länderauswahl beibehalten. Die restlichen Filter werden zurückgesetzt.
    <br> Im Dropdownmenü kann auch direkt nach einem bestimmen Ergebnis, mit einer Eingabe der Anfangsbuchstaben oder des vollständigen Suchbegriffs, gesucht
    werden.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>


  <br>

  <h4 class="heading">Die Tabelle </h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Tabelle.png">
  </div>
  <div class="column" >
  <p class="text">
  Das Tabellen Werkzeug, links oben, zeigt die Ereignisse im Kartenausschnitt in Tabellenform an. Hier kann nach
  <b>Stichwörtern</b> gesucht werden oder angezeigten Ereignisse durch die bekannten Filter weiter eingeschränkt werden. Der Unterschied
  zum Filter liegt hier in der Suchfunktion. Es können Länder gesucht werden, es gibt eine Datumssuche, eine
  Akteurs- und Ereignistyp Suche, eine Suche nach Akteuren und es kann zusätzlich nach Konflikttoten gefiltert werden.
  <br><br>
  Schließt man die Tabelle, dann sind nur noch die ausgewählten, gefilterten Ergebnisse angezeigt. Die Tabellenfunktion
  hat Auswirkungen  auf die <b>Ereignismarker</b> und die folgenden <b>aggregierten Layer</b>:
    <ul>
      <li>ANZAHL Ereignisse und Konflikttote</li>
      <li>ANZAHL Akteure</li>
      <li>HOTSPOTMAP Ereignisse und Konflikttote</li>
    </ul>

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/TabelleSuche.png">
  </div>
  <div class="column" >
  <p class="text">
  Die Ergebnisse einer Tabellensuche beeinflussen nicht nur die Visualisierungen auf der Karte sondern auch das <b>Netzwerk</b>
    (siehe Abschnitt Netzwerk). Der Tabellenfilter kann für alle Datenbanken angewendet werden.(siehe Kapitel Datenbanken).<br><br>
  Eine wichtige Tabellenfunktion ist die Gesamtsuche. In dieser Suche kann nach allen Stichworten gesucht werden.
  Die Suche kann mit dem Button "Suche zurücksetzen" (blauer Button oben) wieder zurück gesetzt werden, so dass die
  Initialergebnisse (beim Start der App) angezeigt werden. Das Suchergebnis ist vollständig, wenn der blaue Ladebalken
  am oberen Rand komplett durchgelaufen ist.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/TabelleDownload.png">
  </div>
  <div class="column" >
  <p class="text">
  Eine weitere wichtige Funktion ist der Download als csv Datei. Der Download Button befindet sich unten.
  Die Suchergebnisse sind auf mehrere Seiten verteilt. Unten rechts kann die Seite geändert werden.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <br>

  <h4 class="heading">Die Charts</h4>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Chart.png">
  </div>
  <div class="column" >
  <p class="text">
  Mit der Chartfunktion können zahlreiche Graphen erstellt werden und verschiedene Filterauswahlen miteinander
  verglichen werden. Das Werkzeug ist sehr komplex und benötigt daher etwas Einarbeitungszeit. Die Chart bezieht sich
  auf die aktuelle Datenbank (siehe Kapitel Datenbanken).
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartStart.png">
  </div>
  <div class="column" >
  <p class="text">
  Jede neue Chart wird mit dem Pluszeichen, links oben, gestartet. Es öffnet sich ein Filtermenü.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartFilterAuswahl.png">
  </div>
  <div class="column" >
  <p class="text">
  Hier kann der Name der Chart vergeben werden, der Haupt- und Nebenereignistyp gewählt werden, sowie
  Akteur 1 und Akteur 2. Es kann ein Kontinent oder ein Land gewählt werden oder die
  Koordinaten der Region (ganz unten) eingetragen werden. Außerdem kann eine Schlüsselwortsuche
  (z.B. Taliban) einbezogen werden.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartKarte.png">
  </div>
  <div class="column" >
  <p class="text">
  Mit dem grünen Button unten "Filter auf Karte übernehmen" kann die Filterauswahl in den Kartenausschnitt übernommen
  werden. Dabei wird der Button "Fixieren" rechts oben aktiviert (siehe Abschnitt Fixieren) und keine neuen
  Ereignismarker nachgeladen. Mit der Karte rechts unten kann der Kartenausschnitt verändert werden. Anschließend wird
  die Chart mit dem Button rechts, mitte "Chart berechnen" erstellt.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartBerechnen.png">
  </div>
  <div class="column" >
  <p class="text">
  Die Chart wird mit dem blauen Button, mitte oben "Chart berechnen" erzeugt.<br>
  Auf der x-Achse des Graphen ist der ausgewählte Zeitraum dargestellt und auf der y-Achse die Konflikttoten.
  Die y-Achsen Ansicht kann rechts oben zu Anzahl der Ereignissen geändert werden. Links oben hat man die Möglichkeit
  die Zeitachse von einzelnen Tagen auf Monate oder Jahre zu ändern. Dabei wird zwischen den Monaten, bzw.
  Jahren interpoliert.
  </p>
  </div>
  </div>


  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartFunktionen.png">
  </div>
  <div class="column" >
  <p class="text">
  Rechts oben, unter dem Wechselmöglichkeit der x-Achse, befinden sich weiter Funktionen, wie z.B. Zoom, Lupe
  und ein Datei Download.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ChartHinzufügen.png">
  </div>
  <div class="column" >
  <p class="text">
  Um verschiedene Graphen vergleichen zu können, gibt es die Möglichkeit weitere Charts hinzuzufügen. Ganz links kann
  das Chartfenster mit dem ">" Zeichen geschlossen werden. Mit dem Pluszeichen, links oben, kann man jetzt eine weitere
  Chart hinzufügen.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <br>

  <h4 class="heading">Das Netzwerk </h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Netz.png">
  </div>
  <div class="column" >
  <p class="text">
  Das Akteursnetzwerk nimmt immer Bezug auf den aktuellen Kartenausschnitt und die durch die Filter oder Tabelle
  selektierten Ereignismarker. Das, was im Kartenausschnitt visualisiert wird, ist auch im Netzwerk abgebildet.
  Das Akteursnetzwerk zeigt die Akteure und deren Verbindungen zu anderen Akteuren. Je nach Anzahl der Ereignisse
  (bzw. Größe des Kartenausschnitts) kann die Berechnung der Netzwerke etwas dauern. Das Netzwerk ist fertig abgebildet,
  wenn der Ladebalken ganz durchgelaufen ist.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/NetzTaliban.png">
  </div>
  <div class="column" >
  <p class="text">
  Die Akteure sind den verschiedenen Akteursgruppen durch Farben zugeordnet. Je dicker der Verbindungslinie zwischen
  zwei Akteuren, umso mehr Interaktionen haben stattgefunden. Im Abschnitt über dem Netzwerk ist die Auswahl durch den
  Filter oder Tabelle dargestellt.
  <br>
  Die Größe, Breite und Höhe des Netzwerks kann durch die Schieber geändert werden. Außerdem kann die Knotengröße,
  links, verändert werden.

  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/NetzTalibanDetails.png">
  </div>
  <div class="column" >
  <p class="text">
  Die Verbindungslinie kann angeklickt werden um die genaue Anzahl der Interaktionen zu erfragen. Mit einem Klick auf
  den Knoten wird der Name des Akteurs in einem Popup angezeigt. Die Knoten können mit der Maus in eine andere Position
  gezogen werden.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <br>

  <h4 class="heading">Der Datenbankwechsel</h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Datenbankswitch.png">
  </div>
  <div class="column" >
  <p class="text">
  Beim Start der Webapp werden die Ereignisse aus der Datenbank ACLED angezeigt. Außer der ACLED Datenbank stehen noch
  zwei weitere Datenbanken zur Verfügung. Der Datenbankwechsel erfolgt über die Radiobuttons in der Mitte oben.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/DatenbankFunktionenLayer.png">
  </div>
  <div class="column" >
  <p class="text">
  Folgende Darstellungen und Funktionen sind mit den Datenbanken verknüpft:
  <br>
  <strong>Funktionen: </strong>Alle Buttons links (Filter, Tabelle, Chart, Netz) sind mit den Datenbanken (ACLED, GTD, UCDP) verknüpft.  <br>
    <strong>Ereignisse: </strong> Jede Datenbank hat andere Ereignisse, die je nach eingestellter Datenbank neu geladen werden müssen. <br>
  <strong>Aggregierte Layer: </strong> Die folgenden Layer werden für jede Datenbank extra aggregiert und dargestellt:
    <ul>
      <li>ANZAHL Ereignisse und Konflikttote</li>
      <li>ANZAHL Akteure</li>
      <li>HOTSPOTMAP Ereignisse und Konflikttote</li>
    </ul>

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column2">
  <p class="text">
    <strong>Datenbankbeschreibungen</strong>  <br>
  <ol>
    <li>
      Die ACLED (Armed Conflict Location & Event Data) Datenbank ist eine Sammlung von gewalttätigen Konflikten
      und Protesten. <a href="https://acleddata.com/">https://acleddata.com/</a>
    </li>
    <li>
      Die GTD (Global Terrorism Database) Datenbank ist eine Sammlung von terroristischen Anschlägen, beginnend im
      Jahr 1970 bis 2019. <a href="https://www.start.umd.edu/gtd/">https://www.start.umd.edu/gtd/</a>
    </li>
    <li>
       Die UCDP (Uppsala Conflict Data Programm ) Datanbank ist eine Sammlung von organisierten Gewaltereignissen und
       bewaffneten Konflikten, beginnend im Jahr 1980. <a href="https://ucdp.uu.se/">https://ucdp.uu.se/</a>
    </li>
  </ol>
  </p>


  </div>
  </div>
  <div class="vspace10blue"></div>
  <br>

  <h4 class="heading">Die Layer </h4>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Layer.png">
  </div>
  <div class="column" >
  <p class="text">
  Layer sind das, was auf dem Kartenausschnitt grafisch dargestellt wird. Das können Basislayer (Hintergrundkarten)
  oder Datenlayer sein. Das Symbol zum Öffnen der Layerauswahl befindet sich ganz rechts unten.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/LayerAufteilung.png">
  </div>
  <div class="column" >
  <p class="text">

  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>
  <br>

  <h5 class="heading">Die Basislayer </h5>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column2" >
  <p class="text">
  Es stehen vier Basiskarten zur Verfügung. Bei allen Karten wird mit der Zoomstufe die Detailgenauigkeit gesteuert.<br>
  <ol>
    <li>
      <strong>Esri Welt Grau: </strong> Dieses Basislayer eignet sich gut für die Visualisierung. Hier sind nur die
      Ländergrenznen und Ländernamen abgebildet. Ab einer bestimmten Zoomstufe werden die Länder in Regionen unterteilt.
    </li>
    <li>
      <strong>Esri Welt: </strong> Dieses Basislayer besteht aus Satellitenbildern, ohne Landesgrenzen oder Namen.
      Bergregionen oder wichtiges Terrain kann hier gut erkannt werden.
    </li>
    <li>
      <strong>Esri Welt Straßenkarte: </strong> Auf diesem Basislayer sind sowohl Terrain abgebildet, als auch Straßen,
      Landes- und Regionengrenzen und Städte.
    </li>
    <li>
      <strong>Welt Topokarte: </strong> Dieses Basislayer basiert auf OpenStreetMap und ähnelt der Esri Welt
      Straßenkarte. Hier hat man weniger den 3D Terraineffekt, dafür sind Flüsse und Landesgrenzen besser zu erkennen.
      Die Landesgrenzen sind hier bis ins Meer eingezeichnet. Auf der Esri Welt Straßenkarte enden die Landesgrenzen
      am Ufer. Außerdem sind noch Strecken von Fähren und Nationalparks eingezeichnet.
    </li>
  </ol>
  Bei den beiden Basislayern Esri Welt Straßenkarte und Welt Topokarte sind die regionalen Grenzen schon auf einer
  hohen Zoomstufe erkennbar. Das kann von Nutzen sein, wenn nicht aggregierte Datenlayer (z.B. die Ereignisse oder
  Rohstoffvorkommen) den Regionen zugeordnet werden sollen.
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>
  <br>

  <h5 class="heading">Die Datenlayer </h5>

  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Legenden.png">
  </div>
  <div class="column" >
  <p class="text">
  Beim Start der Webapp öffnet sich automatisch das Ereignislayer mit den gebündelten Ereignissen. Das Datenlayer Menü (Button rechts unten)
    bietet die Möglichkeit verschiedene Datenlayer hinzuzufügen oder die Basiskarte zu ändern. <br><br>
  Zu den Datenlayern gehört jeweils eine Legende, links unten. Die Legende kann mit dem kleinen Pfeilsymbol, an der
  Legende links oben, ausgeblendet werden.
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/LayerDatenAufteilung.png">
  </div>
  <div class="column" >
  <p class="text">
  Die Datenlayer werden in drei Klassen unterteilt:
    <ul>
    <li>Das Ereignis Layer</li>
    <li>Die WMS Layer</li>
    <li>Die Aggregations Layer</li>
  </ul>
    Das Ereignis Layer und die WMS Layer sind durch eine Checkbox auswählbar und können kombiniert werden. Die Aggregations Layer
    und die Basiskarten sind durch einen Radiobutton auszuwählen, da sie nicht übereinander gelegt werden können. Jeweils ein Aggregations
    Layer ist mit den Ereignissen und/oder den WMS Layern und einem der Basiskarten kombinierbar.
    <br><br> Die Daten der WMS Layer werden von einem Tile Server abgerufen. Durch die Zoomstufe kann hier die Auflösung gesteuert werden.

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
  <div class="row">
  <div class="column2">
    <p class="text"> Die Aggregations Layer beeinhalten aufsummierte Daten. Die meisten Aggregations Layer sind in Layer auf landesebene (Admin0) und
    auf regionaler Ebene (Admin1) aufgeteilt. Die ersten drei Gruppen der aggregierten Layer sind außerdem durch den Filter, die Tabelle, die Chart
    und die Datenbanken steuerbar. So kann beispielsweise der Zeitraum über den Filter gewählt oder bestimmte Akteure über
    den Filter ausgewählt werden. Informationen über die Aggregations Layer können mit einem Mouseover angezeigt werden. Außerdem kann durch einen
    Klick auf ein Land oder eine Region ein Popup mit weiteren Informationen und Grafiken geöffnet werden.</p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/MarkerPopuo.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li>
      <strong>Die Ereignisse</strong><br>
      Ereignisse sind hier bewaffnete Auseinandersetzungen, sowohl mit als auch ohne Konflikttoten. Die Daten stammen
      aus drei verschiedenen Datenbankquellen. Die Datenbanken können mit einem Datenbankwechsel (siehe Beschreibung
      Datenbankwechsel) ausgewählt werden. Beim Start der Webapp ist die ACLED Datenbank aktiv.<br>
      Die Ereignisse sind zu sogenannten "Clustern" gebündelt. Die Zahl in der Mitte der Cluster beziffert die Anzahl
      der einzelnen Ereignisse im Cluster. Der Ring um die Cluster ist farblich unterteilt. Jede Farbe steht für einen
      bestimmten Ereignistyp, der in der Legende zugeordnet ist. Die Größe des Ringabschnittes steht für den
      prozentualen Anteil der Ereignistypen im Cluster. Wird in den Kartenausschnitt hineingezoomt, dann teilen sich
      die Cluster in kleinere Cluster oder einzelne Ereignisse.
    </li>

  </ol>
  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/MarkerNetz.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
      Einzelne Ereignisse sind kleine farbige Kreise. Auch
      hier steht die Farbe für einen Ereignistyp. Mit einem Klick auf ein Cluster wird automatisch hineingezoomt und
      das Cluster entweder in Untercluster aufgeteilt oder in einzelne Ereignisse.<br>
      Mit einem Klick auf ein einzelnes Ereignis öffnet sich ein Popup mit weiteren Informationen.<br><br>
      Des Öfteren sind verschiedene Ereignisse am selben Ort angegeben. Wenn dies der Fall ist, dann wird beim Klick auf
      das Cluster nicht ein einzelnes Ereignis angezeigt, sondern es öffnet sich eine Spirale um die Position der
      Ereignisse herum. Es handelt sich um unterschiedliche Ereignisse, die zu verschiedenen Zeit am selben Ort
      stattgefunden haben.
    </li>
  </ol>

  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Rohstoffvorkommen.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="2">
    <li>
      <strong>Die Mineralstoffvorkommen</strong> <br>
      Die Daten der Mineralvorkommen stammen von der USGS (Science for a changing world) Seite
      <a href="https://mrdata.usgs.gov/major-deposits/">https://mrdata.usgs.gov/major-deposits/</a> und
      zeigen die geologischen und nicht brennbaren Mineralvorkommen der Welt. Darunter sind beispielsweise Nickel,
      Eisen, Aluminium, Kupfer, Gold, ...
    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/MineralienKritisch.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="3">
    <li>
      <strong>Die kritischen Mineralstoffvorkommen</strong> <br>
      Die Daten der kritischen Mineralvorkommen stammen ebenfalls von USGS
      <a href="https://mrdata.usgs.gov/pp1802/">https://mrdata.usgs.gov/pp1802/</a> und zeigen die geschätzte Postion
      von Minen und Vorkommen von kritischen Mineralien. Darunter sind beispielsweise Beryllium, Kobalt, Grafit,
      Titan, ...
    </li>
  </ol>

  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Population2020.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="4">
    <li>
      <strong>Die Population 2020</strong> <br>
      Die Daten zur geschätzten Populationsdichte 2020 stammen von der SEDAC (Socioeconomic Data and Applications
      Center) Seite <a href="https://sedac.ciesin.columbia.edu/data/set/gpw-v4-population-density-adjusted-to-2015-unwpp-country-totals-rev11">
      https://sedac.ciesin.columbia.edu/data/set/gpw-v4-population-density-adjusted-to-2015-unwpp-country-totals-rev11</a>
      und basieren auf Zählungen, wie nationalen Volkszählungen und Bevölkerungsregistern.<br>
      In diesem Layer kann die Auflösung durch die Zoomstufe gesteuert werden. Je weiter hineingezoomt wird, desto mehr
      Details werden erkennbar.
    </li>
  </ol>

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>


  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregationen.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="5">
    <li>
      <strong>Die Aggregationen</strong> <br>
      Unter Aggregation versteht man in diesem Kontext die Aufsummierung von Daten für ein bestimmtes Land oder für
      eine bestimmte Region. Um Unterschied zu den oberen Datenlayern werden bei der Aggregation nur Daten pro Land
      oder Region aufgezeigt und keine einzelnen Daten für eine bestimmte Position oder für bestimmte Flächen.<br>

    </li>
  </ol>

  </p>
  </div>
  </div>
  <div class="vspace10blue"></div>



  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Ereignisse.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="5">

      <li class="nostyle">5.1 <strong>ANZAHL Ereignisse und Konflikttote</strong><br>
      In den Datenlayern Konflikttote und Ereignisse werden die aufsummierten (aggregierten) Toten oder Ereignisse
      durch Konflikte pro Land dargestellt. Ereignisse können dabei auch Konflikte ohne Tote sein. Die Daten bezieht
      sich auf die oben genannten drei Datenbanken (siehe Datenbankswitch). Die Daten könnten über den Filter-,
      Tabellen- oder Chartbutton gefiltert werden. So kann man zum Beispiel den Zeitraum wählen, bestimmte Ereignistypen und
      Akteursgruppen wählen oder im Button Tabelle eine Suche nach Schlagworten starten. Die Datenbanken können über
      den Datenbankswitch gewechselt werden.<br><br>
      In der Legende kann die Zuordnung, Farbe - Anzahl der Toten, bzw. Farbe - Anzahl der Ereignisse, abgelesen werden.
      Mit dem Mouseover über einem Land erhält man die genaue Anzahl an Konflikttoten oder Ereignissen.
    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Anzahl.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.1 <strong>Popup ANZAHL Ereignisse und Konflikttote</strong><br>
      In diesem Balkendiagramm wird die Anzahl der einzelnen Akteure zu den aggregierten Ereignissen oder Konflikttoten dargestellt. Dabei
        wird auch der Akteur, der an einer Interaktion beteiligt war (falls vorhanden) mit einbezogen. Jeder Akteur gehört einer Kategorie von Akteurstypen
        an, die in diesem Diagram durch die Farben gekennzeichten sind. Im Balkendiagramm werden nur die 15 häufigsten Akteure angezeigt. <br>


        Mit dem Menübutton rechts über dem Diagram werden folgende Ansichten und Funktionen ermöglicht:
        <ul>
          <li>Vollbildschirmanzeige</li>
          <li>Druck</li>
          <li>Verschiedene Downloads (PNG, JPEG, PDF, SVG, CSV, XLS)</li>
          <li>Tabellenansicht</li>
        </ul>

        Mit einem Mouseover über den Infobutton werden die durch den Filter oder die Chart eingestellten Selektionen angzeigt.
    </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>


  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Akteure.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="5">

      <li class="nostyle">5.2 <strong>ANZAHL Akteure</strong><br>
      In den Datenlayern für die Anzahl der Akteure kann zwischen einer Darstellung auf Landesebene (Admin0) und regionaler Ebene (Admin1)
        gewählt werden. In die Anzahl der Akteure geht jeweils auch der interagierende Akteur ein.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Akteure1.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.2 <strong>Popup ANZAHL Akteure</strong><br>
      In diesem Popup können verschiedene Darstellungsmöglichkeiten gewählt werden (Button Darstellungswechsel) und in einem
        Dropdown zwischen dem zeitlichen Verlauf und der statischen Darstellung der Akteure gewählt werden.
        <br>Alle Grafiken beziehen sich auf die fünf aktivsten Akteure für den im Filter gewählten Zeitraum, bzw. die an einer Interaktion
        beteiligten Akteure.
        <ul>
          <li><b>Matrixdarstellung </b>
          <br>Die Darstellung der "inverse" Korrelationsmatrix bildet die Anzahl der Ereignisse für die Interaktionen zwischen zwei
          Akteuren, für den im Filter eingestellten Zeitraum und anderen Filtereinstellungen, ab. Da Akteure nicht mit sich selber
          interagieren ist die Diagonale 0. </li>
        </ul>
    </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
    <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Akteure2.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle"><br>
        <ul>
          <li><b>Netzwerkdarstellung </b>
          <br>Die Netzwerkdarstellung zeigt die fünf aktivsten Akteure (jeweils für die gewählten Filtereinstellungen). Die Zugehörigkeit
          zu einer Kategorie der Akteurstypen wird farblich gekennzeichnet. Die Größe eines Knotens wird durch die Anzahl der Interaktionen
          bestimmt. Die Dicke der Verbindungslinien hängt von der Anzahl der Interaktionen zwischen den beiden beteiligten Akteuren ab, wobei die
          Anzahl der Interaktionen auf den Verbindungslinien abgebildet ist.
          <br> Die Anordnung der Knoten kann durch ziehen der Knotenkugeln verändert werden.</li>
        </ul>
    </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>
      <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Akteure3.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle"><br>
        <ul>
          <li><b>Sankeydarstellung </b>
          <br>Das Sankey Diagramm ist eine graphische Darstellung von Mengenflüssen, wobie hier die Mengenflüsse durch die Anzahl
          der Interaktionen zwischen zwei Akteuren definiert wird. Mit einem Mousover über die Intaraktionsflüsse erhält man
            Informationen wie Anzahl der Ereignisse und Name der beteiligten Akteure. Die länglichen Balken links, rechts und in der Mitte des Diagramms
          symbolisieren den Akteur.</li>
        </ul>
   </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Akteure4.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">
        <ul>
          <li><b>Zeitraum aktivste Akteure </b>
          <br>In dieser Darstellung kann der Verlauf der Aktivitäten der fünf aktivsten Akteure analysiert werden. Der Zeitraum kann
          über den Filter verändert werden. Einzelne Aktivitätsverläufe können mit einem Klick auf den Grafen in der Legende ein- und
          ausgeblendet werden.</li>
        </ul>

        Mit dem Menübutton rechts über dem Diagram werden folgende Ansichten und Funktionen ermöglicht:
        <ul>
          <li>Vollbildschirmanzeige</li>
          <li>Druck</li>
          <li>Verschiedene Downloads (PNG, JPEG, PDF, SVG, CSV, XLS)</li>
          <li>Tabellenansicht</li>
        </ul>
        Mit einem Mouseover über den Infobutton werden die durch den Filter oder die Chart eingestellten Selektionen angzeigt.
    </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>


  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Hotspot.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol start="5">

      <li class="nostyle">5.3 <strong>HOTSPOTMAP Ereignisse und Konflikttote</strong><br>
      Dieser Layer Gruppe stellt den Trend, bzw die Entwicklung der Konflikttoten und Ereignisse dar. Die Berechnung berücksichtigt die
        Entwicklung der letzten sieben Tage im Verhältnis zum, durch den Filter gewählten, Zeitraum. Das Ergebnis "Delta" ist die Differenz
        zwischen der Entwicklung der letzten sieben Tage und der Entwicklung im eingestellten Zeitraum davor.

        <br>Ist das Delta positiv, so kann man
        von einer Zunahme der Konflikttoten oder Ereignissen sprechen. Ein negatives Delta bezeichnet dagegen eine Abnahme der Ereignisse oder
        Konflikttoten. Eine Zunahme ist in Rot dargestellt, wobei in zwei Abstufungen die schwere der Entwicklung dargestellt wird. Eine Abnahme
        ist Grün gefärbt, ebenfalls in zwei Abstufungen.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column2" >
    <p class="text">
    Die Schwellenwerte der Abstufungen sind Abhängig von der Auswahl der Darstellung von
        Land bzw. Region und von Ereignissen bzw. Konflikttoten.


        Die Gruppe der Hotspotmap Layer ist ähnlich aufgebaut wie die Layer Gruppe Anzahl Ereignisse und Konflikttote. Es kann zwischen den folgenden
        Kategorien gewählt werden:
        <ul>
          <li>Konflikttote auf Landesebene</li>
          <li>Konflikttote auf regionaler Ebene</li>
          <li>Ereignisse auf Landesebene</li>
          <li>Ereignisse auf regionaler Ebene</li>
        </ul>

    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>



  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Hotspot.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.3 <strong>Popup HOTSPOTMAP Ereignisse und Konflikttote</strong><br>
       In diesem Popup wird der zeitliche Verlauf der Anzahl der Ereignisse bzw. Konflikttoten abgebildet. Der Pfeil symboliert den Trend
        in Form einer Zunahme bzw. Abnahme von Konflikttoten oder Ereignissen.
        Mit dem Menübutton rechts über dem Diagram werden folgende Ansichten und Funktionen ermöglicht:
        <ul>
          <li>Vollbildschirmanzeige</li>
          <li>Druck</li>
          <li>Verschiedene Downloads (PNG, JPEG, PDF, SVG, CSV, XLS)</li>
          <li>Tabellenansicht</li>
        </ul>

        Mit einem Mouseover über den Infobutton werden die durch den Filter oder die Chart eingestellten Selektionen angzeigt.

    </li>
  </ol>
    </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Population.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.4 <strong>Bevölkerungsdichte </strong><br>
    Die Daten zum Layer der Bevölkerungsdichte stammen von den beiden folgenden World Pop Seiten:
      <ul>
        <li><a href="https://www.worldpop.org/geodata/summary?id=50575">Geodaten World Pop 1</a></li>
        <li><a href="https://www.worldpop.org/geodata/summary?id=50573">Geodaten World Pop 2</a></li>
      </ul>
    Es wurde bewusst dieser Datensatz gewählt, da hier auch Daten auf (fast allen) Regionalebene vorhanden sind.
      Manuell hinzugefügt wurden die Daten für Kosovo auf Landes- als auch auf Regionalebene. Die Daten für den Kosovo stammen von
      der folgenden Quelle:
      <ul>
        <li><a href="https://www.citypopulation.de/en/kosovo/admin/">City Population</a></li>
      </ul>
      Außerdem wurden für Russland und die USA jeweils die Regionen aus den folgenden Datenquellen hinzugefügt:
     <ul>
        <li><a href="https://worldpopulationreview.com/states/district-of-columbia-population">Columbia</a></li>
        <li><a href="https://en.wikipedia.org/wiki/Chukotka_Autonomous_Okrug">Chukotka Autonomous Okrug</a></li>
      </ul>

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column2" >
    <p class="text">

    In der Legende befindet sich die Zuordnung: Farbe - Bevölkerungsdichte, als auch ein Zeitschieber in dem zwischen den Jahren 2000 und 2016 das Jahr eingestellt werden
    kann. Da sich die Gesamtbevölkerungsanzahl pro Jahr, im Vergleich zu den Bevölkerungsunterschieden pro Land, nicht
    stark verändert hat, ist die Farbänderung durch den Jahreswechsel nur schlecht zu erkennen. Im
    Mouseover Kontextmenü kann man aber die genaue Bevölkerungszahl, und deren Änderung zum Vorjahr, gut ablesen.

    </p>
  </div>
  </div>

  <div class="vspace10blue"></div>


  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Bevoelkerung.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.4 <strong>Popup Dropdown Bevölkerung</strong><br>
        In dieser Grafik wird die Anzahl der Einwohner (rot) als auch das Bevölkerungswachstum (blau) dargestellt. Der linke, gelb gefärbte
        Bereich bezieht sich auf historische Daten und der rechte grün gefärbte Bereich auf Prognosen.

        <br><br> Die Daten stammen aus der folgenden Weltbankquelle:
        <ul>
          <li><a href="https://databank.worldbank.org/source/subnational-population/">Weltbank Bevölkerung</a></li>
        </ul>

    </li>
  </ol>
    </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Bevoelkerung1.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.4 <strong>Popup Dropdown Bevölkerungsdichte</strong><br>
        In dieser Grafik wird die zeitliche Entwicklung der Bevölkerungsdichte dargestellt.
        <br><br>
        Datenquelle siehe Beschreibung Bevölkerungsdichte

    </li>
  </ol>
    </p>
  </div>
  </div>


  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Lebenserwartung.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.4 <strong>Popup Dropdown Lebenserwartung</strong><br>
        Diese Grafik stellt die Lebenserwartung bei Geburt auf Landesebene im Zeitverlauf dar.
        <br><br> Die Daten stammen aus folgender Quelle:
        <ul>
          <li><a href="https://data.worldbank.org/indicator/SP.DYN.LE00.IN">Weltbank Lebenserwartung</a></li>
        </ul>

    </li>
  </ol>
    </p>
  </div>
  </div>


  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Bevoelkerung2.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.4 <strong>Popup Dropdown Bevölkerungspyramide</strong><br>
        Hier wird die Bevölkerungspyramide auf Länderebene dargestellt.
        <br><br>
        Datenquelle siehe Beschreibung Bevölkerung (Weltbank Projections and Estimations

         <br><br> Mit dem Menübutton rechts über dem Diagram werden folgende Ansichten und Funktionen ermöglicht:
        <ul>
          <li>Vollbildschirmanzeige</li>
          <li>Druck</li>
          <li>Verschiedene Downloads (PNG, JPEG, PDF, SVG, CSV, XLS)</li>
          <li>Tabellenansicht</li>
        </ul>

        <br><br> Mit einem Mouseover über den Infobutton werden die durch den Filter oder die Chart eingestellten Selektionen angzeigt.

    </li>
  </ol>
    </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Zugänglichkeit.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.5 <strong>Zugänglichkeit </strong><br>
    Die Daten für den Layer Zugänglichkeit stammen von der Seite "Malaria Atlas Projekt"
    <a href="https://malariaatlas.org/research-project/accessibility-to-cities/">
    https://malariaatlas.org/research-project/accessibility-to-cities/</a> . Die dargestellte, gewichtete Zahl steht
    für die Möglichkeit mit der sich die Bevölkerung mit Diensten, Institutionen und Einzelpersonen verbinden kann.
    Eine geringe Zahl bedeutet, dass dies einfach für die Menschen ist und je höher die Zahl, umso schwieriger wird die
    Zugänglichkeit. diese Zugänglichkeit zu Diensten, Institutionen und Einzelpersonen ist wichtig für den
    sozioökonomischen Erfolg, für die Gesundheit und das allgemeine Wohlbefinden. <br>
    Dieses Datenlayer und seine Informationen sind auch granularer, auf Regionalebene abrufbar.
    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Ethnien.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.6 <strong>Ethnien </strong><br>
    Der ethnische Fraktionalisierungsindex stellt .... Text von Daniel ....
    </li>
  </ol>
  </p>
  </div>
  </div>

    <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Popup_Ethnien.png">
   </div>
  <div class="column" >
    <p class="text">
      <ol start="5">

      <li class="nostyle">5.6 <strong>Popup Ethnien</strong><br>
        Die Daten dieses Popups zur Ethnienvielfalt eines Landes stammen von der ETH Zürich <a href="https://growup.ethz.ch/">
    https://growup.ethz.ch/</a> .<br>
    Das hier dargestellten Histogramme zeigen Siedlungsmuster politisch aktiver ethnischer Gruppen weltweit von 1946-2020.
    Darüber hinaus bietet es Informationen über den Zugang ethnischer Gruppen zur Macht der Exekutive, ihrer Beteiligung
    am Bürgerkrieg und föderale Verwaltungseinheiten. <br><br>
    Für manche Länder, wie z.B. Indien, China oder Russland, ist die Ethnienvielfalt so groß, dass nicht
    alle Ethnien in einem Popup realisiert werden konnten und nur die 21 wichtigsten abgebildet sind. Daher summieren sich die Balken auch nicht auf 100% auf. <br>
    Die relevanten Zeitspannen können über einen Button im Popup abgerufen werden.


    </li>
  </ol>
    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column2" >
    <p class="text">
    Im Link "Mehr Infos", unter dem Titel, wird man zur Seite des GrowUp Projekts weitergeleitet. Dort sind noch
    regionalere Informationen zu den Ethniengruppen abrufbar. <br>
    Mit einem Klick auf den ersten Balken des Histogramms, wird dieser entfernt. Dadurch können auch Minderheiten genauer
    analysiert werden.

         <br> Mit dem Menübutton rechts über dem Diagram werden folgende Ansichten und Funktionen ermöglicht:
        <ul>
          <li>Vollbildschirmanzeige</li>
          <li>Druck</li>
          <li>Verschiedene Downloads (PNG, JPEG, PDF, SVG, CSV, XLS)</li>
          <li>Tabellenansicht</li>
        </ul>

        Mit einem Mouseover über den Infobutton werden die durch den Filter oder die Chart eingestellten Selektionen angzeigt.

    </p>
  </div>
  </div>
  <div class="vspace10blue"></div>



  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Aggregation_Geflüchtete.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.7 <strong>Geflüchtete </strong><br>
    In diesem Datenlayer sind Flüchtlingsströme für ein gewähltes Land visualisiert. Möchte man beispielsweise die
    Flüchtlingsbewegungen im Kongo anschauen, klickt man auf das Land Kongo und kann anhand der Pfeile erkennen in
    welches Land geflüchtet wurde. Die Dicke der Pfeile symbolisiert grob die Anzahl der Geflüchteten. Mit einem Klick
    auf das Zelticon, in einem der Asylländer, erhält man in einem Popup genauere Informationen zur Anzahl der
    Geflüchteten. In der Legende, links unten, kann das Jahr eingestellt werden.<br>
    Die Daten stammen von der Seite <a href="https://refugeemovements.com/">https://refugeemovements.com/</a> . In
    diesem Projekt wurden Daten der UNHCR (UN Refugee Agency) im Zeitraum 1989 - 2013 gesammelt und teilweise Lücken
    durch andere Datensätze geschlossen.
    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Vorhersage.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.8 <strong>Vorhersage </strong><br>
    Dieser Datenlayer visualisiert die Wahrscheinlichkeit einer zukünftigen Konflikteskalation (innerhalb der
    nächsten drei Monate) auf Landesebene. Die Vorhersagevisualisierung ist ausschließlich bei Auswahl der ACLED-Datenbank verfügbar, da
    diese dem verwendeten Prognosemodell weitgehend zugrunde liegt.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/HistorischeDaten.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.8 <strong>Popup Dropdown Historische Daten </strong><br>
    Dieses Popup stellt die monatlich aggregierte Anzahl an Todesopfern und Ereignissen im Zeitverlauf auf Landesebene dar, die
    auch für die Definition der Zielvariable innerhalb des Vorhersagemodells genutzt werden. Eine detaillierte Beschreibung ist im Popup
    "Datenglossar" verfügbar.<br>
    Zudem sind jene Monate rot hinterlegt, für die laut Defintion der Zielvariablen des Vorhersagemodells eine zukünftige
    Kriseneskalation (innerhalb der darauffolgenden 3 Monate) in der Vergangenheit vorlag. Eine detaillierte Beschreibung ist ebenfalls im Popup
    "Datenglossar" verfügbar.<br>
    Die prognostizierte Konflikteskalationswahrscheinlichkeit aus dem Datenlayer "Vorhersage" wird außerdem nochmals
    über der Grafik angezeigt.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/Datenglossar.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.8 <strong>Popup Dropdown Datenglossar </strong><br>
    Hier werden die verwendeten Variablen aus dem Popup "Historische Daten" und somit auch die
    Zielvariable des genutzten Vorhersagemodells näher erläutert.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/PressFreedomIndex.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.9 <strong>Press Freedom Index </strong><br>
    Dieser Datenlayer visualisiert den Grad der Pressefreiheit anhand des Press Freedom Index des Jahres 2022 auf Landesebene.
    Diese  Visualisierung ist nur bei Auswahl der ACLED-Datenbank verfügbar, da diese ausschließlich für das
    Popup "ACLED-Quellen" genutzt wurde. Die verwendeten Daten stammen von <a href="https://rsf.org/en/index">Reporters without Borders</a>.

    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/ACLEDSources.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.9 <strong>Popup Dropdown ACLED-Quellen</strong><br>
    Dieses Popup stellt die Anzahl der Meldungen sowie deren Anteil an den Gesamtereignissen aus
    Quellen, die in mindestens 5 Prozent aller Ereignisse zitiert wurden, auf Landesebene dar.<br>
    Die Betrachtung umfasst alle ACLED-Ereignisse während des Zeitraums von 01.01.2017 - 09.12.2021.<br>
    Sofern der Name der Datenquelle hellblau eingefärbt ist, gelangt man durch Klick auf
    diesen Link  direkt zum Webauftritt.


    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/SMIUsers.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.9 <strong>Popup Dropdown Social Media & Internet-Nutzung</strong><br>
    Dieses Popup stellt aus dem Bereich Social Media den Bvölkerungsanteil der
    Facebook-, Twitter- und TikTok-Nutzer im Jahr 2022 auf Landesebene dar. Zudem werden die Bevölkerungsanteile, welche Internet,
    Social Media allgemein sowie mobile Endgeräte nutzen, für das Jahr 2021 visualisiert.<br>
    Die verewendeten Daten stammen von <a href="https://datareportal.com/library">DATAREPORTAL</a>.


    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/FOPTimeSeries.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.9 <strong>Popup Dropdown Press Freedom Index/Rank Zeitreihen</strong><br>
    Dieses Popup stellt den Press Freedom Index auf Landesebene von 2013-2021 grafisch als
    Zeitreihe dar. Das Jahr 2022, wie im Datenlayer "Press Freedom Index" gezeigt, wird gesondert betrachtet,
    da im betreffenden Jahr die Methodik geändert wurde.<br>
    Durch Klick auf "Rang" (hier in grau), lässt sich zudem die eine Zeitreihe einblenden, welche die Platzierung im
    Ländervergleich weltweit zeigt.<br>
    Die genutzten Daten stammen von <a href="https://rsf.org/en/index">Reporters without Borders</a>.


    </li>
  </ol>
  </p>
  </div>
  </div>


  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/FOPSubindices.png">
  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.9 <strong>Popup Dropdown Press Freedom Subindices 2022</strong><br>
    Dieses Popup stellt neben dem Press Freedom Index  2022 auch die Subindices "Politischer Kontext",
    "Wirtschaftlicher Kontext", "Rechtlicher Rahmen", "Soziokultureller Kontext" und "Sicherheit", aus
    denen sich der Hauptindex zusammensetzt (<a href="https://rsf.org/en/index-methodologie-2022?year=2022&data_type=general">Methodik</a>),
    auf Landesebene dar.<br>
    Zudem können durch Klick auf "Rang" auch die Platzierungen im weltweiten Ländervergleich innerhalb
    der Haupt- und Subkategorie(n) eingeblendet werden. <br>
    Durch Mouse-Over eines der Kategorie-Labels im unteren Teil des Popups, öffnet sich eine kurze verbale
    Lagedarstellung für die gewählte Kategorie.<br>
    Die genutzten Daten stammen von <a href="https://rsf.org/en/index">Reporters without Borders</a>.


    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

  <div class="row">
  <div class="column" >

  </div>
  <div class="column" >
  <p class="text">
  <ol>
    <li class="nostyle">
    5.10 <strong>Keine </strong><br>
    Möchte man keine Aggregations Layer angezeigt bekommen, ist dieser Radiobutton die richtige Wahl.
    </li>
  </ol>
  </p>
  </div>
  </div>

  <div class="vspace10blue"></div>

<!--
  <h5 class="heading"> </h5>

  <div class="row">
  <div class="column" >
  <img class= "scrshot" src="./assets/.png">
  </div>
  <div class="column" >
  <p class="text">

  </p>
  </div>
  </div>

  <br>
-->

</div>
</template>


<script>
export default {
  name: "manual",
}
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position:absolute;
  left: 5px;
  top:5px;
  right: 5px;
  bottom: 5px;
}

html,body {
  height: 100%;

}

.scrshot{
  width: 100%;
}

.heading {
  font-weight: bold;
  font-family: "Lucida Console", "Courier New", monospace;
}

.text {
  font-size: 14px;
  color: rgba(4, 59, 153, 1);
  font-family: "Lucida Console", "Courier New", monospace;
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
}
.column2 {
  float: left;
  width: 100%;
  padding: 10px;
}


.row{
  background-color: rgba(46, 34, 43, 0.15);
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

li.nostyle {
  list-style-type: none;
}
.vspace15 {
  height: 15px;
  background-color: transparent;
}

.vspace10 {
  height: 10px;
  background-color: transparent;
}

.vspace10blue {
  height: 10px;
  background-color: rgba(116, 183, 218, 0.5);
}

.vspace5blue {
  height: 5px;
  background-color: rgba(116, 183, 218, 0.5);
}


</style>

<template>
<b-sidebar id="sidebar-2" title="Table" width="1550px" #default="{ hide }"
           no-header shadow>

  <b-row style="position:fixed; right:0;" >
      <b-col cols="11"/>
      <b-col align-self="end" >
        <b-button @click="hide">
          <b-icon icon="x"/>
        </b-button>
      </b-col>
  </b-row>

  <b-row style="position:fixed; right:0;" >
      <b-col cols="11"></b-col>
       <b-col></b-col>
  </b-row>
  <b-row style="margin-top:10px;">
      <b-col></b-col>
      <b-col cols="10">
        <TableContent :message="event"/></b-col>
      <b-col></b-col>
  </b-row>

</b-sidebar>
</template>

<script>
import $ from 'jquery';
import TableContent from './TableContent.vue';

export default {
  name: 'Table',
  components: {
    TableContent,
  },
  props: {
    event: Object,
  },
  watch: {
    event: {
      deep: true,
      handler(message) {
        const e = message.message;
        if (e.data.dataTable) {
          this.data = e.data.dataTable;
        }
      },
    },
  },
  mounted() {
    $('#sidebar-2').css(
      {
        top: '.1em',
        left: '0em',
        right: '0em',
        buttom: '0em',
        opacity: '100%',
        height: '100%',
        width: '100%',
        'font-family': 'Calibri, Helvetica, monospace, Arial, sans-serif',
        'font-size': '18px',
        border: '0.1em solid black',
        'close button': true,
      },
    );
  },
};
</script>

<style scoped>
@import './../assets/css/colors.css';

.btn {
    border:0.1em solid black !important;
    margin:0 0.3em 0.3em 0 !important;
    background-color: var(--primary) !important;
    color: var(--text) !important;
    border-radius: 5px;
    width: 3em;
    hight: 1em;
    right: 0em;
    top: 0em;
}

.btn:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8) !important;
}
.btn:focus {
    box-shadow: none;
}


</style>

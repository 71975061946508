import {
    svgDropDown,
    deleteDivElements,
    deleteAndCreateDivElements,
    deleteCheckboxes
} from '../Utils/Map.Layers.Popups.Utils.js';

const Highcharts = require('highcharts');
require('highcharts/modules/streamgraph')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

const d3 = require('d3');

const w = document.body.clientWidth - document.body.clientWidth*0.2;  // width histogram;
const h = document.body.clientHeight;
const marginNo = document.body.clientHeight*0.001;
const fontSize = document.body.clientHeight*0.02;
let popAgesData = {}
let buttonCounter = 0;

const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };

const heightSpaceForDropDown = document.body.clientHeight*0.22;
const yStart = document.body.clientHeight*0.02;

const colorFill = '#7777BB';
const hoverColor = '#0000ff';
const pressedColor = '#000077';


function getChartPopulationPyramid(svg, config, maleData, femaleData, name, year){
    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

        deleteAndCreateDivElements();
        svgDropDown(config);

      // Age categories
        var categories = [
            '0-4', '5-9', '10-14', '15-19',
            '20-24', '25-29', '30-34', '35-39', '40-44',
            '45-49', '50-54', '55-59', '60-64', '65-69',
            '70-74', '75-79', '80 + '
        ];
        Highcharts.chart('divID', {
            chart: {
                type: 'bar'
            },

            navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom'
                }
            },

            title: {
                text: `Bevölkerungspyramide für ${name} im Jahr ${year}`
            },
            subtitle: {
                text: 'Quelle: <a href="https://databank.worldbank.org/source/population-estimates-and-projections#" target="_blank"> The World Bank, Population estimates and projections, Last Updated:12/17/2021</a>'
            },
            xAxis: [{
                categories: categories,
                reversed: false,
                labels: {
                    step: 1
                }
            }, { // mirror axis on right side
                opposite: true,
                reversed: false,
                categories: categories,
                linkedTo: 0,
                labels: {
                    step: 1
                }
            }],
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter: function () {
                        return Math.abs(this.value) + '%';
                    }
                }
            },

            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },

            tooltip: {
                formatter: function () {
                    return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
                        'Population: ' + Highcharts.numberFormat(Math.abs(this.point.y), 2);
                },
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                },
            series: [{
                name: 'Männer',
                data: maleData
            }, {
                name: 'Frauen',
                data: femaleData
            }]
        });
}

function getChartDensity(svg, config, densityArray, name){

        svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

        deleteAndCreateDivElements();
        svgDropDown(config);

        Highcharts.chart('divID', {

            chart:{
                marginRight: 30
            },

            navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },

            title: {
                text: `Bevölkerungsdichte für ${name}`
            },

            subtitle: {
                text: 'Quelle: World Pop: Population Weighted Density'
            },

            yAxis: {
                title: {
                    text: 'Anzahl Einwohner / km^2'
                }
            },

            xAxis: {
                title: {
                    text: 'Jahr'
                }
            },

            tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2000
                }
            },

            series: [{
                name: 'Bevölkerungsdichte',
                data: densityArray
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });
}

function getChartPopulationlifeExp(svg, config, dataLengthlifeExp, lifeExpArray, name){

        svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

        deleteAndCreateDivElements();

        svgDropDown(config);

        let source = 'Quelle: <a href="https://data.worldbank.org/indicator/SP.DYN.LE00.IN" target="_blank"> The World Bank, Population estimates and projections, Last Updated:12/17/2021</a>'
        if(dataLengthlifeExp < 10) {source = "Quelle: World Pop: Life Expectancy"}

        Highcharts.chart('divID', {

            chart:{
                marginRight: 30
            },

            navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },

            title: {
                text: `Lebenserwartung für ${name}`
            },

            subtitle: {
                text: source
            },

            yAxis: {
                title: {
                    text: 'Lebenserwartung'
                }
            },

            xAxis: {
                title: {
                    text: 'Jahr'
                }
            },

           tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },

            series: [{
                name: 'Lebenserwartung',
                data: lifeExpArray
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        });
}



function getChartPopulation(svg, config, dataLengthPop, popArray, dataLengthpop_Growth, pop_GrowthArray, name){
    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

        deleteAndCreateDivElements();
        svgDropDown(config);

        let source = 'Quelle: <a href="https://databank.worldbank.org/source/population-estimates-and-projections#" target="_blank"> The World Bank, Population estimates and projections, Last Updated:12/17/2021</a>'
        if(dataLengthPop < 10) {source = "Quelle: World Pop: Population Weighted Density"}

        Highcharts.chart('divID', {

            chart:{
                zoomType: 'xy'
                //marginRight: 30
            },

            navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },

            title: {
                text: `Bevölkerung für ${name}`
            },

            subtitle: {
                text: source
            },

            yAxis: [
                {
                title: {
                    text: 'Bevölkerungswachstum',
                    style: {
                        color: '#2f7ed899'
                    }
                },
                labels:{
                    format: '{value} %',
                    style: {
                        color: '#2f7ed899'
                    }
                },
                opposite: true

            }, {
                title: {
                    text: 'Anzahl Einwohner in Mio.',
                    style: {
                        color: '#FF000099'
                    }
                },
                labels: {
                    //format: '{value} Mio.',
                    style: {
                        color: '#FF000099'
                    }
                 }
            }
            ],


            xAxis: [{
                title: {
                    text: 'Jahr'
                },
                crosshair: true,
                plotBands: [{
                    color: '#ddfada',
                    from: 2022,
                    to: 2050,
                    label: {
                        text: '<em>Prognose</em>',
                        style: {
                            color: 'rgba(16, 185, 36, 0.6)',
                            fontWeight: 'bold'
                        },
                        align: 'center',
                        y:-5,

                    }
                },{
                    color: '#ffffe0',
                    from: 1960,
                    to: 2022,
                    label: {
                        text: '<em>Historische Daten</em>',
                        style: {
                            color: 'rgba(214, 221, 30, 0.8)',
                            fontWeight: 'bold'
                        },
                        align: 'center',
                        y:-5,

                        }

                }],
            }],


           tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                    shared: true,
                xDateFormat:'%Y',
                },

            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            //var xAxis = this.chart.xAxis[0];
                            if (this.name === 'Bevölkerung') {
                                if (this.visible) {
                                    this.chart.yAxis[1].update({
                                        visible: false
                                    });
                                } else {
                                    this.chart.yAxis[1].update({
                                        visible: true
                                    });
                                }
                            } else {
                                if (this.visible) {
                                    this.chart.yAxis[0].update({
                                        visible: false
                                    });
                                } else {
                                    this.chart.yAxis[0].update({
                                        visible: true
                                    });
                                }
                            }
                        }
                    },
                    label: {
                        enabled: true,
                        connectorAllowed: false
                    }
                }
            },
           series: [{
                type: 'spline',
                yAxis: 1,
                name: 'Bevölkerung',
                data: popArray,
                color: '#FF0000'
            }, {
                type:'column',
                name:'Wachstum (%)',
                data: pop_GrowthArray,
                color: '#2f7ed899'
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        });
}

function getChartChangeButton(svg, config, name) {
  const bWidth = document.body.clientWidth*0.11;
  const bHeight = document.body.clientHeight*0.04;

  const allButtons = svg.append('g').attr('id', 'allButtons');
  const buttonGroups = allButtons.selectAll('g.button')
    .data("test")
    .enter()
    .append('g')
    .attr('class', 'button')
    .style('cursor', 'pointer')
    .on('click', function (d, i) {
      updateButtonColors(d3.select(this), d3.select(this.parentNode));

      if(buttonCounter == 2){buttonCounter = 0}
      if(buttonCounter == 0) {
          getChartPopulationGraphTimes(name);
      }
      if(buttonCounter == 1) {
          getChartPopulationPyramidTimeRace(svg, config, name);
      }
      buttonCounter += 1;
    })
    .on('mouseover', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', hoverColor);
      }
    })
    .on('mouseout', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', colorFill);
      }
    });

  buttonGroups.append('rect')
    .attr('class', 'buttonRect')
    .attr('width', bWidth)
    .attr('height', bHeight)
    .attr('x', w - w*0.2)
    .attr('y', yStart)
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('fill', colorFill);

  buttonGroups.append('text')
    .attr('class', 'buttonText')
    .attr('id', 'buttonText')
    //.attr('font-family', 'FontAwesome')
    .attr('x', w - w*0.2 + bWidth/2)
    .attr('y', yStart + bHeight/2)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('fill', 'white')
      .style('font-size', fontSize)
    .text("Darstellungswechsel");

  function updateButtonColors(button, parent) {
    parent.selectAll('rect')
      .attr('fill', colorFill);

   // button.select('rect')
   //   .attr('fill', pressedColor);
  }

  return svg;
}

function getChartPopulationGraphTimes(name){
    var colors = Highcharts.getOptions().colors;
    deleteAndCreateDivElements();

     const allYears = Object.keys(Object.values(popAgesData)[0]);
     const amountArray = Object.values(Object.values(popAgesData)).reverse();
     const allAges = Object.keys(popAgesData).reverse();

    let dataAges = [];
     for (let i in allAges) {
        let dataDict = {
            name: allAges[i],
            data: Object.values(amountArray[i])
        };
        dataAges.push(dataDict);
    }


Highcharts.chart('divID', {

    chart: {
        type: 'streamgraph',
        marginBottom: 15,
        zoomType: 'x'
    },

    navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },

    colors: colors,

    title: {
        floating: true,
        align: 'center',
        text: `Bevölkerung für ${name} pro Altersgruppe`
    },

    xAxis: {
        maxPadding: 0,
        type: 'category',
        crosshair: true,
        categories: allYears,
        labels: {
            align: 'left',
            reserveSpace: false,
            rotation: 270
        },
        lineWidth: 0,
        margin: 20,
        tickWidth: 0
    },

    yAxis: {
        visible: false,
        startOnTick: false,
        endOnTick: false
    },

    legend: {
        enabled: false
    },
     tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                },

    plotOptions: {
        series: {
            label: {
                minFontSize: 5,
                maxFontSize: 15,
                style: {
                    color: 'rgba(255,255,255,0.75)'
                }
            }
        }
    },

    // Data
    series: dataAges,

    exporting: {
        sourceWidth: 800,
        sourceHeight: 600
    }

});

}

function getChartPopulationPyramidTimeRace(svg, config, name){

    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

    //    var colors = Highcharts.getOptions().colors;
        deleteAndCreateDivElements();

    d3.select("#modalContainer")
          .append("button")
          .attr("class","fa fa-play")
          .attr("id","play-pause-button")
          .attr("titel","play")
          .append("div");

    d3.select("#modalContainer")
          .append("input")
          .attr("type","range")
          .attr("id","play-range")
          .attr("value","1960")
          .attr("min","1960")
          .attr("max","2050")
          .append("div");
    var initialData, chart;
    const startYear = 1960, endYear = 2050,
    btn = document.getElementById('play-pause-button'),
    input = document.getElementById('play-range');

/**
 * Animate dataLabels functionality
 */
(function(H) {
  const FLOAT = /^-?\d+\.?\d*$/;

  // Add animated textSetter, just like fill/strokeSetters
  H.Fx.prototype.textSetter = function(proceed) {
    var startValue = this.start.replace(/ /g, ''),
      endValue = this.end.replace(/ /g, ''),
      currentValue = this.end.replace(/ /g, '');

    if ((startValue || '').match(FLOAT)) {
      startValue = parseInt(startValue, 10);
      endValue = parseInt(endValue, 10);

      // No support for float
      currentValue = Highcharts.numberFormat(
        Math.round(startValue + (endValue - startValue) * this.pos), 0);
    }

    this.elem.endText = this.end;

    this.elem.attr(
      this.prop,
      currentValue,
      null,
      true
    );
  };

  // Add textGetter, not supported at all at this moment:
  H.SVGElement.prototype.textGetter = function(hash, elem) {
    var ct = this.text.element.textContent || '';
    return this.endText ? this.endText : ct.substring(0, ct.length / 2);
  }

  // Temporary change label.attr() with label.animate():
  // In core it's simple change attr(...) => animate(...) for text prop
  H.wrap(H.Series.prototype, 'drawDataLabels', function(proceed) {
    var ret,
      attr = H.SVGElement.prototype.attr,
      chart = this.chart;

    if (chart.sequenceTimer) {
      this.points.forEach(
        point => (point.dataLabels || []).forEach(
          label => label.attr = function(hash, val) {
            if (hash && hash.text !== undefined) {
              var text = hash.text;

              delete hash.text;

              this.attr(hash);

              this.animate({
                text: text
              });
              return this;
            } else {
              return attr.apply(this, arguments);
            }
          }
        )
      );
    }


    ret = proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    this.points.forEach(
      p => (p.dataLabels || []).forEach(d => d.attr = attr)
    );

    return ret;

  });
})(Highcharts);

/**
 * Calculate the data output
 */

function getData(year) {
    initialData = popAgesData;
  let output = Object.entries(initialData).map(country => {
    const [countryName, countryData] = country;
    return [countryName, Number(countryData[year])]
  }).sort((a, b) => b[1] - a[1]);

  return ([output[0], output.slice(0, 18)]);
}

  chart = Highcharts.chart('divID', {
    chart: {
      animation: {
        duration: 500
      },
      events: {
        render() {
          let chart = this;

          // Responsive input
          input.style.width = chart.plotWidth - chart.legend.legendWidth + chart.plotLeft / 2 - 10 + 'px' // where 10 is a padding
        }
      },
      marginRight: 50,
    },

    navigation: {
      buttonOptions: {
          horizontalAlign: 'bottom',
          x: -20
      }
    },

    plotOptions: {
      series: {
        animation: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0
      }
    },
    title: {
      useHTML: true,
      text: `Bevölkerung für ${name} pro Altersgruppe`
    },
     tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                    },
                    borderWidth: 1,
                    borderColor: '#AAA',
                },

    legend: {
      align: 'right',
      verticalAlign: 'bottom',
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '50px',
      },
      symbolHeight: 0.001,
      symbolWidth: 0.001,
      symbolRadius: 0.001,
    },
    xAxis: {
      title: {
      text: 'Altersgruppe'
    },
      type: 'category',
    },
    yAxis: [{
      opposite: true,
      title: {
        text: 'Bevölkerung pro Altersgruppe'
      },
      tickAmount: 5
    }],
    series: [{
      colorByPoint: true,
      dataSorting: {
        enabled: true,
        matchByName: true
      },
      type: 'bar',
      dataLabels: [{
        enabled: true,
      }],
      name: startYear,
      data: getData(startYear)[1]
    }]
});

/**
 * Update the chart. This happens either on updating (moving) the range input,
 * or from a timer when the timeline is playing.
 */
function update(increment) {
  if (increment) {
    input.value = parseInt(input.value) + increment;
  }
  if (input.value >= endYear) { // Auto-pause
    pause(btn);
  }

  chart.update({
    title: {
      useHTML: true,
      text: `Bevölkerung für ${name} pro Altersgruppe`
    },
  }, false, false, false)

  chart.series[0].update({
    name: input.value,
    data: getData(input.value)[1]
  })
}

/**
 * Play the timeline.
 */
function play(button) {
  button.title = 'pause';
  button.className = 'fa fa-pause';
  chart.sequenceTimer = setInterval(function() {
    update(1);
  }, 500);

}

/**
 * Pause the timeline, either when the range is ended, or when clicking the pause button.
 * Pausing stops the timer and resets the button to play mode.
 */
function pause(button) {
  button.title = 'play';
  button.className = 'fa fa-play';
  clearTimeout(chart.sequenceTimer);
  chart.sequenceTimer = undefined;
}


btn.addEventListener('click', function() {
  if (chart.sequenceTimer) {
    pause(this)
  } else {
    play(this)
  }
})
/**
 * Trigger the update on the range bar click.
 */
input.addEventListener('click', function() {
  update()
})
}


export default function getPopulationChart(popData, densityData, femaleData, maleData, data, lifeExpData, pop_GrowthData, div, clickedPolygon) {

  popAgesData = data;
  const name = clickedPolygon['ADMIN'];
  let GID = clickedPolygon['level'];
  let year = clickedPolygon['year'].substring(2);
  var members;

  if(GID == 'GID_1'){
    members = [{
      label: "Bevölkerung",
      value: 1
    },
    {
      label: "Bevölkerungsdichte",
      value: 2
    }
  ];
  }
  else{
    members = [{
      label: "Bevölkerung",
      value: 1
    },
    {
      label: "Bevölkerungsdichte",
      value: 2
    },
    {
      label: "Bevölkerungspyramide",
      value: 3
    },
    {
      label: "Bevölkerungspyramide Zeitreihe",
      value: 4
    },
    {
      label: "Lebenserwartung",
      value: 5
    }
  ];
  }

  const yearsPop = Object.keys(popData); //xData
  let dataPop = Object.values(popData); //yData
  const dataLengthPop = dataPop.length;

  const yearslifeExp = Object.keys(lifeExpData); //xData
  let datalifeExp = Object.values(lifeExpData); //yData
  const dataLengthlifeExp = datalifeExp.length;

  const yearspop_Growth = Object.keys(pop_GrowthData);
  let datapop_Growth = Object.values(pop_GrowthData);
  const dataLengthpop_Growth = datapop_Growth.length;

  const yearsDensity = Object.keys(densityData); //xData
  let dataDensity = Object.values(densityData); //yData

  let densityArray = [];
  let popArray = [];
  let lifeExpArray =[];
  let pop_GrowthArray = [];
  for (var i in dataDensity){
      let densityDict = {};
      densityDict['x'] = parseInt(yearsDensity[i].substring(2));
      densityDict['y'] = parseInt(dataDensity[i]);
      densityArray.push(densityDict)
  }
  for (var i in dataPop){
      let popDict = {};
      if(dataLengthPop < 10){popDict['x'] = parseInt(yearsPop[i].substring(2));}
      else{popDict['x'] = parseInt(yearsPop[i].substring(0,4));}
      popDict['y'] = parseInt(dataPop[i]);
      popArray.push(popDict)
  }
  for (var i in datalifeExp){
      let lifeExpDict = {};
      if(dataLengthlifeExp < 10){lifeExpDict['x'] = parseInt(yearslifeExp[i].substring(2));}
      else{lifeExpDict['x'] = parseInt(yearslifeExp[i].substring(0,4));}
      lifeExpDict['y'] = parseInt(datalifeExp[i]);
      lifeExpArray.push(lifeExpDict)
  }
  for (var i in datapop_Growth){
      let pop_GrowthDict = {};
      if(dataLengthpop_Growth < 10){pop_GrowthDict['x'] = parseInt(yearspop_Growth[i].substring(2));}
      else{pop_GrowthDict['x'] = parseInt(yearspop_Growth[i].substring(0,4));}
      pop_GrowthDict['y'] = Number(datapop_Growth[i]);
      pop_GrowthArray.push(pop_GrowthDict)
  }

   //negativ values to list
  maleData = maleData.map(x => x * -1);

  deleteDivElements();
  deleteCheckboxes();

  let svg = div.append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
        .attr('id', 'fitFunction');

  var config = {
    width: document.body.clientWidth*0.18,
    container: svg,
    members,
    fontSize: fontSize,
    color: "#333",
    fontFamily: "calibri",
    x: document.body.clientWidth*0.035,
    y: yStart,
    changeHandler: function(option) {
      // "this" refers to the option group
      // Change handler code goes here
      svg.selectAll("*").remove();

      if (option.label == 'Bevölkerung'){
          getChartPopulation(svg, config, dataLengthPop, popArray, dataLengthpop_Growth, pop_GrowthArray, name);
      }
      else if (option.label == 'Bevölkerungsdichte'){
          getChartDensity(svg, config, densityArray, name);
      }
      else if (option.label == 'Bevölkerungspyramide') {
          getChartPopulationPyramid(svg, config, maleData, femaleData, name, year);
      }
      else if (option.label == 'Bevölkerungspyramide Zeitreihe') {
          buttonCounter = 0;
          svgDropDown(config);
          getChartChangeButton(svg, config, name);
          getChartPopulationPyramidTimeRace(svg, config, name);
      }
      else if (option.label == 'Lebenserwartung') {
          getChartPopulationlifeExp(svg, config, dataLengthlifeExp, lifeExpArray, name);
      }
    }
  };

  getChartPopulation(svg, config, dataLengthPop, popArray, dataLengthpop_Growth, pop_GrowthArray, name);


  return svg.node()

}

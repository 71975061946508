import L from 'leaflet';
import './L.Control.Layers.Tree';
import {
  geoJsonCountryFat, geoJsonCountryEve, geoJsonRegionFat, geoJsonRegionEve, geoJsonCountryPre, geoJsonCountryFop,
  geoJsonCountryEth, geoJsonCountryRef, pruneCluster, geoJsonCountryReli,
  geoJsonCountryFatMoni, geoJsonRegionFatMoni, geoJsonCountryEveMoni, geoJsonRegionEveMoni,
  refugeeLayer, geoJsonRegionDensity, geoJsonCountryDensity, geoJsonCountryActors, geoJsonRegionActors,
  internationalAirports, mediumAirports, smallAirports, heliportAirports, seaplaneAirports, militaryAirports, militaryBunkers,
  militaryCheckpoints, militaryTraining, militaryBarracks, hospitals, embassies, bordercontrol, granite, limestone,
  aluminium, oil, coal, liquified_natural_gas, liquified_petrolium_gas, natural_gas, diamond, petroleum_products, uranium, copper, tin,
    helium, lithium, berrylium, magnesium, molybdenum, manganese, arsenic, bismut, bromine, cadmium, chromium, gallium, germanium, gold, indium, iron, cobalt,
    lead, nickel, niobium, nitrogen, palladium, plutonium, silicon, silver, sulfur, tantalum, titanium, vanadium, zinc,
    gemstones, sand,steel, cement, phosphate, strontium, thallium, deckLayer, deckLayerGrid
} from '../../components/Map.vue';
import namingConventions from '../../../NamingConventions';

const basemaps = namingConventions().german.technical.control.basemaps;
const worldGrey = namingConventions().german.technical.control.basemapGrey;
const worldTopo = namingConventions().german.technical.control.basemapTopo;
const worldStreet = namingConventions().german.technical.control.basemapStreet;
const events = namingConventions().german.technical.control.events;
const layerGroupAmount = namingConventions().german.technical.control.layerGroupAmount;
const layerGroupActors = namingConventions().german.technical.control.layerGroupActors;
const layerGroupHotspot = namingConventions().german.technical.control.layerGroupHotspot;
const layerGroupPop = namingConventions().german.technical.control.layerGroupPop;
const layerGroupEth = namingConventions().german.technical.control.layerGroupEth;
const layerGroupReli = namingConventions().german.technical.control.layerGroupReli;
const layerGroupPre = namingConventions().german.technical.control.layerGroupPre;
const layerGroupFop = namingConventions().german.technical.control.layerGroupFop;


export default function setLayerControl(esriWorldGrayCanvas, esriWorldImagery, esriWorldStreetMap,
  setPredictionLayer, setSourcesLayer) {
  const baseTree = {
    label: basemaps,
    collapsed: true,
    children: [
      { label: worldGrey, layer: esriWorldGrayCanvas },
      { label: worldTopo, layer: esriWorldImagery },
      { label: worldStreet, layer: esriWorldStreetMap },
    ],
  };

  const empty = L.tileLayer('', { attribution: '' });

   // einfach 0 setzten, wenn nicht angezeigt werden soll
   let dictAkteure = {
              label: layerGroupActors,
              collapsed: true,
              children: [
                {
                  label: 'Länder Akteure',
                  name: 'geoJsonCountryActors',
                  layer: geoJsonCountryActors,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Regionen Akteure',
                  name: 'geoJsonRegionActors',
                  layer: geoJsonRegionActors,
                  radioGroup: 'aggregation',
                },
              ],
            };
  let dictAggregationWachstum = {
              label: layerGroupHotspot,
              collapsed: true,
              children: [
                {
                  label: 'Länder Konflikttote',
                  name: 'geoJsonCountryFatMoni',
                  layer: geoJsonCountryFatMoni,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Regionen Konflikttote',
                  name: 'geoJsonRegionFatMoni',
                  layer: geoJsonRegionFatMoni,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Länder Ereignisse',
                  name: 'geoJsonCountryEveMoni',
                  layer: geoJsonCountryEveMoni,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Regionen Ereignisse',
                  name: 'geoJsonRegionEveMoni',
                  layer: geoJsonRegionEveMoni,
                  radioGroup: 'aggregation',
                }],
            };

  let dictBevölkerungsdichte = {
              label: layerGroupPop,
              collapsed: true,
              children: [
                {
                  label: 'Länder Bevölkerungsdichte',
                  name: 'geoJsonCountryDensity',
                  layer: geoJsonCountryDensity,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Regionen Bevölkerungsdichte',
                  name: 'geoJsonRegionDensity',
                  layer: geoJsonRegionDensity,
                  radioGroup: 'aggregation',
                },
              ],
            };
  let dictEthnien = {
              label: layerGroupEth,
              collapsed: true,
              children: [
                {
                  label: 'Länder Ethnien', name: 'geoJsonCountryEth', layer: geoJsonCountryEth, radioGroup: 'aggregation',
                },
              ],
            };
  let dictReligion = {
              label: layerGroupReli,
              collapsed: true,
              children: [
                {
                  label: 'Länder Religionen', name: 'geoJsonCountryReli', layer: geoJsonCountryReli, radioGroup: 'aggregation',
                },
              ],
            };
  let dictVorhersage = {
            label: layerGroupPre,
            collapsed: true,
            children: [
              {
                label: 'Länder Vorhersage',
                name: 'geoJsonCountryPre',
                layer: geoJsonCountryPre,
                radioGroup: 'aggregation',
              },
              {
                label: 'Grid Vorhersage',
                name: 'geoJsonGridPre',
                layer: deckLayerGrid,
                radioGroup: 'aggregation',
              },
            ],
          };
    if(setPredictionLayer == false){dictVorhersage = 0;}
  let dictInfrastructure = {
            label: 'Infrastruktur',
            collapsed: true,
            children: [
              {
                label: 'Militär',
                collapsed: true,
                children: [
                  {
                    label: 'Flughafen',
                    name: 'militaryAirports',
                    layer: militaryAirports,
                  },
                  {
                    label: 'Bunker',
                    name: 'militaryBunkers',
                    layer: militaryBunkers,
                  },
                  {
                    label: 'Checkpoints',
                    name: 'militaryCheckpoints',
                    layer: militaryCheckpoints,
                  },
                  {
                    label: 'Training',
                    name: 'militaryTraining',
                    layer: militaryTraining,
                  },
                  {
                    label: 'Kasernen',
                    name: 'militaryBarracks',
                    layer: militaryBarracks,
                  },
                ]
              },
              {
                label: 'Flughäfen',
                collapsed: true,
                children: [
                  {
                    label: 'Groß',
                    name: 'internationalAirports',
                    layer: internationalAirports,
                  },
                  {
                    label: 'Medium',
                    name: 'mediumAirports',
                    layer: mediumAirports,
                  },
                  {
                    label: 'Klein',
                    name: 'smallAirports',
                    layer: smallAirports,
                  },
                  {
                    label: 'Helikopter',
                    name: 'heliportAirports',
                    layer: heliportAirports,
                  },
                  {
                    label: 'Wasser',
                    name: 'seaplaneAirports',
                    layer: seaplaneAirports,
                  },
               ]},
                {
                label: 'Institutionen',
                collapsed: true,
                children: [
                {
                    label: 'Krankenhaus',
                    name: 'hospitals',
                    layer: hospitals,
                  },
                  {
                    label: 'Botschaft',
                    name: 'embassies',
                    layer: embassies,
                  },
                   {
                    label: 'Grenzübergang',
                    name: 'bordercontrol',
                    layer: bordercontrol,
                  },
                ]},
            ],
          };
    let dictRohstoffe = {
            label: 'Rohstoffe',
            collapsed: true,
            children: [
                {
                    label: 'Elemente',
                    collapsed: true,
                    children: [
                        {
                            label: 'Actinoide',
                            collapsed: true,
                            children:  [
                                {
                                    label: 'Plutonium',
                                    name: 'plutonium',
                                    layer: plutonium,
                                },
                                {
                                    label: 'Uran',
                                    name: 'uranium',
                                    layer: uranium,
                                },
                            ]
                        },
                        {
                            label: 'Seltene Erden',
                            collapsed: true,
                            children: [
                                {
                                    },
                            ]
                        },
                        {
                            label: 'Alkalimetalle',
                            collapsed: true,
                            children: [
                                {
                                    label: 'Berrylium',
                                    name: 'berrylium',
                                    layer: berrylium,
                                },
                                {
                                    label: 'Magnesium',
                                    name: 'magnesium',
                                    layer: magnesium,
                                },
                                {
                                    label: 'Lithium',
                                    name: 'lithium',
                                    layer: lithium,
                                },
                                {
                                    label: 'Strontium',
                                    name: 'strontium',
                                    layer: strontium,
                                },
                            ]
                        },
                        {
                            label: 'Metalle',
                            collapsed: true,
                            children: [
                                {
                                    label: 'Aluminium',
                                    name: 'aluminium',
                                    layer: aluminium,
                                },
                                {
                                    label: 'Bismut',
                                    name: 'bismut',
                                    layer: bismut,
                                },
                                {
                                    label: 'Blei',
                                    name: 'lead',
                                    layer: lead,
                                },
                                {
                                    label: 'Cadmium',
                                    name: 'cadmium',
                                    layer: cadmium,
                                },
                                {
                                    label: 'Chromium',
                                    name: 'chromium',
                                    layer: chromium,
                                },
                                {
                                    label: 'Eisen',
                                    name: 'iron',
                                    layer: iron,
                                },
                                {
                                    label: 'Gallium',
                                    name: 'gallium',
                                    layer: gallium,
                                },
                                {
                                    label: 'Gold',
                                    name: 'gold',
                                    layer: gold,
                                },
                                {
                                    label: 'Indium',
                                    name: 'indium',
                                    layer: indium,
                                },
                                {
                                    label: 'Kobalt',
                                    name: 'cobalt',
                                    layer: cobalt,
                                },
                                {
                                    label: 'Kupfer',
                                    name: 'copper',
                                    layer: copper,
                                },
                                {
                                    label: 'Mangan',
                                    name: 'manganese',
                                    layer: manganese,
                                },
                                {
                                    label: 'Molybdän',
                                    name: 'molybdenum',
                                    layer: molybdenum,
                                },
                                {
                                    label: 'Nickel',
                                    name: 'nickel',
                                    layer: nickel,
                                },
                                {
                                    label: 'Niobium',
                                    name: 'niobium',
                                    layer: niobium,
                                },
                                {
                                    label: 'Palladium',
                                    name: 'palladium',
                                    layer: palladium,
                                },
                                {
                                    label: 'Silber',
                                    name: 'silver',
                                    layer: silver,
                                },
                                {
                                    label: 'Tantalum',
                                    name: 'tantalum',
                                    layer: tantalum,
                                },
                               /* {
                                    label: 'Thallium',
                                    name: 'thallium',
                                    layer: thallium,
                                },*/
                                {
                                    label: 'Titan',
                                    name: 'titanium',
                                    layer: titanium,
                                },
                                {
                                    label: 'Vanadium',
                                    name: 'vanadium',
                                    layer: vanadium,
                                },
                                {
                                    label: 'Zink',
                                    name: 'zinc',
                                    layer: zinc,
                                },
                            ]
                        },
                        {
                            label: 'Halbmetalle',
                            collapsed: true,
                            children: [
                                {
                                    label: 'Arsen',
                                    name: 'arsenic',
                                    layer: arsenic,
                                },
                                {
                                    label: 'Brom',
                                    name: 'bromine',
                                    layer: bromine,
                                },
                                {
                                    label: 'Germanium',
                                    name: 'germanium',
                                    layer: germanium,
                                },
                                {
                                    label: 'Silizium',
                                    name: 'silicon',
                                    layer: silicon,
                                },
                                {
                                    label: 'Zinn',
                                    name: 'tin',
                                    layer: tin,
                                },
                            ]
                        },
                        {
                            label: 'Nichtmetalle',
                            collapsed: true,
                            children: [
                                {
                                    label: 'Helium',
                                    name: 'helium',
                                    layer: helium,
                                },
                                {
                                    label: 'Nitrogen',
                                    name: 'nitrogen',
                                    layer: nitrogen,
                                },
                                {
                                    label: 'Schwefel',
                                    name: 'sulfur',
                                    layer: sulfur,
                                },
                            ]
                        },
                    ],
                },
                {
                    label: 'Fossile Energieträger',
                    collapsed: true,
                    children: [
                        {
                            label: 'Öl',
                            name: 'oil',
                            layer: oil,
                        },
                        {
                            label: 'Erdölprodukte',
                            name: 'petroleum_products',
                            layer: petroleum_products,
                        },
                        {
                            label: 'Kohle',
                            name: 'coal',
                            layer: coal,
                        },
                        {
                            label: 'Gas',
                            collapsed: true,
                            children: [
                                {
                                    label: 'Verflüssigtes Erdgas',
                                    name: 'liquified_natural_gas',
                                    layer: liquified_natural_gas,
                                },
                                {
                                    label: 'Flüssiggas (Petrolium)',
                                    name: 'liquified_petrolium_gas',
                                    layer: liquified_petrolium_gas,
                                },
                                {
                                    label: 'Erdgas',
                                    name: 'natural_gas',
                                    layer: natural_gas,
                                },
                            ]
                        },
                    ],
                },
                {
                    label: 'Mineralien',
                    collapsed: true,
                    children: [
                        {
                            label: 'Diamant',
                            name: 'diamond',
                            layer: diamond,
                        },
                        {
                            label: 'Edelsteine',
                            name: 'gemstones',
                            layer: gemstones,
                        },
                        {
                            label: 'Granit',
                            name: 'granite',
                            layer: granite,
                        },
                        {
                            label: 'Kalkstein',
                            name: 'limestone',
                            layer: limestone,
                        },
                        {
                            label: 'Phosphat',
                            name: 'phosphate',
                            layer: phosphate,
                        },
                        {
                            label: 'Sand',
                            name: 'sand',
                            layer: sand,
                        },
                        {
                            label: 'Stahl',
                            name: 'steel',
                            layer: steel,
                        },
                        {
                            label: 'Zement',
                            name: 'cement',
                            layer: cement,
                        },
                    ]
                }
            ],
          };

  let dictPressFreedom = {
            label: layerGroupFop,
            collapsed: true,
            children: [
              {
                label: 'Länder Pressefreiheit Index',
                name: 'geoJsonCountryFop',
                layer: geoJsonCountryFop,
                radioGroup: 'aggregation',
              },
            ],
          };
  if(setSourcesLayer == false){dictPressFreedom = 0;}
  let dictRefugees = 0 /*{
              label: 'Geflüchtete',
              collapsed: true,
              children: [
                {
                  label: 'Geflüchtete', name: 'geoJsonCountryRef', layer: geoJsonCountryRef, radioGroup: 'aggregation',
                },
              ],
            };
  if (refugeeLayer == false){dictRefugees = 0;}*/
    let overlayTreeComposition = {
      label: 'Layer',
      children: [
        {label: events, name: 'pruneCluster', layer: pruneCluster},
        {label: 'Ereignisse (WebGL)', name: 'eventsWebgl', layer: deckLayer} ,
        dictInfrastructure,
        dictRohstoffe,
        {
          label: 'Aggregation',
          radioGroup: 'aggregation',
          collapsed: true,
          children: [
            {
              label: layerGroupAmount,
              collapsed: true,
              children: [
                {
                  label: 'Regionen Konflikttote',
                  name: 'geoJsonRegionFat',
                  layer: geoJsonRegionFat,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Länder Konflikttote',
                  name: 'geoJsonCountryFat',
                  layer: geoJsonCountryFat,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Regionen Ereignisse',
                  name: 'geoJsonRegionEve',
                  layer: geoJsonRegionEve,
                  radioGroup: 'aggregation',
                },
                {
                  label: 'Länder Ereignisse',
                  name: 'geoJsonCountryEve',
                  layer: geoJsonCountryEve,
                  radioGroup: 'aggregation',
                 },
          ],
        },
        dictAkteure,
        dictAggregationWachstum,
        dictBevölkerungsdichte,
        dictEthnien,
        dictVorhersage,
        dictPressFreedom,
        dictRefugees,
        dictReligion,
       {
         label: 'Keine', name: 'Empty', layer: empty, radioGroup: 'aggregation',
       },
      ],
    },],}


    return L.control.layers.tree(baseTree, overlayTreeComposition,  {spaceSymbol: ' ', position: 'bottomright'});

}

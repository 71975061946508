<template>
  <a-tree-select
    style="width: 100%"
    treeNodeFilterProp="title"
    :dropdownStyle="{ maxHeight : '250px' }"
    :tree-data="actorOptions1"
    tree-checkable
    v-model="actor1"
    :show-checked-strategy="SHOW_PARENT"
    search-placeholder="Auswahl"/>
</template>
<script>
import axios from 'axios';
import { TreeSelect } from 'ant-design-vue';
import { url } from '../App.vue';

const { SHOW_PARENT } = TreeSelect;

export default {
  name: 'ActorFilter1',
  data() {
    return {
      SHOW_PARENT,
      tabs: [],
      visible: true,
      actorPuffer1: this.actorInput1,
      actorOptions1: [],
      size: 12,
      filterSize: 12,
    };
  },
  async mounted() {
    const accessToken = await this.$auth.getTokenSilently();
    const form = this.$store.state.filter;
    const db = this.$store.state.db;
    await axios.post(url + 'actor1/', {form, db},
        {headers:{authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        this.actorOptions1 = response.data;
      }).catch();
  },
  props: {
    actorInput1: {
      required: true,
      type: Array,
    },
  },
  computed: {
    actor1: {
     get() {
        return this.actorPuffer1;
      },
      set(value) {
        this.$emit('update:actorInput1', value);
      },
    },
  },
  watch: {
    '$store.state.db': function () {
      this.eventPuffer = [];
      this.eventOptions = [];
      this.getSelectOptionsActor1Name(true);
    },
    '$store.state.filter.country': function () {
      this.getSelectOptionsActor1Name(false);
    },
    actorInput1: {
      deep: true,
      handler(value) {
        this.actorPuffer1 = value;
      },
    },
  },
  methods: {
    async getSelectOptionsActor1Name(dbSwitch){
        if(dbSwitch) {
          this.$store.state.filter.actor1 = ["Alle"];
          this.$store.state.filter.actor2 = ["Alle"];
        }
        const form = this.$store.state.filter;
        const db = this.$store.state.db;
        const accessToken = await this.$auth.getTokenSilently();
        await axios.post(url + 'actor1/', {form, db},
            {headers:{authorization: `Bearer ${accessToken}`}})
        .then((response) => {
          this.actorOptions1 =  response.data;
          this.actorPuffer1 = ['Alle'];
          this.$emit('update:actorInput1', this.actorPuffer1);
        }).catch();
    }
  },
};
</script>

const Swal = require('sweetalert2');

export default function getPopUp(data) {
  let text = `${"<div align='left'>"
  + "<span style='font-size:16px; font-family: Calibri, Helvetica, monospace, Arial, sans-serif'>"
  + '<div style="text-align: center;"><span style="font-size:18px;"> '
  + '<strong>Details </strong></span></div>'}${
    data.properties.NOTES
  }<br> <br><strong>Konflikttote: </strong>${data.properties.FATALITIES
  }<br><strong>Ereignistyp: </strong>${data.properties.FIVE_TYPE_DEU
  }<br><strong>Nebenereignistyp: </strong>${data.properties.SUB_EVENT_TYPE_DEU
  }<br><strong>Akteur 1: </strong>${data.properties.ACTOR1} : ${data.properties.INTER1
  }<br><strong>Akteur 2: </strong>${data.properties.ACTOR2} : ${data.properties.INTER2
  }<br><strong>Ereignisdatum: </strong>${data.properties.EVENT_DATE
  }<br><strong>Quelle: </strong>${data.properties.SOURCE}`;
  if (data.properties.similar_fatalities) {
    text = `${text}<br><strong>Alternative Quellen Details: </strong>${data.properties.similar_notes}<br><strong>Alternative Quellen Tote: </strong>${data.properties.similar_fatalities}</span></div>`;
  } else {
    text += '</span></div>';
  }
  Swal.fire({
    html: text,
    confirmButtonText: 'OK',
    confirmButtonColor: 'orange',
    background: 'white',
    position: 'top-end',
    opacity: '80%',
  });
}

import {
   maxColorCountryEve, maxColorRegionEve, maxColorCountryPre, maxColorCountryFop, maxColorCountryEth,maxColorCountryReli,
  maxColorCountryFat, maxColorRegionFat, maxColorCountryActors, maxColorRegionActors, minColorCountryReli,
  geoJsonCountryFat, geoJsonCountryFatMoni,geoJsonRegionFatMoni, geoJsonCountryEve, geoJsonRegionFat, geoJsonRegionEve, geoJsonCountryRef,
  geoJsonCountryEth, geoJsonCountryReli, geoJsonCountryActors, geoJsonCountryPre, geoJsonCountryFop,
  geoJsonRegionActors, geoJsonCountryEveMoni, geoJsonRegionEveMoni, geoJsonRegionDensity, geoJsonCountryDensity, maxColorCountryDensity, maxColorRegionDensity,
    maxColorMoni, minColorMoni
} from '../../components/Map.vue';
// eslint-disable-next-line import/no-cycle
import {
  time, timeIndex, times, removeLegendPolygon, addLegendPolygon, updateTime,
} from './Map.Legend';

const d3 = require('d3');

function getColor(t, type, maxColor, minColor) {
  let logScale;
  if (type === 'country_fat' || type === 'country_eve' || type === 'region_fat' || type === 'region_eve'
    || type === 'country_density' || type === 'region_density'){
    logScale = d3.scaleSymlog().domain([0, maxColor]).range([0, 1]);
  } else if (type === 'country_ref' || type === 'country_eth'
    || type === 'country_act' || type === 'region_act') {
    logScale = d3.scaleSqrt().domain([0, maxColor]).range([0, 1]);
  }
  else if (type === 'country_reli'){
    logScale = d3.scaleLinear().domain([minColor, maxColor]).range([0, 1]);
  }
  const colorScaleLog = d3.scaleSequential(
    (d) => d3.interpolateReds(logScale(d)),
  );

  return colorScaleLog(t);
}

export function getaltColor(t,type,maxColor) {
  let linScale;
  let colorScaleLog;
  if (type === 'country_pre') {
    linScale = d3.scalePow().exponent(0.25).domain([0, 100]).range([0, 1]);
    colorScaleLog = d3.scaleSequential(
      (d) => d3.interpolateTurbo(linScale(d))
    );
  } else if (type === 'grid_pred') {
    linScale = d3.scaleLinear().domain([0, maxColor]).range([0, 1]);
    colorScaleLog = d3.scaleSequential(
      (d) => d3.interpolateTurbo(linScale(d))
    );
    return d3.color(colorScaleLog(t))
  } else if (type === 'country_fop') {
    linScale = d3.scaleLinear().domain([0, maxColor]).range([-0.25, 1]);
    colorScaleLog = d3.scaleSequential(
        (d) => d3.interpolateHslLong("red", "green")(linScale(d))
    );
  }
  return colorScaleLog(t);
}

function getMoniColor(t,minColor, maxColor) {
  let linScale;
  let colorScaleLog;
    linScale = d3.scaleSqrt().domain([minColor, maxColor]).range([0, 1]);
    colorScaleLog = d3.scaleSequential((d) => d3.interpolateHslLong("green", "red")(linScale(d)));
  return colorScaleLog(t);
}


function getTimes(feature) {
  if (times.length === 0) {
    for (const key in feature.properties) {
      if (key.startsWith('YR')) {
        times.push(key);
      }
    }
  }
}

export function styleCountryFat(feature) {
  const fillColor = getColor(feature.properties.FATALITIES, 'country_fat', maxColorCountryFat, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryActors(feature) {
  const fillColor = getColor(feature.properties.NumberOfActors, 'country_act', maxColorCountryActors, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionActors(feature) {
  const fillColor = getColor(feature.properties.NumberOfActors, 'region_act', maxColorRegionActors, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryFatMoni(feature) {
  const fillColor = getMoniColor(feature.properties.delta_fat, minColorMoni['country_fat'], maxColorMoni['country_fat']);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryEveMoni(feature) {
  const fillColor = getMoniColor(feature.properties.delta_eve, minColorMoni['country_eve'], maxColorMoni['country_eve']);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionFatMoni(feature) {
  const fillColor = getMoniColor(feature.properties.delta_fat, minColorMoni['region_fat'], maxColorMoni['region_fat']);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionEveMoni(feature) {
  const fillColor = getMoniColor(feature.properties.delta_eve, minColorMoni['region_eve'], maxColorMoni['region_eve']);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionFat(feature) {
  const fillColor = getColor(feature.properties.FATALITIES, 'region_fat', maxColorRegionFat, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryEve(feature) {
  const fillColor = getColor(feature.properties.Length, 'country_eve', maxColorCountryEve, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionEve(feature) {
  const fillColor = getColor(feature.properties.Length, 'region_eve', maxColorRegionEve, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}


export function styleCountryPre(feature) {
  const fillColor = getaltColor(feature.properties.PREDICTION, 'country_pre', maxColorCountryPre);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryFop(feature) {
  const fillColor = getaltColor(feature.properties.Gesamt, 'country_fop', maxColorCountryFop);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}


export function styleCountryDensity(feature) {
  if( typeof time === 'undefined' || time === null ){
    updateTime(0, 'countryPop');
  }
  const fillColor = getColor(feature.properties[time], 'country_density', maxColorCountryDensity[time], 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleRegionDensity(feature) {
  if( typeof time === 'undefined' || time === null ){
    updateTime(0, 'regionPop');
  }
  const fillColor = getColor(feature.properties[time], 'region_density', maxColorRegionDensity[time], 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryEth(feature) {
  const fillColor = getColor(feature.properties.actv_groups_count, 'country_eth', maxColorCountryEth, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryReli(feature) {
  const fillColor = getColor(feature.properties.Religion, 'country_reli', maxColorCountryReli, minColorCountryReli);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.5,
  };
}

export function styleCountryRef() {
  const fillColor = getColor(1, 'country_eth', 1, 0);
  return {
    fillColor,
    weight: 0.5,
    opacity: 0.5,
    color: 'black',
    dashArray: '3',
    fillOpacity: 0.1,
  };
}

function highlightFeature() {
  this.setStyle({
    weight: 3,
    color: '#27571a',
    dashArray: '',
    fillOpacity: 0.7,
  });
}

function refreshData() {
  removeLegendPolygon();
  addLegendPolygon('country_ref');
}

function resetHighlightCountryFat() {
  geoJsonCountryFat.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryFatMoni() {
  geoJsonCountryFatMoni.resetStyle(this);
  this.bringToFront();
}
function resetHighlightRegionFatMoni() {
  geoJsonRegionFatMoni.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryEveMoni() {
  geoJsonCountryEveMoni.resetStyle(this);
  this.bringToFront();
}
function resetHighlightRegionEveMoni() {
  geoJsonRegionEveMoni.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryEve() {
  geoJsonCountryEve.resetStyle(this);
  this.bringToFront();
}

function resetHighlightRegionFat() {
  geoJsonRegionFat.resetStyle(this);
  this.bringToFront();
}

function resetHighlightRegionEve() {
  geoJsonRegionEve.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryActors() {
  geoJsonCountryActors.resetStyle(this);
  this.bringToFront();
}

function resetHighlightRegionActors() {
  geoJsonRegionActors.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryPre() {
  geoJsonCountryPre.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryFop() {
  geoJsonCountryFop.resetStyle(this);
  this.bringToFront();
}

function resetHighlightCountryDensity() {
  geoJsonCountryDensity.resetStyle(this);
  this.bringToFront();
}
function resetHighlightRegionDensity() {
  geoJsonRegionDensity.resetStyle(this);
  this.bringToFront();
}
function resetHighlightCountryEth() {
  geoJsonCountryEth.resetStyle(this);
  this.bringToFront();
}
function resetHighlightCountryReli() {
  geoJsonCountryReli.resetStyle(this);
  this.bringToFront();
}
function resetHighlightCountryRef() {
  geoJsonCountryRef.resetStyle(this);
  this.bringToFront();
}

export function onEachFeatureCountryRef(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryRef,
    click: refreshData,
  });
}
export function onEachFeatureCountryEth(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryEth,
  });
}

export function onEachFeatureCountryReli(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryReli,
  });
}

export function onEachFeatureCountryFat(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryFat,
  });
}

export function onEachFeatureCountryFatMoni(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryFatMoni,
  });
}

export function onEachFeatureRegionFatMoni(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionFatMoni,
  });
}

export function onEachFeatureCountryEveMoni(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryEveMoni,
  });
}

export function onEachFeatureRegionEveMoni(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionEveMoni,
  });
}

export function onEachFeatureCountryEve(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryEve,
  });
}

export function onEachFeatureRegionFat(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionFat,
  });
}

export function onEachFeatureRegionEve(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionEve,
  });
}

export function onEachFeatureCountryActors(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryActors,
  });
}

export function onEachFeatureRegionActors(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionActors,
  });
}

export function onEachFeatureCountryPop(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryPop,
  });
  // keien Eslint Korrektur für ===
  if (feature.id == 0) { getTimes(feature); }
}

export function onEachFeatureRegionPop(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionPop,
  });
}

export function onEachFeatureCountryPre(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryPre,
  });
}

export function onEachFeatureCountryFop(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryFop,
  });
}

export function onEachFeatureRegionDensity(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightRegionDensity,
  });
}
export function onEachFeatureCountryDensity(feature, layer) {
  layer.on({
    mouseover: highlightFeature,
    mouseout: resetHighlightCountryDensity,
  });
}

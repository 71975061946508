<template>
  <div class="container-fluid h-100" id="app">
      <Navbar />
      <AuthNav />
  <router-view/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import AuthNav from './components/AuthNav.vue';
const axios = require("axios");

//url für fastapi backend
//export const url = 'http://127.0.0.1:5000/';
export const url = process.env.VUE_APP_BACKEND_URL;
export const urlFrontendResource = process.env.VUE_APP_FRONTEND_RESOURCE_MANUAL;
axios.defaults.baseURL = url;


//url für frontend - wird momentan nur für das wms populations layer (bild für legende) benötigt
//export const urlWebapp = 'http://localhost:8080/';

export default {
  name: 'navbar',
  components: {
    Navbar, AuthNav
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title; //|| 'Some Default Title';
      },
    },
  },
};
</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position:absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
}
html,body {
  height: 100%;
}
</style>

<template>
  <div>
  <a-collapse default-active-key="1">
    <a-collapse-panel key="8" header="Name des Filters:">
      <b-row>

        <b-col>
          <a-input
              v-model="filterName"/>
        </b-col>
      </b-row>
    </a-collapse-panel>
    <a-collapse-panel key="2" header="Ereignistyp:">
      <EventFilter :eventInput.sync="filter.event"/>
    </a-collapse-panel>
   <!-- <a-collapse-panel key="3" header="Akteurstyp:">
      <ActorFilter1 :actorInput1.sync="filter.actor"/>
    </a-collapse-panel>-->
    <a-collapse-panel key="3" header="Akteur 1:">
      <ActorFilter1 :actorInput1.sync="filter.actor1"/>
    </a-collapse-panel>
    <a-collapse-panel key="3" header="Akteur 2:">
      <ActorFilter2 :actorInput2.sync="filter.actor2"/>
    </a-collapse-panel>
    <a-collapse-panel key="4" header="Land:">
      <a-tree-select
          style="width: 100%"
          :tree-data="optionsCountry"
          tree-checkable
          treeNodeFilterProp="title"
          v-model="filter.country"
          :show-checked-strategy="SHOW_PARENT"
          search-placeholder="Auswahl"/>
    </a-collapse-panel>

    <a-collapse-panel key="6" header="Schlüsselwortsuche:">
      <b-row>

        <b-col>
          <a-input
              :placeholder="`Durchsuche alle Daten`"
              v-model="filter.search"
          />
        </b-col>
      </b-row>
    </a-collapse-panel>
    <a-collapse-panel key="7" header="Region:">
    <b-row>
      <b-col>
      <SliderInput :title="'Longitude'" :min=-180 :max=180 :value.sync="longitude"/>
      </b-col>
      <b-col>
      <SliderInput :title="'Latitude'" :min=-90 :max=90 :value.sync="latitude"/>
      </b-col>
    </b-row>

    </a-collapse-panel>
  </a-collapse>
  <b-row>
    <b-col>
      <b-button size="sm" variant="danger" class="float-right" @click="$emit('closeTab')">
        Filter schließen
      </b-button>
    </b-col>
    <b-col>
      <b-button size="sm" variant="success" class="float-right" @click="takeOver">
        Filter auf Karte übernehmen
      </b-button>
    </b-col>
  </b-row>
    </div>
</template>
<script>
import { TreeSelect } from 'ant-design-vue';
import $ from 'jquery';
import L from 'leaflet';
import SliderInput from './SliderInput.vue';
import worker from '../assets/js/worker';
import ActorFilter1 from './ActorFilter1.vue';
import ActorFilter2 from './ActorFilter2.vue';
import EventFilter from './EventFilter.vue';
import { url } from '../App.vue';
import axios from "axios";

const { SHOW_PARENT } = TreeSelect;



export default {
  name: 'FilterMenu',
  components: { ActorFilter2, ActorFilter1, SliderInput, EventFilter },
  data() {
    return {
      SHOW_PARENT,
      tabs: [],
      visible: true,
      optionsCountry:[],
      filterPuffer: this.filterInput,
      size: 12,
      filterSize: 12,
      // filter: this.filterInput,
    };
  },
  props: {
    filterInput: {
      required: true,
      type: Object,
    },
    bounds: {
      required: true,
      type: Object,
    },
    filterNameInput: {
      required: true,
      type: String,
    },
  },

  async beforeMount() {
    const accessToken = await this.$auth.getTokenSilently();
    await axios.get(url + 'countries/', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params: {
        db: this.$store.state.db,
      },
    })
      .then((response) => {
        this.optionsCountry = response.data;
      }).catch();
  },
  mounted() {
  },
  computed: {
    longitude: {
      get() {
        // eslint-disable-next-line no-underscore-dangle
        return [this.filter.bounds._southWest.lng, this.filter.bounds._northEast.lng];
      },
      set(value) {
        // eslint-disable-next-line no-underscore-dangle
        this.filter.bounds = L.latLngBounds([this.filter.bounds._southWest.lat, value[0]], [this.filter.bounds._northEast.lat, value[1]]);
        this.$emit('update:filterInput', this.filter);
      },
    },
    latitude: {
      get() {
        // eslint-disable-next-line no-underscore-dangle
        return [this.filter.bounds._southWest.lat, this.filter.bounds._northEast.lat];
      },
      set(value) {
        // eslint-disable-next-line no-underscore-dangle
        this.filter.bounds = L.latLngBounds([value[0], this.filter.bounds._southWest.lng], [value[1], this.filter.bounds._northEast.lng]);
        this.$emit('update:filterInput', this.filter);
      },
    },
    filter: {
      get() {
        return this.filterPuffer;
      },
      set(value) {
        this.$emit('update:filterInput', value);
      },
    },
    filterName: {
      get() {
        return this.filterNameInput;
      },
      set(value) {
        this.$emit('update:filterNameInput', value);
      },
    },
    db: {
      get() {
        return this.$store.state.db;
      },
    },
  },
  watch: {
    filterInput: {
      deep: true,
      handler(value) {
        this.filterPuffer = value;
      },
    },
  },
  methods: {

    changeName(value) {
      // eslint-disable-next-line no-underscore-dangle
      this.$emit('update:filterName', value.target._value);
    },
    async takeOver() {
      const accessToken = await this.$auth.getTokenSilently();
      this.$store.commit('setFilter', this.filter);
      this.$store.commit('setFreeze', false);
      worker.send({
        form: this.filter,
        bbox: this.filter.bounds,
        token: accessToken,
      });
    },
  },
};
</script>

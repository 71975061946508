
export function getContextDict(data) {
    var text = "";
    for (const [key, value] of Object.entries(data)) {
        if (value.startsWith("http://") || value.startsWith("https://")) {
            text += '<a href=' + value + ' target="_blank">' + key + '</a> <br>';
        }
        else{text += key + ': ' + value + '<br>';}
    }
    return text;
}
import {
    maxColorCountryPre,
    maxColorCountryFop,
    maxColorCountryEve,
    maxColorRegionEve,
    maxColorCountryActors,
    maxColorRegionActors,
    maxColorCountryEth,
    maxColorCountryReli,
    minColorCountryReli,
    maxColorCountryFat,
    maxColorRegionFat,
    maxColorCountryDensity,
    maxColorRegionDensity,
    geoJsonCountryPre,
    geoJsonCountryFop,
    geoJsonCountryDensity,
    geoJsonRegionDensity,
    maxColorMoni,
    minColorMoni
} from '../../components/Map.vue';

import * as d3 from "d3";
import namingConventions from '../../../NamingConventions';
import {
    styleCountryDensity,
    styleRegionDensity,
    styleCountryPre,
    styleCountryFop
  } from "./Map.Layers.Customized";

import {
  time
} from "./Map.Legend";

const events = namingConventions().german.technical.legend.events;
const hotspotmapFatalities = namingConventions().german.technical.legend.hotspotFat;
const hotspotmapEvents = namingConventions().german.technical.legend.hotspotEve;
const prediction = namingConventions().german.technical.legend.prediction;
const pressfreedom = namingConventions().german.technical.legend.pressfreedom;
const countryFat = namingConventions().german.technical.legend.countryFat;
const countryEve = namingConventions().german.technical.legend.countryEve;
const regionFat = namingConventions().german.technical.legend.regionFat;
const regionEve = namingConventions().german.technical.legend.regionEve;
const countryAct = namingConventions().german.technical.legend.countryAct;
const regionAct = namingConventions().german.technical.legend.regionAct;
const countryEth = namingConventions().german.technical.legend.ethnien;
const countryReli = namingConventions().german.technical.legend.religions;
const countryRef = namingConventions().german.technical.legend.refugees;
const countryPop = namingConventions().german.technical.legend.countryPop;
const regionPop = namingConventions().german.technical.legend.regionPop;
const minerals = namingConventions().german.technical.legend.minerals;
const criticalMinerals = namingConventions().german.technical.legend.criticalMinerals;

// Whyyyy?
let colorMoni;
const timeMax = 'YR2020';

/**
 * Static legends
 */
const legendAcled = {
    id: 'markerCluster',
    active: true,
    title: "Ereignistypen",
    checkbox: true,
    iconType: 'dot',
    items:
    [
        {'id': 'events-0', 'selected': true, 'icon': '#FFB300', 'label': 'Ausschreitungen'},
        {'id': 'events-1', 'selected': true, 'icon': '#803E75', 'label': 'Explosionen/Gewalt auf Distanz'},
        {'id': 'events-2', 'selected': true, 'icon': '#FF6800', 'label': 'Gewalt gegen Zivilisten'},
        {'id': 'events-3', 'selected': true, 'icon': '#A6BDD7', 'label': 'Kampfhandlungen'},
        {'id': 'events-4', 'selected': true, 'icon': '#C10020', 'label': 'Proteste'},
        {'id': 'events-5', 'selected': true, 'icon': '#CEA262', 'label': 'Strategische Entwicklungen'}
    ]
};

const legendAirport = {
    id: 'airportCluster',
    title: "Flughafen",
    checkbox: false,
    iconType: 'icon',
    meta: 'OSM last update: 2022-08-02',
    items:
    [
        {'id': 'airport-0', 'selected': true, 'icon': 'icons/airport_big_black.svg', 'label': 'Großer Landflughafen', 'href': 'https://ourairports.com/help/#legend'},
        {'id': 'airport-1', 'selected': true, 'icon': 'icons/airport_medium_black.svg', 'label': 'Mittelgroßer Landflughafen', 'href': 'https://ourairports.com/help/#legend'},
        {'id': 'airport-2', 'selected': true, 'icon': 'icons/airport_small_black.svg', 'label': 'Kleiner Landflughafen', 'href': 'https://ourairports.com/help/#legend'},
        {'id': 'airport-3', 'selected': true, 'icon': 'icons/heli_airport_black.svg', 'label': 'Hubschrauberlandeplatz', 'href': 'https://ourairports.com/help/#legend'},
        {'id': 'airport-4', 'selected': true, 'icon': 'icons/waterplane_black.svg', 'label': 'Wasserflugzeuge', 'href': 'https://ourairports.com/help/#legend'},
    ]
};

const legendMilitary = {
    id: 'militaryCluster',
    title: "Militär",
    checkbox: false,
    iconType: 'icon',
    meta: 'OSM last update: 2022-08-11',
    items:
    [
        {'id': 'military-0', 'selected': true, 'icon': 'icons/airport_black.svg', 'label': 'Militärflughafen', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:military=airfield'},
        {'id': 'military-1', 'selected': true, 'icon': 'icons/bunker_black.svg', 'label': 'Bunker', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:military=bunker'},
        {'id': 'military-2', 'selected': true, 'icon': 'icons/checkpoint_black.svg', 'label': 'Checkpoint', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:military=checkpoint'},
        {'id': 'military-3', 'selected': true, 'icon': 'icons/training_black.svg', 'label': 'Training', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:military=training_area'},
        {'id': 'military-4', 'selected': true, 'icon': 'icons/barracks_black.svg', 'label': 'Kasernen', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:military=barracks'},
    ]
};

const legendInstitutions = {
    id: 'institutionCluster',
    title: "Institutionen",
    checkbox: false,
    iconType: 'icon',
    meta: 'OSM last update: 2022-08-16',
    items:
    [
        {'id': 'institutions-0', 'selected': true, 'icon': 'icons/hospital_black.svg', 'label': 'Krankenhaus', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:amenity=hospital'},
        {'id': 'institutions-1', 'selected': true, 'icon': 'icons/embassy_black.svg', 'label': 'Botschaft', 'href': 'https://wiki.openstreetmap.org/wiki/Tag:office%3Ddiplomatic'},
        {'id': 'institutions-2', 'selected': true, 'icon': 'icons/border_control_black.svg', 'label': 'Grenzkontrolle', 'href': 'https://geonode.wfp.org/layers/geonode:wld_poi_bcp_wfp'},
    ]
};

const legendLookup = {
    'markerCluster': legendAcled,
    'airportCluster': legendAirport,
    'militaryCluster': legendMilitary,
    'institutionCluster': legendInstitutions
}

export function getLegendObject(type) {
    if(Object.hasOwn(legendLookup, type)) {
        return legendLookup[type]
    }
    throw new Error(`'${type}' is not a valid legend type.`)
}

function ramp(color, n = 256) {
    const canvas = document.createElement('canvas');
    canvas.width = n;
    canvas.height = 1;
    const context = canvas.getContext('2d');
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color(i / (n - 1));
      context.fillRect(i, 0, 1, 1);
    }
    return canvas;
  }

export function getLegendPolygon(type) {
    // Quick mapping of types so I don't have to change the code in Map.vue
    const typeDict = {
        'geoJsonCountryFat' : 'country_fat',
        'geoJsonCountryEve' : 'country_eve',
        'geoJsonRegionFat' : 'region_fat',
        'geoJsonRegionEve' : 'region_eve',
        'geoJsonCountryActors' : 'country_act',
        'geoJsonRegionActors' : 'region_act',
        'geoJsonCountryEth' : 'country_eth',
        'geoJsonCountryReli' : 'country_reli',
        //'' : 'country_ref',
        'geoJsonCountryDensity' : 'country_density',
        'geoJsonRegionDensity' : 'region_density',
        'geoJsonCountryFatMoni' : 'countryMoniFat',
        'geoJsonCountryEveMoni' : 'countryMoniEve',
        'geoJsonRegionFatMoni' : 'regionMoniFat',
        'geoJsonRegionEveMoni' : 'regionMoniEve',
        'geoJsonCountryPre' : 'country_pre',
        'geoJsonGridPre' : 'grid_pred',
        'geoJsonCountryFop' : 'country_fop',
        //'' : 'country_ref'
    }
    // TODO: Add error handling
    //type = typeDict[type]
    let colorType;
    let titleType;
    if (type === 'country_fat') {
      colorType = d3.scaleSequentialSymlog([0, maxColorCountryFat], d3.interpolateReds);
      titleType = countryFat;
    }
    if (type === 'country_eve') {
      colorType = d3.scaleSequentialSymlog([0, maxColorCountryEve], d3.interpolateReds);
      titleType = countryEve;
    }
    if (type === 'region_fat') {
      colorType = d3.scaleSequentialSymlog([0, maxColorRegionFat], d3.interpolateReds);
      titleType = regionFat;
    }
    if (type === 'region_eve') {
      colorType = d3.scaleSequentialSymlog([0, maxColorRegionEve], d3.interpolateReds);
      titleType = regionEve;
    }
    if (type === 'country_act') {
      colorType = d3.scaleSequentialSymlog([0, maxColorCountryActors], d3.interpolateReds);
      titleType = countryAct;
    }
    if (type === 'region_act') {
      colorType = d3.scaleSequentialSymlog([0, maxColorRegionActors], d3.interpolateReds);
      titleType = regionAct;
    }
    if (type === 'country_eth') {
      colorType = d3.scaleSequentialSqrt([0, maxColorCountryEth], d3.interpolateReds);
      titleType = countryEth;
    }
    if (type === 'country_reli') {
      colorType = d3.scaleSequential([minColorCountryReli, maxColorCountryReli], d3.interpolateReds);
      titleType = countryReli;
    }
    if (type === 'country_ref') {
      titleType = countryRef + timeMigrationMax;
      getTitleWithoutLegend({
        title: titleType,
      });
      // TODO: add time slider
/*       const sliderContainerDiv = document.getElementById('sliderContainer');
      sliderContainerDiv.id = 'sliderContainer';
      const element = document.getElementById('timeSlider');
      if (element === null || element.value === '') {
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.id = 'timeSlider';
        slider.setAttribute('value', timeMigrationMax);
        slider.setAttribute('min', '0');
        slider.setAttribute('max', (timeIndexMigrationMax));
        slider.addEventListener('mouseup', function () {
          timeIndexMigration = this.value;
          timeMigration = timesMigration[timeIndexMigration];
          while (div.firstChild) { div.removeChild(div.lastChild); }
          titleType = 'Geflüchtete im Jahr ' + timeMigration;
          getTitleWithoutLegend({
            title: titleType,
          });
          updateTime(timeIndexMigration, 'countryRef');
        });
        sliderContainerDiv.prepend(slider);
      } */
    }
  
    if (type === 'country_density') {
      //time = times[timeIndexMax];
      colorType = d3.scaleSequentialSymlog([0, maxColorCountryDensity['YR2020']], d3.interpolateReds);
      titleType = countryPop + time;
      /**const sliderContainerDiv = document.getElementById('sliderContainer');
      sliderContainerDiv.id = 'sliderContainer';
      const element = document.getElementById('timeSlider');
      if (element === null || element.value === '') {
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.id = 'timeSlider';
        slider.setAttribute('value', timeMax);
        slider.setAttribute('min', '0');
        slider.setAttribute('max', (timeIndexMax));
        slider.addEventListener('mouseup', function () {
          timeIndex = this.value;
          time = times[timeIndex];
          while (div.firstChild) { div.removeChild(div.lastChild); }
          titleType = countryPop + time;
          getLegendAggregation({
            color: colorType,
            title: titleType,
            tickValues: getTickValues(type, 220),
            width: 220,
            tickFormat: 'f',
          });
          updateTime(timeIndex, 'countryPop');
        });
        sliderContainerDiv.prepend(slider);
      }**/
    }
  
    if (type === 'region_density') {
      //time = times[timeIndexMax];
      colorType = d3.scaleSequentialSymlog([0, maxColorRegionDensity['YR2020']], d3.interpolateReds);
      titleType = regionPop + time;
      /**const sliderContainerDiv = document.getElementById('sliderContainer');
      sliderContainerDiv.id = 'sliderContainer';
      const element = document.getElementById('timeSlider');
      if (element === null || element.value === '') {
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.id = 'timeSlider';
        slider.setAttribute('value', timeMax);
        slider.setAttribute('min', '0');
        slider.setAttribute('max', (timeIndexMax));
        slider.addEventListener('mouseup', function () {
          timeIndex = this.value;
          time = times[timeIndex];
          while (div.firstChild) { div.removeChild(div.lastChild); }
          titleType = regionPop + time;
          getLegendAggregation({
            color: colorType,
            title: titleType,
            tickValues: getTickValues(type, 220),
            width: 220,
            tickFormat: 'f',
          });
          updateTime(timeIndex, 'countryPop');
        });
        sliderContainerDiv.prepend(slider);
      }**/
    }
    if (type === 'countryMoniFat') {
      if(Math.abs(minColorMoni['country_fat']) > Math.abs(maxColorMoni['country_fat'])){
        colorMoni = Math.abs(minColorMoni['country_fat']);
      }
      else{colorMoni = Math.abs(maxColorMoni['country_fat']);}
      colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
      titleType = hotspotmapFatalities;
    }
    if (type === 'countryMoniEve') {
      if(Math.abs(minColorMoni['country_eve']) > Math.abs(maxColorMoni['country_eve'])){
        colorMoni = Math.abs(minColorMoni['country_eve']);
      }
      else{colorMoni = Math.abs(maxColorMoni['country_eve']);}
      colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
      titleType = hotspotmapEvents;
    }
    if (type === 'regionMoniFat') {
      if(Math.abs(minColorMoni['region_fat']) > Math.abs(maxColorMoni['region_fat'])){
        colorMoni = Math.abs(minColorMoni['region_fat']);
      }
      else{colorMoni = Math.abs(maxColorMoni['region_fat']);}
      colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
      titleType = hotspotmapFatalities;
    }
    if (type === 'regionMoniEve') {
      if(Math.abs(minColorMoni['region_eve']) > Math.abs(maxColorMoni['region_eve'])){
        colorMoni = Math.abs(minColorMoni['region_eve']);
      }
      else{colorMoni = Math.abs(maxColorMoni['region_eve']);}
      colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
      titleType = hotspotmapEvents;
    }
  
    if (type === 'country_pre') {
      colorType = d3.scaleSequentialPow([0, 1], d3.interpolateTurbo).exponent(0.25).domain([0, 100])
      titleType = prediction;
    }
    if (type === 'grid_pred') {
      colorType = d3.scaleSequential([0, 1], d3.interpolateTurbo).domain([0, 1])
      titleType = 'Konfliktwahrscheinlichkeit t+1 (!!DEMODATA!!)';
    }
    if (type === 'country_fop') {
      colorType = d3.scaleSequential([0, maxColorCountryFop], d3.interpolateHslLong("red", "green"));
      titleType = pressfreedom;
    }
    if (type !== 'country_ref') {
      return getLegendAggregation({
        color: colorType,
        title: titleType,
        tickValues: getTickValues(type, 220),
        width: 220,
        tickFormat: 'f',
      });
    }
  }

function getLegendAggregation({
    color,
    title,
    tickSize = 6,
    width = 220,
    height = 44 + tickSize,
    marginTop = 18,
    marginRight = 0,
    marginBottom = 16 + tickSize,
    marginLeft = 0,
    ticks = width / 64,
    tickFormat,
    tickValues,
  } = {}) {
    const svg = d3.create('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .style('overflow', 'visible')
      .style('display', 'block');
  
    let tickAdjust = (g) => g.selectAll('.tick line').attr('y1', marginTop + marginBottom - height);
    let x;
  
    // Continuous
    if (color.interpolate) {
      const n = Math.min(color.domain().length, color.range().length);
  
      x = color.copy().rangeRound(d3.quantize(d3.interpolate(marginLeft, width - marginRight), n));
  
      svg.append('image')
        .attr('x', marginLeft)
        .attr('y', marginTop)
        .attr('width', width - marginLeft - marginRight)
        .attr('height', height - marginTop - marginBottom)
        .attr('preserveAspectRatio', 'none')
        .attr('xlink:href', ramp(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))).toDataURL());
    } else if (color.interpolator) {
      x = Object.assign(color.copy()
        .interpolator(d3.interpolateRound(marginLeft, width - marginRight)), {
        range() {
          return [marginLeft, width - marginRight];
        },
      });
  
      svg.append('image')
        .attr('x', marginLeft)
        .attr('y', marginTop)
        .attr('width', width - marginLeft - marginRight)
        .attr('height', height - marginTop - marginBottom)
        .attr('preserveAspectRatio', 'none')
        .attr('xlink:href', ramp(color.interpolator()).toDataURL());
  
      if (!x.ticks) {
        if (tickValues === undefined) {
          const n = Math.round(ticks + 1);
          // eslint-disable-next-line no-param-reassign
          tickValues = d3.range(n).map((i) => d3.quantile(color.domain(), i / (n - 1)));
        }
        if (typeof tickFormat !== 'function') {
        // eslint-disable-next-line no-param-reassign
          tickFormat = d3.format(tickFormat === undefined ? ',f' : tickFormat);
        }
      }
    } else if (color.invertExtent) {
      const thresholds = color.thresholds ? color.thresholds() // scaleQuantize
        : color.quantiles ? color.quantiles() // scaleQuantile
          : color.domain(); // scaleThreshold
  
      const thresholdFormat = tickFormat === undefined ? (d) => d
        : typeof tickFormat === 'string' ? d3.format(tickFormat)
          : tickFormat;
  
      x = d3.scaleLinear()
        .domain([-1, color.range().length - 1])
        .rangeRound([marginLeft, width - marginRight]);
  
      svg.append('g')
        .selectAll('rect')
        .data(color.range())
        .join('rect')
        .attr('x', (d, i) => x(i - 1))
        .attr('y', marginTop)
        .attr('width', (d, i) => x(i) - x(i - 1))
        .attr('height', height - marginTop - marginBottom)
        .attr('fill', (d) => d);
      // eslint-disable-next-line no-param-reassign
      tickValues = d3.range(thresholds.length);
      // eslint-disable-next-line no-param-reassign
      tickFormat = (i) => thresholdFormat(thresholds[i], i);
    } else {
      x = d3.scaleBand()
        .domain(color.domain())
        .rangeRound([marginLeft, width - marginRight]);
  
      svg.append('g')
        .selectAll('rect')
        .data(color.domain())
        .join('rect')
        .attr('x', x)
        .attr('y', marginTop)
        .attr('width', Math.max(0, x.bandwidth() - 1))
        .attr('height', height - marginTop - marginBottom)
        .attr('fill', color);
  
      tickAdjust = () => {
      };
    }
  
    // eslint-disable-next-line no-shadow
    const g = svg.append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x)
        .ticks(ticks, typeof tickFormat === 'string' ? tickFormat : undefined)
        .tickFormat(typeof tickFormat === 'function' ? tickFormat : undefined)
        .tickSize(tickSize)
        .tickValues(tickValues))
      .call(tickAdjust)
    // eslint-disable-next-line no-shadow
      .call((g) => g.select('.domain').remove())
    // eslint-disable-next-line no-shadow
      .call((g) => g.append('text')
        .attr('x', marginLeft)
        .attr('y', marginTop + marginBottom - height - 6)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .attr('font-weight', 'bold')
        .text(title));
    //const div = document.getElementById('polygonLegend');
    // eslint-disable-next-line no-underscore-dangle
    //div.style.width = `${Math.max(width, g._groups[0][0].getBoundingClientRect().width + 23)}px`;
    return svg.node().outerHTML;
  }

function getTickValues(type, width) {
    const tickValues = [0];
    let multiplier = 1;
    let counter = 1;
    let maxColor;
    if (type === 'country_fat') {
        maxColor = maxColorCountryFat;
    }
    if (type === 'country_eve') {
        maxColor = maxColorCountryEve;
    }
    if (type === 'region_fat') {
        maxColor = maxColorRegionFat;
    }
    if (type === 'region_eve') {
        maxColor = maxColorRegionEve;
    }
    if (type === 'country_act') {
        maxColor = maxColorCountryActors;
    }
    if (type === 'region_act') {
        maxColor = maxColorRegionActors;
    }
    if (type === 'country_eth') {
        maxColor = maxColorCountryEth;
    }
    if (type === 'country_reli') {
        maxColor = maxColorCountryReli;
    }
    if (type === 'countryMoniFat' || type === 'countryMoniEve' || type === 'regionMoniEve' || type === 'regionMoniFat') {
        tickValues.push(-colorMoni);
        tickValues.push(colorMoni);
        return tickValues;
    }
    //if (type === 'country_pre') {maxColor = maxColorCountryPre; geoJsonCountryPre.setStyle(styleCountryPre)}
    if (type === 'country_pre') { return [0, 1, 10, 50, 100]}
    if (type === 'grid_pred') { return [0, 0.1, 0.5, 1]}
    if (type === 'country_fop') {maxColor = maxColorCountryFop; geoJsonCountryFop.setStyle(styleCountryFop)}
    if (type === 'country_ref') {maxColor = 1;}
    if (type === 'country_density') { maxColor =  maxColorCountryDensity['YR2020']; geoJsonCountryDensity.setStyle(styleCountryDensity) }
    if (type === 'region_density') { maxColor = maxColorRegionDensity['YR2020']; geoJsonRegionDensity.setStyle(styleRegionDensity) }
    let lastTick = 0;
    while (maxColor / multiplier > 10) {
        // eslint-disable-next-line no-mixed-operators
        if (Math.log(multiplier) * width / Math.log(maxColor) - Math.log(lastTick) * width / Math.log(maxColor) > 5 * counter) {
        tickValues.push(multiplier);
        lastTick = multiplier;
        }
        multiplier *= 10;
        counter++;
    }
    tickValues.push(maxColor);
    return tickValues;
}
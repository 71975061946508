<template>
  <a-tree-select
      style="width: 100%"
      :tree-data="actorOptions"
      treeNodeFilterProp="title"
      tree-checkable
      v-model="actor"
      :show-checked-strategy="SHOW_PARENT"
      search-placeholder="Auswahl"/>
</template>
<script>
import axios from 'axios';
import { TreeSelect } from 'ant-design-vue';
import { url } from '../App.vue';

const { SHOW_PARENT } = TreeSelect;
export default {
  name: 'ActorFilter',
  data() {
    return {
      SHOW_PARENT,
      tabs: [],
      visible: true,
      actorPuffer: this.actorInput,
      actorOptions: [],
      size: 12,
      filterSize: 12,
      // filter: this.filterInput,
    };
  },
  async mounted() {
    const form = this.$store.state.filter;
    const db = this.$store.state.db;
    const accessToken = await this.$auth.getTokenSilently();
    await axios.post(url + 'interactions/', {form, db},
        {headers:{authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        this.actorOptions = response.data;
      }).catch();
  },
  props: {
    actorInput: {
      required: true,
      type: Array,
    },
  },
  computed: {
    actor: {
      get() {
        return this.actorPuffer;
      },
      set(value) {
        this.$emit('update:actorInput', value);
      },
    },
  },
  watch: {
    '$store.state.db': function () {
      const form = this.$store.state.filter;
      const db = this.$store.state.db;
      this.getSelectOptionActor(db, form);
    },
    actorInput: {
      deep: true,
      handler(value) {
        this.actorPuffer = value;
      },
    },
  },
  methods: {
    async getSelectOptionActor(db, form) {
      const accessToken = await this.$auth.getTokenSilently();
      await axios.post(url + 'interactions/',{form, db},
          {headers: {authorization: `Bearer ${accessToken}`}})
        .then((response) => {
          this.actorOptions = response.data;
          this.actorPuffer = ['Alle'];
          this.$emit('update:actorInput', this.actorPuffer);
        }).catch();
    },

  },
};
</script>

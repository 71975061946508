import { message } from "ant-design-vue";

const worker = new Worker('./worker.js', { type: 'module' });
// const gridWorker = new Worker('./grid.js', { type: 'module' });

const send = (message) => {
  worker.postMessage({
    message,
  });
};

// const sendGrid = (message) => {
//   gridWorker.postMessage({
//     message,
//   })
// }

export default {
  worker,
  // gridWorker,
  send,
  // sendGrid
};

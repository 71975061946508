import namingConventions from '../../../../NamingConventions';
const Swal = require('sweetalert2');

const title = namingConventions().german.messages.startPopups.title;
const subTitle = namingConventions().german.messages.startPopups.subTitle;

export default function getPopUpStart() {
  const text = `${"<div align='left'>"
  + "<span style='font-size:16px; font-family: Calibri, Helvetica, monospace, Arial, sans-serif'>"
  + '<div style="text-align: center;"><span style="font-size:18px;"> '
  + '<strong>' +  title + '</strong></span></div>'
  + '<p>' +  subTitle + '</p></span></div>'}`;
  Swal.fire({
    html: text,
    confirmButtonText: 'OK',
    confirmButtonColor: 'orange',
    background: 'white',
    position: 'inherit',
    opacity: '80%',
  });
}

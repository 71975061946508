/*eslint-disable*/
import {
    svgDropDown,
    deleteAndCreateDivElements,
    deleteDivElements,
    getInfoHover,
    getPopupLegend, deleteCheckboxes
} from '../Utils/Map.Layers.Popups.Utils.js';
import namingConventions from '../../../../NamingConventions';
const amountEve = namingConventions().german.technical.popups.axis.amountEve;

const d3 = require('d3');
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/networkgraph')(Highcharts);
require('highcharts/modules/sankey')(Highcharts);


const w = document.body.clientWidth - document.body.clientWidth*0.2; // width histogram
const h = document.body.clientHeight - document.body.clientHeight*0.7;

const marginNo = document.body.clientHeight*0.001;
const fontSize = document.body.clientHeight*0.02;
let buttonCounter = 0;

const colorFill = '#7777BB';
const hoverColor = '#0000ff';
const pressedColor = '#000077';

const yStart = document.body.clientHeight*0.02;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };

let sizeMatrix;
let dictInfo = {};
let div;
let members = [];
let config;

const actorColorMapping = {
  "Staatlicher Akteur": '#e3dfb8',
  "Rebellengruppen": '#912323',
  "politische Milizen": '#0b610c',
  "ethnische Milizen": '#9bcc9c',
  "gewalt. Demonstranten": '#37409e',
  "Demonstranten": '#a3a8cf',
  "Zivilisten": '#631596',
  "sonstige Kräfte": '#d8b6de'
}

const actorsMapping = {
  1: "Staatlicher Akteur",
  2: "Rebellengruppen",
  3: "politische Milizen",
  4: "ethnische Milizen",
  5: "gewalt. Demonstranten",
  6: "Demonstranten",
  7: "Zivilisten",
  8: "sonstige Kräfte"
}

let actorsXAxis = [];
let actorsYAxis = [];
let infoList = [];
let actors_data = [];
let actors_data_sankey = [];
let countryName = "";
let Colors1, Colors2, Size1, Size2, Actors1, Actors2 = 0;



function getChartChangeButton(svg) {
  const bWidth = document.body.clientWidth*0.11;
  const bHeight = document.body.clientHeight*0.04;

  const allButtons = svg.append('g').attr('id', 'allButtons');
  const buttonGroups = allButtons.selectAll('g.button')
    .data("test")
    .enter()
    .append('g')
    .attr('class', 'button')
    .style('cursor', 'pointer')
    .on('click', function (d, i) {
      updateButtonColors(d3.select(this), d3.select(this.parentNode));

      if(buttonCounter === 3){buttonCounter = 0;}
      if(buttonCounter == 0) {
          svg = getHighchartNetwork(svg);
      }
      else if(buttonCounter == 1) {
         svg = getHighchartSankey(svg);
      }
      if(buttonCounter == 2) {
         svg = getHighchartMatrix(svg);
      }
      buttonCounter += 1;
    })
    .on('mouseover', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', hoverColor);
      }
    })
    .on('mouseout', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', colorFill);
      }
    });

  buttonGroups.append('rect')
    .attr('class', 'buttonRect')
    .attr('width', bWidth)
    .attr('height', bHeight)
    .attr('x', w - w*0.2)
    .attr('y', yStart)
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('fill', colorFill);

  buttonGroups.append('text')
    .attr('class', 'buttonText')
    .attr('id', 'buttonText')
    //.attr('font-family', 'FontAwesome')
    .attr('x', w - w*0.2 + bWidth/2)
    .attr('y', yStart + bHeight/2)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('fill', 'white')
      .style('font-size', fontSize)
    .text("Darstellungswechsel");

  function updateButtonColors(button, parent) {
    parent.selectAll('rect')
      .attr('fill', colorFill);
  }

  return svg;
}

function getPointCategoryName(point, dimension) {
    var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
}

function getHighchartSankey(svg) {
  const fitNode = document.getElementById("fitFunction");
  while (fitNode.firstChild) {
    fitNode.removeChild(fitNode.lastChild);
  }

   svgDropDown(config);
   getChartChangeButton(svg);
   getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);

    Highcharts.chart('divID', {

    title: {
        text: 'Ereignisse für die aktivsten Akteure in ' + countryName
    },
    series: [{
        keys: ['from', 'to', 'weight'],
        data: actors_data_sankey,
        type: 'sankey',
    }]

});


}

function getHighchartNetwork(svg) {

    deleteAndCreateDivElements();

    svg = getPopupLegend(svg, document.body.clientWidth, document.body.clientHeight);

    let actorsDataDict = [];
    for (let item of actors_data)
    {
        let infoDict = {};
        infoDict['from'] = item[0];
        infoDict['to'] = item[1];
        infoDict['width'] = item[2];
        infoDict["label"] = item[3];
        actorsDataDict.push(infoDict)
    }

    let NodesList = [];

    let count = 0;
    for (let actor of Actors1){
        let nodeDict1 = {};
        let colorDict1 = {}
        let radiusDict1 = {};
        let nodeDict2 = {};
        let radiusDict2 = {};
        nodeDict1['id'] = actor;
        radiusDict1['radius'] = Size1[count]*8;
        let colorActor = actorColorMapping[actorsMapping[Colors1[count]]];
        colorDict1['color'] = colorActor;
        nodeDict1['marker'] = radiusDict1;
        nodeDict1['color'] = colorActor;
        NodesList.push(nodeDict1);
        nodeDict2['id'] = Actors2[count];
        radiusDict2['radius'] = Size2[count]*8;
        colorActor = actorColorMapping[actorsMapping[Colors2[count]]];
        nodeDict2['marker'] = radiusDict2;
        nodeDict2['color'] = colorActor;
        NodesList.push(nodeDict2);
        count++;
    }


    Highcharts.chart('divID', {
    chart: {
        type: 'networkgraph',
        plotBorderWidth: 1
    },
    title: {
        text: 'Ereignisse für die aktivsten Akteure in ' + countryName
    },
    plotOptions: {
        networkgraph: {
            layoutAlgorithm: {
                enableSimulation: false,
                linkLength: 150,
                integration: 'verlet',
                approximation: 'barnes-hut',
                gravitationalConstant: 0.8
            }
        }
    },
    series: [{
        marker: {
            radius: 3,
            lineWidth: 1
        },
        dataLabels: {
            enabled: true,
            allowOverlap: false,
            linkFormat: '{point.label}'
        },
       nodes: NodesList,
       data: actorsDataDict,
    }]
});

    return svg;
}

function getHighchartMatrix(svg)
{

  deleteAndCreateDivElements();
    Highcharts.chart('divID', {

    chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1
    },


    title: {
        text: 'Ereignisse für die ' + sizeMatrix + ' aktivsten Akteure in ' + countryName
    },

    xAxis: {
        categories: actorsXAxis,
        labels: {
        formatter: function() {
          var string = (this.value.length < 13) ? this.value : this.value.substring(0, 12) + '...'
          return string;
        }
      }
    },

    yAxis: {
        categories: actorsYAxis,
        title: amountEve,
        reversed: false,
        labels: {
        formatter: function() {
          var string = (this.value.length < 25) ? this.value : this.value.substring(0, 24) + '...'
          return string;
        }
      }
    },

    accessibility: {
        point: {
            descriptionFormatter: function (point) {
                var ix = point.index + 1,
                    xName = point.x,
                    yName = point.y,
                    val = point.value;
                return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
            }
        }
    },

    colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: colorFill
    },

    legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280
    },

    tooltip: {
        formatter: function () {
            return '<b>' +  getPointCategoryName(this.point, 'x') + '</b> hatte <br><b>' +
                this.point.value + '</b> Ereignisse mit <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
        }
    },

    series: [{
        name: 'Ereignisse für die ' + sizeMatrix + ' aktivsten Akteure',
        borderWidth: 1,
        data: infoList,
        dataLabels: {
            enabled: true,
            color: '#000000'
        }
    }],

});

    return svg
}


function getTimeLineActors(svg, actorsTimeline) {

    deleteAndCreateDivElements();
    document.getElementById("allButtons").remove();
    var actorNames = [];
    var dataActors = {};

    for (const [key, value] of Object.entries(actorsTimeline)) {
            actorNames.push(key);
            var allDateEvents = []
            for (const [keysub, valuesub] of Object.entries(value)){
                var dateEvent = [];
                const year = valuesub['EVENT_DATE'].split('-')[0];
                const month = valuesub['EVENT_DATE'].split('-')[1];
                const day = valuesub['EVENT_DATE'].split('-')[2];
                dateEvent.push(Date.UTC(year, month - 1, day));
                dateEvent.push(valuesub['EVENTS'])
                allDateEvents.push(dateEvent);
            }
            dataActors[key] = allDateEvents;
        }

    var seriesData = [];
    for (var n in actorNames){
        var seriesDict = {}
        seriesDict['name'] = actorNames[n];
        seriesDict['data'] = dataActors[actorNames[n]];
        seriesData.push(seriesDict)
    }


    Highcharts.addEvent(Highcharts.Point, 'click', function () {
    if (this.series.options.className.indexOf('popup-on-click') !== -1) {
        const chart = this.series.chart;
        const date = Highcharts.dateFormat('%A, %b %e, %Y', this.x);
        const text = `<b>${date}</b><br/>${this.y} Ereignisse für ${this.series.name}`;

        const anchorX = this.plotX + this.series.xAxis.pos;
        const anchorY = this.plotY + this.series.yAxis.pos;
        const align = anchorX < chart.chartWidth - 200 ? 'left' : 'right';
        const x = align === 'left' ? anchorX + 10 : anchorX - 10;
        const y = anchorY - 30;
        if (!chart.sticky) {
            chart.sticky = chart.renderer
                .label(text, x, y, 'callout',  anchorX, anchorY)
                .attr({
                    align,
                    fill: 'rgba(0, 0, 0, 0.75)',
                    padding: 10,
                    zIndex: 7 // Above series, below tooltip
                })
                .css({
                    color: 'white'
                })
                .on('click', function () {
                    chart.sticky = chart.sticky.destroy();
                })
                .add();
        } else {
            chart.sticky
                .attr({ align, text })
                .animate({ anchorX, anchorY, x, y }, { duration: 250 });
        }
    }
});


Highcharts.chart('divID', {

    chart: {
        type: 'spline',
        scrollablePlotArea: {
            minWidth: 700
        }
    },

    title: {
        text: 'Aktivitätszeitraum der ' + sizeMatrix + ' aktivsten Akteure in ' + countryName
    },

     xAxis: {
                type: 'datetime',
                crosshair: true,

            },

    yAxis: [{ // left y axis
        allowDecimals: false,
        title: {
            text: 'Ereignisse'
        },
        labels: {
            align: 'left',
            x: 3,
            y: 16,
            format: '{value:.,0f}'
        },
        showFirstLabel: false
    }, { // right y axis
        linkedTo: 0,
        gridLineWidth: 0,
        opposite: true,
        title: {
            text: null
        },
        labels: {
            align: 'right',
            x: -3,
            y: 16,
            format: '{value:.,0f}'
        },
        showFirstLabel: false
    }],

    legend: {
        align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },

    tooltip: {
        shared: true,
        crosshairs: true
    },

    plotOptions: {
        spline: {
            cursor: 'pointer',
            className: 'popup-on-click',
            lineWidth: 2,
            marker: {
                enabled: false,
                //radius: 2,
                //lineWidth: 1
            }
        }
    },

    series: seriesData

});

    return svg;

}


export default function  getActorsMatrix(dictInfo_, matrixActors, actorsList, actors1, actors2, weight, colors1, colors2, size1, size2, actorsTimeline, div_){
    if (matrixActors == 0){alert("Keine Interaktionen zwischen den Akteuren vorhanden!");};

    actorsXAxis = [];
  actorsYAxis = [];
  infoList = [];
  actors_data = [];
  actors_data_sankey = [];
  buttonCounter = 0;
  dictInfo = dictInfo_;
  div = div_;

  sizeMatrix = actorsList.length;
  countryName = dictInfo_.countryName;

  actorsXAxis = actorsList;
  actorsYAxis = actorsList;
  infoList = [];


  Colors1 = colors1;
  Colors2 = colors2;
  Size1 = size1;
  Size2 = size2;
  Actors1 = actors1;
  Actors2 = actors2;

  for (let i = 0; i < matrixActors.length; i++){
      let infoElement = [matrixActors[i]['matrixX'], matrixActors[i]['matrixY'], matrixActors[i]['events'], matrixActors[i]['fatalitites']]
      infoList.push(infoElement);
  }


  let i = 0;
  for (let actor of actors1) {
      let width = weight[i];
      if(width >= 3 && width < 13){width = 3}
      if(width >=13 && width < 23){width = 4}
      if(width >= 23 && width < 33){width = 5}
      if(width >= 33 && width < 43){width = 6}
      if(width >= 43 && width < 53){width = 7}
      if(width >= 53){width = 8}
      const actors = [actor, actors2[i], width, weight[i]]
      actors_data.push(actors)
      i++;
  }

  let j = 0;
  for (let actor of actors1) {
      const actors = [actor, actors2[j], weight[j]];
      actors_data_sankey.push(actors);
      j++;
  }

  deleteDivElements();
  deleteCheckboxes();

  let svg = div.append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .attr("id", 'fitFunction')


    members = [{
        label: "Aktivste Akteure",
        value: 1
    },
        {
            label: "Zeitraum Aktivste Akteure",
            value: 2
        }
    ];

    config = {
    width: document.body.clientWidth*0.18,
    container: svg,
    members,
    fontSize: fontSize,
    color: "#333",
    fontFamily: "calibri",
    x: document.body.clientWidth*0.035,
    y: yStart ,
    changeHandler: function(option) {
      svg.selectAll("*").remove();

      if (option.label == 'Aktivste Akteure'){
          svgDropDown(config);
          svg = getChartChangeButton(svg);
          svg = getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);
          svg = getHighchartMatrix(svg);
      }
      else if (option.label == 'Zeitraum Aktivste Akteure') {
          svgDropDown(config);
          svg = getChartChangeButton(svg);
          svg = getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);
          svg = getTimeLineActors(svg, actorsTimeline);
      }
    }
  };

    svgDropDown(config);
    getInfoHover(svg, div, dictInfo, document.body.clientHeight*1.15);
    svg = getChartChangeButton(svg);
    svg = getHighchartMatrix(svg);

  return div.node();
}

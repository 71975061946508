<template>
  <div>
      <b-progress :value="value" :max="max"  :animated="animated"></b-progress>

    <div id="settings" style="text-align: center">
      <p>Zeitraum: {{ this.$store.state.filter.startDate }} bis {{ this.$store.state.filter.endDate }}</p>
    </div>

    <div class="row">
          <b-button id="filter" class="btn-primary border-0 text-dark" v-b-toggle.sidebar-1>
           <b-icon icon="filter" aria-hidden="true" ></b-icon> Filter
          </b-button>
    </div>
    <div class="row">
          <b-button id="table" @click="startTable" class="btn-primary border-0 text-dark" v-b-toggle.sidebar-2>
           <b-icon icon="table" aria-hidden="true" ></b-icon> Tabelle
          </b-button>
     </div>
    <div class="row">
          <b-button id="chart" class="btn-primary border-0 text-dark" v-b-toggle.sidebar-4>
           <b-icon icon="bar-chart" aria-hidden="true" ></b-icon> Chart
          </b-button>
    </div>
      <div class="row" >
            <b-button id="network" @click="startNetwork" class="btn-primary border-0 text-dark" v-b-toggle.sidebar-3>
              <b-icon icon="diagram3-fill" aria-hidden="true"></b-icon> Netz
            </b-button>
      </div>
    <SideBar/>

    <Table :event="message"/>
    <Map :event="message"/>
    <Chart :event="message" />
    <Network/>
  </div>
</template>
<script>



import Map from './Map.vue';
import { viewBounds } from './Map';
import SideBar from './SideBar.vue';
import Table from './TableSideBar.vue';
import Chart from './ChartSideBar.vue';
import Network from './Network.vue';
import worker from '../assets/js/worker';
import axios from "axios";
import {url} from "../App";


const jsonMarker = [];
const message = { message: [] };
export default {
  name: '',
  data() {
    return {

      max: 100,
      message,
      value: 100,
      animated: false,

    };
  },
  components: {
    Map,
    SideBar,
    Table,
    Chart,
    Network,

  },
   created() {
    this.$root.$refs.Home = this;
  },
  computed: {

    e() {
      return jsonMarker.slice();
    },
  },
  watch: {


    geo_json_country() {
    },
    message: {
      deep: true,
      handler(workerMessage) {
        if (workerMessage.message.data.progress) {
          this.value = workerMessage.message.data.progress;
          if (this.value >= 100) {
            this.animated = false;
          } else {
            this.animated = true;
          }
        }
      },
    },
  },
  async mounted () {
    const { filter } = this.$store.state;
    const accessToken = await this.$auth.getTokenSilently();
    await axios.post(url + 'layerInfo/',{},{headers: {
        authorization: `Bearer ${accessToken}`}})
        .then((response) => {
        const EndDate = response.data['newest_dates'][0];
        this.$store.commit('setEndDate', EndDate);
        const StartDate = new Date(EndDate)//.toISOString().slice(0, 10)
        StartDate.setMonth(StartDate.getMonth() - 1)
        const newStartDate = StartDate.toISOString().slice(0, 10)
        this.$store.commit('setStartDate', newStartDate);
    });
    worker.send({
      form: this.$store.state.filter,
      bbox: filter.bounds,
      token: accessToken,
    });
    worker.worker.onmessage = function (e) {
      message.message = e;
    };
  },
  methods: {
    startNetwork() {
      this.$root.$refs.Network.getJSON();
    },
    async startTable(){
      const accessToken = await this.$auth.getTokenSilently();
      worker.send({
        selectedLayer: 'tableconflicts/',
        bounds: viewBounds,
        token: accessToken
      });
    }
  },
};
</script>

<style scoped>
@import './../assets/css/colors.css';

#filter{
  top: 6em;
  position: relative;
  left: 1em;
  width: 7em;
  z-index: 5;
}
#table{
  top: 7em;
  position: relative;
  left: 1em;
  width: 7em;
  z-index: 5;
}
#chart{
  top: 8em;
  position: relative;
  left: 1em;
  width: 7em;
  z-index: 5;
}
#network{
  top:9em;
  position: relative;
  left: 1em;
  width: 7em;
  z-index: 5;
}
.btn.btn-primary {
    border:0.1em solid black !important;
    margin:0 0.3em 0.3em 0 !important;
    background-color: var(--primary);
    background: rgba(0,0,0,0.05);
    color: var(--text);
    border-radius: 5px;
    width: 5rem;
}
.btn.btn-primary:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8) !important;
}
.btn.btn-primary:focus {
    box-shadow: none;
}
.progress{
top: 0em;
  right: 0.05%;
  height: 4px;
  width: 100%;
  position: fixed;
  z-index: 5;
  background: transparent;
  background-color:#DCE8FC;
}
#settings{
  top: 2px;
  right: 0.05%;
  height: 20px;
  width: 100%;
  position: fixed;
  z-index: 6;
  color:black;
  font-weight: bold;
}






</style>

<template >
  <b-sidebar  id="sidebar-1" title="Filter" #default="{ hide }" no-header shadow >
    <div class="px-3 py-2">

          <b-button @click="hide" style="position: absolute; right: 5px;">
            <b-icon icon="x"></b-icon>
          </b-button>
          <br><br>

            <div class="control_form">
              <p><label>Zeitraum: </label>
              <a-range-picker v-model="date" style="width: 100%" /> </p>

              <p><label>Ereignistypen: </label>
              <EventFilter :eventInput.sync="event"/></p>

             <!-- <p><label>Akteurstypen: </label>
              <ActorFilter :actorInput.sync="actor"/></p>-->

              <p><label>Länder: </label>
              <CountryFilter :countryInput.sync="country"/></p>

             <p><label>Akteur 1: </label>
              <ActorFilter1 :actorInput1.sync="actor1"/></p>

              <p><label>Akteur 2: </label>
              <ActorFilter2 :actorInput2.sync="actor2"/></p>

            </div>
            <p><b-button id="submit" v-on:click="filter">Filter</b-button></p>
    </div>

  </b-sidebar>

</template>

<script>

import $ from 'jquery';
import worker from '../assets/js/worker';
import ActorFilter from './ActorFilter.vue';
import EventFilter from './EventFilter.vue';
import CountryFilter from './CountryFilter.vue';
import ActorFilter1 from './ActorFilter1.vue';
import ActorFilter2 from './ActorFilter2.vue';
import axios from "axios";
import {url} from "../App";
import moment from "moment";

let newestDates;
let newestStartDate;
let newestDateDB;
let startDate;
let endDate;


export default {
  name: 'SideBar',
  components: { ActorFilter, EventFilter, CountryFilter, ActorFilter1, ActorFilter2},
  data() {
    return {
      priority: 'LEAF_PRIORITY',
      format: 'YYYY-MM-DD',
      renderComponent: true,
    };
  },
  computed: {
    date: {
      get() {
        return [this.$store.state.filter.startDate, this.$store.state.filter.endDate];
      },
      set(value) {
        endDate = value[1].format('YYYY-MM-DD');
        startDate = value[0].format('YYYY-MM-DD');
        if(this.$store.state.db == 0){
          newestDateDB = newestDates[0];
          if (new Date(endDate) > new Date(newestDateDB)) {
              alert("Datumsauswahl für ACLED Daten nur bis zum " + newestDateDB + " möglich.");
              value[1] = moment(newestDateDB);
            }
          if (new Date(startDate) > new Date(newestDateDB)) {
             newestStartDate = moment(newestDateDB).subtract(1, 'months').format("YYYY-MM-DD");
             value[0] = moment(newestStartDate);
          }
        }
        else if(this.$store.state.db == 1){
          newestDateDB = newestDates[1];
          if (new Date(endDate) > new Date(newestDateDB)) {
              alert("Datumsauswahl für GTD Daten nur bis zum " + newestDateDB + " möglich.");
              value[1] = moment(newestDateDB);
            }
          if (new Date(startDate) > new Date(newestDateDB)) {
             newestStartDate = moment(newestDateDB).subtract(1, 'months').format("YYYY-MM-DD");
             value[0] = moment(newestStartDate);
          }
        }
        else if(this.$store.state.db == 2){
          newestDateDB = newestDates[3];
          if (new Date(endDate) > new Date(newestDateDB)) {
              alert("Datumsauswahl für UCDP Daten nur bis zum " + newestDateDB + " möglich.");
              value[1] = moment(newestDateDB);
            }
          if (new Date(startDate) > new Date(newestDateDB)) {
             newestStartDate = moment(newestDateDB).subtract(1, 'months').format("YYYY-MM-DD");
             value[0] = moment(newestStartDate);
          }
        }
        this.$store.commit('setStartDate', value[0].format('YYYY-MM-DD'));
        this.$store.commit('setEndDate', value[1].format('YYYY-MM-DD'));
      },
    },
    event: {
      get() {
        return this.$store.state.filter.event;
      },
      set(value) {
        this.$store.commit('setEvent', value);
      },
    },
    actor: {
      get() {
        return this.$store.state.filter.interaction;
      },
      set(value) {
        this.$store.commit('setInteraction', value);
      },
    },
    country: {
      get() {
        return this.$store.state.filter.country;
      },
      set(value) {
        this.$store.commit('setCountry', value);
      },
    },
    actor1: {
      get() {
        return this.$store.state.filter.actor1;
      },
      set(value) {
        this.$store.commit('setActor1', value);
      },
    },
    actor2: {
      get() {
        return this.$store.state.filter.actor2;
      },
      set(value) {
        this.$store.commit('setActor2', value);
      },
    },
  },
  async mounted() {
    const accessToken = await this.$auth.getTokenSilently();
    axios.post(url + 'layerInfo/',{},{headers: {
        authorization: `Bearer ${accessToken}`}})
      .then((response) => {
        newestDates = response.data['newest_dates'];
      })
    $('#sidebar-1').css(
      {
        top: '4em',
        left: '1em',
        opacity: '100%',
        height: '80%',
        'border-radius': '5px',
        'font-family': 'Calibri, Helvetica, monospace, Arial, sans-serif',
        'font-size': '18px',
        border: '0.1em solid black',
        'close-button': true,

      },
    );
  },
  methods: {
    async filter() {
      const { filter } = this.$store.state;
      const accessToken = await this.$auth.getTokenSilently();
      worker.send({
        form: filter,
        bbox: filter.bounds,
        token: accessToken,
      });
    },
  },
};
</script>

<style scoped>
@import './../assets/css/colors.css';

.btn {
     border:0.1em solid black !important;
    margin:0 1em 1em 0 !important;
    background-color: var(--primary) !important;
    color: var(--text) !important;
    border-radius: 5px;
}

.btn:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8) !important;
}
.btn:focus {
    box-shadow: none;
}

</style>


import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
const d3 = require('d3')
import {getContextDict} from './Utils/Map.Layers.Properties.Utils';
import {getLayerPopup} from '../../components/Map';
import getResourcesChart from "../js/Popups/Map.Layers.Resources.Popup";
import axios from 'axios';
import { getTokenSilently } from "../../auth/getToken";
var capital = false;

function controlIcon(path) {
  const controlIcon = L.icon({
    iconUrl: path,
    iconSize: [18, 18],
    popupAnchor: [-1, -37]
  });
  return controlIcon
}

export function initMarkerCluster(markerClusterGroup, iconPath){
  markerClusterGroup = new window.L.MarkerClusterGroup({
      polygonOptions: {
        opacity: 0,
        fillOpacity: 0
        },
      iconCreateFunction : function (cluster) {
        const icon = L.divIcon({
          className: 'marker--state--active',
          html: '<img src=' + iconPath + '>',
        });
        return icon;
      }
    });
  return markerClusterGroup;
}

export async function markerCluster(markerClusterGroup, iconPath, features, type, endpointUrl){
  // const start = window.performance.now();
   const accessToken = await getTokenSilently();
   markerClusterGroup.clearLayers();
   let popupUrl = endpointUrl.slice(0, -1) + '2/';
   Object.keys(features).forEach( function (key) {
     if (features[key].properties.resource == 'capital')
        {
            var marker = L.marker([features[key].geometry.coordinates[1], features[key].geometry.coordinates[0]], {icon: controlIcon('icons/capital.svg')});
        }
     else {
         var marker = L.marker([features[key].geometry.coordinates[1], features[key].geometry.coordinates[0]], {icon: controlIcon(iconPath)});
     }
     marker.on('click', function (e) {
         var data_dict = {};
         data_dict.type = type;
         data_dict.key = features[key].id;
         data_dict.capital = "";
         if(features[key].properties.oldIndex){
               data_dict.oldIndex = features[key].properties.oldIndex;
           }
         if(features[key].properties.resource == 'capital'){
             data_dict.capital = 'capital';
         }
        axios.post(popupUrl, {'data_dict': data_dict},
         {headers: {authorization: `Bearer ${accessToken}`}})
           .then((response) => {
             let div = d3.select("#modalContainer");
             getResourcesChart(response.data.meta_info, response.data.dict_iea_price_2022, response.data.dict_comtrade, response.data.dict_comtrade_import, response.data.wb_price_nominal, response.data.dict_iea_price, response.data.resources, response.data.eventsACLED, response.data.eventsGTD, response.data.eventsUSDP, response.data.GID, div);
           getLayerPopup();
           })
         })

     marker.on('mouseover', function (e) {
       var data_dict = {};
       data_dict.type = type;
       if(features[key].properties.resource == 'capital'){data_dict.type = 'capital' }
       data_dict.key = features[key].id;
       if(features[key].properties.oldIndex){
               data_dict.oldIndex = features[key].properties.oldIndex;
       }
       axios.post(endpointUrl, {'data_dict': data_dict},
         {headers: {authorization: `Bearer ${accessToken}`}})
           .then((response) => {
             var text = getContextDict(response.data);
             if (text == "") {
               text = 'keine Daten'
             }
             marker.bindPopup(text).openPopup();
           })
     });
     markerClusterGroup.addLayer(marker);
   })
  // const end = window.performance.now();
  // console.log(`Time of adding markers and clusters: ${end - start}ms`);
  return markerClusterGroup;
}

/* eslint no-undef: 0 */

import {
  maxColorCountryPre,
  maxColorCountryFop,
  maxColorCountryEve,
  maxColorRegionEve,
  maxColorCountryActors,
  maxColorRegionActors,
  maxColorCountryEth,
  maxColorCountryReli,
  minColorCountryReli,
  maxColorCountryFat,
  maxColorRegionFat,
  maxColorCountryDensity,
  maxColorRegionDensity,
  geoJsonCountryPre,
  geoJsonCountryFop,
  geoJsonCountryDensity,
  geoJsonRegionDensity,
  maxColorMoni,
  minColorMoni
} from '../../components/Map.vue';
import { updateData } from './Map.Layers.Refugee.Movements';
import {
  styleCountryDensity,
  styleRegionDensity,
  styleCountryPre,
  styleCountryFop,
} from "./Map.Layers.Customized";
import namingConventions from '../../../NamingConventions';

const events = namingConventions().german.technical.legend.events;
const hotspotmapFatalities = namingConventions().german.technical.legend.hotspotFat;
const hotspotmapEvents = namingConventions().german.technical.legend.hotspotEve;
const prediction = namingConventions().german.technical.legend.prediction;
const pressfreedom = namingConventions().german.technical.legend.pressfreedom;
const countryFat = namingConventions().german.technical.legend.countryFat;
const countryEve = namingConventions().german.technical.legend.countryEve;
const regionFat = namingConventions().german.technical.legend.regionFat;
const regionEve = namingConventions().german.technical.legend.regionEve;
const countryAct = namingConventions().german.technical.legend.countryAct;
const regionAct = namingConventions().german.technical.legend.regionAct;
const countryEth = namingConventions().german.technical.legend.ethnien;
const countryReli = namingConventions().german.technical.legend.religions;
const countryRef = namingConventions().german.technical.legend.refugees;
const countryPop = namingConventions().german.technical.legend.countryPop;
const regionPop = namingConventions().german.technical.legend.regionPop;
const minerals = namingConventions().german.technical.legend.minerals;
const criticalMinerals = namingConventions().german.technical.legend.criticalMinerals;

const d3 = require('d3');

const colors = ['#FFB300', '#803E75', '#FF6800', '#A6BDD7', '#C10020', '#CEA262', '#817066', '#007D34', '#F6768E', '#00538A', '#FF7A5C', '#53377A', '#FF8E00', '#B32851', '#F4C800', '#7F180D', '#93AA00', '#593315', '#F13A13', '#232C16'];
/* eslint-disable import/no-mutable-exports */
//population layer paramters
export let timeIndex = 4;
export let time = 'YR2020';
export let times = [];
const timeMax = 'YR2020';
const timeIndexMax = 4;

export const timesMigration = [1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013];

export let timeMigration = '2013';
export let timeIndexMigration = 24;
/* eslint-enable import/no-mutable-exports */
const timeIndexMigrationMax = 24;
const timeMigrationMax = '2013';

let colorMoni;

export let clusterLayer = '', wmsLayer = [], polyLayer = '';

function ramp(color, n = 256) {
  const canvas = document.createElement('canvas');
  canvas.width = n;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  for (let i = 0; i < n; ++i) {
    context.fillStyle = color(i / (n - 1));
    context.fillRect(i, 0, 1, 1);
  }
  return canvas;
}

function getTickValues(type, width) {
  const tickValues = [0];
  let multiplier = 1;
  let counter = 1;
  let maxColor;
  if (type === 'country_fat') {
    maxColor = maxColorCountryFat;
  }
  if (type === 'country_eve') {
    maxColor = maxColorCountryEve;
  }
  if (type === 'region_fat') {
    maxColor = maxColorRegionFat;
  }
  if (type === 'region_eve') {
    maxColor = maxColorRegionEve;
  }
  if (type === 'country_act') {
    maxColor = maxColorCountryActors;
  }
  if (type === 'region_act') {
    maxColor = maxColorRegionActors;
  }
  if (type === 'country_eth') {
    maxColor = maxColorCountryEth;
  }
  if (type === 'country_reli') {
    maxColor = maxColorCountryReli;
  }
  if (type === 'countryMoniFat' || type === 'countryMoniEve' || type === 'regionMoniEve' || type === 'regionMoniFat') {
    tickValues.push(-colorMoni);
    tickValues.push(colorMoni);
    return tickValues;
  }
  if (type === 'country_pre') {maxColor = maxColorCountryPre; geoJsonCountryPre.setStyle(styleCountryPre)}
  if (type === 'country_fop') {maxColor = maxColorCountryFop; geoJsonCountryFop.setStyle(styleCountryFop)}
  if (type === 'country_ref') {maxColor = 1;}
  if (type === 'country_density') { maxColor =  maxColorCountryDensity['YR2020']; geoJsonCountryDensity.setStyle(styleCountryDensity) }
  if (type === 'region_density') { maxColor = maxColorRegionDensity['YR2020']; geoJsonRegionDensity.setStyle(styleRegionDensity) }
  let lastTick = 0;
  while (maxColor / multiplier > 10) {
    // eslint-disable-next-line no-mixed-operators
    if (Math.log(multiplier) * width / Math.log(maxColor) - Math.log(lastTick) * width / Math.log(maxColor) > 5 * counter) {
      tickValues.push(multiplier);
      lastTick = multiplier;
    }
    multiplier *= 10;
    counter++;
  }

  tickValues.push(maxColor);
  return tickValues;
}

export function updateTime(timeIndexNew, type) {
  if (type === 'countryRef') {
    timeIndexMigration = timeIndexNew;
    timeMigration = timesMigration[timeIndexMigration];
    updateData(timeMigration);
  }
  else if (type === 'countryPop' || type === 'regionPop' ){
    times = ['YR2000', 'YR2005', 'YR2010', 'YR2015', 'YR2020'];
    time = times[timeIndexNew];
  }
  else {
    time = times[timeIndex];
  }
}

function getTitleWithoutLegend({
  title,
  width = 220,
  height = 30,
  marginTop = 18,
  marginBottom = 0,
  marginLeft = 30,
} = {}) {
  const svg = d3.select('#polygonLegend').append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .style('overflow', 'visible')
    .style('display', 'block');

  // eslint-disable-next-line no-shadow
  svg.append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
  // eslint-disable-next-line no-shadow
    .call((g) => g.append('text')
      .attr('x', marginLeft)
      .attr('y', marginTop + marginBottom - height - 6)
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', 'bold')
      .text(title));
  return svg.node();
}

function getLegendAggregation({
  color,
  title,
  tickSize = 6,
  width = 220,
  height = 44 + tickSize,
  marginTop = 18,
  marginRight = 0,
  marginBottom = 16 + tickSize,
  marginLeft = 0,
  ticks = width / 64,
  tickFormat,
  tickValues,
} = {}) {
  const svg = d3.select('#polygonLegend').append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .style('overflow', 'visible')
    .style('display', 'block');

  let tickAdjust = (g) => g.selectAll('.tick line').attr('y1', marginTop + marginBottom - height);
  let x;

  // Continuous
  if (color.interpolate) {
    const n = Math.min(color.domain().length, color.range().length);

    x = color.copy().rangeRound(d3.quantize(d3.interpolate(marginLeft, width - marginRight), n));

    svg.append('image')
      .attr('x', marginLeft)
      .attr('y', marginTop)
      .attr('width', width - marginLeft - marginRight)
      .attr('height', height - marginTop - marginBottom)
      .attr('preserveAspectRatio', 'none')
      .attr('xlink:href', ramp(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))).toDataURL());
  } else if (color.interpolator) {
    x = Object.assign(color.copy()
      .interpolator(d3.interpolateRound(marginLeft, width - marginRight)), {
      range() {
        return [marginLeft, width - marginRight];
      },
    });

    svg.append('image')
      .attr('x', marginLeft)
      .attr('y', marginTop)
      .attr('width', width - marginLeft - marginRight)
      .attr('height', height - marginTop - marginBottom)
      .attr('preserveAspectRatio', 'none')
      .attr('xlink:href', ramp(color.interpolator()).toDataURL());

    if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        // eslint-disable-next-line no-param-reassign
        tickValues = d3.range(n).map((i) => d3.quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== 'function') {
      // eslint-disable-next-line no-param-reassign
        tickFormat = d3.format(tickFormat === undefined ? ',f' : tickFormat);
      }
    }
  } else if (color.invertExtent) {
    const thresholds = color.thresholds ? color.thresholds() // scaleQuantize
      : color.quantiles ? color.quantiles() // scaleQuantile
        : color.domain(); // scaleThreshold

    const thresholdFormat = tickFormat === undefined ? (d) => d
      : typeof tickFormat === 'string' ? d3.format(tickFormat)
        : tickFormat;

    x = d3.scaleLinear()
      .domain([-1, color.range().length - 1])
      .rangeRound([marginLeft, width - marginRight]);

    svg.append('g')
      .selectAll('rect')
      .data(color.range())
      .join('rect')
      .attr('x', (d, i) => x(i - 1))
      .attr('y', marginTop)
      .attr('width', (d, i) => x(i) - x(i - 1))
      .attr('height', height - marginTop - marginBottom)
      .attr('fill', (d) => d);
    // eslint-disable-next-line no-param-reassign
    tickValues = d3.range(thresholds.length);
    // eslint-disable-next-line no-param-reassign
    tickFormat = (i) => thresholdFormat(thresholds[i], i);
  } else {
    x = d3.scaleBand()
      .domain(color.domain())
      .rangeRound([marginLeft, width - marginRight]);

    svg.append('g')
      .selectAll('rect')
      .data(color.domain())
      .join('rect')
      .attr('x', x)
      .attr('y', marginTop)
      .attr('width', Math.max(0, x.bandwidth() - 1))
      .attr('height', height - marginTop - marginBottom)
      .attr('fill', color);

    tickAdjust = () => {
    };
  }

  // eslint-disable-next-line no-shadow
  const g = svg.append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x)
      .ticks(ticks, typeof tickFormat === 'string' ? tickFormat : undefined)
      .tickFormat(typeof tickFormat === 'function' ? tickFormat : undefined)
      .tickSize(tickSize)
      .tickValues(tickValues))
    .call(tickAdjust)
  // eslint-disable-next-line no-shadow
    .call((g) => g.select('.domain').remove())
  // eslint-disable-next-line no-shadow
    .call((g) => g.append('text')
      .attr('x', marginLeft)
      .attr('y', marginTop + marginBottom - height - 6)
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', 'bold')
      .text(title));
  const div = document.getElementById('polygonLegend');
  // eslint-disable-next-line no-underscore-dangle
  div.style.width = `${Math.max(width, g._groups[0][0].getBoundingClientRect().width + 23)}px`;
  return svg.node();
}

export function addLegendCluster(type) {
  const legendClosed = false;
  if (type == 'airportCluster') {
    let div = document.getElementById('airportLegend');
    div.innerHTML = '';
    div = L.DomUtil.create('div', 'airportLegendDiv', div);
    const labels = ['<strong> Flughafen </strong>'];
      div.innerHTML
          += labels.push(
          `<label> <img src="icons/airport_big_black.svg" width="20";><a href="https://ourairports.com/help/#legend" target="_blank">Großer Landflughafen</a></label>`,
           `<label> <img src="icons/airport_medium_black.svg" width="20";><a href="https://ourairports.com/help/#legend" target="_blank">Mittelgroßer Landflughafen</a></label>`,
                `<label> <img src="icons/airport_small_black.svg" width="20";><a href="https://ourairports.com/help/#legend" target="_blank">Kleiner Landflughafen </a></label>`,
           `<label> <img src="icons/heli_airport_black.svg" width="20";><a href="https://ourairports.com/help/#legend" target="_blank">Hubschrauberlandeplatz</a></label>`,
          `<label> <img src="icons/waterplane_black.svg" width="20";><a href="https://ourairports.com/help/#legend" target="_blank">Wasserflugzeuge</a></label>`,
          `<label> last update: 2022-08-02 </label>`
          );
    div.innerHTML = labels.join('<br>');
    document.getElementById('minusAirport').style.display = '';
    }
  else if (type == 'militaryCluster') {
    let div = document.getElementById('militaryLegend');
    div.innerHTML = '';
    div = L.DomUtil.create('div', 'militaryLegendDiv', div);
    const labels = ['<strong> Militär </strong>'];
      div.innerHTML
          += labels.push(
          `<label> <img src="icons/airport_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:military=airfield" target="_blank">Militärflughafen</a></label>`,
          `<label> <img src="icons/bunker_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:military=bunker" target="_blank">Bunker</a></label>`,
          `<label> <img src="icons/checkpoint_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:military=checkpoint" target="_blank">Checkpoint</a></label>`,
          `<label> <img src="icons/training_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:military=training_area" target="_blank">Training</a></label>`,
          `<label> <img src="icons/barracks_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:military=barracks" target="_blank">Kasernen</a></label>`,
          `<label>OSM last update: 2022-08-11</label>`
          );
    div.innerHTML = labels.join('<br>');
    document.getElementById('minusMilitary').style.display = '';
    }
  else if (type == 'institutionCluster') {
    let div = document.getElementById('institutionLegend');
    div.innerHTML = '';
    div = L.DomUtil.create('div', 'institutionLegendDiv', div);
    const labels = ['<strong> Institutionen </strong>'];
      div.innerHTML
          += labels.push(
          `<label> <img src="icons/hospital_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:amenity=hospital" target="_blank">Krankenhaus</a></label>`,
          `<label> <img src="icons/embassy_black.svg" width="20";><a href="https://wiki.openstreetmap.org/wiki/Tag:office%3Ddiplomatic" target="_blank">Botschaft</a></label>`,
          `<label> <img src="icons/border_control_black.svg" width="20";><a href="https://geonode.wfp.org/layers/geonode:wld_poi_bcp_wfp" target="_blank">Grenzkontrolle</a></label>`,
          `<label>OSM last update: 2022-08-16</label>`
          );
    div.innerHTML = labels.join('<br>');
    document.getElementById('minusInstitution').style.display = '';
    }
  else if (type == 'markerCluster') {
    const legendClosed = false;
    let div = document.getElementById('markerLegend');
    div.innerHTML = '';
    div = L.DomUtil.create('div', 'markerLegendDiv', div);
    const labels = ['<strong>' + events + '</strong>'];
    for (let i = 0; i < this.events.length; i++) {
      div.innerHTML
          += labels.push(
          `<span class="dot" style="height: 12px;width: 12px;background-color: ${colors[i]};border-radius: 50%;display: inline-block;"></span> ${
              this.events[i] ? this.events[i] : '+'}`,
      );
    }
    div.innerHTML = labels.join('<br>');
    document.getElementById('minus').style.display = '';
  }
  return legendClosed;
}

export function addLegendPolygon(type) {
  polyLayer = type;
  const legendPolyClosed = false;
  const div = document.getElementById('polygonLegend');
  div.innerHTML = '';
  document.getElementById('minusPoly').style.display = '';

  let colorType;
  let titleType;
  div.name = type;

  if (type === 'country_fat') {
    colorType = d3.scaleSequentialSymlog([0, maxColorCountryFat], d3.interpolateReds);
    titleType = countryFat;
  }
  if (type === 'country_eve') {
    colorType = d3.scaleSequentialSymlog([0, maxColorCountryEve], d3.interpolateReds);
    titleType = countryEve;
  }
  if (type === 'region_fat') {
    colorType = d3.scaleSequentialSymlog([0, maxColorRegionFat], d3.interpolateReds);
    titleType = regionFat;
  }
  if (type === 'region_eve') {
    colorType = d3.scaleSequentialSymlog([0, maxColorRegionEve], d3.interpolateReds);
    titleType = regionEve;
  }
  if (type === 'country_act') {
    colorType = d3.scaleSequentialSymlog([0, maxColorCountryActors], d3.interpolateReds);
    titleType = countryAct;
  }
  if (type === 'region_act') {
    colorType = d3.scaleSequentialSymlog([0, maxColorRegionActors], d3.interpolateReds);
    titleType = regionAct;
  }
  if (type === 'country_eth') {
    colorType = d3.scaleSequentialSqrt([0, maxColorCountryEth], d3.interpolateReds);
    titleType = countryEth;
  }
  if (type === 'country_reli') {
    colorType = d3.scaleSequential([minColorCountryReli, maxColorCountryReli], d3.interpolateReds);
    titleType = countryReli;
  }
  if (type === 'country_ref') {
    titleType = countryRef + timeMigrationMax;
    getTitleWithoutLegend({
      title: titleType,
    });
    const sliderContainerDiv = document.getElementById('sliderContainer');
    sliderContainerDiv.id = 'sliderContainer';
    const element = document.getElementById('timeSlider');
    if (element === null || element.value === '') {
      const slider = document.createElement('input');
      slider.type = 'range';
      slider.id = 'timeSlider';
      slider.setAttribute('value', timeMigrationMax);
      slider.setAttribute('min', '0');
      slider.setAttribute('max', (timeIndexMigrationMax));
      slider.addEventListener('mouseup', function () {
        timeIndexMigration = this.value;
        timeMigration = timesMigration[timeIndexMigration];
        while (div.firstChild) { div.removeChild(div.lastChild); }
        titleType = 'Geflüchtete im Jahr ' + timeMigration;
        getTitleWithoutLegend({
          title: titleType,
        });
        updateTime(timeIndexMigration, 'countryRef');
      });
      sliderContainerDiv.prepend(slider);
    }
  }

  if (type === 'country_density') {
    time = times[timeIndexMax];
    colorType = d3.scaleSequentialSymlog([0, maxColorCountryDensity['YR2020']], d3.interpolateReds);
    titleType = countryPop + timeMax;
    const sliderContainerDiv = document.getElementById('sliderContainer');
    sliderContainerDiv.id = 'sliderContainer';
    const element = document.getElementById('timeSlider');
    if (element === null || element.value === '') {
      const slider = document.createElement('input');
      slider.type = 'range';
      slider.id = 'timeSlider';
      slider.setAttribute('value', timeMax);
      slider.setAttribute('min', '0');
      slider.setAttribute('max', (timeIndexMax));
      slider.addEventListener('mouseup', function () {
        timeIndex = this.value;
        time = times[timeIndex];
        while (div.firstChild) { div.removeChild(div.lastChild); }
        titleType = countryPop + time;
        getLegendAggregation({
          color: colorType,
          title: titleType,
          tickValues: getTickValues(type, 220),
          width: 220,
          tickFormat: 'f',
        });
        updateTime(timeIndex, 'countryPop');
      });
      sliderContainerDiv.prepend(slider);
    }
  }

  if (type === 'region_density') {
    time = times[timeIndexMax];
    colorType = d3.scaleSequentialSymlog([0, maxColorRegionDensity['YR2020']], d3.interpolateReds);
    titleType = regionPop + timeMax;
    const sliderContainerDiv = document.getElementById('sliderContainer');
    sliderContainerDiv.id = 'sliderContainer';
    const element = document.getElementById('timeSlider');
    if (element === null || element.value === '') {
      const slider = document.createElement('input');
      slider.type = 'range';
      slider.id = 'timeSlider';
      slider.setAttribute('value', timeMax);
      slider.setAttribute('min', '0');
      slider.setAttribute('max', (timeIndexMax));
      slider.addEventListener('mouseup', function () {
        timeIndex = this.value;
        time = times[timeIndex];
        while (div.firstChild) { div.removeChild(div.lastChild); }
        titleType = regionPop + time;
        getLegendAggregation({
          color: colorType,
          title: titleType,
          tickValues: getTickValues(type, 220),
          width: 220,
          tickFormat: 'f',
        });
        updateTime(timeIndex, 'countryPop');
      });
      sliderContainerDiv.prepend(slider);
    }
  }
  if (type === 'countryMoniFat') {
    if(Math.abs(minColorMoni['country_fat']) > Math.abs(maxColorMoni['country_fat'])){
      colorMoni = Math.abs(minColorMoni['country_fat']);
    }
    else{colorMoni = Math.abs(maxColorMoni['country_fat']);}
    colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
    titleType = hotspotmapFatalities;
  }
  if (type === 'countryMoniEve') {
    if(Math.abs(minColorMoni['country_eve']) > Math.abs(maxColorMoni['country_eve'])){
      colorMoni = Math.abs(minColorMoni['country_eve']);
    }
    else{colorMoni = Math.abs(maxColorMoni['country_eve']);}
    colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
    titleType = hotspotmapEvents;
  }
  if (type === 'regionMoniFat') {
    if(Math.abs(minColorMoni['region_fat']) > Math.abs(maxColorMoni['region_fat'])){
      colorMoni = Math.abs(minColorMoni['region_fat']);
    }
    else{colorMoni = Math.abs(maxColorMoni['region_fat']);}
    colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
    titleType = hotspotmapFatalities;
  }
  if (type === 'regionMoniEve') {
    if(Math.abs(minColorMoni['region_eve']) > Math.abs(maxColorMoni['region_eve'])){
      colorMoni = Math.abs(minColorMoni['region_eve']);
    }
    else{colorMoni = Math.abs(maxColorMoni['region_eve']);}
    colorType = d3.scaleSequentialSqrt([-colorMoni, colorMoni], d3.interpolateHslLong("green", "red"));
    titleType = hotspotmapEvents;
  }

  if (type === 'country_pre') {
    colorType = d3.scaleSequentialSqrt([0, maxColorCountryPre], d3.interpolateHslLong("green", "red"));
    titleType = prediction;
  }
  if (type === 'country_fop') {
    colorType = d3.scaleSequential([0, maxColorCountryFop], d3.interpolateHslLong("red", "green"));
    titleType = pressfreedom;
  }
  if (type !== 'country_ref') {
    getLegendAggregation({
      color: colorType,
      title: titleType,
      tickValues: getTickValues(type, 220),
      width: 220,
      tickFormat: 'f',
    });
  }

  return legendPolyClosed;
}


export function removeLegendPolygon(minus) {
  polyLayer = '';
  const legendPolyClosed = true;
  const div = document.getElementById('polygonLegend');
  div.innerHTML = '';
  div.style.width = '';

  while (div.firstChild) {
    div.removeChild(div.lastChild);
  }
  const div2 = document.getElementById('sliderContainer');
  div2.innerHTML = '';
  div2.style.width = '';
  while (div2.firstChild) {
    div2.removeChild(div2.lastChild);
  }
  if (minus === 'visible') {
    document.getElementById('minusPoly').style.float = 'left';
  }
  if (minus === 'unvisible') {
    document.getElementById('minusPoly').style.display = 'none';
  }
  return legendPolyClosed;
}

export function removeLegendCluster(type, minus) {
  const legendClosed = true;
  if(type == 'airportCluster')
  {
       const div = document.getElementById('airportLegend');
        div.innerHTML = '';
        if (minus === 'visible') {
          document.getElementById('minusAirport').style.float = 'left';
        }
        if (minus === 'unvisible') {
          document.getElementById('minusAirport').style.display = 'none';
        }
  }
  else if(type == 'militaryCluster')
  {
       const div = document.getElementById('militaryLegend');
        div.innerHTML = '';
        if (minus === 'visible') {
          document.getElementById('minusMilitary').style.float = 'left';
        }
        if (minus === 'unvisible') {
          document.getElementById('minusMilitary').style.display = 'none';
        }
  }
  else if(type == 'institutionCluster')
  {
       const div = document.getElementById('institutionLegend');
        div.innerHTML = '';
        if (minus === 'visible') {
          document.getElementById('minusInstitution').style.float = 'left';
        }
        if (minus === 'unvisible') {
          document.getElementById('minusInstitution').style.display = 'none';
        }
  }
  else if (type == 'markerCluster'){
    const div = document.getElementById('markerLegend');
    div.innerHTML = '';
    if (minus === 'visible') {
      document.getElementById('minus').style.float = 'left';
    }
    if (minus === 'unvisible') {
      document.getElementById('minus').style.display = 'none';
    }
  }
  return legendClosed;
}


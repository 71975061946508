import { getInstance as getAuth0 } from './auth0-plugin.js';
import { getInstance as getCognito } from './cognito-plugin.js';
import { authMode } from '../../auth_config.json';

// TODO: re-evaluate whether we should simply hook into the Vue.prototype api instead of fetching the instance here
function getAuthService() {
  if (authMode == "COGNITO") {
    return getCognito();
  } else {
    return getAuth0();
  }
}

export const authenticationGuard = (to, from, next) => {
  const authService = getAuthService()

  const guardAction = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If the Auth0Plugin or CognitoPlugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch('isLoading', (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
<template>
  <div>
    <LogoutButton v-if="$auth.isAuthenticated" />
    <LoginButton v-else />
  </div>
</template>

<script>
  import LoginButton from './LoginButton.vue';
  import LogoutButton from './LogoutButton.vue';

  export default {
    name: 'AuthenticationButton',
    components: { LogoutButton, LoginButton },
  };
</script>
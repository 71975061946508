import {
    svgDropDown,
    deleteDivElements,
    deleteAndCreateDivElements,
    deleteCheckboxes
} from '../Utils/Map.Layers.Popups.Utils.js';
import namingConventions from '../../../../NamingConventions';
const amountFat = namingConventions().german.technical.popups.axis.amountFat;
const amountEve = namingConventions().german.technical.popups.axis.amountEve;

const Highcharts = require('highcharts');
require('highcharts/modules/streamgraph')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

const w = document.body.clientWidth - document.body.clientWidth*0.2;  // width histogram
const h = document.body.clientHeight;
const fontSize = document.body.clientHeight*0.02;
const marginNo = document.body.clientHeight*0.001;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
      };
const heightSpaceForDropDown = document.body.clientHeight*0.13;
const yStart = document.body.clientHeight*0.02;


function getChartPrediction(svg, config, preArray, binArray, eveArray, cur, hor, name){

    svg.attr('width', w + margin.left + margin.right)
       .attr('height', heightSpaceForDropDown)

    deleteAndCreateDivElements();

    svgDropDown(config);

    // Plotband-Construction

    var plotBands = [],
        plotBandsone = [],
        i = 0;

    while (i < preArray.length - 1) {
        if (binArray[i]['y'] == 1) {
        plotBands.push({
            from: preArray[i]['x'],
            to: preArray[i+1]['x'],
            color: 'rgba(255, 114, 114, 0.3)',
        });
        } else {
        plotBands.push({
            from: preArray[i]['x'],
            to: preArray[i + 1]['x'],
            color: 'transparent',
        });}
        i++;
    }

    for (var j of plotBands) {
    plotBandsone.push(j);
    }
    plotBandsone.push({
            id: 'mask-before',
            from: preArray[0]['x'],
            to: preArray[preArray.length - 1]['x'],
            color: 'transparent'
    });

    //Master-Detail-Chart
    let detailChart;

    function createDetail(masterChart) {

        var detailData = [],
            detailStart = preArray[0]['x'],
            edetailData = [],
            edetailStart = eveArray[0]['x'];
        masterChart.series[0].data.forEach(point => {
            if (point.x >= detailStart) {
                detailData.push(point.y);
            }
        });
        masterChart.series[1].data.forEach(point => {
            if (point.x >= edetailStart) {
                edetailData.push(point.y);
            }
        });

        let source= '<strong>Konflikteskalationswahrscheinlichkeit: </strong>' + '<strong>'+ cur + '</strong>'+
            '<strong> % - </strong>' + '<strong> Vorhergesagte Monate: </strong>' + '<strong>'+ hor + '</strong>'

        detailChart = Highcharts.chart('detail-container', {
            chart: {
                marginLeft: 70,
                marginRight: 85,
                height: 29  + '%'
            },
            credits: {
                enabled: false
            },
            title: {
                useHTML: true,
                text: `Historische Daten Konflikteskalation für ${name}`,
                align: 'center',
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                }
            },
            subtitle: {
                style: {
                    fontSize: '12px',
                    color: 'black'
                },
                text: source
            },
            xAxis: {
                minRange: 30 * 24 * 3600000 * 6, //6months
                offset: 1,
                crosshair: {
                    color:'black',
                    dashStyle: 'shortdot'
                },
                type: 'datetime',
                labels: {
                    style: {
                        color: 'black'
                    }
                },
                plotBands: plotBands
            },
            yAxis: [{
                gridLineWidth: 0.5,
                allowDecimals: false,
                title: {
                    text: amountFat,
                    style: {
                        color: 'rgba(17, 179, 8, 1)',
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        color: 'rgba(17, 179, 8, 1)',
                        fontWeight: 'bold',
                    }
                }
            },
            {
                gridLineWidth: 0.5,
                allowDecimals: false,
                title: {
                    text: amountEve,
                    style: {
                        color: 'rgba(0, 75, 147, 1)',
                        fontWeight: 'bold',
                    }
                },
                labels:{
                    style:{
                        color: 'rgba(0, 75, 147, 1)',
                        fontWeight: 'bold',
                    }
                },
                opposite: true
            }],
            tooltip: {
                borderColor:'black',
                shared: true
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                borderWidth: 0,
                itemMarginTop: -10,
                itemMarginBottom: -10
            },
            plotOptions: {
                series: {
                    lineWidth: 1.5,
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: 1.5
                        }
                    },
                    label: {
                        enabled: true,
                        connectorAllowed: true
                    },
                    turboThreshold: 0,
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: true,
                                radius: 3,
                            }
                        }
                    },
                    events: {
                        legendItemClick: function () {
                            var xAxis = this.chart.xAxis[0];
                            if (this.name === 'Eskalation') {
                                if (this.visible) {
                                    xAxis.update({
                                        plotBands: []
                                    });
                                } else {
                                    xAxis.update({
                                        plotBands: plotBands
                                    });
                                }
                            }
                            else if (this.name === amountEve) {
                                if (this.visible) {
                                    this.chart.yAxis[1].update({
                                        visible: false
                                    });
                                } else {
                                    this.chart.yAxis[1].update({
                                        visible: true
                                    });
                                }
                            } else {
                                if (this.visible) {
                                    this.chart.yAxis[0].update({
                                        visible: false
                                    });
                                } else {
                                    this.chart.yAxis[0].update({
                                        visible: true
                                    });
                                }
                            }
                        }
                    }
                }
            },
            series: [{
                name: amountFat,
                type: 'spline',
                color: 'rgba(17, 179, 8, 1)',
                pointStart: detailStart,
                data: detailData
                },
                {
                type:'spline',
                name: amountEve,
                yAxis: 1,
                pointStart: edetailStart,
                data: edetailData,
                color: 'rgba(0, 75, 147, 1)',
                },
                {
                name: 'fake series',
                type: 'spline',
                color: 'transparent',
                showInLegend: false,
                enableMouseTracking: false,
                pointStart: detailStart,
                data: detailData,
                },
                {
                type: 'scatter',
                marker: {
                    enabled:true,
                    symbol: 'square',
                    fillColor: 'rgba(255, 114, 114, 0.3)',
                    radius: 10,
                    },
                lineWidth: 0,
                name: 'Eskalation',
                color: 'transparent',
                }],
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: true,
                        menuItems: Highcharts.defaultOptions.exporting.buttons.contextButton.menuItems.slice(0,7),
                        x: -7,
                        y: -2
                    }
                }
            }
        });
    }

    function createMaster() {

        Highcharts.chart('master-container', {
            chart: {
                backgroundColor: null,
                marginLeft: 70,
                marginRight: 85,
                zoomType: 'x',
                height: 10 + '%', //+ '%',
                events: {
                    load: function() {
                        var detailData = preArray,
                            edetailData = eveArray;

                        detailChart.series[0].setData(detailData);
                        detailChart.series[1].setData(edetailData);
                        detailChart.series[2].setData(detailData);
                    },
                    selection: function (event) {
                        var extremesObject = event.xAxis[0],
                            min = extremesObject.min,
                            max = extremesObject.max,
                            detailData = [],
                            edetailData = [],
                            xAxis = this.xAxis[0];

                        this.series[0].data.forEach(point => {
                            if (point.x > min && point.x < max) {
                                detailData.push([point.x, point.y]);
                            }
                        });
                        this.series[1].data.forEach(point => {
                            if (point.x > min && point.x < max) {
                                edetailData.push([point.x, point.y]);
                            }
                        });

                        xAxis.removePlotBand('mask-before');
                        xAxis.addPlotBand({
                            id: 'mask-before',
                            from: preArray[0]['x'],
                            to: min,
                            color: 'rgba(0, 0, 0, 0.1)'
                        });
                        xAxis.removePlotBand('mask-after');
                        xAxis.addPlotBand({
                            id: 'mask-after',
                            from: max,
                            to: preArray[preArray.length - 1]['x'],
                            color: 'rgba(0, 0, 0, 0.1)'
                        });

                        detailChart.series[0].setData(detailData);
                        detailChart.series[1].setData(edetailData);
                        detailChart.series[2].setData(detailData);

                        return false;
                    }
                }
            },
            title: {
                text: null
            },
            accessibility: {
                enabled: false
            },
            xAxis: {
                minRange: 30 * 24 * 3600000 * 6, //6 Months
                offset: 1,
                type: 'datetime',
                showLastTickLabel: true,
                plotBands: plotBandsone,
                title: {
                    text: 'Monat',
                    style: {
                        color: 'transparent',
                        fontSize: '0'
                    }

                },
                labels: {
                    style: {
                        color: 'black'
                    }
                },
            },
            yAxis: [{
                gridLineWidth: 0.5,
                title: {
                    text: null,
                    style: {
                        color: 'black'
                    }
                },
                labels: {
                    enabled:false
                },
            },
            {
                title: {
                    text: null,
                },
                opposite: true
            }],
            tooltip: {
                formatter: function () {
                    return false;
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    lineWidth: 1,
                    marker: {
                        enabled: false
                    },
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    enableMouseTracking: false
                }
            },
            series: [{
                type: 'spline',
                name: amountFat,
                pointStart: preArray[0]['x'],
                data: preArray,
                color: 'rgba(17, 179, 8, 1)',
                },
                {
                type:'spline',
                name: amountEve,
                pointStart: eveArray[0]['x'],
                data: eveArray,
                color: 'rgba(0, 75, 147, 1)',
                }],
            exporting: {
                buttons: {
                    contextButton: {
                        x: -7,
                        y: -5,
                        enabled: true,
                        menuItems: Highcharts.defaultOptions.exporting.buttons.contextButton.menuItems.slice(8,11)
                    },
                },
                csv: {
                    dateFormat: '%b %Y'
                },
            }
        }, masterChart => {
            createDetail(masterChart);
        });
    }

    const container = document.getElementById('divID');
    container.innerHTML += '<div id="detail-container"></div><div id="master-container"></div>';

    createMaster();
};

function getChartDescription(svg, config){

    svg.attr('width', w + margin.left + margin.right)
        .attr('height', heightSpaceForDropDown)

        deleteAndCreateDivElements();

        svgDropDown(config);

    Highcharts.chart('divID', {
        chart: {
            reflow: true,
            borderWidth: 0,
            height: 67 + '%',
            backgroundColor: 'white',
            events: {
                load: function () {
                    var ren = this.renderer

                    ren.label('Variable', 30, 60)
                        .css({
                            fontWeight: 'bold'
                        })
                        .add();
                    ren.label('Beschreibung', 150, 60)
                        .css({
                            fontWeight: 'bold'
                        })
                        .add();
                    ren.label(amountEve, 30, 90)
                        .attr({
                            fill: 'white',
                            stroke: 'rgba(0, 75, 147, 1)',
                            'stroke-width': 3,
                            padding: 5,
                            r: 5
                        })
                        .css({
                            color: 'black'
                        })
                        .add()
                        .shadow(true);
                    ren.label(amountFat, 30, 190)
                        .attr({
                            fill: 'white',
                            stroke: 'rgba(17, 179, 8, 1)',
                            'stroke-width': 3,
                            padding: 5,
                            r: 5
                        })
                        .css({
                            color: 'black',
                            width: '100px'
                        })
                        .add()
                        .shadow(true);
                    ren.label('Eskalation', 30, 290)
                            .attr({
                                fill: 'white',
                                stroke: 'rgba(255, 114, 114, 0.4)',
                                'stroke-width': 3,
                                padding: 5,
                                r: 5
                            })
                            .css({
                                color: 'black',
                                width: '100px'
                            })
                            .add()
                            .shadow(true);
                    ren.label('- Ereignistyp: Kampfhandlungen' + '</br>' +
                              '- Interaktionstypen:' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. Rebellengruppen' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. politische Milizen' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. ethnische Milizen' + '</br>'
                              ,150, 90)
                            .attr({
                                fill: 'white',
                                stroke: 'rgba(0, 75, 147, 1)',
                                'stroke-width': 3,
                                padding: 5,
                                r: 5
                            })
                            .css({
                                color: 'black'
                            })
                            .add()
                            .shadow(true);
                    ren.label('- Ereignistyp: Kampfhandlungen' + '</br>' +
                              '- Interaktionstypen:' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. Rebellengruppen' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. politische Milizen' + '</br>' +
                              '&nbsp;&nbsp;&nbsp; - Staatlicher Akteur vs. ethnische Milizen' + '</br>'
                              ,150, 190)
                            .attr({
                                fill: 'white',
                                stroke: 'rgba(17, 179, 8, 1)',
                                'stroke-width': 3,
                                padding: 5,
                                r: 5
                            })
                            .css({
                                color: 'black'
                            })
                            .add()
                            .shadow(true);
                    ren.label('Anzeige von "Eskalation" während eines gegebenen Monats, wenn:  </br>'+
                              '&nbsp;&nbsp;&nbsp;1. Die durchschnittliche Anzahl der Konflikttoten (wie oben definiert) aus den' +'</br>' +
                              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; nächsten 3 Monaten mindestens der Summe über die durchschnittliche'+'</br>' +
                              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Anzahl der Konflikttoten und 2 Standardabweichungen aus dem Zeitraum ' + '</br>' +
                              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; der letzten 24 Monate entspricht.' + '</br>' +
                              '&nbsp;&nbsp;&nbsp;2. Die durchschittliche Anzahl der Konflikttoten (wie oben definiert) aus den nächsten' +'</br>' +
                              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3 Monaten einen Wert von 5 erreicht oder übersteigt.' +'</br>' +
                              '&nbsp;&nbsp;&nbsp;3. Die Summe der Ereignisse (wie oben definiert) aus den nächsten 3 Monaten' +'</br>' +
                              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mindestens einen Wert von 3 ereicht.'
                              ,150, 290)
                            .attr({
                                fill: 'white',
                                stroke: 'rgba(255, 114, 114, 0.4)',
                                'stroke-width': 3,
                                padding: 5,
                                r: 5
                            })
                            .css({
                                color: 'black',
                            })
                            .add()
                            .shadow(true);
                }
            }
        },
        credits: {
                enabled: false
        },
        title: {
            text: 'Datenglossar',
            align: 'center',
            style: {
                color: 'black'
            }
        },
        subtitle: {
            text: 'Quelle: ACLED (2022)',
            align: 'center',
            style: {
                color: 'black'
            }
        },
        accessibility: {
            enabled: false
        },
        exporting: {
            buttons: {
                contextButton: {
                    x: -7,
                    y: -2,
                    enabled: true,
                    menuItems: Highcharts.defaultOptions.exporting.buttons.contextButton.menuItems.slice(0,7)
                },
            },
        },
        lang: {
            noData: null
        }
    });
}

export default function getPredictionChart(pre_Data, bin_Data, ev_Data, cur_Data, div, clickedPolygon) {

    const name = clickedPolygon['ADMIN'];
    let GID = clickedPolygon['level'];
    var members;

    if(GID == 'GID_0'){
        members = [{
        label: "Historische Daten",
        value: 1
        },
        {
        label: "Datenglossar",
        value: 2
        }];
    }

    if (Object.keys(bin_Data).length === 0){
        var w = window.open('','','width=300px,height=50px, top=150px, left=500px')
        w.document.write('Keine Daten !')
        w.focus()
        setTimeout(function() {w.close();}, 1000)
    }

    let cur = Object.values(cur_Data); //current Prediction data
    let hor = Object.keys(cur_Data); // current prediction horizon

    const yearsbin = Object.keys(bin_Data); //xData
    let databin = Object.values(bin_Data); //yData
    let binArray =[];

    for (var i in databin) {
        let binDict = {};
        binDict['x'] = new Date(yearsbin[i]).getTime();
        binDict['y'] = parseInt(databin[i]);
        binArray.push(binDict)
    }

    const yearspre = Object.keys(pre_Data); //xData
    let datapre = Object.values(pre_Data); //yData
    let preArray =[];

    for (var i in datapre){
        let preDict = {};
        preDict['x'] = new Date(yearspre[i]).getTime();
        preDict['y'] = parseInt(datapre[i]);
        preArray.push(preDict)
    }

    const yearseve = Object.keys(ev_Data); //xData
    let dataeve = Object.values(ev_Data); //yData
    let eveArray =[];

    for (var i in dataeve){
        let eveDict = {};
        eveDict['x'] = new Date(yearseve[i]).getTime();
        eveDict['y'] = Number(dataeve[i]);
        eveArray.push(eveDict)
    }

    deleteDivElements();
    deleteCheckboxes();

    let svg = div.append('svg')
        .attr('width', w + margin.left + margin.right)
        .attr('height', h + margin.top + margin.bottom)
        .attr('id', 'fitFunction');


    var config = {
            width: document.body.clientWidth*0.18,
            container: svg,
            members,
            fontSize: fontSize,
            color: "#333",
            fontFamily: "calibri",
            x: document.body.clientWidth*0.035,
            y: yStart,
            changeHandler: function (option) {
                svg.selectAll("*").remove();
                if (option.label == 'Historische Daten') {
                    getChartPrediction(svg, config, preArray, binArray, eveArray, cur, hor, name);
                }
                else {
                    getChartDescription(svg, config);
                }
            }
    };

    getChartPrediction(svg, config, preArray, binArray, eveArray, cur, hor, name);

    return svg.node()
}

<template>

    <b-sidebar  id="sidebar-3" title="Netzwerk" #default="{ hide }" width = "98%" no-header shadow >
        <b-row style="position:fixed; right:0;" >
          <b-col cols="11"/> <p> </p> </bcol>
          <b-col align-self="end" >
            <b-button @click="hide">
              <b-icon icon="x"/>
            </b-button>
          </b-col>
        </b-row>

      <b-row >

      <b-col cols="11">






        <!-- control -->
      <table id="info">
         <tr>
            <th>
                <label> Zeitraum: {{nodes_change[2]}} -  {{nodes_change[3]}} </label>
            </th>
            <th>
                <label> Ereignistypen: {{nodes_change[1]}} </label>
            </th>
            <th>
                <label> Akteursgruppen: {{nodes_change[0]}} </label>
            </th>
           <th>
                <label> Suche: {{nodes_change[4]}} </label>
            </th>
            <th>
                <label> Land: {{nodes_change[5]}} </label>
            </th>
           <th>
                <label> Konflikttote: {{nodes_change[6]}} </label>
            </th>
         </tr>
         </table>
         <table  id="info">
         <tr>
            <th>
                <label> Node Größe {{nodeSize }}% </label>
                <input type="range" min="1" max="50" step="1" v-model='nodeSize' />
              {{ options.nodeSize }}
            </th>
            <th>
                 <label>Größe: {{force}} % </label>
                 <input type="range" min="0" max="8000" step="10" v-model="force" />
              {{ options.force }}
            </th>
            <th>
                 <label>Breite: {{fX}} </label>
                 <input type="range" min="0" max="1" step=".01" v-model="fX" />  {{ options.fX }}
            </th>
            <th>
                 <label>Höhe: {{fY}} </label>
                 <input type="range" min="0" max="1" step=".01" v-model="fY" />  {{ options.fY }}
            </th>
         </tr>
         </table>
        <table  id="info">
         <tr>
            <th>
              <ul class="legend">
                  <li><span class="StaatlicherAkteur"></span> Staatlicher Akteur</li>
                  <li><span class="Rebellengruppen"></span> Rebellengruppen</li>
                  <li><span class="politischeMelizen"></span> politische Milizen</li>
                  <li><span class="ethnischeMelizen"></span> ethnische Milizen</li>
                  <li><span class="gewalttaetigeDemonstranten"></span> gewalttätige Demonstranten</li>
                  <li><span class="Demonstranten"></span> Demonstranten</li>
                  <li><span class="Zivilisten"></span> Zivilisten</li>
                  <li><span class="sonstigeKraefte"></span> sonstige Kräfte</li>
              </ul>
            </th>
         </tr>

         </table>


         <d3-network ref='net' :net-nodes="nodes" :net-links="links" :options="options"
                     @link-click='getLinkInfo' @node-click='getNodeInfo' />

          </b-col>

      </b-row>

     </b-sidebar>
</template>

<script>
import D3Network from 'vue-d3-network';
import $ from 'jquery';
import axios from 'axios';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { viewBounds } from './Map';
import worker from '../assets/js/worker';
import { url } from '../App.vue';

const Swal = require('sweetalert2');

let nodeList = [];
let nodeLinks = [];

let actor1List = [];
let actor2List = [];
let actorCategory = [];
let strengthList = [];

const COLOR1 = '#e3dfb8';
const COLOR2 = '#912323';
const COLOR3 = '#0b610c';
const COLOR4 = '#9bcc9c';
const COLOR5 = '#37409e';
const COLOR6 = '#a3a8cf';
const COLOR7 = '#631596';
const COLOR8 = '#d8b6de';

function makePopUp(data, type) {
  const text = `${"<div align='left'>"
            + "<span style='font-size:14px; font-family: Calibri, Helvetica, monospace, Arial, sans-serif'>"
            + '<div style="text-align: center;"><span style="font-size:16px;"> <strong>Details </strong></span></div>'
            + '<br><strong>'}${type}</strong>${data}`;
  Swal.fire({
    html: text,
    confirmButtonText: 'OK',
    confirmButtonColor: 'orange',
    background: 'white',
    position: 'top',
    opacity: '80%',
  });
}

export default {
  el: '#network',
  components: {
    D3Network,
  },
  data() {
    return {
      nodes: nodeList,
      links: nodeLinks,
      nodeSize: 11,
      fontSize: 14,
      force: 400,
      fX: 0.5,
      fY: 0.5,
      centerX: -100,
      centerY: 0,
      canvas: this.canvas

    };
  },

  computed: {
    nodes_change: {
      get() {
        return [this.$store.state.filter.interaction,
          this.$store.state.filter.event,
          this.$store.state.filter.startDate,
          this.$store.state.filter.endDate,
          this.$store.state.filter.search,
          this.$store.state.filter.country,
          this.$store.state.filter.deaths,
          this.$store.state.filter.actorName];
      },
    },
    options() {
      return {
        size: { w: 1400, h: 1000 },
        offset: { x: this.centerX, y: this.centerY },
        nodeSize: this.nodeSize,
        nodeLabels: true,
        linkLabels: false,
        fontSize: 1.25 * this.nodeSize,
        canvas: this.canvas,
        force: this.force,
        forces: {
          Center: true,
          X: 1 - this.fX,
          Y: 1 - this.fY,
        },
      };
    },
  },
  created() {
    this.$root.$refs.Network = this;
  },
  mounted() {
    $('#sidebar-3').css(
      {
        top: '0.1em',
        left: '0em',
        right: '0em',
        buttom: '0em',
        opacity: '100%',
        height: '100%',
        width: '100%',
        'font-family': 'Calibri, Helvetica, monospace, Arial, sans-serif',
        'font-size': '18px',
        border: '0.1em solid black',
        'close button': true,
      },
    );
  },
  methods: {
    getLinkInfo(event, link) {
      makePopUp(link.name, 'Anzahl der Interaktionen: ');
    },
    getNodeInfo(event, node) {
      makePopUp(JSON.stringify(node.name), 'Akteur: ');
    },

    async getJSON() {
      worker.send({ progress: 0 });
      if (nodeList.length > 0) { nodeList = []; }
      if (nodeLinks.length > 0) { nodeLinks = []; }
      const form = this.$store.state.filter;
      const db = this.$store.state.db;
      const accessToken = await this.$auth.getTokenSilently();
      axios.post(url + 'network/', { form, viewBounds, db }, {headers: {
        authorization: `Bearer ${accessToken}`}
      })
      .then((response) => {
          actor1List = Object.values(response.data.actor1);
          actor2List = Object.values(response.data.actor2);
          strengthList = Object.values(response.data.strength);
          const ACTORS_UNIQUE_LIST = Object.values(response.data.unique_actors);

          actorCategory = Object.values(response.data.actor_category);

          // set initial object size
          let forceStart = 8000 - 10 * ACTORS_UNIQUE_LIST.length;
          if (forceStart <= 150) { forceStart = 150; }
          if (forceStart >= 7850) { this.centerY = -50; }
          if (forceStart >= 7900) { this.centerY = -100; }
          if (forceStart >= 7950) { this.centerY = -300; }
          this.force = forceStart;

          let lineWidth;
          let no1;
          let no2;
          let strength;
          const maxWidth = Math.max.apply(null, strengthList);
          let node1ID;
          let node2ID;
          let color;

          // worker.send({ progress: 20});

          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in ACTORS_UNIQUE_LIST) {
            // set nodes
            if (actorCategory[i] === 1) { color = COLOR1; }
            if (actorCategory[i] === 2) { color = COLOR2; }
            if (actorCategory[i] === 3) { color = COLOR3; }
            if (actorCategory[i] === 4) { color = COLOR4; }
            if (actorCategory[i] === 5) { color = COLOR5; }
            if (actorCategory[i] === 6) { color = COLOR6; }
            if (actorCategory[i] === 7) { color = COLOR7; }
            if (actorCategory[i] === 8) { color = COLOR8; }

            nodeList.push({
              id: node1ID, name: ACTORS_UNIQUE_LIST[i], _color: color,
            });
          }

          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const j in actor1List) {
            // set links
            no1 = ACTORS_UNIQUE_LIST.indexOf(actor1List[j]); // index of actor 1 in actors_unique_list
            no2 = ACTORS_UNIQUE_LIST.indexOf(actor2List[j]); // index of actor 2 in actors_unique_list

            // set line width
            strength = strengthList[j];
            if (strength > 1) { lineWidth = 1 + (5 * strength) / maxWidth; } else { lineWidth = 1; }

            node1ID = no1;
            node2ID = no2;
            nodeLinks.push({
              sid: node1ID,
              tid: node2ID,
              _svgAttrs: { 'stroke-width': lineWidth, opacity: 1 },
              name: strength,
            });
          }

          this.nodes = nodeList;
          this.links = nodeLinks;
          worker.send({ progress: 100 });
        });
      worker.send({ progress: 50 });
    },
  },
};
</script>
<style src="vue-d3-network/dist/vue-d3-network.css"></style>
<style scoped>
@import './../assets/css/colors.css';

.btn {
    border:0.1em solid black !important;
    margin:0 0.3em 0.3em 0 !important;
    background-color: var(--primary) !important;
    color: var(--text) !important;
    border-radius: 5px;
    width: 3em;
    hight: 1em;
    right: 0em;
    top: 0em;
}

.btn:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.8) !important;
}
.btn:focus {
    box-shadow: none;
}

label,button{
  display: block;
}
label.in{
  display: inline;
}
#info {
  width: 100%;
  align: left;
  margin-left: 0em;
  font-size: 12px;

}

#info td, #info th {
  border: 1px solid var(--text);
  padding: 1em;
}

#info th {
  padding: 1em;
  text-align: center;
  background-color: White;
  color: var(--text);
}

/* basic positioning */
.legend { list-style: none; }
.legend li {font-size: 12px;  float: left; background-color: White; color: var(--text); }
.legend span { border: 0.5px solid #ccc; border-radius: 8px; float: left; width: 1em; height: 1em; margin: 1em; }
/* your colors */
.legend .StaatlicherAkteur { background-color: #e3dfb8; }
.legend .Rebellengruppen { background-color: #912323; }
.legend .politischeMelizen { background-color: #0b610c; }
.legend .ethnischeMelizen { background-color: #9bcc9c; }
.legend .gewalttaetigeDemonstranten { background-color: #37409e; }
.legend .Demonstranten { background-color: #a3a8cf; }
.legend .Zivilisten { background-color: #631596; }
.legend .sonstigeKraefte { background-color: #d8b6de; }

</style>

<template>
  <keep-alive>
  <a-tree-select
    style="width: 100%"
    :tree-data="eventOptions"
    treeNodeFilterProp="title"
    tree-checkable
    v-model="event"
    :show-checked-strategy="SHOW_PARENT"
    search-placeholder="Auswahl"/>
    </keep-alive>
</template>
<script>
import axios from 'axios';
import { TreeSelect } from 'ant-design-vue';
import { url } from '../App.vue';

const { SHOW_PARENT } = TreeSelect;
export default {
  name: 'EventFilter',
  data() {
    return {
      SHOW_PARENT,
      tabs: [],
      visible: true,
      eventPuffer: this.eventInput,
      eventOptions: [],
      size: 12,
      filterSize: 12,
      // filter: this.filterInput,
    };
  },
  async mounted() {
    const accessToken = await this.$auth.getTokenSilently();
    await axios.get(url + 'subtypes/', {
      headers: {
        authorization: `Bearer ${accessToken}`
      },
      params: {
        db: this.$store.state.db,
      },
    })
      .then((response) => {
        this.eventOptions = response.data;
      }).catch();
  },
  props: {
    eventInput: {
      required: true,
      type: Array,
    },
  },
  computed: {
    event: {
      get() {
        return this.eventPuffer;
      },
      set(value) {
        this.$emit('update:eventInput', value);
      },
    },
  },
  watch: {
    '$store.state.db': function () {
      this.eventPuffer = [];
      this.eventOptions = [];
      this.getSelectOptionEvent(this.$store.state.db);
    },
    eventInput: {
      deep: true,
      handler(value) {
        this.eventPuffer = value;
      },
    },
  },
  methods: {
    async getSelectOptionEvent(db) {
      const accessToken = await this.$auth.getTokenSilently();
      await axios.get(url + 'subtypes/', {
        headers: {
          authorization: `Bearer ${accessToken}`
        },
        params: {
          db,
        },
      })
        .then((response) => {
          this.eventOptions = response.data;
          this.eventPuffer = ['Alle'];
          this.$emit('update:eventInput', this.eventPuffer);
        }).catch();
    },

  },
};
</script>

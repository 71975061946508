/*eslint-disable*/
import {deleteAndCreateDivElements, deleteCheckboxes} from '../Utils/Map.Layers.Popups.Utils.js';


const d3 = require('d3');
const Highcharts = require('highcharts');

const width = document.body.clientWidth - document.body.clientWidth*0.2; // width histogram
let height = document.body.clientHeight - document.body.clientHeight*0.7;
const marginNo = document.body.clientHeight*0.001;
const fontSize = document.body.clientHeight*0.02;
const margin = {
    left: marginNo, right: marginNo, top: marginNo, bottom: marginNo,
  };
  const colorFill = '#7777BB';
  const hoverColor = '#0000ff';
  const pressedColor = '#000077';
  let newYear;



function getPopupTimeButtons(svg, g, countryName, data) {
  const bWidth = document.body.clientWidth*0.05;
  const bHeight = document.body.clientHeight*0.04;
  const bSpace = document.body.clientHeight*0.007;
  const x0 = 0;
  const y0 = document.body.clientHeight*0.1;

  const allButtons = svg.append('g').attr('id', 'allButtons');
  const labels = ['2000', '2010', '2020'];
  const buttonGroups = allButtons.selectAll('g.button')
    .data(labels)
    .enter()
    .append('g')
    .attr('class', 'button')
    .style('cursor', 'pointer')
    .on('click', function (d, i) {
      updateButtonColors(d3.select(this), d3.select(this.parentNode));
      newYear = i;
      let dataReliList = getDataSet(data, newYear)[0];
      let dataReliRedList = getDataSet(data, newYear)[1];
      getPopupHistogramm(countryName, newYear, dataReliList, dataReliRedList);

    })
    .on('mouseover', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', hoverColor);
      }
    })
    .on('mouseout', function () {
      if (d3.select(this).select('rect').attr('fill') != pressedColor) {
        d3.select(this)
          .select('rect')
          .attr('fill', colorFill);
      }
    });

  buttonGroups.append('rect')
    .attr('class', 'buttonRect')
    .attr('width', bWidth)
    .attr('height', bHeight)
    .attr('x', (d, i) => {
      let xPos = x0 + (bWidth + bSpace) * i;
      return xPos;
    })
    .attr('y', (d, i) => {
      return y0;
    })
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('fill', colorFill);

  buttonGroups.append('text')
    .attr('class', 'buttonText')
      .attr('font-size', fontSize)
    .attr('x', (d, i) => {
      let xPos = x0 + (bWidth + bSpace) * i + bWidth / 2;
      return xPos;
    })
    .attr('y', (d, i) => {
      return y0 + bHeight / 2;
    })
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('fill', 'white')
    .text((d) => d);

  function updateButtonColors(button, parent) {
    parent.selectAll('rect')
      .attr('fill', colorFill);

    button.select('rect')
      .attr('fill', pressedColor);
  }

  return [svg, newYear];
}

function getPopupHistogramm(countryName, newYear, dataReliList, dataReliRedList){
    // Create the chart
Highcharts.chart('divID', {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Aufteilung in Religionen für ' + countryName + ', ' + newYear
  },
  subtitle: {
    text: 'Quelle: Todd M. Johnson and Brian J. Grim, eds. World Religion Database (Leiden/Boston: Brill, 2022)'
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  navigation: {
                buttonOptions: {
                    horizontalAlign: 'bottom',
                    x: -20
                }
            },
  xAxis: {
    title: {
      text: 'Religionen'
    },
    type: 'category'
  },
  yAxis: {
    title: {
      text: 'Anteil der Religionen in %'
    }

  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      }
    }
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: '<span style="color:black">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
    backgroundColor: {
      linearGradient: [0, 0, 0, 60],
      stops: [
          [0, '#FFFFFF'],
        [1, '#E0E0E0']
      ]
    },
    borderWidth: 1,
    borderColor: '#AAA',
  },

  series: [
    {
      name: "Anteil der Religionen in %",
      colorByPoint: true,
      data: dataReliList
    }
  ],
  drilldown: {
    series: dataReliRedList
  }
});
}

function dynamicSort(property) {
    let sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function getDataSet(data, newYear){

  let dataReliList = [];
  let dataReliDrillDownList = [];
  let data_year = data[newYear];

  for (const key in data_year){
    let drillDownDict = {};
    let yValue = 0;
    if (typeof data_year[key] == 'object'){
      let data_sub_dict = data_year[key];
      drillDownDict['id'] = key;
      let drillDownDataList = [];
      for (const subKey in data_sub_dict){
        yValue += parseFloat(data_sub_dict[subKey])
        let dataList = [];
        dataList.push(subKey);
        dataList.push(parseFloat(data_sub_dict[subKey]));
        drillDownDataList.push(dataList);
      }
      drillDownDataList = drillDownDataList.sort(function(a,b){return a[1] - b[1];}).reverse();
      drillDownDict['data'] = drillDownDataList;
    }
    else {
      yValue = parseFloat(data_year[key]);
    }
    let dataReliDict = {
      name: key,
      y: Math.round(yValue * 100) / 100,
      drilldown: key,
      //color: 'green'
    };
    dataReliList.push(dataReliDict)
    dataReliDrillDownList.push(drillDownDict);
  }
  dataReliList = dataReliList.sort(dynamicSort("y")).reverse();

  return [dataReliList, dataReliDrillDownList];
}

export default function getReligionHistogram(data, div, dictInfo) {

  const countryName = dictInfo['countryName'];
  newYear = 2020;

  let dataReliList = getDataSet(data, newYear)[0];
  let dataReliRedList = getDataSet(data, newYear)[1];
  
  let svg = div.append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .attr("id", 'fitFunction')

  let g = svg.append('g')
    .attr('transform', `translate(${[margin.left, margin.top]})`);

  deleteAndCreateDivElements();
  deleteCheckboxes();

  svg = getPopupTimeButtons(svg, g, countryName, data)[0];
  newYear = getPopupTimeButtons(svg, g, countryName, data)[1];

  getPopupHistogramm(countryName, newYear, dataReliList, dataReliRedList);

  return div.node();
}
